import React from "react";
import './NovelRegistModal.css';

const NovelRegistModal = () => {

    return (
        <div className="NovelRegist_Modal_dal">
            <h3 className="NovelRegist_Modal_title">
                웹 소설 작품
            </h3>
            <div className="NovelRegist_Modal_context">
                작품명은 60자를 초과할 수 없습니다.
            </div>
        </div>
    )
}

export default NovelRegistModal;