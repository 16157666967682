import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAnother, updateAnother } from "../../api/news/another";
import { getNewsByNids } from "../../api/news/newsBriefing";
import { TableHeader, TableRow } from "../../style/layout";
import Button from "../../common/Button";
import Check from "../../common/Check";
import Detail from "./Detail";
import MenuIcon from '@material-ui/icons/Menu';
import {  SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import News from "../../common/search/News";
import { MessageModalFlag } from '../../slice/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from "../../modal/Message";

const MenuToggle = styled.div`
    display: flex;
    align-items: center;
`;

const Body = () => {

    const [size, setSize] = useState(1);// eslint-disable-line no-unused-vars
    const [page_no, setPage_no] = useState(1);// eslint-disable-line no-unused-vars
    const [searchOpen, setSearchOpen] = useState(false);
    const [anotherList, setAnotherList] = useState();
    const [anotherOpen, setAnotherOpen] = useState(false);
    const [anotherData, setAnotherData] = useState();
    const [delTempList, setDelTempList] = useState([]);
    const [mainData, setMainData] = useState();
    const MModal = useSelector((state) => state.modal.value.Message);
    const dispatch = useDispatch();
    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        getAnother('2021-07-01', moment().add(1, 'days').format('yyyy-MM-DD'), page_no, size)
            .then((response) => {
                const data = JSON.parse(response.data.results[0].sentiData).data
                const nidList = data.map(item => item.nid)
                getNewsByNids(nidList)
                    .then((response) => {
                        setAnotherList(response.data.news_list);
                    })
                setMainData(data);
            })
    }, [size, page_no])

    const handleDetailClose = (resData) => {
        if (resData && resData.nid) {
            const temp = mainData;
            const index = temp.findIndex(item => item.nid === resData.nid);
            const targetItem = temp[index];
            targetItem.today = {
                neutral: resData.neutral,
                positive: resData.positive,
                negative: resData.negative
            }
            targetItem.yesterday = {
                neutral: [],
                positive: [],
                negative: []
            }
            targetItem.two_days_ago = {
                neutral: [],
                positive: [],
                negative: []
            }
            temp[index] = targetItem;
            setMainData(temp);
        }
        setAnotherData();
        setAnotherOpen(false);
    }

    const arrayMove = (array, oldIndex, newIndex) => {
        let arr = [...(array ?? [])]
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newList = arrayMove(anotherList, oldIndex, newIndex);
        const mainList = arrayMove(mainData, oldIndex, newIndex);
        setAnotherList(newList);
        setMainData(mainList);
        // setNowData(nidListTemp);
    };
    const onAdd = (list) => {
        const temp = anotherList.concat(list);
        setAnotherList(temp);
        const mTemp = mainData;
        const template = {
            neutral: [],
            positive: [],
            negative: [],
        }
        list.map(item => {//eslint-disable-line
            const mainDataItem = {
                nid: item.nid,
                keyword: item.title,
                sentiment: 1,
                today: template,
                yesterday: template,
                two_days_ago: template,
            }
            mTemp.push(mainDataItem);
        })
        setMainData(mTemp);
    }

    const handleItemClick = (nid) => {
        const target = mainData.filter(mainItem => mainItem.nid === nid);
        var positive = null;
        var neutral = null;
        var negative = null;
        if (target[0]) {
            positive = target[0].today.positive.concat(target[0].yesterday.positive.concat(target[0].two_days_ago.positive));
            neutral = target[0].today.neutral.concat(target[0].yesterday.neutral.concat(target[0].two_days_ago.neutral));
            negative = target[0].today.negative.concat(target[0].yesterday.negative.concat(target[0].two_days_ago.negative));
        }
        setAnotherData({ nid: nid, positive: positive, neutral: neutral, negative: negative })
        setAnotherOpen(true);
    }

    const handleDelCheckChange = (e, nid) => {
        var temp = delTempList;
        if (e.target.checked === true) {
            temp.push(nid)
            setDelTempList(temp);
        } else if (e.target.checked === false) {
            temp = temp.filter(function (item) { return item !== nid })
            setDelTempList(temp);
        }
    }

    const handleDeleteClick = () => {
        var atemp = anotherList;
        var mtemp = mainData;
        delTempList.map((target) => {//eslint-disable-line
            atemp = atemp.filter(function (item) { return item.nid !== target })
            mtemp = mtemp.filter(function (item) { return item.nid !== target })
        })

        setAnotherList(atemp);
        setMainData(mtemp);
    }

    const SortableDrag = SortableHandle(() => (
        <MenuToggle>
            <MenuIcon />
        </MenuToggle>
    ));

    const SortableList = SortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    const SortableItem = SortableElement(({ item, num }) => {
        const target = mainData.filter(mainItem => mainItem.nid === item.nid);
        var posCount = 0;
        var neuCount = 0;
        var negCount = 0;
        if (target[0]) {
            posCount = target[0].today.positive.length + target[0].yesterday.positive.length + target[0].two_days_ago.positive.length
            neuCount = target[0].today.neutral.length + target[0].yesterday.neutral.length + target[0].two_days_ago.neutral.length
            negCount = target[0].today.negative.length + target[0].yesterday.negative.length + target[0].two_days_ago.negative.length
        }
        return (
            <TableRow >
                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check onChange={(e) => handleDelCheckChange(e, item.nid)} /></div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><SortableDrag /></div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={() => handleItemClick(item.nid)}>{item.nid}</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }} onClick={() => handleItemClick(item.nid)}>
                    <img src={item.img_urls} style={{ height: '42px' }} alt='news thumbnail' />
                </div>
                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} onClick={() => handleItemClick(item.nid)}>{item.title}</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={() => handleItemClick(item.nid)}>{item.med_nm}</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={() => handleItemClick(item.nid)}>{item.cat_nm}</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '40px' }} onClick={() => handleItemClick(item.nid)}>{posCount}</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '40px' }} onClick={() => handleItemClick(item.nid)}>{neuCount}</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '40px' }} onClick={() => handleItemClick(item.nid)}>{negCount}</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={() => handleItemClick(item.nid)}>추가자</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} onClick={() => handleItemClick(item.nid)}>뉴스등록일시</div>
            </TableRow>
        )
    });

    const handleRegistClick = () => {
        if (mainData.length < 10) {
            setMsgTitle("이슈 VS 이슈");
            setMessage(`이슈vs이슈의 개수가 10개 미만(${mainData.length})입니다.`);
            dispatch(MessageModalFlag(true));
            return;
        }
        const checkZero = mainData.map(mainItem => {//eslint-disable-line
            var posCount = 0;
            var negCount = 0;
            if (mainItem) {
                posCount = mainItem.today.positive.length + mainItem.yesterday.positive.length + mainItem.two_days_ago.positive.length
                negCount = mainItem.today.negative.length + mainItem.yesterday.negative.length + mainItem.two_days_ago.negative.length
            }
            if (posCount === 0 && negCount === 0) {
                return false;
            }
        });
        const falseC = checkZero.filter(item => item === false)
        if (falseC.length > 0) {
            setMsgTitle("이슈 VS 이슈");
            setMessage("같은의견과 다른의견이 등록되지 않은 뉴스가 있습니다.");
            dispatch(MessageModalFlag(true));
            return;
        }

        if (window.confirm("아직 정밀한 값의 체크는 구현하지 못했습니다. 이대로 정말 등록해도 되겠습니까? 되돌리기 위해선 개발팀에 연락부탁드립니다.")) {
            updateAnother(mainData)
                .then((response) => {
                    if (response.status === 200) {
                        setMsgTitle("이슈 VS 이슈");
                        setMessage("등록 성공하였습니다.");
                        dispatch(MessageModalFlag(true));
                    } else {
                        setMsgTitle("이슈 VS 이슈");
                        setMessage("등록 실패");
                        dispatch(MessageModalFlag(true));
                    }
                })
        }
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '40px' }}>
                <Button name='추가' sType='sm' onClick={(e) => setSearchOpen(true)} />&nbsp;
                <Button name='삭제' cType='b_line' sType='sm' onClick={handleDeleteClick} />
                <News open={searchOpen} close={(e) => setSearchOpen(false)} onAdd={onAdd} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(anotherList && anotherList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check /></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}>순서</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 아이디</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>이미지</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '40px' }}>긍정</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '40px' }}>중립</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '40px' }}>부정</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>추가자</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>뉴스등록일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            <SortableList useDragHandle onSortEnd={onSortEnd}>
                                {anotherList.map((item, index) => {
                                    return (
                                        <SortableItem index={index} key={`item-${index}`} item={item} num={index} />
                                    )
                                })
                                }
                            </SortableList>
                        </div>

                    </React.Fragment>
                }
                <div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '90px' }}>
                    <Button name='등록' sType='md' onClick={handleRegistClick} />
                </div>
                <Detail open={anotherOpen} data={anotherData} close={handleDetailClose} />
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Body;