import { Dialog } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Button from "../Button";
import Check from "../Check";
import TextField from "../TextField";
import Form from 'react-bootstrap/Form';
import { Collapse } from '@material-ui/core';
import { getMainCat, getNewsSearch, getMainMedia, getMedia } from "../../api/news/newsBriefing";
import React, { forwardRef } from "react";
import Paging from "../Paging";
import moment from "moment";
import DatePicker from "react-datepicker";
import NewsSearchModal from "../../modal/NewsSearch";
import { useDispatch, useSelector } from 'react-redux';
import { NewsSearchModalFlag } from '../../slice/modalSlice';

const NewsC = styled.div`
    width: 1084px;
    height: 943px;
    max-height: 943px;
    min-height: 943px;
    background:#ffffff;
    border-radius:10px;
    padding:30px 30px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    overflow:hidden;
    .popTitle {
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }
`;

const News = ({ open, close, onAdd }) => {

    const [catList, setCatList] = useState();

    const [show, setShow] = useState(true);
    const [totalCnt, setTotalCnt] = useState();
    const [newsList, setNewsList] = useState();
    const [from_dt, setFrom_dt] = useState();// eslint-disable-line no-unused-vars
    const [to_dt, setTo_dt] = useState();
    const [page_no, setPage_no] = useState(1);
    const [size, setSize] = useState(5);
    const [search_opt, setSearch_opt] = useState(0);
    const [keyword, setKeyword] = useState();
    const [inKeyword, setInKeyword] = useState();
    const [cat_cds, setCat_cds] = useState();
    const [medial_cds, setMedial_cds] = useState();
    const [mediaList, setMediaList] = useState();
    const [selectMediaList, setSelectMediaList] = useState('');
    const [mediaCdsList, setMediaCdsList] = useState('');
    const [checkList, setCheckList] = useState([]);
    const [contents, setContents] = useState()
    const ret = moment().format('yyyyMMDD')
    const data = ret.substr(0, 6);
    const now = new Date();
    const [startDay, setStartDay] = useState(data + "01")
    const [monthStartDate, setMonthStartDate] = useState(new Date(now.setMonth(now.getMonth())));
    const NModal = useSelector((state) => state.modal.value.NewsSearch);
    const [newsMessage, setNewsMessage] = useState('');// eslint-disable-line no-unused-vars
    const [analy, setAnaly] = useState();// eslint-disable-line no-unused-vars
    const dispatch = useDispatch();

    useEffect(() => {
        if (open === true) {
            setTotalCnt();
            setNewsList();
            setFrom_dt();
            setTo_dt();
            setTo_dt();
            setPage_no(1);
            setSize(5);
            setSearch_opt(0);
            setKeyword();
            setInKeyword();
            setCat_cds();
            setCheckList([])
            getMainCat()
                .then((response) => {
                    var targetList = [{ cat_cd: '', cat_nm: '전체' }].concat(response.data.category_grps)
                    setCatList(targetList);
                })
            getMedia()
                .then((response) => {
                    const mediaList = [{ grp_cd: '', grp_nm: '전체' }].concat(response.data.media_grps)
                    setMediaCdsList(mediaList);
                })
        }
    }, [open])

    useEffect(() => {
        const lastDate = new Date(startDay.substring(0, 4), startDay.substring(4, 6), 0);
        const toDt = startDay.substring(0, 6) + "" + lastDate.getDate();
        if (open === true) {
            getNewsSearch({
                from_dt: startDay,
                to_dt: toDt,
                page_no: page_no,
                size: size,
                sort: 1,
                search_opt: search_opt,
                keyword: keyword,
                cat_cd: cat_cds,
                grp_cd: selectMediaList
            }
            ).then((response) => {
                var arr = new Array([]);
                arr.splice(0, 1);
                var nidArr = new Array([]);
                nidArr.splice(0, 1);
                setTotalCnt(response.data.total_row);
                setNewsList(response.data.news_list);
                for (var i = 0; i < response.data.news_list.length; i++) {
                    nidArr.push(response.data.news_list[i].nid);
                }
                if (checkList.length) {
                    for (var j = 0; j < checkList.length; j++) {
                        arr.push(checkList[j].nid);
                    }
                    for (var k = 0; k < nidArr.length; k++) {
                        if (arr.indexOf(nidArr[k]) === -1) {
                            response.data.news_list[k].status = "";
                        } else {
                            response.data.news_list[k].status = "checked";
                        }
                    }
                }
            })
        }
    }, [open, startDay, to_dt, page_no, size, search_opt, keyword, cat_cds, selectMediaList])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getMainMedia(medial_cds)
            .then((response) => {
                const mediaList = [{ med_cd: '', med_nm: '전체' }].concat(response.data.medias);
                setSelectMediaList('');
                setMediaList(mediaList);
            })
    }, [medial_cds])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getMainMedia(medial_cds)
            .then((response) => {
                const mediaList = [{ med_cd: '', med_nm: '전체' }].concat(response.data.medias);
                setMediaList(mediaList);
            })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div
                style={{
                    width: "155px",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={onClick}
                ref={ref}
            >
                <div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
                <img
                    src={"/images/component/ic_calendar.png"}
                    style={{ width: "16.67px", height: "15px" }}
                    alt="calendar_icon"
                />
            </div>
        );
    });

    const handleKeywordKeydown = (e) => {
        e.keyCode === 13 && setKeyword(inKeyword);
    }

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
    }

    const handleRegistClick = () => {
        onAdd && onAdd(checkList);

        close();
    }

    const handleCloseClick = () => {
        setMedial_cds('');
        setSelectMediaList('');
        close();
    }

    const handleCheck = (e, item, index) => {
        var temp = checkList;
        if (e.target.checked === true) {
            temp.push(item)
            setCheckList(temp);
        } else if (e.target.checked === false) {
            const newArr = checkList.splice(index, 1);// eslint-disable-line no-unused-vars
            const newArr2 = checkList.slice();
            setCheckList(newArr2);
        }
    }

    const showNews = (contents) => {
        setContents(contents);
        dispatch(NewsSearchModalFlag(true));
    }

    const MonthStartSet = (date) => {
        const yy = String(date.getFullYear());
        const mm = String(date.getMonth() + 1).padStart(2, "0");
        setMonthStartDate(date);
        setStartDay(yy + "" + mm + "" + "01");//eslint-disable-line
    }

    return (
        <Dialog maxWidth={'lg'} onClose={close} open={open}>
            <NewsC>
                <span className='popTitle'>뉴스 검색</span>
                <div style={{ height: '40px', minHeight: '40px' }} />
                <div style={{ width: '100%' }}>
                    <SearchBar>
                        <div className='filter'>
                            <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트{totalCnt && `(${totalCnt}건)`}</div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField placeholder={`검색어를 입력해주세요`} onChange={(e) => setInKeyword(e.target.value)} onKeydown={handleKeywordKeydown} />&nbsp;
                                <Button cType='b_line' name='필터접기' onClick={(e) => { setShow(!show) }} />
                            </div>
                        </div>
                        <Collapse in={show}>
                            <div className='title'>상세필터</div>
                            <div style={{ display: 'flex' }}>
                                <div className='component'>
                                    <span>뉴스타입</span>
                                    {(catList && catList.length > 0) ?
                                        <Form.Select className='selectBox' defaultValue={cat_cds} onChange={(e, value) => setCat_cds(value)}>
                                            {catList.map((item, index) => {
                                                return <option key={index} value={item.cat_cd}>{item.cat_nm}</option>
                                            })}
                                        </Form.Select>
                                        :
                                        'Loding'
                                    }
                                </div>
                                <div className='component'>
                                    <span>검색일자</span>
                                    <DatePicker
                                        customInput={<DatePickerInput />}
                                        selected={monthStartDate}
                                        onChange={(date) => MonthStartSet(date)}
                                        dateFormat="yyyy-MM"
                                        showMonthYearPicker
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className='component'>
                                    <span>매체분류</span>
                                    {(mediaCdsList && mediaCdsList.length > 0) ?
                                        <Form.Select defaultValue={medial_cds} className='selectBox' onChange={(e, value) => setMedial_cds(value)}>
                                            {mediaCdsList.map((item, index) => {
                                                return <option key={index} value={item.grp_cd} >{item.grp_nm}</option>
                                            })}
                                        </Form.Select>
                                        :
                                        'Loding'
                                    }
                                </div>
                                <div className='component'>
                                    <span>매체사</span>
                                    {(mediaList && mediaList.length > 0) ?
                                        <Form.Select className='selectBox' defaultValue={selectMediaList} onChange={(e, value) => setSelectMediaList(value)}>
                                            {mediaList.map((item, index) => {
                                                return <option key={index} value={item.med_cd} >{item.med_nm}</option>
                                            })}
                                        </Form.Select>
                                        :
                                        'Loding'
                                    }
                                </div>
                            </div>
                            <div style={{ background: '#ffffff', width: '100%', height: '20px' }} />
                        </Collapse>
                    </SearchBar>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        {(newsList && newsList.length > 0) &&
                            <React.Fragment>
                                <TableHeader>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name='ALL' onChange={(e) => handleCheck()} /></div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스아이디</div>
                                    <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 타입</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>등록일시</div>
                                </TableHeader>
                                <div style={{ flexGrow: 1, maxHeight: show ? '252px' : '360px', overflow: 'auto' }}>
                                    {newsList.map((item, index) => {
                                        return (
                                            <TableRow key={index} >
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name={item.nid} check={item.status} onChange={(e) => handleCheck(e, item, index)} /></div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.nid}</div>
                                                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} onClick={(e) => showNews(item.contents)}>{item.title}</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.med_nm}</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.cat_nm}</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} onClick={(e) => showNews(item.contents)}>{item.news_dt}</div>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        {(totalCnt && totalCnt > 0) &&
                            <Paging count={totalCnt} pageSize={size} viewSize={10} onClick={handlePageClick} />
                        }
                        <Form.Select onChange={(e, value) => setSize(value)}>
                            <option value={5} >5개씩 보기</option>
                            <option value={10} >10개씩 보기</option>
                            <option value={15} >15개씩 보기</option>
                            <option value={20} >20개씩 보기</option>
                        </Form.Select>
                    </div>
                </div>
                <div style={{ width: '100%', height: '174px', maxHeight: '174px', minHeight: '174px' }}>
                    <div style={{ height: '20px' }} />
                    <div style={{
                        width: '100%', height: '82px', maxHeight: '82px', minHeight: '82px',
                        display: 'flex', alignItems: 'center',
                        borderTop: '1px solid #C6C6C6', borderBottom: '1px solid #C6C6C6'
                    }}>
                    </div>
                    <div style={{ height: '30px', maxHeight: '30px', minHeight: '30px' }} />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button name={`등록`} onClick={handleRegistClick} />&nbsp;
                        <Button cType={`cancel`} name={`취소`} onClick={handleCloseClick} />
                    </div>
                </div>
            </NewsC>
            {NModal &&
                <div className='NewsSearch_Modal_Container' onClick={() => dispatch(NewsSearchModalFlag(false))}>
                    <div className='NewsSearch_Modal'>
                        <NewsSearchModal message={contents} analy={[]} />
                    </div>
                </div>
            }
        </Dialog>
    )

}

export default News;