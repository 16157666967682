import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export const TwoBtnSubmit = (title, message) => {
    confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
            {
                label: "예",
                onClick: () => alert("Click Yes"),
            },
            {
                label: "아니요",
                onClick: () => alert("Click No"),
            },
        ],
    });
};

export const OneBtnSubmit = (title, message) => {
    confirmAlert({
        title: title,
        message: message,
        buttons: [
            {
                label: "확인",
                onClick: () => { },
            },
        ],
    });
};
