import { instance } from "..";

export const getSecossionList = async (data) => {
	const url = `/secossion`
	const response = await instance.get(
		url,
		data,
		{
			headers: {
				'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
			}
		}
	)
		.then((response) => {
			return response;
		}).catch(error => {
			return error.response;
		})
	return response;
}


export const getSecossionPV = async (data) => {
	const url = `/secossion/secossionPV`
	const response = await instance.get(
		url,
		data,
		{
			headers: {
				'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
			}
		}
	)
		.then((response) => {
			return response;
		}).catch(error => {
			return error.response;
		})
	return response;
}