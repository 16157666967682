import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import TextField from "../../common/TextField";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Paging from "../../common/Paging";
import { getNovel } from "../../api/novel/novel";

const Body = () => {

    const navigate = useNavigate();
    const [pageRows, setPageRows] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [realPageNo, setRealPageNo] = useState(1);

    const [searchList, setSearchList] = useState('1');
    const [searchText, setSearchText] = useState("");
    const [totalCount, setTotalCount] = useState();
    const [novelList, setNovelList] = useState();

    useEffect(() => {
        const data = { params: { "searchType": searchList, "searchValue": searchText, "size": pageRows, "page": page_no } };
        getNovel(data)
            .then((response) => {
                for (var i = 0; i < response.data.list.length; i++) {
                    if (response.data.list[i].status === false) {
                        response.data.list[i].statusNm = "종료"
                    } else if (response.data.list[i].status === true) {
                        response.data.list[i].statusNm = "라이브"
                    }
                    if (response.data.list[i].issuance === 0 || response.data.list[i].issuance === false) {
                        response.data.list[i].issuanceNm = "고정"
                    } else if (response.data.list[i].issuance === 1 || response.data.list[i].issuance === true) {
                        response.data.list[i].issuanceNm = "타임"
                    }
                    if (response.data.list[i].to_dt === null) {
                        response.data.list[i].to_dt = "없음"
                    }
                }
                setNovelList(response.data.list);
                setTotalCount(response.data.total);
            })
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPageNo(1);
        const data = { params: { "searchType": searchList, "searchValue": searchText, "size": pageRows, "page": 1 } };
        getNovel(data)
            .then((response) => {
                for (var i = 0; i < response.data.list.length; i++) {
                    if (response.data.list[i].status === false) {
                        response.data.list[i].statusNm = "종료"
                    } else if (response.data.list[i].status === true) {
                        response.data.list[i].statusNm = "라이브"
                    }
                    if (response.data.list[i].issuance === 0 || response.data.list[i].issuance === false) {
                        response.data.list[i].issuanceNm = "고정"
                    } else if (response.data.list[i].issuance === 1 || response.data.list[i].issuance === true) {
                        response.data.list[i].issuanceNm = "타임"
                    }
                    if (response.data.list[i].to_dt === null) {
                        response.data.list[i].to_dt = "없음"
                    }
                }
                setNovelList(response.data.list);
                setTotalCount(response.data.total);
            })
    }, [searchText, searchList, pageRows]);


    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPageNo(pageNum);
    }

    const handlePageRowsChange = (value) => {
        setPageRows(value)
    }


    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {totalCount &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCount}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select className="selectBox" defaultValue={searchList} onChange={(e) => setSearchList(e.target.value)}>
                            <option value='1' >제목</option>
                            <option value='2' >구분</option>
                            <option value='3' >공급사</option>
                            <option value='4' >작가명</option>
                        </Form.Select>&nbsp;
                        <TextField value={searchText || ""} placeholder={`검색어를 입력해주세요`} onChange={(e) => setSearchText(e.target.value)} />&nbsp;
                        <Form.Select className="selectBox" defaultValue={pageRows} onChange={(e) => handlePageRowsChange(e.target.value)}>
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                    </div>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(novelList && novelList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>순서</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>포스트번호</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>상태</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '36px' }}>구분</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>작가명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>공급사</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>적용시작일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>적용종료일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '400px' }}>작품 제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>조회수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>좋아요수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>평점</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>회차수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>등록일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>수정일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {novelList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.n_order}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.pid}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.statusNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '36px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.issuanceNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.writer}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.affiliates}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.from_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.to_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'left', width: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.read_cnt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.like_cnt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.rating}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.episode}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.reg_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }} onClick={(e) => navigate(`/operation/novelManagement/view/${item.nkey}`)}>{item.mod_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCount} pageSize={pageRows} viewSize={realPageNo} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;