import { createSlice } from '@reduxjs/toolkit';
export const pushSlice = createSlice({
    name: 'push',
    initialState: {
        value: {
            pushTitle: "", pushContents: "", pushTexturl: "", pushDate: "0", pushSendRange: "0", pushFromTime: "23:59", pushRepeatTime: "23:59", pushUseDate: "",
            multivalues: "", pushWeekPushType: "1234567", pushUrl: "2", dayPushType: "1", pushType: "1", pushInsert: false, insertFlag: false
        }
    },
    reducers: {
        DefaultPushData: (state) => {
            state.value.pushTitle = "";
            state.value.pushContents = "";
            state.value.pushTexturl = "";
            state.value.pushDate = "0";
            state.value.pushSendRange = 0;
            state.value.pushFromTime = "23:59";
            state.value.pushRepeatTime = "23:59";
            state.value.multivalues = [];
            state.value.pushWeekPushType = "1234567";
            state.value.dayPushType = "1";
            state.value.pushType = "1";
        },
        PushTitle: (state, action) => {
            state.value.pushTitle = action.payload;
        },
        PushContents: (state, action) => {
            state.value.pushContents = action.payload;
        },
        PushTexturl: (state, action) => {
            state.value.pushTexturl = action.payload;
        },
        PushDate: (state, action) => {
            state.value.pushDate = action.payload;
        },
        PushSendRange: (state, action) => {
            state.value.pushSendRange = action.payload;
        },
        PushFromTime: (state, action) => {
            state.value.pushFromTime = action.payload;
        },
        PushRepeatTime: (state, action) => {
            state.value.pushRepeatTime = action.payload;
        },
        PushUseDate: (state, action) => {
            state.value.pushUseDate = action.payload;
        },
        Multivalues: (state, action) => {
            state.value.multivalues = action.payload;
        },
        PushWeekPushType: (state, action) => {
            state.value.pushWeekPushType = action.payload;
        },
        DayPushType: (state, action) => {
            state.value.dayPushType = action.payload;
        },
        PushType: (state, action) => {
            state.value.pushType = action.payload;
        },
        PushInsert: (state, action) => {
            state.value.pushInsert = action.payload;
        },
        Flag: (state, action) => {
            state.value.insertFlag = action.payload;
        },

    },
});
export const { PushTitle, PushContents, PushTexturl, PushDate, PushSendRange, PushFromTime, PushRepeatTime, PushUseDate, Multivalues, PushWeekPushType, DayPushType, PushType, DefaultPushData, Flag, PushInsert } = pushSlice.actions;
export default pushSlice.reducer;
