import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { EnterInsert } from '../../../slice/enterSlice';
import { useDispatch } from 'react-redux';
const RegistTitle = ({ mode }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleRegist = () => {
        dispatch(EnterInsert(true));
    };

    return (
        <BodyTitle>
            <BodyTitleFont>엔터 {mode === "add" ? "등록" : "수정"}</BodyTitleFont>
            <div>
                <Button
                    name={mode === "add" ? "등록" : "수정"}
                    onClick={handleRegist}
                />
                &nbsp;
                <Button
                    cType={`cancel`}
                    name={`취소`}
                    onClick={
                        (e) =>
                            navigate("/operation/enter/")
                    }
                />
            </div>
        </BodyTitle>
    );
};

export default RegistTitle;
