import { instance, cdnuploadinstance } from "..";

export const getSplashList = async (page, categoryCd, size) => {
	let url = null;
	if (categoryCd) {
		url = `/splash?page=${page}&size=${size}&isDesc=true&categoryCd=${categoryCd}`;
	} else {
		url = `/splash?page=${page}&size=${size}&isDesc=true`;
	}

	const response = await instance.get(url, null, null).then((response) => {
		return response;
	});
	return response;
};

export const getSplashModify = async (post, itoId) => {
	const url = `/splash/${itoId}`;

	const response = await instance
		.put(url, post, null)
		.then((response) => {
			return response;
		})
		.catch((e) => {
		});
	return response;
};

export const getSplashRegist = async (post) => {
	const url = `/splash`;

	const response = await instance
		.post(url, post, null)
		.then((response) => {
			return response;
		})
		.catch((e) => {
		});
	return response;
};

export const getSplashInfoByItold = async (itoId) => {
	const url = `/splash/view?itoId=${itoId}`;
	const response = await instance.get(url, null, null).then((response) => {
		return response;
	});
	return response;
};

export const getUploadImageReturnUrl = async (file) => {
	const url = `/store/image/upload`;
	const formData = new FormData();
	formData.append("file", file);
	const response = await cdnuploadinstance
		.post(url, formData, null)
		.then((response) => {
			return response;
		});
	return response;
};
