import React from 'react';
import styled, { css } from 'styled-components';

const GreyLineStyled = styled.div`
    font-family: 'NS re';
    font-size: 14px;
    letter-spacing: -0.28px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    background-color: #00000019;

    height: ${({ height }) => (height ? height : '1px')}};
    ${({ box }) =>
        box &&
        css`
            box-sizing: border-box;
            padding: 14px 16px;
            height: 48px;
        `};
`;

const GreyLine = ({ box, height, children, ...rest }) => {
    return (
        <GreyLineStyled box={box} height={height} {...rest}>
            {children}
        </GreyLineStyled>
    );
};

export default GreyLine;
