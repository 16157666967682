
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../style/layout";
import Button from "../../common/Button";


const Title = () => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>웹소설 작품 관리</BodyTitleFont>
            <Button name={`웹소설 작품 등록`} width={'180px'} onClick={(e) => navigate('/operation/novel/add/')} />
        </BodyTitle>
    )
}

export default Title;