import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const TabsC = styled.div`
    background:#F5F5F5;
    display: flex;
    align-items: flex-end;
    height: 50px;
`;

const TabItemC = styled.div`
    transition: all 0.15s ease-in;
    width:142px;
    height: 42px;
    color: #A7A7A7;
    border: 1px solid #DDDDDD;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.index > 0 && 'border-left: none;'}
    ${props => props.index === 0 && 'border-top-left-radius: 4px;'}
    ${props => props.index === (props.length - 1) && 'border-top-right-radius: 4px;'}
    ${(props) => props.selected && selected}
`;

const selected = css`
    background : #ffffff;
    border-bottom: 1px solid #ffffff;
    color: #000000;
`;

const Tabs = ({ items, onClick }) => {

    const [status, setStatus] = useState();
    const updateStatus = (e, selectedIndex) => {
        const statusTemp = items.map((_item, index) => index === selectedIndex ? true : false);
        setStatus(statusTemp);
        onClick && onClick(e, selectedIndex);
    }

    useEffect(() => {
        updateStatus(null, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (e, index) => {
        updateStatus(e, index);
    }

    return (
        <TabsC>
            {(items && status) && items.map((item, index) => {
                return (
                    <TabItemC key={index} index={index} length={items.length} selected={status[index]} onClick={(e) => handleClick(e, index)}>
                        {item}
                    </TabItemC>
                )
            })}
            <div style={{ flexGrow: 1, borderBottom: '1px solid #DDDDDD' }} />
        </TabsC>
    )
}

export default Tabs;