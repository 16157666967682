import styled from "styled-components";
import { DetailTable } from "../../../style/layout";
import { useEffect, useState } from "react";
import React from "react";
import Label from "../../../common/Label";
import { getDetailPush } from "../../../api/push/push";

const BasicInfoC = styled.div`
`;
const DetailInfoC = styled.div``;

export const Text = styled.div`
    display: block;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 24px;
    padding-bottom: 50px;
    word-wrap: break-word;
`;

const ViewBody = ({ pseq }) => {

    const [pushDetail, setPushDetail] = useState();

    useEffect(() => {
        const data = pseq * 1
        getDetailPush(data)
            .then((response) => {
                for (var i = 0; i < response.data.pushList.length; i++) {
                    if (response.data.pushList[i].sendTp === '0') {
                        response.data.pushList[i].sendNm = '즉시발송';
                    } else if (response.data.pushList[i].sendTp === '9') {
                        response.data.pushList[i].sendNm = '예약발송';
                    } else {
                        response.data.pushList[i].sendNm = '반복발송';
                    }
                }
                setPushDetail(response.data.pushList[0]);
            })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {pushDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>푸시 seq</th><td>{pushDetail.pseq}</td>
                                <th>url</th><td>{pushDetail.url}</td>
                            </tr>
                            <tr>
                                <th>발송수</th><td>{pushDetail.total}</td>
                                <th>발성성공수</th><td>{pushDetail.success}</td>
                                <th>발송대상</th><td>{pushDetail.desc}</td>
                            </tr>
                            <tr>
                                <th>방식</th><td>{pushDetail.sendNm}</td>
                                <th>발송일</th><td>{pushDetail.bookDt}</td>
                            </tr>
                            <tr>
                                <th>등록일시</th><td>{pushDetail.regDt}</td>
                                <th>수정일시</th><td>{pushDetail.regDt}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
            {pushDetail &&
                <DetailInfoC>
                    <Label type='title'>상세정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>제목</th><td>{pushDetail.title}</td>
                            </tr>
                            <tr>
                                <th>내용</th><td>{pushDetail.msg}</td>
                            </tr>
                            {pushDetail.image !== "" &&
                                <tr>
                                    <th>이미지</th><td><img src={pushDetail.image} alt="이미지"></img></td>
                                </tr>
                            }
                        </tbody>
                    </DetailTable>
                </DetailInfoC>
            }
        </React.Fragment>
    );
};

export default ViewBody;