import { createSlice } from '@reduxjs/toolkit';

export const bannerSlice = createSlice({
	name: 'banner',
	initialState: { value: { bannerInsert: false, insertFlag: false } },
	reducers: {
		BannerInsert: (state, action) => {
			state.value.bannerInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { BannerInsert, Flag } = bannerSlice.actions;
export default bannerSlice.reducer;
