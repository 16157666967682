import { createSlice } from '@reduxjs/toolkit';

export const bannerListSlice = createSlice({
	name: 'bannerList',
	initialState: { value: { bannerListInsert: false, insertFlag: false } },
	reducers: {
		BannerListInsert: (state, action) => {
			state.value.bannerListInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { BannerListInsert, Flag } = bannerListSlice.actions;
export default bannerListSlice.reducer;
