
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../../style/layout";
import Button from "../../../../common/Button";
import { useEffect, useState } from "react";
import { getList } from "../../../../api/cmspost/post";

const ViewTitle = ({ pid, rid }) => {
    const navigate = useNavigate();
    const [setbutton, setButton] = useState(0);
    const userInfo = localStorage.getItem('ROZ_ADMIN_USERINFO1');
    const temp = JSON.parse(userInfo);

    useEffect(() => {

        const data = { params: { "pid": pid } };
        getList(data).then((response) => {
            if (temp.uid * 1 !== response.data.data[0].uid * 1) {
                setButton("c");
            }
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BodyTitle>
            <BodyTitleFont>포스트 상세정보</BodyTitleFont>
            <div>
                {setbutton !== "c" &&
                    <Button name={`수정`} onClick={(e) => navigate(`/cms/postInsert/update/${pid}/${rid}`)} />
                }
                &nbsp;
                <Button cType={`cancel`} width={'150px'} name={`목록으로 이동`} onClick={(e) => navigate(`/cms/postInsert`)} />
            </div>
        </BodyTitle>
    )
}
export default ViewTitle;