import React from "react";
import { NewsSearchModalFlag } from '../slice/modalSlice';
import { useDispatch } from 'react-redux';
import Highlighter from "react-highlight-words";
import './NewsSearch.css';

const NewsSearchModal = ({ message, analy }) => {

    const dispatch = useDispatch();

    return (
        <div className="NewsSearch_Modal_dal" onClick={() => dispatch(NewsSearchModalFlag(false))}>
            <pre style={{ width: '950px', whiteSpace: 'pre-wrap' }}>
                <Highlighter
                    activeIndex={0}
                    caseSensitive={true}
                    highlightStyle={{ color: '#ED5255', background: '#cccccc' }}
                    searchWords={analy}
                    textToHighlight={message}
                />
            </pre>
        </div>
    )
}
export default NewsSearchModal;