import { historyLogger } from './historyLogger';

export const newsLinkAction = (item, authData, history, nativeApp) => {

    /**
     * 뉴스링크시 히스토리로거 호출 아래와같이 사용이유
     * history가 페이지 이동시 location 키값이 변경되면서 다른 히스토리 로케이션을 보내고있음
     * 원인 파익이 힘들어 임시적으로 뉴스링크 페이지 이동시 강제로 로케이션을 생성하여 처리
     */

    const location = JSON.parse(`{"pathname" : "/newsLink/${item.nid}/N", "search" : ""}`);
    historyLogger(location, authData);

    if (nativeApp) {
        const rid = authData && authData.rid && authData.rid !== '' ? authData.rid : null;
        if (rid !== null) {
            window.parent.postMessage({ name: 'NEWSLINK', item: item, rid: rid }, '*');
        } else {
            if (authData.rid === 0) {
                window.parent.postMessage({ name: 'NEWSLINK', item: item, rid: null }, '*');
            } else {
                const rid1 = authData && authData.data.rid && authData.data.rid !== '' ? authData.data.rid : null;
                window.parent.postMessage({ name: 'NEWSLINK', item: item, rid: rid1 }, '*');
            }
        }
    } else {
        if (!!window.ReactNativeWebView) {
            const rid = authData && authData.rid && authData.rid !== '' ? authData.rid : null;
            if (rid !== null) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'NEWSLINK', item: item, rid: rid }));
            } else {
                if (authData.rid === 0) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'NEWSLINK', item: item, rid: null }));
                } else {
                    const rid1 = authData && authData.data.rid && authData.data.rid !== '' ? authData.data.rid : null;
                    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'NEWSLINK', item: item, rid: rid1 }));
                }
            }
        } else {
            // window.open(item.url);
            if (item.url.indexOf('http://') > -1) {
                window.open(item.url);
            } else {
                history.push(`/newsLink/${item.nid}/N`, item);
            }
        }
    }
};
