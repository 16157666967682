import Button from "../../common/Button"
import { utils, writeFile } from 'xlsx';
import { useState } from "react";
import moment from "moment";
import OneDayCalendarPush from "../../common/OneDayCalendarPush";
import { getUserList } from "../../api/download/download";
import { useDispatch, useSelector } from 'react-redux';
import { MessageModalFlag } from '../../slice/modalSlice';
import MessageModal from "../../modal/Message";

const UserListDown = () => {
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months'));
    const MModal = useSelector((state) => state.modal.value.Message);
    const [message, setMessage] = useState();
    const [title, setTitle] = useState();
    const dispatch = useDispatch();

    const handleDownload = () => {
        setMessage('다운로드 중');
        setTitle('엑셀 다운로드');
        dispatch(MessageModalFlag(true));

        getUserList(moment(fromDate).format('yyyyMMDD') + '16000000', moment().format('yyyyMMDD') + '16000000')
            .then((res) => {
                const target = res.map((item) => {
                    return { user_id: item }
                })
                exportDataToXlsx(target, fromDate)
            })
    }

    const exportDataToXlsx = (data, fromDate) => {
        const worksheet = utils.json_to_sheet(data);
        const new_workbook = utils.book_new();
        utils.book_append_sheet(new_workbook, worksheet, 'UserList');
        writeFile(new_workbook, moment(fromDate).format('yyyyMMDD') + '_' + moment().format('yyyyMMDD') + '.xlsx');
        dispatch(MessageModalFlag(false));
    }

    return (
        <div>
            <OneDayCalendarPush setDate={setFromDate} splashDate={fromDate} />
            <br />
            16시를 기준으로 다운로드 합니다.
            <br />
            <Button sType='md' name='UserList DownLoad' width='120px' onClick={handleDownload} />
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal title={title} message={message} />
                    </div>
                </div>
            }
        </div>
    )
}

export default UserListDown;