import styled, { css } from 'styled-components';

export const RedButton = styled.button`
    width: 100%;
    height: 48px;
    margin: 6px 0 20px 0;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    background: ${(props) => props.theme.color.MainPrimary};
    font-family: RO me;
    font-size: 16px;
    color: #ffffff;
    &:active {
        background-color: #d94144;
    }
`;
export const LargeButton = styled.button`
    width: 63px;
    height: 32px;
    font-size: 14px;
    margin: 0 0 1.5em 0;
    border: none;
    box-sizing: border-box;
    background: #202020;
    color: #ffffff;
    border-radius: 4px;
    ${(props) => props.theme.fontSize.caption};
`;
export const ErrorMsg = styled.div`
    display: flex;
    padding: 0 0 0 0px;
    color: #ed5255;
    ${(props) => props.theme.fontSize.caption};
    height: 34px;
    align-items: flex-start;
`;
export const InputBox = styled.div`
    display: flex;
    flex-direction: column;

    .pwdInput {
        display: flex;
        position: relative;
        width: 100%;
        height: 48px;
        background-color: #ffffff;
        border-radius: 3px;
        margin-bottom: 4px;
        border: 1px solid #00000033;
        box-sizing: border-box;
        padding: 0 0 0 16px;
        ${(props) => props.theme.fontSize.body1};
        input {
            border: none;
            width: 80%;
            -webkit-appearance: none;
            ${(props) => props.theme.fontSize.body1};
            &::placeholder {
                color: #000000;
                ${(props) => props.theme.fontSize.body2};
                opacity: 0.3;
            }
            &:focus {
                outline: none;
            }
        }

        .view_icon {
            position: absolute;
            top: 13px;
            right: 16px;
            width: 24px;
            height: 24px;
            opacity: 0.3;
        }
    }
`;

export const InputLabel = styled.div`
    font-size: 12px;
`;
export const NormalInput = styled.input`
    height: 48px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 3px;
    margin-bottom: 4px;
    border: 1px solid #00000033;
    box-sizing: border-box;
    padding: 0 0 0 16px;
    ${(props) => props.theme.fontSize.body1};
    -webkit-appearance: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::placeholder {
        color: #000000;
        ${(props) => props.theme.fontSize.body2};
        opacity: 0.3;
    }
    &:focus {
        outline: none;
    }
`;
export const Subject_S = styled.span`
    opacity: 0.4;
    color: #000000;
    margin-left: 4px;
    letter-spacing: -0.48px;
    ${(props) => props.theme.fontSize.caption};
`;
export const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    width: 100%;
    height: 50px;
    font-weight: 600;
    align-items: center;
    justify-items: center;
    background-color: white;
    ${(props) => props.theme.fontSize.subTitle2};
    border-bottom: 2px solid lightgray;
`;
export const NonBorderHeader = styled.div`
    display: grid;
    grid-template-columns: ${(props) => (props.third ? '20px 5fr' : '1fr 5fr 1fr ')};
    width: 100%;
    height: 56px;
    font-weight: 600;
    align-items: center;
    justify-items: center;
    background-color: white;
    ${(props) => props.theme.fontSize.subTitle2};
    .back_btn {
        display: flex;
        background-color: white;
        border: none;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        cursor:pointer;
        -webkit-tap-highlight-color: transparent;
        &:hover {
            opacity: 0.7;
        }
    }
    .back {
        padding-left: 16px;
        background-color: white;
        border: none;
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
    .beta {
        ${(props) => props.theme.fontSize.captionBold};
        color: #3276b8;
        margin-left: 4px;
    }
    .icons {
        display: flex;
        align-items: center;
    }
    .end_btn {
        padding: 0;
        display: flex;
        width: 100%;
        background-color: white;
        border: none;
        justify-content: flex-end;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        &:hover {
            opacity: 0.7;
        }
    }
    .end {
        display: flex;
        width: 100%;
        background-color: white;
        border: none;
        padding-right: 16px;
        justify-content: flex-end;
        img {
            &:active {
                background-color: #cbcbcb;
            }
        }
    }
`;

export const Icons = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 30px;
    align-items: center;
    justify-content: center;
`;
export const PageStyle = styled.div`
    padding: 0 16px 0 16px;
`;

export const PageStyleCss = css`
    padding: 0 16px 0 16px;
    overflow: hidden;
`;

export const Description = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1em;
    border-radius: 5px;
    font-size: 10px;
    height: 8.5vh;
    padding: 0.5em;
    background-color: lightgray;
    line-height: 15px;
    color: gray;

    .icons {
        font-size: 30px;
        margin: 0 10px 0 10px;
        color: black;
    }
`;
export const Subject = styled.div`
    ${(props) => props.theme.fontSize.caption};
    color: #000000;
    opacity: 0.7;
    letter-spacing: -0.48px;
    margin-bottom: 9px;
`;
export const SecondSubject = styled.div`
    ${(props) => props.theme.fontSize.caption};
    color: #000000;
    opacity: 0.7;
    margin: 0px 0 7px 0;
    letter-spacing: -0.48px;
`;

const fontSize = {
    mainTitle: css`
        font-family: 'NS bo';
        font-size: 24px;
    `,
    headLine1: css`
        font-family: 'NS Light';
        font-size: 22px;
    `,
    headLine2: css`
        font-family: 'NS Regular';
        font-size: 20px;
    `,
    subTitle1: css`
        font-family: 'NS bo';
        font-size: 20px;
    `,
    subTitle2: css`
        font-family: 'NS bo';
        font-size: 18px;
    `,
    subTitle2regular: css`
        font-family: 'NS Regular';
        font-size: 18px;
    `,
    subTitle3: css`
        font-family: 'NS bo';
        font-size: 16px;
    `,
    body1: css`
        font-family: 'NS Regular';
        font-size: 16px;
    `,
    body2: css`
        font-family: 'NS Regular';
        font-size: 14px;
    `,
    body2bold: css`
        font-family: 'NS bo';
        font-size: 14px;
    `,
    body3: css`
        font-family: 'NS Regular';
        font-size: 13px;
    `,
    captionBold: css`
        font-family: 'NS bo';
        font-size: 12px;
    `,
    caption: css`
        font-family: 'NS Regular';
        font-size: 12px;
    `,
    small: css`
        font-family: 'NS Regular';
        font-size: 10px;
    `,
};
const theme = {
    color: {
        MainPrimary: '#ED5255',
        SubPrimary: '#7875C9',
        WhiteColor: '#FFFFFF',
        GrayColor: '#0000001A',
        DangerColor: '#D91717',
        LightGrayColor: '#F6F7FB',
        IconsColor: '#E2E2E2',
        ActiveColor: '#000000',
        BorderColor: '#0000004D',
    },
    buttonConstant: {
        ButtonBorder: '1px solid #000000',
        ButtonDisabled: '#9B9C9F',
        ButtonDisabledText: '#9B9C9F',
    },
    buttonStyle1: {
        ButtonDefault: '#FFFFFF',
        ButtonPress: '#FFFFFF',
        ButtonText: '#000000',
    },
    buttonStyle2: {
        ButtonDefault: '#ED5255',
        ButtonPress: '#D94144',
        ButtonText: '#FFFFFF',
    },
    buttonStyle3: {
        ButtonDefault: '#7875C9',
        ButtonPress: '#6865B1',
        ButtonText: '#FFFFFF',
    },
    buttonStyle4: {
        ButtonDefault: '#E2E2E2',
        ButtonPress: '#CBCBCB',
        ButtonText: '#000000',
    },
    fontSize,
};

export default theme;
