import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/InNewsList/Body";
import RegistBody from "../../components/InNewsList/regist/RegistBody";
import RegistTitle from "../../components/InNewsList/regist/RegistTitle";
import Title from "../../components/InNewsList/Title";
import ViewBody from "../../components/InNewsList/view/ViewBody";
import ViewTitle from "../../components/InNewsList/view/ViewTitle";
import InNewsList from "../../templates/inNewsList/InNewsList";

const InNewsListContainer = ({ page }) => {

    const { nid } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={nid && nid !== "" ? "modify" : "add"} nid={nid} />);
            setBody(<RegistBody mode={nid && nid !== "" ? "modify" : "add"} nid={nid} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle nid={nid} />);
            setBody(<ViewBody nid={nid} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, nid])

    return (
        <InNewsList
            title={title}
            body={body}
        />
    )
}

export default InNewsListContainer;