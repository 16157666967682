import React from "react";

const DashboardContainer = () => {

    return (
        <div>123</div>
    )
}

export default DashboardContainer;
