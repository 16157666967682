import React, { useEffect, useState } from "react";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Form from 'react-bootstrap/Form';
import { bannerSelect, bannerSelectBox } from "../../api/bannerList/bannerList";
import { useNavigate } from "react-router-dom";

const Body = () => {

    const [selectBox, setSelectBox] = useState();
    const [selectList, setSelectList] = useState();
    const [selectBlocation, setSelectBlocation] = useState();
    const navigate = useNavigate();
    const selectBoxList = (e) => {

        bannerSelectBox()
            .then((response) => {
                var arr = new Array([]);
                arr.splice(0, 1);
                for (var i = 0; i < response.data.length; i++) {
                    arr.push(response.data[i].b_location);
                }

                var newArr = [...new Set(arr)];
                var lastarr = new Array([]);
                lastarr.splice(0, 1);
                lastarr.push({ "b_location": "선택해주세요" });
                for (var j = 0; j < newArr.length; j++) {
                    lastarr.push({ "b_location": newArr[j] });
                }
                var flag = false;
                for (var k = 0; k < lastarr.length; k++) {
                    if (lastarr[k].b_location === selectBlocation) {
                        flag = true;
                    }
                }
                if (flag === false) {
                    setSelectBlocation("선택해주세요");
                }
                setSelectBox(lastarr);
            })
    }

    useEffect(() => {
        selectBoxList();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectBox = (e, value) => {
        const selectDate = e.target.value;
        const data = { params: { "b_location": selectDate } };
        bannerSelect(data)
            .then((response) => {
                setSelectBlocation(e.target.value);
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].is_use === 1 || response.data[i].is_use === 1) {
                        response.data[i].is_use_name = "사용";
                    } else if (response.data[i].is_use === 0 || response.data[i].is_use === 0) {
                        response.data[i].is_use_name = "미사용";
                    }
                    if (response.data[i].b_type === "0" || response.data[i].b_type === 1) {
                        response.data[i].b_type_name = "롤링";
                    } else if (response.data[i].b_type === "1" || response.data[i].b_type === 1) {
                        response.data[i].b_type_name = "리스트";
                    } else if (response.data[i].b_type === "2" || response.data[i].b_type === 2) {
                        response.data[i].b_type_name = "웹소설";
                    }
                }
                setSelectList(response.data);
            })
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter' style={{ display: 'flex' }}>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>그룹 리스트</div>
                    {(selectBox && selectBox.length > 0) ?
                        <Form.Select className="selectBox" defaultValue={selectBlocation} onChange={(e, value) => handleSelectBox(e, value)}>
                            {selectBox.map((item, index) => {
                                return <option key={index} value={item.b_location}>{item.b_location}</option>
                            })}
                        </Form.Select>
                        :
                        'Loding'
                    }
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(selectList && selectList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}>베너 리스트 경로</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}>배너 리스트 순서</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}>베너 리스트 SEQ</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}>베너 리스트 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}>베너 리스트 사용유무</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}>베너 리스트 시작일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}>베너 리스트 종료일</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {selectList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}
                                            onClick={(e) => navigate(`/operation/bannerList/view/${item.b_location}`)}>{item.b_location}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}
                                            onClick={(e) => navigate(`/operation/bannerList/view/${item.b_location}`)}>{item.b_order}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}
                                            onClick={(e) => navigate(`/operation/bannerList/view/${item.b_location}`)}>{item.b_seq}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}
                                            onClick={(e) => navigate(`/operation/bannerList/view/${item.b_location}`)}>{item.b_type_name}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}
                                            onClick={(e) => navigate(`/operation/bannerList/view/${item.b_location}`)}>{item.is_use_name}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '230px' }}
                                            onClick={(e) => navigate(`/operation/bannerList/view/${item.b_location}`)}>{item.from_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}
                                            onClick={(e) => navigate(`/operation/bannerList/view/${item.b_location}`)}>{item.to_dt}</div>
                                    </TableRow>
                                )
                            })}
                        </div>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;