import styled from "styled-components";

const TextFieldC = styled.div`
    display:flex;
    align-items: center;
    box-sizing: border-box;
`;

const InputC = styled.textarea`
    box-sizing: border-box;
    border:none;
    height:${(props) => props.height ? props.height : '130px'};
    width:${(props) => props.width ? props.width : '600px'};
    padding:12px 12px;
    border-radius: 4px;
    font-size: 14px;
    color: #9B9C9F;
    background: ${(props) => !props.disabled ? '#ffffff' : '#f0f0f0'};
    border:1px solid rgba(0, 0, 0, 0.1);
    resize:none;
    overflow-y:scroll;
    transition: all 0.15s ease-in;
    &:hover{
        border:1px solid ${(props) => (!props.disabled ? '#c0c0c0;' : 'rgba(0, 0, 0, 0.1)')};
    };
    &:focus{
        outline:none;
        color: #000000;
        border:1px solid #646464;
    };
    &::placeholder{
        font-size: 14px;
        color: #9B9C9F;
    }
`;

const TextArea = ({ value, width, height, placeholder, disabled, onChange, onKeydown }) => {
    return (
        <div style={{ display: 'inline-block' }}>
            <TextFieldC disabled={disabled} >
                <InputC
                    placeholder={placeholder}
                    value={value}
                    width={width}
                    height={height}
                    disabled={disabled}
                    onChange={onChange}
                    onKeyDown={onKeydown}
                />
            </TextFieldC>
        </div>
    )
}

export default TextArea;