import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginContainer from './containers/login/LoginContainer';
import MainContainer from './containers/main/MainContainer';
import DashboardContainer from './containers/dashboard/DashboardContainer';
import MemberUpdateContainer from './containers/member/MemberUpdateContainer';
import AuthorityContainer from './containers/authority/AuthorityContainer';
import NewsSearchTestContainer from './containers/newsSearch/NewsSearchTestContainer';
import ForMKTETC from './containers/download/ForMKT_ETC';
import NoticeContainer from './containers/notice/NoticeContainer';
import QnAContainer from './containers/qna/QnAContainer';
import SplashIntroContainer from './containers/splashIntro/SplashIntroContainer';
import PushContainer from './containers/push/PushContainer';
import BannerContainer from './containers/banner/BannerContainer';
import BannerListContainer from './containers/bannerList/BannerListContainer';
import InNewsContainer from './containers/inNews/InNewsContainer';
import InNewsListContainer from './containers/inNewsList/InNewsListContainer';
import SecessionControlContainer from './containers/secoession/SecessionControlContainer'
import HomeClickContainer from './containers/homeClick/HomeClickContainer';
import PostControlContainer from './containers/cms/post/PostControlContainer';
import NewsBriefingContainer from './containers/newsbriefing/NewsBriefingContainer';
import AnotherContainer from './containers/another/AnotherContainer';
import IssueContainer from './containers/issue/IssueContainer';
import NovelContainer from './containers/novel/NovelContainer';
import NovelManagementContainer from './containers/novelManagement/NovelManagementContainer';
import TermsContainer from './containers/terms/TermsContainer';
import EnterContainer from './containers/enter/EnterContainer';
import ManagementContainer from './containers/management/ManagementContainer';


function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    {/* 로그인 화면 */}
                    <Route path='/' element={<LoginContainer />} />

                    {/* 대시보드 */}
                    <Route path={'/dashboard'} element={<MainContainer navText='대시보드' targetComp={<DashboardContainer />} />} />

                    {/* 계정 설정 */}
                    <Route path={'/member/update'} element={<MainContainer navText='계정 설정' targetComp={<MemberUpdateContainer />} />} />

                    {/* 회원 정보 메인*/}
                    <Route path={'/member/authority'} element={<MainContainer navText='회원 및 권한 ' targetComp={<AuthorityContainer />} />} />
                    {/* 회원 정보 상세*/}
                    <Route path={'/member/authority/view/:uid'} element={<MainContainer navText='회원 및 권한 상세보기' targetComp={<AuthorityContainer page='view' />} />} />
                    {/* 회원 정보 등록*/}
                    <Route path={'/member/authority/add'} element={<MainContainer navText='회원 및 권한 등록' targetComp={<AuthorityContainer page='add' />} />} />
                    {/* 회원 정보 수정*/}
                    <Route path={'/member/authority/update/:uid'} element={<MainContainer navText='회원 및 권한 수정' targetComp={<AuthorityContainer page='add' />} />} />

                    {/* 뉴스 검색 테스트 */}
                    <Route exact path={'/searchTest'} element={<MainContainer navText=' 뉴스 검색 테스트' targetComp={<NewsSearchTestContainer />} />} />

                    {/* 기타 다운로드 */}
                    <Route exact path={'/forMKT'} element={<MainContainer navText=' 기타 다운로드' targetComp={<ForMKTETC />} />} />

                    {/* QnA 메인*/}
                    <Route exact path={'/operation/qnaIntro'} element={<MainContainer navText=' QnA 관리' targetComp={<QnAContainer />} />} />
                    {/* QnA 상세*/}
                    <Route exact path={'/operation/qnaIntro/view/:qna_id'} element={<MainContainer navText=' QnA 상세정보' targetComp={<QnAContainer page='view' />} />} />

                    {/* 공지 사항 메인*/}
                    <Route path={'/operation/notice'} element={<MainContainer navText='공지 사항' targetComp={<NoticeContainer />} />} />
                    {/* 공지 사항 상세*/}
                    <Route path={'/operation/notice/view/:notice_id'} element={<MainContainer navText='공지 사항 상세정보' targetComp={<NoticeContainer page='view' />} />} />
                    {/* 공지 사항 등록*/}
                    <Route path={'/operation/notice/add'} element={<MainContainer navText='공지 사항 등록' targetComp={<NoticeContainer page='add' />} />} />
                    {/* 공지 사항 수정*/}
                    <Route path={'/operation/notice/update/:notice_id'} element={<MainContainer navText='공지 사항 수정' targetComp={<NoticeContainer page='add' />} />} />

                    {/* 스플래시 인트로 메인 */}
                    <Route path={'/operation/splashIntro'} element={<MainContainer navText='스플레시 인트로' targetComp={<SplashIntroContainer />} />} />
                    {/* 스플래시 인트로 등록 */}
                    <Route path={'/operation/splashIntro/add'} element={<MainContainer navText='스플레시 인트로 등록' targetComp={<SplashIntroContainer page='add' />} />} />
                    {/* 스플래시 인트로 수정 */}
                    <Route path={'/operation/splashIntro/update/:itoId'} element={<MainContainer navText='스플레시 인트로 수정' targetComp={<SplashIntroContainer page='add' />} />} />
                    {/* 스플레시 인트로 상세 */}
                    <Route path={'/operation/splashIntro/view/:itoId'} element={<MainContainer navText='스플레시 인트로 상세정보' targetComp={<SplashIntroContainer page='view' />} />} />

                    {/* 푸시 */}
                    <Route path={'/operation/push'} element={<MainContainer navText='푸시' targetComp={<PushContainer />} />} />
                    {/* 푸시 등록*/}
                    <Route path={'/operation/push/add/'} element={<MainContainer navText='푸시 등록' targetComp={<PushContainer page='add' />} />} />
                    {/* 푸시 수정*/}
                    <Route path={'/operation/push/update/:pseq'} element={<MainContainer navText='푸시 등록' targetComp={<PushContainer page='add' />} />} />
                    {/* 푸시 상세*/}
                    <Route path={'/operation/push/view/:pseq'} element={<MainContainer navText='푸시 상세' targetComp={<PushContainer page='view' />} />} />

                    {/* 인뉴스 */}
                    <Route path={'/operation/inNews'} element={<MainContainer navText='인뉴스 리스트' targetComp={<InNewsContainer />} />} />
                    {/* 인뉴스 등록*/}
                    <Route path={'/operation/inNews/add/'} element={<MainContainer navText='인뉴스 리스트 등록' targetComp={<InNewsContainer page='add' />} />} />
                    {/* 인뉴스 수정*/}
                    <Route path={'/operation/inNews/update/:cid'} element={<MainContainer navText='인뉴스 리스트 등록' targetComp={<InNewsContainer page='add' />} />} />
                    {/* 인뉴스 상세*/}
                    <Route path={'/operation/inNews/view/:cid'} element={<MainContainer navText='인뉴스 리스트 상세' targetComp={<InNewsContainer page='view' />} />} />

                    {/* 인뉴스 리스트 */}
                    <Route path={'/operation/inNewsList'} element={<MainContainer navText='인뉴스 리스트' targetComp={<InNewsListContainer />} />} />
                    {/* 인뉴스 리스트 등록*/}
                    <Route path={'/operation/inNewsList/add/'} element={<MainContainer navText='인뉴스 리스트 등록' targetComp={<InNewsListContainer page='add' />} />} />
                    {/* 인뉴스 리스트 수정*/}
                    <Route path={'/operation/inNewsList/update/:nid'} element={<MainContainer navText='인뉴스 리스트 등록' targetComp={<InNewsListContainer page='add' />} />} />
                    {/* 인뉴스 리스트 상세*/}
                    <Route path={'/operation/inNewsList/view/:nid'} element={<MainContainer navText='인뉴스 리스트 상세' targetComp={<InNewsListContainer page='view' />} />} />

                    {/* 베너 */}
                    <Route path={'/operation/banner'} element={<MainContainer navText='베너' targetComp={<BannerContainer />} />} />
                    {/* 베너 등록*/}
                    <Route path={'/operation/banner/add/'} element={<MainContainer navText='베너 등록' targetComp={<BannerContainer page='add' />} />} />
                    {/* 베너 수정*/}
                    <Route path={'/operation/banner/update/:bSeq'} element={<MainContainer navText='베너 등록' targetComp={<BannerContainer page='add' />} />} />
                    {/* 베너 상세*/}
                    <Route path={'/operation/banner/view/:bSeq'} element={<MainContainer navText='베너 상세' targetComp={<BannerContainer page='view' />} />} />

                    {/* 베너리스트 */}
                    <Route path={'/operation/bannerList'} element={<MainContainer navText='베너리스트' targetComp={<BannerListContainer />} />} />
                    {/* 베너리스트 등록*/}
                    <Route path={'/operation/bannerList/add/'} element={<MainContainer navText='베너리스트 등록' targetComp={<BannerListContainer page='add' />} />} />
                    {/* 베너리스트 수정*/}
                    <Route path={'/operation/bannerList/update/:bLocation'} element={<MainContainer navText='베너리스트 등록' targetComp={<BannerListContainer page='add' />} />} />
                    {/* 베너리스트 상세*/}
                    <Route path={'/operation/bannerList/view/:bLocation'} element={<MainContainer navText='베너리스트 상세' targetComp={<BannerListContainer page='view' />} />} />

                    {/* 탈퇴 내역 */}
                    <Route path={'/operation/secession'} element={<MainContainer navText='탈퇴 관리' targetComp={<SecessionControlContainer />} />} />
                    {/* 탈퇴 내역 상세 */}
                    <Route path={'/operation/secession/:regId'} element={<MainContainer navText='탈퇴 관리' targetComp={<SecessionControlContainer page='view' />} />} />

                    {/* 홈클릭 */}
                    <Route path={'/statistics/homeClick'} element={<MainContainer navText='홈클릭' targetComp={<HomeClickContainer />} />} />

                    {/* cmspost */}
                    <Route path={'/cms/postInsert'} element={<MainContainer navText='CMS POST' targetComp={<PostControlContainer />} />} />
                    {/* cmspost 상세 */}
                    <Route path={'/cms/postInsert/view/:pid/:rid'} element={<MainContainer navText='CMS POST' targetComp={<PostControlContainer page='view' />} />} />
                    {/* cmspost 수정 */}
                    <Route path={'/cms/postInsert/update/:pid/:rid'} element={<MainContainer navText='CMS POST' targetComp={<PostControlContainer page='add' />} />} />
                    {/* cmspost 등록 */}
                    <Route path={'/cms/postInsert/add/'} element={<MainContainer navText='CMS POST' targetComp={<PostControlContainer page='add' />} />} />

                    {/* newsBriefing */}
                    <Route path={'/operation/newsBriefing'} element={<MainContainer navText='뉴스 브리핑' targetComp={<NewsBriefingContainer />} />} />
                    {/* newsBriefing 상세 */}
                    <Route path={'/operation/newsBriefing/view/:pid'} element={<MainContainer navText='뉴스 브리핑' targetComp={<NewsBriefingContainer page='view' />} />} />
                    {/* newsBriefing 수정 */}
                    <Route path={'/operation/newsBriefing/update/:pid'} element={<MainContainer navText='뉴스 브리핑' targetComp={<NewsBriefingContainer page='add' />} />} />
                    {/* newsBriefing 등록 */}
                    <Route path={'/operation/newsBriefing/add/'} element={<MainContainer navText='뉴스 브리핑' targetComp={<NewsBriefingContainer page='add' />} />} />

                    {/* Issue VS Issue */}
                    <Route path={'/operation/another'} element={<MainContainer navText='이슈 VS 이슈' targetComp={<AnotherContainer />} />} />

                    {/* 이슈 따라잡기 */}
                    <Route path={'/operation/issue'} element={<MainContainer navText='이슈 따라잡기' targetComp={<IssueContainer />} />} />
                    {/* 이슈 따라잡기 상세 */}
                    <Route path={'/operation/issue/view/:pid/:rid'} element={<MainContainer navText='이슈 따라잡기' targetComp={<IssueContainer page='view' />} />} />
                    {/* 이슈 따라잡기 수정 */}
                    <Route path={'/operation/issue/add/:pid/:rid'} element={<MainContainer navText='이슈 따라잡기' targetComp={<IssueContainer page='add' />} />} />
                    {/* 이슈 따라잡기 등록 */}
                    <Route path={'/operation/issue/add/'} element={<MainContainer navText='이슈 따라잡기' targetComp={<IssueContainer page='add' />} />} />

                    {/* 웹소설 */}
                    <Route path={'/operation/novel'} element={<MainContainer navText='웹소설 등록' targetComp={<NovelContainer />} />} />
                    {/* 웹소설 상세 */}
                    <Route path={'/operation/novel/view/:nkey'} element={<MainContainer navText='웹소설 등록' targetComp={<NovelContainer page='view' />} />} />
                    {/* 웹소설 등록 */}
                    <Route path={'/operation/novel/add/:nkey'} element={<MainContainer navText='웹소설 등록' targetComp={<NovelContainer page='add' />} />} />
                    {/* 웹소설 등록 */}
                    <Route path={'/operation/novel/add/'} element={<MainContainer navText='웹소설 등록' targetComp={<NovelContainer page='add' />} />} />

                    {/* 웹소설 관리 */}
                    <Route path={'/operation/NovelManagement'} element={<MainContainer navText='웹소설 관리' targetComp={<NovelManagementContainer />} />} />
                    {/* 웹소설 관리 상세*/}
                    <Route path={'/operation/NovelManagement/view/:nkey'} element={<MainContainer navText='웹소설 관리' targetComp={<NovelManagementContainer page='view' />} />} />
                    {/* 웹소설 관리 수정*/}
                    <Route path={'/operation/NovelManagement/add/:nkey'} element={<MainContainer navText='웹소설 관리' targetComp={<NovelManagementContainer page='add' />} />} />

                    {/* 약관 관리 */}
                    <Route path={'/operation/termsofservice'} element={<MainContainer navText='약관 관리' targetComp={<TermsContainer />} />} />
                    {/* 약관 관리 상세 */}
                    <Route path={'/operation/termsofservice/view/:tid'} element={<MainContainer navText='약관 관리' targetComp={<TermsContainer page='view' />} />} />
                    {/* 약관 관리 수정 */}
                    <Route path={'/operation/termsofservice/add/:tid'} element={<MainContainer navText='약관 관리' targetComp={<TermsContainer page='add' />} />} />

                    {/* 엔터 관리 */}
                    <Route path={'/operation/enter'} element={<MainContainer navText='엔터 관리' targetComp={<EnterContainer />} />} />
                    {/* 엔터 관리 상세 */}
                    <Route path={'/operation/enter/view/:enterId'} element={<MainContainer navText='엔터 관리' targetComp={<EnterContainer page='view' />} />} />
                    {/* 엔터 관리 수정 */}
                    <Route path={'/operation/enter/add/:enterId'} element={<MainContainer navText='엔터 관리' targetComp={<EnterContainer page='add' />} />} />
                    {/* 엔터 관리 등록 */}
                    <Route path={'/operation/enter/add/'} element={<MainContainer navText='엔터 관리' targetComp={<EnterContainer page='add' />} />} />

                    {/* Service 회원 관리 */}
                    <Route path={'/member/management'} element={<MainContainer navText='회원 관리' targetComp={<ManagementContainer />} />} />
                    {/* Service 회원 관리 상세 */}
                    <Route path={'/member/management/view/:uid'} element={<MainContainer navText='회원 관리' targetComp={<ManagementContainer page='view' />} />} />
                    {/* Service 회원 관리 등록 */}
                    <Route path={'/member/management/add/'} element={<MainContainer navText='회원 관리' targetComp={<ManagementContainer page='add' />} />} />
                    {/* Service 회원 관리 수정 */}
                    <Route path={'/member/management/add/:uid'} element={<MainContainer navText='회원 관리' targetComp={<ManagementContainer page='add' />} />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
