import styled from "styled-components";
import Label from "../../../common/Label";
import Radio from "../../../common/Radio";
import TextField from "../../../common/TextField";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { OneBtnSubmit } from "../../../common/ReactCustomAlert";
import { getNoticeInfoBynotice_id } from "../../../api/notice/notice";
import { useSelector, useDispatch } from 'react-redux';
import { Title, NoticeTp, IsUse, EditIsReady, EditOverlay, EditNoticeData, NoticeId, NoticeInsert } from '../../../slice/noticeSlice';
import Form from 'react-bootstrap/Form';
import { getNoticeModify, getNoticeRegist } from "../../../api/notice/notice";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import { Flag } from '../../../slice/noticeSlice';
import MessageModal from '../../../modal/Message';
import { useNavigate } from "react-router-dom";


const demoLocation = `${process.env.REACT_APP_EDITOR_URL}`;

const RegistBodyC = styled.div`
    width: 100%;
    margin: 50px 0px;
`;

const IframeBlock = styled.div`
    ${() => `height: calc(100vh - 370px);
    border: 1px solid #C4C4C4;`}
`;

export var noticeEditorRef;

const RegistBody = ({ notice_id, mode }) => {
    noticeEditorRef = useRef(null);

    const noticeTitle = useSelector((state) => state.notice.value.noticeTitle);
    const noticeNoticeTp = useSelector((state) => state.notice.value.noticeNoticeTp);
    const noticeIsUse = useSelector((state) => state.notice.value.noticeIsUse);
    const noticeData = useSelector((state) => state.notice.value.noticeData);
    const nId = useSelector((state) => state.notice.value.nId);
    const insertFlag = useSelector((state) => state.notice.value.insertFlag);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.notice.value.noticeInsert);

    const [modify, setModify] = useState(false);
    const [isNull, setIsNull] = useState();
    const [loading, setLoading] = useState(false);
    const [loadStatus, setLoadStatus] = useState(false);
    const [message, setMessage] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (notice_id) {
            dispatch(NoticeId(notice_id));
            getNoticeInfoBynotice_id(notice_id).then((response) => {
                dispatch(Title(response.data.title));
                if (response.data.contents) {
                    dispatch(EditNoticeData(JSON.parse(response.data.contents)));
                }
                dispatch(NoticeTp(response.data.notice_tp));
                dispatch(IsUse(response.data.is_use * 1));
                setModify(true);
            });
        } else {
            dispatch(NoticeId(0));
            dispatch(Title(""));
            dispatch(NoticeTp("1"));
            dispatch(IsUse("1"))
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (modify) {
            noticeEditorRef.current.contentWindow.postMessage(
                {
                    type: "SET",
                    param: {
                        type: "data",
                        data: noticeData.raw,
                    },
                },
                "*"
            );
        }
    }, [loadStatus, modify]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //에디터 내용 빈값 체크
        if (isNull != null) {
            if (!isNull) {
                noticeEditorRef.current.contentWindow.postMessage({ type: "GET" }, "*");
            } else {
                OneBtnSubmit("알림", "내용을 입력하지 않았습니다.");
            }
            setIsNull(null);
        }
    }, [isNull]);

    useEffect(() => {
        const editFn = (e) => {
            if (e.data.name === "editor") {
                switch (e.data.action) {

                    case "ready": {
                        if (!loading) {
                            setLoading(true);
                            dispatch(EditIsReady(e.data.status));
                            setLoading(false);
                        }
                        break;
                    }
                    case "alert":
                    case "urlLink":
                    case "video":
                    case "openGraph":
                    case "mention":
                    case "newsSearch":
                    case "bookmark":
                    case "gallery":
                    case "camera":
                    case "location":
                        alert("지원하지않는 기능입니다.");
                        break;
                    case "overlayLoading": {
                        if (!loading) {
                            (async () => {
                                setLoading(true);
                                dispatch(EditOverlay(e.data.isLoading));
                                setLoading(false);
                            })();
                        }
                        break;
                    }
                    case "isNull": {
                        setIsNull(e.data.value);
                        break;
                    }
                    case 'changed': {
                        noticeEditorRef.current.contentWindow.postMessage({ type: 'GET' }, '*');
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            if (e.data && e.data.raw && e.data !== "") {
                dispatch(EditNoticeData(e.data));
            }
        };
        window.addEventListener("message", editFn);
        return () => window.removeEventListener("message", editFn);
    }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (Insert === true) {
            if (mode === 'add') {
                setMessage("등록진행중");
                dispatch(LoadingModalFlag(true));
                const PostData = {
                    title: noticeTitle,
                    contents: JSON.stringify(noticeData),
                    notice_tp: noticeNoticeTp,
                    is_use: noticeIsUse,
                };
                getNoticeRegist(PostData)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 400) {
                            dispatch(NoticeInsert(false));
                            setMessage("등록 실패");
                            dispatch(MessageModalFlag(true));
                        } else if (response.status === 200) {
                            dispatch(NoticeInsert(false));
                            setMessage("등록이 완료되었습니다");
                            dispatch(MessageModalFlag(true));
                            dispatch(Flag(true));
                        }
                    })
            } else if (mode !== 'add') {
                setMessage("수정진행중");
                dispatch(LoadingModalFlag(true));
                const PostData = {
                    notice_id: nId,
                    title: noticeTitle,
                    contents: JSON.stringify(noticeData),
                    notice_tp: noticeNoticeTp,
                    is_use: noticeIsUse,
                };
                getNoticeModify(PostData)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 400) {
                            dispatch(NoticeInsert(false));
                            setMessage("수정 실패");
                            dispatch(MessageModalFlag(true));
                        } else if (response.status === 200) {
                            dispatch(NoticeInsert(false));
                            setMessage("수정이 완료되었습니다");
                            dispatch(MessageModalFlag(true));
                        }
                    })
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/notice");
        }
    }, [insertFlag, MModal, navigate, dispatch]);


    return (
        <RegistBodyC>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "170px" }}>
                    <Label type={`nomal`} required={true}>
                        제목
                    </Label>
                </div>
                <div style={{ flexGrow: 1 }}>
                    <TextField value={noticeTitle || ""} width="100%" placeholder="공지사항 제목을 입력하세요" onChange={(e) => dispatch(Title(e.target.value))} />
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", flex: "row", height: "70px" }}>
                <div style={{ width: "170px" }}>
                    <Label type={`nomal`} required={true}>
                        공지사항타입
                    </Label>
                </div>
                <div style={{ display: "flex", flexGrow: 1, flex: "row", height: "70px", alignItems: "center" }}>
                    <Form.Select onChange={(e) => dispatch(NoticeTp(e.target.value))} className="selectBox" value={noticeNoticeTp}>
                        <option value={"1"}>일반공지사항</option>
                        <option value={"0"}>추후추가</option>
                    </Form.Select>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", flex: "row", height: "70px" }}>
                <div style={{ width: "170px" }}>
                    <Label type={`nomal`} required={true}>
                        공개여부
                    </Label>
                </div>
                <div style={{ display: "flex", flexGrow: 1, flex: "row", height: "70px", alignItems: "center" }}>
                    <Radio name="is_use" value="1" checked={noticeIsUse === "1" || noticeIsUse === 1} onChange={(e) => dispatch(IsUse("1"))}>공개</Radio>
                    <Radio name="is_use" value="0" checked={noticeIsUse === "0" || noticeIsUse === 0} onChange={(e) => dispatch(IsUse("0"))}>비공개</Radio>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", height: "60px" }}>
                <div style={{ width: "170px" }}>
                    <Label type={`nomal`} required={true}>
                        내용
                    </Label>
                </div>
            </div>
            <IframeBlock>
                <iframe
                    ref={noticeEditorRef}
                    title="EDITOR"
                    style={{
                        width: "100%",
                        height: "100%",
                        overflowX: "hidden",
                        overflowY: "auto",
                        minHeight: "200px",
                        zIndex: -1,
                    }}
                    src={
                        demoLocation +
                        `?toolbar=true&editMode=true&isApp=${!!window.ReactNativeWebView}`
                    }
                    frameBorder="0"
                    scrolling="yes"
                    allowFullScreen
                    onLoad={(e) => setLoadStatus(true)}
                />
            </IframeBlock>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="공지 사항 등록" message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="공지 사항 수정 수정" message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    );
};

export default RegistBody;
