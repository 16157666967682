import { instance, uploadinstance } from "..";

export const getQnAList = async (page, size, startDt, fromDt) => {
    let url = `/center/getQnAList?from_dt=${startDt}&to_dt=${fromDt}&page=${page - 1}&size=${size}`;
    const response = await instance.get(url, null, null).then((response) => {
        return response;
    });
    return response;
};

export const getQnAInfoByqna_id = async (qna_id) => {
    const url = `/center/getQnADetail?qna_id=${qna_id}`;
    const response = await instance.get(url, null, null).then((response) => {
        return response;
    });
    return response;
};

export const getNoticeRegist = async (post) => {
    const url = `/center/insertNotice`;

    const response = await instance
        .put(url, post, null)
        .then((response) => {
            return response;
        })
        .catch((e) => {
        });
    return response;
};

export const getAnswerModify = async (post) => {
    const url = `/center/putQnA`;

    const response = await instance
        .put(url, post, null)
        .then((response) => {
            return response;
        })
        .catch((e) => {
        });
    return response;
};

export const getUploadImageReturnUrl = async (file) => {
    const url = `/store/image/upload`;
    const formData = new FormData();
    formData.append("file", file);
    const response = await uploadinstance
        .post(url, formData, null)
        .then((response) => {
            return response;
        });
    return response;
};

export const Secession = async (post) => {
    const url = `/manager/api/v1/user/`;
    const response = await uploadinstance
        .put(url + post, null, null)
        .then((response) => {
            return response;
        })
        .catch((e) => {
        });
    return response;
};

export const Recovery = async (post) => {
    const url = `/manager/api/v1/user/recovery/`;
    const response = await uploadinstance
        .put(url + post, null, null)
        .then((response) => {
            return response;
        })
        .catch((e) => {
        });
    return response;
};
