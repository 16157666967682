import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPush, pushDelete } from "../../api/push/push";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Button from "../../common/Button";
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import TextField from "../../common/TextField";
import Form from 'react-bootstrap/Form';
import { MessageModalFlag, LoadingModalFlag, ExcelLoadingModalFlag } from '../../slice/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from "../../modal/Message";
import OneDayCalendarPush from "../../common/OneDayCalendarPush";
import Label from "../../common/Label";
import moment from "moment";
import { PushUseDate, PushSendRange, DefaultPushData } from '../../slice/pushSlice';
import Radio from "../../common/Radio";
import { getPushUseData, getExcelData } from "../../api/push/push";
import { utils, writeFile } from 'xlsx';

const Body = () => {
    const navigate = useNavigate();
    const [pageRows, setPageRows] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [searchList, setSearchList] = useState('1');
    const [pushList, setPushList] = useState();
    const [searchText, setSearchText] = useState("");
    const [totalCount, setTotalCount] = useState();
    const [message, setMessage] = useState('');
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const LEModal = useSelector((state) => state.modal.value.ExcelLoading);
    const [fromDay, setFromDay] = useState(moment().format("YYYY-MM-DD"));
    const [toDay, setToDay] = useState(moment().format("YYYY-MM-DD"));
    const pushUseDate = useSelector((state) => state.push.value.pushUseDate);
    const pushSendRange = useSelector((state) => state.push.value.pushSendRange);
    const [excelTitle, setExcelTitle] = useState();

    const [deleteListPseq, setDeleteListPseq] = useState([]);
    let title = "";
    let msg = "";

    const dispatch = useDispatch();

    useEffect(() => {
        getPush(2, pageRows, page_no, title, msg).then((response) => {
            for (var i = 0; i < response.data.pushList.length; i++) {
                if (response.data.pushList[i].sendTp === '0') {
                    response.data.pushList[i].sendNm = '즉시발송';
                } else if (response.data.pushList[i].sendTp === '9') {
                    response.data.pushList[i].sendNm = '예약발송';
                } else {
                    response.data.pushList[i].sendNm = '반복발송';
                }
            }
            setPushList(response.data.pushList);
        })
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setRealPage_no(1);
        getPush(2, pageRows, 1, title, msg).then((response) => {
            for (var i = 0; i < response.data.pushList.length; i++) {
                if (response.data.pushList[i].sendTp === '0') {
                    response.data.pushList[i].sendNm = '즉시발송';
                } else if (response.data.pushList[i].sendTp === '9') {
                    response.data.pushList[i].sendNm = '예약발송';
                } else {
                    response.data.pushList[i].sendNm = '반복발송';
                }
            }
            setPushList(response.data.pushList);
        })
    }, [pageRows]);// eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        dispatch(DefaultPushData());
        getPushUseData()
            .then((response) => {
                dispatch(PushUseDate(response.data));
            })
        setting();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const setting = () => {
        getPush(1, pageRows, 1).then((response) => {
            for (var i = 0; i < response.data.pushList.length; i++) {
                if (response.data.pushList[i].sendTp === '0') {
                    response.data.pushList[i].sendNm = '즉시발송';
                } else if (response.data.pushList[i].sendTp === '9') {
                    response.data.pushList[i].sendNm = '예약발송';
                } else {
                    response.data.pushList[i].sendNm = '반복발송';
                }
            }
            setPushList(response.data.pushList);
            setTotalCount(response.data.total_count);
            const checkboxes = document.getElementsByName('selectCheckBox');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            })
        })
    }

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }

    const searchhandle = () => {
        title = "";
        msg = "";
        if (searchList === '1') {
            title = searchText;
            getPush(2, pageRows, page_no, title, msg).then((response) => {
                setPushList(response.data.pushList);
                setTotalCount(response.data.total_count);
            })
        } else if (searchList === '2') {
            msg = searchText;
            getPush(2, pageRows, page_no, title, msg).then((response) => {
                setPushList(response.data.pushList);
                setTotalCount(response.data.total_count);
            })
        } else if (searchList === '3') {
            title = searchText;
            msg = searchText;
            getPush(2, pageRows, page_no, title, msg).then((response) => {
                setPushList(response.data.pushList);
                setTotalCount(response.data.total_count);
            })
        }

    }
    const deletehandle = () => {

        const selector = JSON.stringify(deleteListPseq);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"PSEQ":', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = "," + deleteData4;
        const deleteData6 = deleteData5.replaceAll('"', '');
        const deleteData7 = { params: { "pSeq": deleteData6 } };
        setMessage("삭제진행중");
        dispatch(LoadingModalFlag(true));
        pushDelete(deleteData7)
            .then(() => {
                dispatch(LoadingModalFlag(false));
                setMessage("삭제 완료되었습니다");
                dispatch(MessageModalFlag(true));
                setting();
            })
    }

    const enterkey = (e) => {
        if (window.event.keyCode === 13) {
            setPage_no(1);
            searchhandle();
        }
    }

    const checkhandle = (e) => {
        const newArr = deleteListPseq.slice();
        for (const index in deleteListPseq) {
            if (`${deleteListPseq[index].PSEQ}` === e) {
                newArr.splice(index, 1);
                setDeleteListPseq(newArr);
                return;
            }
        }
        newArr.push({
            PSEQ: e,
        });
        setDeleteListPseq(newArr);
    }

    const handleDownload = () => {
        setMessage('다운로드 중');
        setExcelTitle('엑셀 다운로드');
        dispatch(ExcelLoadingModalFlag(true));

        getExcelData(fromDay.replaceAll("-", "") + "000000", toDay.replaceAll("-", "") + "235959", pushSendRange).then((response) => {
            const target = response.data.map((item) => {
                return { "제목": item.title, "발송수": item.total, "성공수": item.success, "방식": item.sendRange, "URL": item.url, "발송일시": item.bookDt.substring(0, 10), "발송시": item.bookDt.substring(11) }
            })
            exportDataToXlsx(target, "push")
        })
    }
    const exportDataToXlsx = (data, title) => {
        const worksheet = utils.json_to_sheet(data);
        const new_workbook = utils.book_new();
        utils.book_append_sheet(new_workbook, worksheet, 'pushList');
        writeFile(new_workbook, title + '.xlsx');
        dispatch(ExcelLoadingModalFlag(false));
    }
    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {totalCount &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCount}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select onChange={(e) => setSearchList(e.target.value)} className="selectBox">
                            <option value={1}>제목</option>
                            <option value={2}>내용</option>
                            <option value={3}>제목+내용</option>
                        </Form.Select>&nbsp;
                        <Form.Select onChange={(e) => setPageRows(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        <TextField value={searchText || ""} placeholder={`검색어를 입력해주세요`} onKeydown={enterkey} onChange={(e) => setSearchText(e.target.value)} />&nbsp;
                        <Button cType='b_line' name='검색' width='65px' onClick={searchhandle} />
                        <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                    </div>
                </div>
                <div className='title'>엑셀 출력 조건</div>
                <div className='component'>
                    <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>시작 일자</Label></div>
                        <div style={{ display: 'flex' }}>
                            <OneDayCalendarPush setDate={setFromDay} splashDate={fromDay} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>종료 일자</Label></div>
                        <div style={{ display: 'flex' }}>
                            <OneDayCalendarPush setDate={setToDay} splashDate={toDay} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <div style={{ width: '50px' }}><Label type={`nomal`}></Label></div>
                        <div style={{ display: 'flex' }}>
                            <Button sType='md' name='엑셀 다운로드' width='150px' onClick={handleDownload} />
                        </div>
                    </div>
                </div>
                {pushUseDate &&
                    <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', paddingBottom: '20px' }}>
                        <div style={{ width: '170px', paddingLeft: '24px' }}>
                            <Label type={`nomal`} required={true}>사용자 범위</Label>
                        </div>
                        <div>
                            {pushUseDate.map((selectitem, index) => {
                                return <Radio key={index} name='aDayAgo' value={selectitem.s_seq} checked={selectitem.s_seq === pushSendRange} onChange={(e) => dispatch(PushSendRange(selectitem.s_seq))}>{selectitem.s_desc}</Radio>
                            })}
                        </div>
                    </div>
                }
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(pushList && pushList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>발송 수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>성공 수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>발송 대상</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>방식</div>
                            <div style={{ display: 'flex', width: '250px', justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '450px' }}>URL</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }}>발송일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }}>등록 일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }}>수정 일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {pushList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px', }} onChange={(e) => checkhandle(item.pseq)}><Check name='selectCheckBox' /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.total}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.success}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.desc}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.sendNm}</div>
                                        <div style={{ width: '200px', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.title}</div>
                                        <div style={{ width: '450px', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.url}</div>
                                        <div style={{ display: 'flex', width: '140px' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.bookDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.regDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }} onClick={(e) => navigate(`/operation/push/view/${item.pseq}`)}>{item.modDt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCount} pageSize={pageRows} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
                {MModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="check" title="푸시 등록" message={message} />
                        </div>
                    </div>
                }
                {LModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="loading" title="푸시 등록" message={message} />
                        </div>
                    </div>
                }
                {LEModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="loading" title={excelTitle} message={message} />
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    )
}

export default Body;