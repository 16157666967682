import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Button from "../../common/Button";
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import Form from 'react-bootstrap/Form';
import { MessageModalFlag, LoadingModalFlag } from '../../slice/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from "../../modal/Message";
import { getEnterList, putEnterCountUpdate, putEnterDelete } from "../../api/enter/enter";
import Tabs from "../../common/Tabs";

const Body = () => {
    const navigate = useNavigate();
    const tabList = ['전체', '월', '화', '수', '목', '금', '토', '일'];
    const [title, setTitle] = useState('');
    const [pageRows, setPageRows] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [enterList, setEnterList] = useState();
    const [totalCount, setTotalCount] = useState();
    const [programDays, setProgramDays] = useState(7);
    const [message, setMessage] = useState('');
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);

    const [deleteListenterId, setDeleteListEnterId] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const data = { params: { "pageRows": pageRows, "pageNum": page_no, "programDays": programDays } };
        getEnterList(data)
            .then((response) => {
                for (var i = 0; i < response.data.enterList.length; i++) {
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("0", "월");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("1", "화");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("2", "수");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("3", "목");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("4", "금");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("5", "토");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("6", "일");
                }
                setEnterList(response.data.enterList);
                setTotalCount(response.data.enterPage.total_count);
            });
    }, [page_no, programDays]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setRealPage_no(1);
        const data = { params: { "pageRows": pageRows, "pageNum": 1, "programDays": programDays } };
        getEnterList(data)
            .then((response) => {
                for (var i = 0; i < response.data.enterList.length; i++) {
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("0", "월");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("1", "화");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("2", "수");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("3", "목");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("4", "금");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("5", "토");
                    response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("6", "일");
                }
                setEnterList(response.data.enterList);
                setTotalCount(response.data.enterPage.total_count);
            });
    }, [pageRows]);// eslint-disable-line react-hooks/exhaustive-deps


    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }

    const deletehandle = () => {
        setTitle("엔터 삭제");
        setMessage("삭제진행중");
        dispatch(LoadingModalFlag(true));
        dispatch(LoadingModalFlag(false));
        const selector = JSON.stringify(deleteListenterId);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"enterId":"', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = "," + deleteData4;
        const deleteData6 = deleteData5.replaceAll('"', '');
        const deleteData7 = deleteData6.substr(1)
        const data = { "enterId": deleteData7 };
        putEnterDelete(data)
            .then((response) => {
                dispatch(LoadingModalFlag(false));
                if (response.status === 200) {
                    setRealPage_no(1);
                    const data = { params: { "pageRows": pageRows, "pageNum": page_no, "programDays": programDays } };
                    getEnterList(data)
                        .then((response) => {
                            for (var i = 0; i < response.data.enterList.length; i++) {
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("0", "월");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("1", "화");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("2", "수");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("3", "목");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("4", "금");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("5", "토");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("6", "일");
                            }
                            setEnterList(response.data.enterList);
                            setTotalCount(response.data.enterPage.total_count);
                            const checkboxes = document.getElementsByName('selectCheckBox');
                            checkboxes.forEach((checkbox) => {
                                checkbox.checked = false;
                            })
                        });
                    setMessage("완료");
                    dispatch(MessageModalFlag(true));
                    return;
                } else {
                    setMessage("실패");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            });
    }

    const checkhandle = (e) => {
        const newArr = deleteListenterId.slice();
        for (const index in deleteListenterId) {
            if (`${deleteListenterId[index].enterId}` === e) {
                newArr.splice(index, 1);
                setDeleteListEnterId(newArr);
                return;
            }
        }
        newArr.push({
            enterId: e,
        });
        setDeleteListEnterId(newArr);
    }

    const docCount = (e) => {
        setTitle("엔터 기사수 업데이트");
        setMessage("진행중");
        dispatch(LoadingModalFlag(true));
        const data = { params: { "enter_id": e * 1 } };
        putEnterCountUpdate(data)
            .then((response) => {
                dispatch(LoadingModalFlag(false));
                if (response.status === 200) {
                    const data = { params: { "pageRows": pageRows, "pageNum": page_no, "programDays": programDays } };
                    getEnterList(data)
                        .then((response) => {
                            for (var i = 0; i < response.data.enterList.length; i++) {
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("0", "월");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("1", "화");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("2", "수");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("3", "목");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("4", "금");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("5", "토");
                                response.data.enterList[i].programDays = response.data.enterList[i].programDays.replace("6", "일");
                            }
                            setEnterList(response.data.enterList);
                            setTotalCount(response.data.enterPage.total_count);
                            const checkboxes = document.getElementsByName('selectCheckBox');
                            checkboxes.forEach((checkbox) => {
                                checkbox.checked = false;
                            })
                            setMessage("완료");
                            dispatch(MessageModalFlag(true));
                            return;
                        });
                } else {
                    setMessage("실패");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            });
    }

    const handleNewsTabClick = (e, i) => {
        if (i === 1) {
            setRealPage_no(1);
            setPage_no(1);
            setProgramDays(0);
        } else if (i === 2) {
            setPage_no(1);
            setRealPage_no(1);;
            setProgramDays(1);
        } else if (i === 3) {
            setPage_no(1);
            setRealPage_no(1);;
            setProgramDays(2);
        } else if (i === 4) {
            setPage_no(1);
            setRealPage_no(1);;
            setProgramDays(3);
        } else if (i === 5) {
            setPage_no(1);
            setRealPage_no(1);;
            setProgramDays(4);
        } else if (i === 6) {
            setPage_no(1);
            setRealPage_no(1);;
            setProgramDays(5);
        } else if (i === 7) {
            setPage_no(1);
            setRealPage_no(1);;
            setProgramDays(6);
        } else {
            setPage_no(1);
            setRealPage_no(1);
            setProgramDays(7);
        }
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {totalCount &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCount}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Form.Select onChange={(e) => setSearchList(e.target.value)} className="selectBox">
                            <option value={1}>제목</option>
                        </Form.Select>&nbsp; */}
                        <Form.Select onChange={(e) => setPageRows(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        {/* <TextField value={searchText || ""} placeholder={`검색어를 입력해주세요`} onKeydown={enterkey} onChange={(e) => setSearchText(e.target.value)} />&nbsp;
                        <Button cType='b_line' name='검색' width='65px' onClick={searchhandle} /> */}
                        <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                    </div>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Tabs items={tabList} onClick={handleNewsTabClick} />
                {(enterList && enterList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>엔터ID</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>방송 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }}>방송사</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }}>뉴스 기사 수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '400px' }}>프로그램 이름</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>요일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '300px' }}>설명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>기사수 적용</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {enterList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px', }} onChange={(e) => checkhandle(item.enterId)}><Check name='selectCheckBox' /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/enter/view/${item.enterId}`)}>{item.enterId}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/enter/view/${item.enterId}`)}>{item.enterTypeDesc}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }} onClick={(e) => navigate(`/operation/enter/view/${item.enterId}`)}>{item.broadCasters}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }} onClick={(e) => navigate(`/operation/enter/view/${item.enterId}`)}>{item.docCount}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '400px' }} onClick={(e) => navigate(`/operation/enter/view/${item.enterId}`)}>{item.programNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => navigate(`/operation/enter/view/${item.enterId}`)}>{item.programDays}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '300px' }} onClick={(e) => navigate(`/operation/enter/view/${item.enterId}`)}>{item.description
                                        }</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }} onClick={(e) => docCount(`${item.enterId}`)}>적용하기</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCount} pageSize={pageRows} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
                {MModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="check" title={title} message={message} />
                        </div>
                    </div>
                }
                {LModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="loading" title={title} message={message} />
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;