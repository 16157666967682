import { BodyTitle, BodyTitleFont } from "../../style/layout";

const Title = () => {

    return (
        <BodyTitle>
            <BodyTitleFont>뉴스 검색 테스트</BodyTitleFont>
        </BodyTitle>
    )
}

export default Title;