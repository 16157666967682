import uploadinstance from '../../../api/index';
import QueryString from 'qs';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';

export const registLog = (uid, rid) => {
    const utm = localStorage.getItem('UTM')
    if (utm && utm.length > 0) {
        localStorage.removeItem('UTM');
    }

    var param = {
        ip: '',
        uid: uid,
        rid: rid,
        os: osName + osVersion,
        browser: browserName + browserVersion,
        log_msg: '/registComplete',
        log_info1: !!window.ReactNativeWebView + '',
        log_info2: (utm && utm.length) ? utm : null,
        log_info3: localStorage.getItem('fromMarket') ?? localStorage.getItem('fromMarket'),
        log_info4: document.referrer,
        log_info5: process.env.REACT_APP_ENV,
    };

    uploadinstance.post('/admin/access', param, null);
}

export const historyLogger = (location, auth) => {

    const urlParam = location.search && QueryString.parse(location.search.substring(1));
    /**
     * 211019 김명종 추가
     * 마켓 정보 구분 log_info3에 처리
     */
    var param = {
        ip: '',
        uid: auth && auth.uid ? auth.uid : 0,
        rid: auth && auth.rid ? auth.rid : 0,
        os: osName + osVersion,
        browser: browserName + browserVersion,
        log_msg: location.pathname,
        log_info1: !!window.ReactNativeWebView + '',
        log_info2: urlParam ? JSON.stringify(urlParam) : null,
        log_info3: localStorage.getItem('fromMarket') ?? localStorage.getItem('fromMarket'),
        log_info4: document.referrer,
        log_info5: process.env.REACT_APP_ENV,
    };
    let logSend = false;
    switch (location.pathname) {
        case '/':
            if (urlParam.utm_source) {
                localStorage.setItem('UTM', JSON.stringify(urlParam))
                logSend = true;
            } else {
                localStorage.removeItem('UTM');
            }
            break;
        case '/home':
        case '/news':
        case '/myPage':
            logSend = true;
            break;
        default:
            if (location && location.pathname) {
                const path = location.pathname;
                if (path.indexOf('/post') > -1 || path.indexOf('/POST') > -1) {
                    logSend = true;
                } else if (path.indexOf('/newsRoom') > -1) {
                    if (
                        path.indexOf('/member') < 0 &&
                        path.indexOf('/following') < 0 &&
                        path.indexOf('/follower') < 0 &&
                        path.indexOf('/alarm') < 0
                    ) {
                        logSend = true;
                    }
                } else if (path.indexOf('BANNER:') > -1) {
                    logSend = true;
                } else if (path.indexOf('/newsLink') > -1) {
                    logSend = true;
                } else if (path.indexOf('/search') > -1) {
                    if (path.indexOf('/search/filter') > -1 || path.indexOf('/search/filter/start') > -1 || path.indexOf('/search/filter/end') > -1) {

                    } else {
                        logSend = true;
                    }
                }
            }
            break;
    }

    if (logSend) {
        uploadinstance.post('/admin/access', param, null)
            .then((res) => {
            })
    }
};
