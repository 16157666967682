import { Dialog } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import React from "react";
import { getNewsByNids } from "../../api/news/newsBriefing";
import { TableHeader, TableRow } from "../../style/layout";
import Check from "../../common/Check";
import Tabs from "../../common/Tabs";
import Button from "../../common/Button";
import News from "../../common/search/News";
import { defaultLoad } from "../../api/news/another";
import MessageModal from "../../modal/Message";
import { useSelector, useDispatch } from 'react-redux';
import { MessageModalFlag } from '../../slice/modalSlice';

const DetailC = styled.div`
    width: 1084px;
    height: 900px;
    max-height: 900px;
    min-height: 900px;
    background:#ffffff;
    border-radius:10px;
    padding:30px 30px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    overflow:hidden;
    .popTitle {
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }
`;

const Detail = ({ open, close, data }) => {

    const [positiveList, setPositiveList] = useState([]);
    const [neutralList, setNeutralList] = useState([]);
    const [negativeList, setNegativeList] = useState([]);
    const [delTempList, setDelTempList] = useState([[], [], []]);
    const [contents, setContents] = useState()
    const [tabIndex, setTabIndex] = useState(0);
    const [searchOpen, setSearchOpen] = useState(false);
    const MModal = useSelector((state) => state.modal.value.Message);
    const dispatch = useDispatch();
    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if ((open === true) && data) {
            data.positive.length > 0 ? getNewsByNids(data.positive.map(item => item.nid))
                .then((response) => {
                    setPositiveList(response.data.news_list);
                })
                : setPositiveList([]);
            data.neutral.length > 0 ? getNewsByNids(data.neutral.map(item => item.nid))
                .then((response) => {
                    setNeutralList(response.data.news_list);
                })
                : setNeutralList([]);
            data.negative.length > 0 ? getNewsByNids(data.negative.map(item => item.nid))
                .then((response) => {
                    setNegativeList(response.data.news_list);
                })
                : setNegativeList([]);
        }
    }, [open, data])
    const handleCheck = (e, item) => {
        var temp = delTempList;
        if (e.target.checked === true) {
            temp[tabIndex].push(item)
        } else if (e.target.checked === false) {
            temp[tabIndex] = temp[tabIndex].filter(function (item) { return item.nid.toString() !== e.target.name })
        }
        setDelTempList(temp);
    }

    const showNews = (contents) => {
        setContents(contents)
    }
    const handleTabClick = (e, index) => {
        setTabIndex(index)
    }
    const onAdd = (list) => {
        switch (tabIndex) {
            case 0:
                const posTemp = positiveList.concat(list);
                setPositiveList(posTemp);
                break;
            case 1:
                const neuTemp = neutralList.concat(list);
                setNeutralList(neuTemp);
                break;
            case 2:
                const negTemp = negativeList.concat(list);
                setNegativeList(negTemp);
                break;
            default:
                break;
        }
    }
    const handleDeleteClick = () => {
        switch (tabIndex) {
            case 0:
                var posTemp = positiveList;
                delTempList[tabIndex].map((target) => {//eslint-disable-line
                    posTemp = posTemp.filter(function (item) { return item.nid !== target.nid })
                })
                setPositiveList(posTemp);
                break;
            case 1:
                var neuTemp = neutralList;
                delTempList[tabIndex].map((target) => {//eslint-disable-line
                    neuTemp = neuTemp.filter(function (item) { return item.nid !== target.nid })
                })
                setNeutralList(neuTemp);
                break;
            case 2:
                var negTemp = negativeList;
                delTempList[tabIndex].map((target) => {//eslint-disable-line
                    negTemp = negTemp.filter(function (item) { return item.nid !== target.nid })
                })
                setNegativeList(negTemp);
                break;
            default:
                break;
        }
        var dtemp = delTempList;
        dtemp[tabIndex] = [];
        setDelTempList(dtemp);
    }
    const handleRegistClick = () => {

        if (positiveList.map(item => { return { nid: item.nid } }).length === 0) {
            setMsgTitle("이슈 VS 이슈");
            setMessage("1개 이상의 긍정 뉴스를 등록하셔야됩니다.");
            dispatch(MessageModalFlag(true));
            return;
        }
        if (negativeList.map(item => { return { nid: item.nid } }).length === 0) {
            setMsgTitle("이슈 VS 이슈");
            setMessage("1개 이상의 부정 뉴스를 등록하셔야됩니다.");
            dispatch(MessageModalFlag(true));
            return;
        }
        const sendData = {
            nid: data.nid,
            positive: positiveList.map(item => { return { nid: item.nid } }),
            neutral: neutralList.map(item => { return { nid: item.nid } }),
            negative: negativeList.map(item => { return { nid: item.nid } }),
        }
        close(sendData)

    }
    const handleDefaultLoadingClick = () => {
        defaultLoad(data.nid, negativeList.map(item => item.nid))
            .then((response) => {
                const posTemp = positiveList.concat(response.data);
                setPositiveList(posTemp);
            })
    }

    return (
        <Dialog maxWidth={'lg'} onClose={close} open={open}>
            <Tabs items={[`긍정(${positiveList ? positiveList.length : 0})`, `중립(${neutralList ? neutralList.length : 0})`, `부정(${negativeList ? negativeList.length : 0})`]} onClick={handleTabClick} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '35px' }}>
                    <Button name='기본뉴스불러오기' sType='sm' onClick={handleDefaultLoadingClick} width='120px' />&nbsp;
                    <Button name='추가' sType='sm' onClick={(e) => setSearchOpen(true)} />&nbsp;
                    <Button name='삭제' cType='b_line' sType='sm' onClick={handleDeleteClick} />
                    <News open={searchOpen} close={(e) => setSearchOpen(false)} onAdd={onAdd} />
                </div>
            </div>
            <DetailC>
                {(tabIndex === 0 && positiveList && positiveList.length > 0) &&
                    <div>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name='ALL' onChange={(e) => handleCheck()} /></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스아이디</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>등록일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
                            {positiveList.map((item, index) => {
                                return (
                                    <TableRow key={index} >
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name={item.nid} onChange={(e) => handleCheck(e, item)} /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.nid}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} onClick={(e) => showNews(item.contents)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.med_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.cat_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} onClick={(e) => showNews(item.contents)}>{item.news_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                {(tabIndex === 1 && neutralList && neutralList.length > 0) &&
                    <div>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name='ALL' onChange={(e) => handleCheck()} /></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스아이디</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>등록일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
                            {neutralList.map((item, index) => {
                                return (
                                    <TableRow key={index} >
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name={item.nid} onChange={(e) => handleCheck(e, item)} /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.nid}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} onClick={(e) => showNews(item.contents)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.med_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.cat_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} onClick={(e) => showNews(item.contents)}>{item.news_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                {(tabIndex === 2 && negativeList && negativeList.length > 0) &&
                    <div>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name='ALL' onChange={(e) => handleCheck()} /></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스아이디</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>등록일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
                            {negativeList.map((item, index) => {
                                return (
                                    <TableRow key={index} >
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check name={item.nid} onChange={(e) => handleCheck(e, item)} /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.nid}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} onClick={(e) => showNews(item.contents)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.med_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => showNews(item.contents)}>{item.cat_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} onClick={(e) => showNews(item.contents)}>{item.news_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                    </div>
                }
            </DetailC>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button name={`등록`} onClick={handleRegistClick} />&nbsp;
                <Button cType={`cancel`} name={`취소`} onClick={close} />
            </div>
            {contents &&
                <Dialog onClose={(e) => setContents(undefined)} aria-labelledby="simple-dialog-title" open={contents}>
                    <pre style={{ width: '600px', whiteSpace: 'pre-wrap' }}>
                        {contents}
                    </pre>
                </Dialog>
            }

            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </Dialog>
    )

}

export default Detail;