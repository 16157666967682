import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme, { NonBorderHeader, PageStyle } from '../../theme';
import GreyLine from '../../../../common/GreyLine';
import NewsList from './NewsList';
import PostingList from './PostingList';
import moment from 'moment';
import { getSearchPost, getSearchNews } from '../../../../api/newsRoom/post';
import { EditNewsSearchModalFlag } from '../../../../slice/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import EditMessage from "../../../../modal/EditMessage";
import { EditMessageModalFlag } from '../../../../slice/modalSlice';
const Layout = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 10;
    background-color: white;
    overflow-y: auto;
`;

const SearchInput = styled.input`
    position: relative;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #0000001a;
    padding: 0 0 0 12px;
    ${(props) => props.theme.fontSize.body2};
    -webkit-appearance: none;
    border-radius: 4px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #9b9c9f;
    }
`;
const SearchIcon = styled.div`
    display: flex;
    position: absolute;
    right: 28px;
    top: 65px;
    img {
        width: 24px;
        height: 24px;
    }
`;
const Margin = styled.div`
    height: ${(props) => props.height};
`;

const Filter = styled.div`
    display: flex;
    height: 48px;
    padding: 0 1em 0 1em;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    letter-spacing: -0.28px;
    .result {
        opacity: 0.6;
        ${(props) => props.theme.fontSize.body2}
    }
    .option {
        display: flex;
        align-items: center;
        flex-direction: row;

        .icon {
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            margin-left: 8px;
            padding: 5px 0 0 0;
            img {
                &:active {
                    background-color: #cbcbcb;
                }
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
`;
const EditSearch = ({ props, editorRef }) => {

    const [sort, setSort] = useState(0);// eslint-disable-line no-unused-vars

    const [keyword, setKeyword] = useState();
    const [searchOn, setSearchOn] = useState(false);
    const [selectData, setSelectData] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);// eslint-disable-line no-unused-vars
    const [postTotal, setPostTotal] = useState(false);
    const [newsTotal, setNewsTotal] = useState(false);
    const [search_opt, setSearch_opt] = useState(2);// eslint-disable-line no-unused-vars
    const MModal = useSelector((state) => state.modal.value.EditMessage);
    const [message, setMessage] = useState();
    const [messageTitle, setMessageTitle] = useState();
    const regExpKey = /[%^]/gi;
    const dispatch = useDispatch();
    const onChange = (e) => {
        setSearchOn(false);
        setSelectData(null);
        setKeyword(e.target.value);
        if (e.key === 'Enter') searchClick();
    };
    const searchClick = () => {

        var searchKeyword = keyword.replace(regExpKey, '');
        var realFrom_dt = moment().subtract(1, 'months').format('YYYYMMDDHHmmss');
        var realTo_dt = moment().format('YYYYMMDDHHmmss');

        setSearchOn(true);
        getSearchPost(realFrom_dt, realTo_dt, 0, searchKeyword)
            .then((response) => {
                setPostTotal(response.data.total_row);
            });
        getSearchNews(realFrom_dt, realTo_dt, sort, search_opt, searchKeyword)
            .then((response) => {
                setNewsTotal(response.data.total_row);
            });
    };

    useEffect(() => {
        if (selectData === null) return;
        let img_urls = null;
        try {
            if (!selectData.img_urls) img_urls = JSON.parse(selectData.contents_props).newsList[0].img_urls;
        } catch (e) { }
        const lowerMedCode = selectData.med_cd != null ? selectData.med_cd.toLowerCase() : selectData.med_cd;
        const defaultImage = `${window.location.origin}/medLogo/${lowerMedCode}.gif`
        editorRef.current.contentWindow.postMessage(
            {
                type: 'SET',
                param: {
                    type: 'newsLink',
                    data: { ...selectData, img_urls: selectData.nid ? selectData.img_urls === '' ? defaultImage : selectData.img_urls : img_urls === '' ? defaultImage : img_urls },
                },
            },
            '*'
        );
        setMessageTitle("기사 추가");
        setMessage(selectData.title + " 추가되었습니다");
        dispatch(EditMessageModalFlag(true));
    }, [selectData]);// eslint-disable-line react-hooks/exhaustive-deps

    const numberHandler = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const close = (e) => {
        dispatch(EditNewsSearchModalFlag(false))
    }

    return (
        <Layout>
            <ThemeProvider theme={theme}>
                <PageStyle>
                    <NonBorderHeader>
                        <div>
                        </div>
                        콘텐츠 선택
                        <button className={'back_btn'} style={{ width: 'unset', marginLeft: 'auto', background: 'none' }}>
                            <img
                                onClick={close}
                                src="/images/icon/ic_closed_black.svg"
                                alt="1234"
                            />
                        </button>
                    </NonBorderHeader>
                </PageStyle>
                <div>
                    <PageStyle>
                        <SearchInput
                            placeholder={'검색어를 입력하세요.'}
                            value={keyword || ""}
                            onChange={onChange}
                            onKeyPress={onChange}
                        />
                        <SearchIcon onClick={() => searchClick()}>
                            <img src={'/images/icon/ic_search.png'} alt="" />
                        </SearchIcon>
                        <Margin height={'8px'} />
                    </PageStyle>
                    <Filter style={{ borderTop: '1px solid #0000004D' }}>
                        <span className={'result'}>
                            총 검색결과
                            {searchOn ? numberHandler(newsTotal + postTotal) : 0}건
                        </span>
                    </Filter>
                </div>
                <GreyLine height={'2px'} />
                <Margin height={'8px'} />
                {keyword && searchOn ? (
                    <>
                        <NewsList
                            setSelectData={setSelectData}
                            props={props}
                            keyword={keyword}
                            title={'뉴스 결과'}
                            edit={true}
                        />
                        <PostingList
                            setSelectData={setSelectData}
                            props={props}
                            keyword={keyword}
                            title={'포스팅 결과'}
                            edit={true}
                        />
                    </>
                ) : null}
            </ThemeProvider>
            {MModal && messageTitle && message &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <EditMessage type="check" title={messageTitle} message={message} />
                    </div>
                </div>
            }
        </Layout>
    );
};

export default EditSearch;
