import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getList } from "../../api/post/common";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Paging from "../../common/Paging";
import Form from 'react-bootstrap/Form';

const Body = () => {

    const navigate = useNavigate();
    const [pagesInfo, setPagesInfo] = useState();
    const [size, setSize] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [postList, setPostList] = useState();
    const [realPage_no, setRealPage_no] = useState(1);
    useEffect(() => {
        getList(4, page_no, size, 'regDt')
            .then((response) => {
                setPagesInfo(response.data.pages);
                setPostList(response.data.results);
            })
    }, [page_no])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPage_no(1);
        getList(4, 1, size, 'regDt')
            .then((response) => {
                setPagesInfo(response.data.pages);
                setPostList(response.data.results);
            })
    }, [size])

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
        setRealPage_no(pageNum);
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {pagesInfo &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{pagesInfo.total_rows}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select className="selectBox" onChange={(e) => setSize(e.target.value)}>
                            <option value='10' >10개씩보기</option>
                            <option value='20' >20개씩보기</option>
                            <option value='20' >30개씩보기</option>
                        </Form.Select>&nbsp;
                        {/* <Button cType='b_line' name='삭제' width='65px'/> */}
                    </div>
                </div>
                <div className='title'></div>
                <div className='component'>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(postList && postList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>이슈따라잡기 아이디</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>조회수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>댓글</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>공개여부</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>뉴스룸 이름</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>등록자 아이디</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>예약일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>등록일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>수정일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {postList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.pid}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'left' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.readCnt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.replyCnt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.isUse === 1 ? '공개' : '비공개'}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.roomNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.userId}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.revDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)}>{item.regDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }} onClick={(e) => navigate(`/operation/newsBriefing/view/${item.pid}`)} >{item.modDt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={pagesInfo.total_rows} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;