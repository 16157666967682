import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import EditOpenGraph from './EditOpenGraph';
import EditRoom from './EditRoom';
import EditSearch from './EditSearch';
import { getList } from "../../../api/terms/terms";
import { EditOpenGraphModalFlag, EditNewsSearchModalFlag, EditRoomModalFlag } from '../../../slice/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import './Edit.css';

const IframeBlock = styled.div`
    ${() => `height: calc(100vh - 450px);`}
`;
const Edit = ({ navigate, props, tid, onChange, editMode }) => {

    const demoLocation = `${process.env.REACT_APP_EDITOR_URL}`;
    const editorRef = useRef(null);
    const [loadStatus, setLoadStatus] = useState(false);
    const [data, setData] = useState();
    const [isReady, setIsReady] = useState(false);//에디터 iframe ready 상태
    const [isNull, setIsNull] = useState();//에디터 본문 null check
    // const [isChanged, setIsChanged] = useState(null);//에디터 변경사항 플래그
    const dispatch = useDispatch();
    const OGModal = useSelector((state) => state.modal.value.EditOpenGraphModal);
    const NSModal = useSelector((state) => state.modal.value.EditNewsSearchModal);
    const ERModal = useSelector((state) => state.modal.value.EditRoomModal);

    useEffect(() => {
        setTimeout(() => {
            if (tid) {
                const data = { params: { "Tid": tid } };
                getList(data)
                    .then((response) => {
                        editorRef.current.contentWindow.postMessage(
                            {
                                type: 'SET',
                                param: { type: 'data', data: response.data[0] && JSON.parse(response.data[0].contents).raw },
                            },
                            '*'
                        );
                        editorRef.current.contentWindow.postMessage({ type: 'GET' }, '*');
                    })
            }
            if (isReady) {
                if (loadStatus && editMode) {

                }
            }
        }, 1000);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onChange({
            contents: data
        });
    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps


    const webToNative = (type, value) => {
        if (window.ReactNativeWebView) {
            // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다.
            if (value) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type, value }));
            } else {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type }));
            }
        }
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const editFn = (e) => {
            if (e.data.name === 'editor') {
                switch (e.data.action) {
                    case 'ready': {
                        if (!loading) {
                            setLoading(true);
                            setIsReady(e.data.status);
                            setLoading(false);
                        }
                        break;
                    }
                    case 'alert': {
                        if (!loading) {
                            setLoading(true);
                            navigate('#', { modal: true });
                            setLoading(false);
                        }
                        break;
                    }
                    case 'location': {
                        if (!loading) {
                            setLoading(true);
                            webToNative('LOCATION');
                        }
                        break;
                    }
                    case 'camera': {
                        if (!loading) {
                            setLoading(true);
                            webToNative('CAMERA', 'image');
                        }
                        break;
                    }
                    case 'gallery': {
                        if (!loading) {
                            setLoading(true);
                            webToNative('LIBRARY', { image: 'image', multiple: true });
                        }
                        break;
                    }
                    case 'video': {
                        if (!loading) {
                            setLoading(true);
                            webToNative('VIDEO', 'video');
                        }
                        break;
                    }
                    case 'bookmark': {
                        if (!loading) {
                            (async () => {
                                setLoading(true);
                                // setBookMark(true);
                                setLoading(false);
                            })();
                        }
                        break;
                    }
                    case 'newsSearch': {
                        if (!loading) {
                            (async () => {
                                setLoading(true);
                                dispatch(EditNewsSearchModalFlag(true));
                                setLoading(false);
                            })();
                        }
                        break;
                    }
                    case 'mention': {
                        if (!loading) {
                            (async () => {
                                setLoading(true);
                                dispatch(EditRoomModalFlag(true));
                                setLoading(false);
                            })();
                        }
                        break;
                    }
                    case 'openGraph': {
                        if (!loading) {
                            (async () => {
                                setLoading(true);
                                dispatch(EditOpenGraphModalFlag(true));
                                setLoading(false);
                            })();
                        }
                        break;
                    }
                    case 'urlLink': {
                        if (!loading) {
                            (async () => {
                                setLoading(true);
                                setLoading(false);
                            })();
                        }
                        break;
                    }
                    case 'overlayLoading': {
                        if (!loading) {
                            (async () => {
                                setLoading(true);
                                setLoading(false);
                            })();
                        }
                        break;
                    }
                    case 'isNull': {
                        setIsNull(e.data.value);
                        break;
                    }
                    case 'changed': {
                        //setIsChanged(e.data.value);
                        editorRef.current.contentWindow.postMessage({ type: 'GET' }, '*');
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            if (e.data && e.data.raw && e.data !== '') {
                setData(e.data);
            }
        };
        window.addEventListener('message', editFn);
        return () => window.removeEventListener('message', editFn);
    }, [loading]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {//에디터 내용 빈값 체크
        if (isNull != null) {
            if (!isNull) {
                editorRef.current.contentWindow.postMessage({ type: 'GET' }, '*');
            } else {
                navigate('#', { modal: true });
            }
            setIsNull(null);
        }
    }, [isNull]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {ERModal &&
                <div className='Edit_Modal_Container'>
                    <div className='Edit_Modal'>
                        <EditRoom props={props} editorRef={editorRef} />
                    </div>
                </div>
            }
            {/* 뉴스 검색 */}
            {NSModal &&
                <div className='Edit_Modal_Container'>
                    <div className='Edit_Modal'>
                        <EditSearch props={props} editorRef={editorRef} />
                    </div>
                </div>
            }
            {/* 오픈그래프 */}
            {OGModal &&
                <div className='Edit_Modal_Container'>
                    <div className='Edit_Modal'>
                        <EditOpenGraph editorRef={editorRef} />
                    </div>
                </div>
            }
            <IframeBlock>
                <iframe
                    ref={editorRef}
                    title="EDITOR"
                    style={{
                        width: '100%',
                        height: '100%',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        minHeight: '200px',
                        zIndex: -1,
                    }}
                    src={demoLocation + `?toolbar=true&editMode=${editMode}&isApp=${!!window.ReactNativeWebView}`}
                    frameBorder="0"
                    scrolling="yes"
                    allowFullScreen
                    onLoad={(e) => setLoadStatus(true)}
                />
            </IframeBlock>
        </>
    );
};

export default Edit;
