import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";

const ViewTitle = () => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>탈퇴 내역 상세정보</BodyTitleFont>
            <div>
                <Button cType={`cancel`} name={`목록으로 이동`} width={'150px'} onClick={(e) => navigate('/operation/secession')} />
            </div>
        </BodyTitle>
    )
}

export default ViewTitle;