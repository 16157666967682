import styled from "styled-components";
import React, { useEffect, useState, forwardRef } from "react";
import Label from "../../../common/Label";
import TextField from "../../../common/TextField";
import { getNewsInNewsCard, getNewsInNewsTitle, getNewsInNewsSetting } from "../../../api/inNewsList/inNewsList";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { InNewsListInsert, Flag } from '../../../slice/inNewsListSlice';
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import MessageModal from "../../../modal/Message";
import { inNewsCardInsert, inNewsCardUpdate } from "../../../api/inNewsList/inNewsList";

const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const RegistBody = ({ mode, nid }) => {

    const [title, setTitle] = useState("1");
    const [fromTime, setFromTime] = useState("23:59");
    const [toTime, setToTime] = useState("23:59");
    const [locationSelectBox, setLocationSelectBox] = useState([]);
    const [locationSelect, setLocationSelect] = useState("home8");
    const [useSelectBox, setUseSelectBox] = useState([]);
    const [titleSelectBox, setTitleSelectBox] = useState([]);
    const [useSelect, setUseSelect] = useState("1");
    const [cidtitle, setCidTitle] = useState("");
    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');

    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);

    var StartDate = new Date();
    var nowDay = StartDate.getDate();
    var nowMonth = StartDate.getMonth();
    var nowYear = StartDate.getFullYear();
    const [startDate, setStartDate] = useState(new Date(new Date(nowYear, nowMonth, nowDay)));
    const [endDate, setEndDate] = useState(new Date(nowYear, nowMonth + 1, nowDay));
    const Insert = useSelector((state) => state.inNewsList.value.inNewsListInsert);
    const insertFlag = useSelector((state) => state.inNewsList.value.insertFlag);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setUseSelectBox([{ IS_USE_NAME: '미사용', IS_USE: "0" }, { IS_USE_NAME: '사용', IS_USE: "1" }]);
        getNewsInNewsCard()
            .then((response) => {
                setLocationSelectBox(response.data);
                setLocationSelect(response.data[0].location);
            })
        const titleData = { params: { "info": "title" } };
        getNewsInNewsTitle(titleData)
            .then((response) => {
                setTitleSelectBox(response.data.data);
            })
        if (nid) {
            const settingData = { params: { "nid": nid } };
            getNewsInNewsSetting(settingData)
                .then((response) => {
                    setTitle(response.data.data[0].cid);
                    setUseSelect(response.data.data[0].isUse);
                    setLocationSelect(response.data.data[0].location);
                    setFromTime(response.data.data[0].fromDt.substr(11, 5));
                    setToTime(response.data.data[0].toDt.substr(11, 5));
                    const from_date = response.data.data[0].fromDt.substr(0, 10);
                    const from_yy = from_date.substr(0, 4);
                    const from_mm = from_date.substr(5, 2);
                    const from_dd = from_date.substr(8, 2);
                    const to_date = response.data.data[0].toDt.substr(0, 10);
                    const to_yy = to_date.substr(0, 4);
                    const to_mm = to_date.substr(5, 2);
                    const to_dd = to_date.substr(8, 2);
                    setEndDate(new Date(new Date(to_yy, to_mm - 1, to_dd)));
                    setStartDate(new Date(new Date(from_yy, from_mm - 1, from_dd)));
                    setCidTitle(response.data.data[0].title);
                })
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Insert === true) {
            setMsgTitle("인뉴스 리스트");
            setMessage("진행중");
            dispatch(LoadingModalFlag(true));
            let TO_DT_Month = String(endDate.getMonth() + 1).padStart(2, "0");
            let FROM_DT_Month = String(startDate.getMonth() + 1).padStart(2, "0");
            let TO_DT_Date = String(endDate.getDate()).padStart(2, "0");
            let FROM_DT_Date = String(startDate.getDate()).padStart(2, "0");
            let to_dt = endDate.getFullYear() + "-" + TO_DT_Month + "-" + TO_DT_Date;
            let from_dt = startDate.getFullYear() + "-" + FROM_DT_Month + "-" + FROM_DT_Date;

            if (mode === "add") {
                const data = { "cid": title, "location": locationSelect, "order": "1", "fromDt": from_dt + " " + fromTime, "toDt": to_dt + " " + toTime, "isUse": useSelect };
                inNewsCardInsert(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 200) {
                            if (response.data === "중복되었습니다.") {
                                setMsgTitle("인뉴스 리스트 등록");
                                setMessage("중복된 데이터");
                                dispatch(MessageModalFlag(true));
                                dispatch(InNewsListInsert(false));
                            } else {
                                setMsgTitle("인뉴스 리스트 등록");
                                setMessage("등록 성공");
                                dispatch(MessageModalFlag(true));
                                dispatch(InNewsListInsert(false));
                                dispatch(Flag(true));
                            }
                        } else {
                            setMsgTitle("인뉴스 리스트 등록");
                            setMessage("수정 실패");
                            dispatch(MessageModalFlag(true));
                            dispatch(InNewsListInsert(false));
                        }
                    })
            } else if (mode === "modify") {
                const data = { "nid": nid, "cid": title, "location": locationSelect, "order": "1", "fromDt": from_dt + " " + fromTime, "toDt": to_dt + " " + toTime, "isUse": useSelect };
                inNewsCardUpdate(data)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(LoadingModalFlag(false));
                            if (response.data === "중복되었습니다.") {
                                setMsgTitle("인뉴스 리스트 수정");
                                setMessage("중복된 데이터");
                                dispatch(MessageModalFlag(true));
                                dispatch(InNewsListInsert(false));
                            } else {
                                setMsgTitle("인뉴스 리스트 수정");
                                setMessage("수정 성공");
                                dispatch(MessageModalFlag(true));
                                dispatch(InNewsListInsert(false));
                            }
                        } else {
                            setMsgTitle("인뉴스 리스트 수정");
                            setMessage("수정 실패");
                            dispatch(MessageModalFlag(true));
                            dispatch(InNewsListInsert(false));
                        }
                    })
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/inNewsList");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

        const titleData = { params: { "info": "title", "title": cidtitle } };
        getNewsInNewsTitle(titleData)
            .then((response) => {
                setTitleSelectBox(response.data.data);
                if (response.data.data[0]) {
                    setTitle(response.data.data[0].cid);
                }

            })
    }, [cidtitle]);

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div
                style={{
                    width: "200px",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={onClick}
                ref={ref}
            >
                <div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
                <img
                    src={"/images/component/ic_calendar.png"}
                    style={{ width: "16.67px", height: "15px" }}
                    alt="calendar_icon"
                />
            </div>
        );
    });

    const weekStartDate = (date) => {
        setStartDate(date);
    }

    const weekEndDate = (date) => {
        setEndDate(date);
    }

    const handelselect = (data) => {
        setUseSelect(data);
    }

    const handellocationSelect = (data) => {
        setLocationSelect(data);
    }

    const handeltitleSelect = (data) => {
        setTitle(data);
    }


    const addInNewsItem = (value, type) => {

        if (type === "TO_DT") {
            weekEndDate(value);
        } else if (type === "FROM_DT") {
            weekStartDate(value);
        }

    };
    return (
        <RegistBodyC>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`} required={true}>
                        카드 위치
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    {(locationSelectBox && locationSelectBox.length > 0) ?
                        <Form.Select className="selectBox" defaultValue={locationSelect} onChange={(e) => handellocationSelect(e.target.value)}>
                            {locationSelectBox.map((selectitem, index) => {
                                return <option key={index} value={selectitem.location} >{selectitem.location}</option>
                            })}
                        </Form.Select>
                        :
                        'Loding'
                    }
                </div>
                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`} required={true}>
                        사용 여부
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    {(useSelectBox && useSelectBox.length > 0) ?
                        <Form.Select className="selectBox" defaultValue={useSelect} onChange={(e) => handelselect(e.target.value)}>
                            {useSelectBox.map((selectitem, index) => {
                                return <option key={index} value={selectitem.IS_USE}>{selectitem.IS_USE_NAME}</option>
                            })}
                        </Form.Select>
                        :
                        'Loding'
                    }
                </div>
            </div>
            <div style={{ height: '20px' }} ></div>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`} required={true}>
                        제목
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    {(titleSelectBox && titleSelectBox.length > 0) ?
                        <Form.Select className="selectBox" defaultValue={title} onChange={(e) => handeltitleSelect(e.target.value)}>
                            {titleSelectBox.map((selectitem, index) => {
                                return <option key={index} value={selectitem.cid}>{selectitem.title}</option>
                            })}
                        </Form.Select>
                        :
                        'Loding'
                    }
                </div>
                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`}>
                        검색
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    <TextField
                        value={cidtitle || ""}
                        width="50%"
                        placeholder={cidtitle}
                        onChange={(e) => setCidTitle(e.target.value)}
                    />
                </div>
            </div>

            <div style={{ height: '20px' }} ></div>

            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`} required={true}>
                        시작일
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    <DatePicker
                        customInput={<DatePickerInput />}
                        selected={startDate ? startDate : ""}
                        onChange={(e) => {
                            addInNewsItem(e, "FROM_DT");
                        }}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>

                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`} required={true}>
                        시작시간
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    <input
                        value={fromTime}
                        type="time"
                        min="00:00"
                        max="23:59"
                        style={{
                            width: "150px",
                            padding: "12px",
                            height: "19px",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                        onChange={(e) => {
                            setFromTime(e.target.value);
                        }}
                    />
                </div>
            </div>

            <div style={{ height: '20px' }} ></div>

            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`} required={true}>
                        종료일
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    <DatePicker
                        customInput={<DatePickerInput />}
                        selected={endDate ? endDate : ""}
                        onChange={(e) => {
                            addInNewsItem(e, "TO_DT");
                        }}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                <div style={{ width: "93px", margin: 'auto', textAlign: 'center' }}>
                    <Label type={`nomal`} required={true}>
                        종료시간
                    </Label>
                </div>
                <div style={{ width: "500px" }} >
                    <input
                        value={toTime}
                        type="time"
                        min="00:00"
                        max="23:59"
                        style={{
                            width: "150px",
                            padding: "12px",
                            height: "19px",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                        onChange={(e) => {
                            setToTime(e.target.value);
                        }}
                    />
                </div>
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC >
    )
}

export default RegistBody;