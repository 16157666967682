import axios from "axios";

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_URL,
    timeout: 100000,
});

export const uploadinstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
});

export const cdnuploadinstance = axios.create({
    baseURL: process.env.REACT_APP_CDN_API_URL,
    timeout: 10000,
});

export const devinstance = axios.create({
    baseURL: process.env.REACT_APP_DEV2_API_SERVER_URL,
    timeout: 10000,
});

export const testinstance = axios.create({
    baseURL: '//som.rozeus.com/sombra',
    timeout: 10000,
});




export default uploadinstance;