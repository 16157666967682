import { createSlice } from '@reduxjs/toolkit';

export const inNewsListSlice = createSlice({
	name: 'inNewsList',
	initialState: { value: { inNewsListInsert: false, insertFlag: false } },
	reducers: {
		InNewsListInsert: (state, action) => {
			state.value.inNewsListInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},

	},
});
export const { InNewsListInsert, Flag } = inNewsListSlice.actions;
export default inNewsListSlice.reducer;
