import styled from "styled-components";
import {
    BannerDetailTable,
} from "../../../style/layout";
import { useEffect, useState } from "react";
import React from "react";
import Label from "../../../common/Label";
import Edit from '../edit/Edit'
import { getList } from "../../../api/terms/terms";
const BasicInfoC = styled.div`
`;

export const Text = styled.div`
    display: block;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 24px;
    padding-bottom: 50px;
    word-wrap: break-word;
`;

const ViewBody = ({ tid }) => {

    const [termsDetail, setTermsDetail] = useState();
    const [editData, setEditData] = useState();// eslint-disable-line no-unused-vars

    useEffect(() => {
        const data = { params: { "Tid": tid } };
        getList(data)
            .then((response) => {
                setTermsDetail(response.data[0]);
            })
    }, [tid]);

    const handleNowData = (editData) => {
        setEditData(editData.contents);
    };

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {termsDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div style={{ height: '20px' }} />
                    <BannerDetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>순서</th><td>{termsDetail.tid}</td>
                                <th>제목</th><td>{termsDetail.title}</td>
                            </tr>
                            <tr>
                                <th>등록일</th><td>{termsDetail.reg_dt}</td>
                                <th>수정일</th><td>{termsDetail.mod_dt}</td>
                            </tr>
                            <tr>
                                <th>내용</th><td colSpan={"3"}><Edit onChange={handleNowData} tid={tid} editMode="false"></Edit></td>
                            </tr>
                        </tbody>
                    </BannerDetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
        </React.Fragment>
    );
};

export default ViewBody;