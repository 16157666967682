import { uploadinstance } from "..";

export const getUploadImageReturnUrl = async (file) => {
    const url = `/store/image/upload`;
    const formData = new FormData();
    formData.append("file", file);
    const response = await uploadinstance
        .post(url, formData, null)
        .then((response) => {
            return response;
        });
    return response;
};

export const getCdnUploadImageReturnUrl = async (file) => {
    const url = `/store/image/upload`;
    const formData = new FormData();
    formData.append("file", file);
    const response = await uploadinstance
        .post(url, formData, null)
        .then((response) => {
            return response;
        });
    return response;
};

export const postIssueInsert = async (post) => {
    const url = `/service/api/v1/post`;
    const response = await uploadinstance.post(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const postIssueUpdate = async (data) => {
    const url = `/service/api/v1/post`;
    const response = await uploadinstance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getList = async (data) => {
    const url = `/service/api/v1/post/issue-catch-up`
    const response = await uploadinstance.post(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}
export const getDetailList = async (pid, rid) => {
    const url = `/service/api/v1/post/view/${pid}/${rid}`
    const response = await uploadinstance.get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
}