import styled from "styled-components";
import Label from "../../../../common/Label";
import TextField from "../../../../common/TextField";
import { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Edit from '../edit/Edit';
import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getRoomList, getRoomFoldIdList, getPostDetailList, getPostNewsRoomList } from "../../../../api/cmspost/post";
import Form from 'react-bootstrap/Form';
import { LoadingModalFlag, MessageModalFlag } from '../../../../slice/modalSlice';
import MessageModal from "../../../../modal/Message";
import { CmsPostListInsert, Flag } from '../../../../slice/cmsPostSlice';
import { cmsPostInsert, cmsPostUpdate } from "../../../../api/cmspost/post";

const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const RegistBody = ({ pid, rid, mode }) => {

    const [reservDate, setReservDate] = useState(moment().format("YYYY-MM-DD"));// eslint-disable-line no-unused-vars
    const [title, setTitle] = useState('');
    const [editData, setEditData] = useState();// eslint-disable-line no-unused-vars
    const [revTime, setRevTime] = useState("23:59");// eslint-disable-line no-unused-vars
    const [revDt, setRevDt] = useState(new Date(new Date()));
    const [fromTime, setFromTime] = useState("23:59");// eslint-disable-line no-unused-vars
    const [roomList, setRoomList] = useState();
    const [roomFoldIdList, setRoomFoldIdList] = useState();
    const [selectRid, setSelectRid] = useState();
    const [selectNewsRoomRid, setSelectNewsRoomRid] = useState();
    const [selectFoldId, setSelectFoldId] = useState();
    const [newsRoomList, setNewsRoomList] = useState();
    const localVar = useRef(1);
    const [uid, setUid] = useState();// eslint-disable-line no-unused-vars
    const [pollList, setPollList] = useState([]);
    const [pollCount, setPollCount] = useState(0);
    const [message, setMessage] = useState();
    const [messageTitle, setMessageTitle] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.cmspost.value.cmsPostListInsert);
    const insertFlag = useSelector((state) => state.cmspost.value.insertFlag);


    var UpdatenewArr = new Array([]);
    UpdatenewArr.splice(0, 1);

    const handleNowData = (editData) => {
        setEditData(editData.contents);
    };


    useEffect(() => {
        if (localVar.current === 1) {
            const userInfo = localStorage.getItem('ROZ_ADMIN_USERINFO1');
            const temp = JSON.parse(userInfo);
            setUid(temp.uid);
            if (pid && pid.length > 0) {
                if (temp.uid) {
                    getRoomList(temp.uid).then((response) => {
                        var arr = new Array([]);
                        arr.splice(0, 1);
                        for (var i = 0; i < response.data.data.length; i++) {
                            arr.push({ "rid": response.data.data[i].rid, "roomNm": response.data.data[i].roomNm });
                        }
                        var newArr = [...new Set(arr)];
                        var lastarr = new Array([]);
                        lastarr.splice(0, 1);
                        lastarr.push({ "roomNm": "선택해주세요", "rid": "" });
                        for (var j = 0; j < newArr.length; j++) {
                            lastarr.push({ "rid": newArr[j].rid, "roomNm": newArr[j].roomNm });
                        }
                        setRoomList(lastarr);
                    })
                }
                getPostDetailList(pid, rid).then((response) => {
                    if (response.data[7].choices !== null) {
                        for (var k = 0; k < response.data[7].choices.length; k++) {
                            UpdateaddInputImgList(response.data[7].choices[k].choiceOption);
                        }
                        const from_date = response.data[7].polls.expirationDt;
                        const from_yy = from_date.substr(0, 4);
                        const from_mm = from_date.substr(4, 2);
                        const from_dd = from_date.substr(6, 2);
                        const from_hour = from_date.substr(8, 2);
                        const from_time = from_date.substr(10, 2);
                        setRevDt(new Date(new Date(from_yy, from_mm - 1, from_dd)));
                        setRevTime(from_hour + ":" + from_time);
                        setPollCount(response.data[7].polls.totalVotes);
                    }
                    if (rid !== undefined) {
                        setSelectRid(rid);
                    }
                    setSelectNewsRoomRid(response.data[0].get_post[0].com_rid);
                    setEditData(response.data[0].get_post[0].contents);
                    setTitle(response.data[0].get_post[0].title);
                    setReservDate(response.data[0].get_post[0].reg_dt.substr(0, 10));
                    setFromTime(response.data[0].get_post[0].reg_dt.substr(11, 5));
                    setSelectFoldId(response.data[0].get_post[0].fold_id);
                })
            } else {
                if (temp.uid) {

                    getRoomList(temp.uid).then((response) => {
                        var arr = new Array([]);
                        arr.splice(0, 1);
                        for (var i = 0; i < response.data.data.length; i++) {
                            arr.push({ "rid": response.data.data[i].rid, "roomNm": response.data.data[i].roomNm });
                        }
                        var newArr = [...new Set(arr)];
                        var lastarr = new Array([]);
                        lastarr.splice(0, 1);
                        lastarr.push({ "roomNm": "선택해주세요", "rid": "" });
                        for (var l = 0; l < newArr.length; l++) {
                            lastarr.push({ "rid": newArr[l].rid, "roomNm": newArr[l].roomNm });
                        }
                        setRoomList(lastarr);
                    })
                }
            }
        } else {
            localVar.current = localVar.current + 1;
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (localVar.current === 1) {
            if (selectRid) {
                getPostNewsRoomList(selectRid).then((response) => {
                    var arr = new Array([]);
                    arr.splice(0, 1);
                    for (var i = 0; i < response.data.length; i++) {
                        arr.push({ "roomNm": response.data[i].room_nm, "rid": response.data[i].rid });
                    }
                    var newArr = [...new Set(arr)];
                    var lastarr = new Array([]);
                    lastarr.splice(0, 1);
                    lastarr.push({ "roomNm": "선택해주세요", "rid": "" });
                    for (var j = 0; j < newArr.length; j++) {
                        lastarr.push({ "roomNm": newArr[j].roomNm, "rid": newArr[j].rid });
                    }
                    setNewsRoomList(lastarr);
                })
            }
        }
    }, [selectRid]);

    useEffect(() => {
        if (selectNewsRoomRid) {
            getRoomFoldIdList(selectNewsRoomRid).then((response) => {
                var arr = new Array([]);
                arr.splice(0, 1);
                for (var i = 0; i < response.data.data.length; i++) {
                    arr.push({ "foldId": response.data.data[i].foldId, "foldNm": response.data.data[i].foldNm });
                }
                var newArr = [...new Set(arr)];
                var lastarr = new Array([]);
                lastarr.splice(0, 1);
                lastarr.push({ "foldNm": "선택해주세요", "foldId": "" });
                for (var j = 0; j < newArr.length; j++) {
                    lastarr.push({ "foldId": newArr[j].foldId, "foldNm": newArr[j].foldNm });
                }
                setRoomFoldIdList(lastarr);
            })
        }
    }, [selectNewsRoomRid]);

    const UpdateaddInputImgList = (selectList) => {
        UpdatenewArr.push({
            choiceOptionList: selectList,
        });
        setPollList(UpdatenewArr);
    };

    const onSetTitle = (e) => {
        if (e.target.value.length > 60) {
            setMessageTitle("CMS POST");
            setMessage("제목은 60자이하만 작성 가능합니다");
            dispatch(MessageModalFlag(true));
            return;
        } else {
            setTitle(e.target.value);
        }
    };

    const handleSelectBox = (value, selectboxid) => {

        if (selectboxid !== undefined) {
            if (selectboxid === "Rid") {
                setSelectRid(value);
                setSelectFoldId("");
                setSelectNewsRoomRid("");
            } else if (selectboxid === "roomNm") {
                setSelectNewsRoomRid(value);
                setSelectFoldId("");
            } else if (selectboxid === "foldId") {
                setSelectFoldId(value);
            }
        }

    }

    const addInputList = () => {
        const newArr = pollList.slice();
        if (pollList.length === 0) {
            newArr.push({
                choiceOptionList: "",
            });
            newArr.push({
                choiceOptionList: "",
            });
        } else if (pollList.length === 5) {
            setMessageTitle("CMS POST");
            setMessage("픽클 항목은 최대 5개 까지 적용됩니다");
            dispatch(MessageModalFlag(true));
        } else {
            newArr.push({
                choiceOptionList: "",
            });
        }
        setPollList(newArr);
    };

    const addImgItem = (index, value) => {
        const newArr = pollList.slice();
        newArr[index].choiceOptionList = value;
        setPollList(newArr);
    };

    const clearInputImgList = (i) => {
        if (pollList.length > 2) {
            const newArr = pollList.slice();
            newArr.splice(i, 1);
            setPollList(newArr);
        } else {
            setMessageTitle("CMS POST");
            setMessage("픽클 항목은 최소 2개 이상입니다");
            dispatch(MessageModalFlag(true));
        }
    };

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div
                style={{
                    width: "200px",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={onClick}
                ref={ref}
            >
                <div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
                <img
                    src={"/images/component/ic_calendar.png"}
                    style={{ width: "16.67px", height: "15px" }}
                    alt="calendar_icon"
                />
            </div>
        );
    });

    useEffect(() => {
        if (Insert === true) {
            setMessageTitle("CMS POST");
            setMessage("등록/수정 진행중");
            dispatch(LoadingModalFlag(true));
            if (title === undefined || title === '') {
                dispatch(LoadingModalFlag(false));
                setMessageTitle("CMS POST");
                setMessage("제목을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(CmsPostListInsert(false));
                return;
            } else if (selectRid === undefined || selectRid === '') {
                dispatch(LoadingModalFlag(false));
                setMessageTitle("CMS POST");
                setMessage("뉴스룸을 선택해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(CmsPostListInsert(false));
                return;
            } else if (selectNewsRoomRid === undefined || selectNewsRoomRid === '') {
                dispatch(LoadingModalFlag(false));
                setMessageTitle("CMS POST");
                setMessage("멤버을 선택해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(CmsPostListInsert(false));
                return;
            } else if (selectFoldId === undefined || selectFoldId === '') {
                dispatch(LoadingModalFlag(false));
                setMessageTitle("CMS POST");
                setMessage("폴더를 선택해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(CmsPostListInsert(false));
                return;
            } else if (editData === undefined) {
                dispatch(LoadingModalFlag(false));
                setMessageTitle("CMS POST");
                setMessage("내용을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(CmsPostListInsert(false));
                return;
            }

            const realContents = JSON.stringify(editData);
            let REV_DT_Month = String(revDt.getMonth() + 1).padStart(2, "0");
            let REV_DT_Date = String(revDt.getDate()).padStart(2, "0");
            let rev_dt = revDt.getFullYear() + REV_DT_Month + REV_DT_Date;
            let rev_time = revTime.replace(":", "");
            const add_poll = {};
            add_poll.question = title;
            add_poll.expirationDate = rev_dt + rev_time + "00";
            add_poll.choiceOptionList = [];

            const arr = new Array([]);
            arr.splice(0, 1);
            for (var i = 0; i < pollList.length; i++) {
                arr.push(pollList[i].choiceOptionList);
            }
            add_poll.choiceOptionList = arr;
            if (mode === "add") {
                let data = {};
                if (pollList.length === 0) {
                    data = { "rid": selectRid * 1, "post_tp": "1", "post_st": "N", "title": title, "contents": realContents, "fold_id": selectFoldId * 1 }
                } else {
                    data = { "rid": selectRid * 1, "post_tp": "1", "post_st": "N", "title": title, "contents": realContents, "fold_id": selectFoldId * 1, "add_poll": add_poll }
                }
                cmsPostInsert(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        setMessageTitle("CMS POST");
                        setMessage("등록성공");
                        dispatch(MessageModalFlag(true));
                        dispatch(CmsPostListInsert(false));
                        dispatch(Flag(true));
                    })
            }
            if (mode === "modify") {
                let data = {};
                if (pollList.length === 0) {
                    data = { "rid": selectRid * 1, "post_tp": "1", "post_st": "N", "title": title, "contents": realContents, "fold_id": selectFoldId * 1, "add_poll": null, "pid": pid }
                } else {
                    data = { "rid": selectRid * 1, "post_tp": "1", "post_st": "N", "title": title, "contents": realContents, "fold_id": selectFoldId * 1, "add_poll": add_poll, "pid": pid }
                }
                cmsPostUpdate(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 200) {
                            setMessageTitle("CMS POST");
                            setMessage("수정 완료되었습니다");
                            dispatch(MessageModalFlag(true));
                            dispatch(CmsPostListInsert(false));
                        } else {
                            setMessageTitle("CMS POST");
                            setMessage("수정 오류");
                            dispatch(MessageModalFlag(true));
                            dispatch(CmsPostListInsert(false));
                        }

                    })
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/cms/postInsert");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RegistBodyC>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px', textAlign: 'center' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={title || ""} width='100%' placeholder='포스트 제목을 입력하세요' onChange={onSetTitle} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px', textAlign: 'center' }}><Label type={`nomal`} required={true}>뉴스룸</Label></div>
                {mode === 'add' &&
                    <div style={{ flexGrow: 1 }}>
                        {(roomList && roomList.length > 0) ?
                            <Form.Select className="selectBox" onChange={(e) => handleSelectBox(e.target.value, "Rid")}>
                                {roomList.map((item, index) => {
                                    return <option key={index} value={item.rid}>{item.roomNm}</option>
                                })}
                            </Form.Select>
                            :
                            'Loding'
                        }
                    </div>
                }
                {mode === 'modify' &&
                    <div style={{ flexGrow: 1 }}>
                        {(roomList && roomList.length > 0 && selectRid !== undefined) ?
                            <Form.Select className="selectBox" defaultValue={selectRid} onChange={(e) => handleSelectBox(e.target.value, "Rid")}>
                                {roomList.map((item, index) => {
                                    return <option key={index} value={item.rid}>{item.roomNm}</option>
                                })}
                            </Form.Select>
                            :
                            'Loding'
                        }
                    </div>
                }
                {newsRoomList && newsRoomList.length > 0 &&
                    <div style={{ width: '170px', textAlign: 'center' }}><Label type={`nomal`} required={true}>멤버</Label></div>
                }
                <div style={{ flexGrow: 1 }}>
                    {(newsRoomList && newsRoomList.length > 0) ?
                        <Form.Select className="selectBox" defaultValue={selectNewsRoomRid} onChange={(e) => handleSelectBox(e.target.value, "roomNm")}>
                            {newsRoomList.map((item, index) => {
                                return <option key={index} value={item.rid}>{item.roomNm}</option>
                            })}
                        </Form.Select>
                        :
                        ""
                    }
                </div>
                {roomFoldIdList && roomFoldIdList.length > 1 &&
                    <div style={{ width: '170px', textAlign: 'center' }}><Label type={`nomal`} required={true}>폴더</Label></div>
                }
                <div style={{ flexGrow: 1 }}>
                    {(roomFoldIdList && roomFoldIdList.length > 1) ?
                        <Form.Select className="selectBox" defaultValue={selectFoldId} onChange={(e) => handleSelectBox(e.target.value, "foldId")}>
                            {roomFoldIdList.map((item, index) => {
                                return <option key={index} value={item.foldId} >{item.foldNm}</option>
                            })}
                        </Form.Select>
                        :
                        ""
                    }
                </div>
            </div>
            <div style={{ height: '7px' }}></div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ width: '170px', textAlign: 'center' }}><Label type={`nomal`} required={true}>내용</Label></div>
                <div style={{ flexGrow: 1, border: '1px solid rgba(0,0,0,0.1)' }}>
                    <Edit onChange={handleNowData} pid={pid} rid={rid}></Edit>
                </div>
            </div>
            {pollCount === 0 &&
                <div style={{ display: "flex" }}>
                    <div style={{ width: "1300px" }}></div>
                    <div style={{ width: "100px", margin: "auto" }}>
                        픽클 추가하기
                    </div>
                    <div style={{ width: "30px", margin: "auto" }}>
                        <img alt={'pickel img'} onClick={addInputList} src={`/images/component/ic_plus.png`} style={{ width: '40px', height: '40px', margin: 'auto' }}></img>
                    </div>
                </div>
            }
            {pollList && pollList.map((item, i) => (
                <div key={i} style={{ display: "flex" }}>
                    <div style={{ width: '170px', margin: 'auto', textAlign: 'center' }}><Label type={`nomal`} required={true}>{i + 1}번째 항목</Label></div>
                    <div style={{ width: '400px' }}>
                        {pollCount === 0 &&
                            <TextField value={item.choiceOptionList || ""} width='100%' placeholder='' onChange={(e) => addImgItem(i, e.target.value)} />
                        }
                        {pollCount !== 0 &&
                            <TextField disabled value={item.choiceOptionList || ""} width='100%' placeholder='' onChange={(e) => addImgItem(i, e.target.value)} />
                        }
                    </div>
                    <div style={{ flexGrow: 1 }}></div>
                    <div style={{ margin: 'auto', width: "50px" }}>
                        {pollCount === 0 &&
                            <img alt={'poll img'} onClick={(e) => clearInputImgList(i)} src={`/images/component/ic_minus.png`} style={{ width: '40px', height: '40px', margin: 'auto' }}></img>
                        }
                    </div>
                </div>
            ))}
            {pollList.length > 0 &&
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "170px", margin: 'auto', textAlign: 'center' }}>
                        <Label type={`nomal`} required={true}>
                            예약등록
                        </Label>
                    </div>
                    <div style={{ margin: 'auto', width: "250px" }}>
                        {pollCount === 0 &&
                            <DatePicker
                                customInput={<DatePickerInput />}
                                selected={revDt ? revDt : ""}
                                onChange={(e) => {
                                    setRevDt(e);
                                }}
                                dateFormat="yyyy-MM-dd"
                            />
                        }
                        {pollCount !== 0 &&
                            <DatePicker
                                disabled
                                customInput={<DatePickerInput />}
                                selected={revDt ? revDt : ""}
                                onChange={(e) => {
                                    setRevDt(e);
                                }}
                                dateFormat="yyyy-MM-dd"
                            />
                        }
                    </div>
                    <div style={{ margin: 'auto', width: "250px" }}>
                        {pollCount === 0 &&
                            <input
                                value={revTime}
                                type="time"
                                min="00:00"
                                max="23:59"
                                style={{
                                    width: "150px",
                                    padding: "12px",
                                    height: "16px",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderRadius: "4px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                                onChange={(e) => {
                                    setRevTime(e.target.value);
                                }}
                            />
                        }
                        {pollCount !== 0 &&
                            <input
                                disabled
                                value={revTime}
                                type="time"
                                min="00:00"
                                max="23:59"
                                style={{
                                    width: "150px",
                                    padding: "12px",
                                    height: "16px",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderRadius: "4px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                                onChange={(e) => {
                                    setRevTime(e.target.value);
                                }}
                            />
                        }

                    </div>
                    <div style={{ flexGrow: 1 }}></div>
                </div>
            }
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={messageTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={messageTitle} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}

export default RegistBody;