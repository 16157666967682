import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Timer from './time'
const HeaderC = styled.div`
    width: calc(100% - 2px);
    height: 86px;
    background: #ffffff;
    vertical-align:middle;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items : center;
`;
const HeaderLogoC = styled.div`
    width : 254px;
    height: 100%;
    background : #2B2626;
    display: flex;
    justify-content: center;
    align-items : center;
`;
const HeaderMain = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 254px - 80px);
    margin: 0px 40px;
`;

const Header = ({ navInfo, userInfo }) => {

    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/');
    }

    const memberUpdate = () => {
        navigate('/member/update');
    }

    return (
        <React.Fragment>
            <HeaderC>
                <HeaderLogoC>
                    <img src={`/images/menu/logo.png`} alt='MENU HOME LOGO' style={{ width: '116px' }} />
                </HeaderLogoC>
                <HeaderMain>
                    <div>
                        홈 / {navInfo}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <div style={{ flexGrow: 1 }}>{userInfo.email}</div>
                        <div style={{ width: '20px' }} />
                        <div style={{ fontSize: '12px', color: '#000000', opacity: '0.7', cursor: 'pointer' }} onClick={memberUpdate}>계정설정</div>
                        <div style={{ width: '20px' }} />
                        <div style={{ fontSize: '12px', color: '#000000', opacity: '0.7', cursor: 'pointer' }} onClick={() => { window.open('/dashboard') }}>대시보드</div>
                        <div style={{ width: '20px' }} />
                        <div style={{ fontSize: '12px', color: '#000000', opacity: '0.7', cursor: 'pointer' }} onClick={logout}>로그아웃</div>
                        <div style={{ width: '20px' }} />
                        <img src={`/images/header/ic_question.png`} alt='Question' style={{ width: '20px', height: '20px' }} />
                        <div style={{ width: '20px' }} />
                        <div style={{ fontSize: '12px', color: '#000000', opacity: '0.7' }}><Timer hh="01" mm="00" ss="0" /></div>
                    </div>
                </HeaderMain>
            </HeaderC>
        </React.Fragment>
    )
}

export default Header;