import React, { useState } from "react"
import styled, { keyframes } from 'styled-components';
import { useNavigate } from "react-router-dom";

const MenuC = styled.div`
    flex-grow: 1;
    width: 100%;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    color: #ffffff;
    display: flex;
    flex-direction : column;
    justify-content: center;
    align-items : space-between;
    cursor : pointer;
    opacity: ${(props) => props.show ? '1' : '0.5'};
    :hover {
        opacity: 1;
    }
`;

const SubMenuC = styled.div`
    height: 42px;
    padding: 0px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
    :hover {
        background: #403C3C;
        opacity: 1;
    }

`;
const show = keyframes`
    0% {
        opacity:0;
    }
    100% {
        opacity:0.9;
    }
`;

const SubThirdDepth = styled.div`
    display: ${(props) => (props.show && props.show === true) ? 'block' : 'none'};
    position: absolute;
    padding-top: 8px;
    padding-bottom: 8px;
    right:-179px;
    width:180px;
    margin-left:18px;
    background:#343131;
    color:#ffffff;
    z-index:1;
    animation-name: ${show};
    animation-duration:0.4s;
    animation-duration: leaner;
    animation-direction:alternate;
    animation-fill-mode: forwards;
`;

const ThirdDepth = styled.div`
    display: ${(props) => (props.show && props.show === true) ? 'block' : 'none'};
    background:#343131;
    color:#ffffff;
    z-index:1;
    animation-name: ${show};
    animation-duration:0.4s;
    animation-duration: leaner;
    animation-direction:alternate;
    animation-fill-mode: forwards;
`;

const Menu = ({ menuInfo, subList }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <MenuC show={show} onClick={(e) => (subList && subList.length > 0) && setShow(!show)}>
                <div style={{ display: 'flex', height: '100%', padding: '12px', alignItems: 'center' }}>
                    {(menuInfo.icon && menuInfo.icon !== '') &&
                        <div style={{ display: 'flex', width: '26px', height: '100%', alignItems: 'center' }}>
                            <img src={menuInfo.icon} alt={menuInfo.name} style={{ width: '20px' }} />
                        </div>
                    }
                    <div
                        style={{ display: 'flex', flexGrow: 1, height: '100%', alignItems: 'center' }}
                        onClick={(e) => (menuInfo.targetUrl && menuInfo.targetUrl.length > 0) && navigate(menuInfo.targetUrl)}>
                        {menuInfo.name}
                    </div>
                    {(subList && subList.length > 0) &&
                        <div style={{ width: '24px', height: '100%' }}>
                            <img src={`/images/menu/ic_` + (show ? `up` : `down`) + `_arrow.png`} alt='ARROW' style={{ width: '24px' }} />
                        </div>
                    }
                </div>
            </MenuC>
            {(subList && subList.length > 0) &&
                <ThirdDepth show={show}>
                    <div style={{ width: '100%', background: '#343131', padding: '8px 0px' }}>
                        {subList.map((item, idx) =>
                            <SubMenu key={idx} item={item} />
                        )}
                    </div>
                </ThirdDepth>
            }
        </React.Fragment>
    )
}
const SubMenu = ({ item }) => {
    const [subShow, setSubShow] = useState(false);
    const navigate = useNavigate();
    return (
        <SubMenuC onMouseOver={(e) => setSubShow(true)} onMouseOut={(e) => setSubShow(false)} onClick={(e) => (item.targetUrl && item.targetUrl.length > 0) ? navigate(item.targetUrl) : null}>
            <div style={{ display: 'flex', alignItems: 'center' }}>{item.name}</div>
            {(item.children && item.children.length > 0) &&
                <React.Fragment>
                    <div style={{ display: 'flex', alignItems: 'center' }}><img src={`/images/menu/ic_left_arrow.png`} alt='LEFT ARROW' style={{ width: '24px', height: '24px' }} /></div>
                    <SubThirdDepth show={subShow}>
                        {item.children.map((subItem, subIndex) => {
                            return (
                                <div key={subIndex}>
                                    {subItem.mid !== 0 &&
                                        <SubMenuC key={subIndex} onClick={(e) => navigate(subItem.targetUrl)}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>{subItem.name}</div>
                                        </SubMenuC>
                                    }
                                </div>
                            )
                        })}
                    </SubThirdDepth>
                </React.Fragment>
            }
        </SubMenuC>
    )
}

export default Menu