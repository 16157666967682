import { useEffect, useState } from 'react';
import moment from 'moment';

const TimeGap = ({ data }) => {
    const [sec, setSec] = useState(0);
    const gap = (value) => {
        const now = moment();
        const timeValue = moment(value, 'YYYY-MM-DD HH:mm:ss');
        const secondTime = Math.floor(now - timeValue) / 1000;
        const betweenTime = Math.floor((now - timeValue) / 1000 / 60);
        if (secondTime < 60) return `1분전`;
        if (betweenTime < 60) {
            const gaps = () => {// eslint-disable-line no-unused-vars
                if (secondTime - betweenTime * 60 < 10) {
                    return '0' + Math.floor(secondTime - betweenTime * 60);
                } else return Math.floor(secondTime - betweenTime * 60);
            };
            return `${betweenTime.toString()}분전`;
        }
        const betweenTimeHour = Math.floor(betweenTime / 60);
        if (betweenTimeHour < 24) {
            return `${betweenTimeHour.toString()}시간전`;
        }
        const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
        if (betweenTimeDay < 365) {
            return `${betweenTimeDay}일전`;
        }
        return `${Math.floor(betweenTimeDay / 365).toString()}년전`;
    };

    useEffect(() => {
        const i = setInterval(() => setSec(sec + 1), 1000);
        return () => clearInterval(i);
    }, [sec]);

    return gap(data);
};
export default TimeGap;
