import React, { useEffect, useState } from "react";
import { getAuthMenu } from "../../api/menu/menu";
import AdminMenu from "../../common/menu/AdminMenu";
import AdminTag from "../../common/menu/AdminTag";
import UserTag from "../../common/menu/UserTag";
import LeftMenuTemplate from "../../templates/LeftMenuTemplate";


const MenuContainer = ({ mode, userInfo }) => {

    const [menuList, setMenuList] = useState();
    useEffect(() => {
        if (localStorage.getItem('ROZ_ADMIN_USERINFO1')!==null) {
            const userInfo = localStorage.getItem('ROZ_ADMIN_USERINFO1');
            const data = { "userId": JSON.parse(userInfo).loginId };
            
            getAuthMenu(data)
                .then((response) => {
                    setMenuList(response.data);
                })
        }
    }, [])

    return (
        <LeftMenuTemplate
            userTag={mode === 'admin' ? <AdminTag /> : <UserTag userInfo={userInfo} />}
            menu={mode === 'admin' ? <AdminMenu menuList={menuList} /> : null}
        />
    )
}

export default MenuContainer;