import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Label from "../../../common/Label";
import TextField from "../../../common/TextField";
import { getUserDetails, insertUser, updateUser } from "../../../api/member/member";
import { useSelector, useDispatch } from 'react-redux';
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import { Flag } from '../../../slice/memberSlice';
import { useNavigate } from "react-router-dom";
import MessageModal from "../../../modal/Message";
import Form from 'react-bootstrap/Form';
import OneDayCalendarPush from "../../../common/OneDayCalendarPush";
import { MemberInsert } from '../../../slice/serviceMemberSlice';
import moment from "moment";
const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const RegistBody = ({ uid, mode }) => {

    const insertFlag = useSelector((state) => state.serviceMember.value.insertFlag);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.serviceMember.value.memberInsert);

    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [memberUid, setMemberUid] = useState('');
    const [memberId, setMemberId] = useState('');
    const [birthday, setBirthday] = useState('X');
    const [birthdayDt, setBirthdayDt] = useState(moment().format("2021-01-01"));
    const [gender, setGender] = useState('X');
    const [memberPassCheck, setMemberPassCheck] = useState('');
    const [memberPass, setMemberPass] = useState('');
    const [modeType, setModeType] = useState('A');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (uid) {
            setTitle('회원 관리 상세')
            setMessage("회원 정보 조회중");
            dispatch(LoadingModalFlag(true));
            const data = {
                params: { "uid": uid }
            }
            getUserDetails(data).then((response) => {
                dispatch(LoadingModalFlag(false));
                setMemberUid(response.data.userInfo[0].uid);
                setMemberId(response.data.userInfo[0].userId);
                if (response.data.userInfo[0].birthday.length === 8) {
                    setBirthday('O');
                    setBirthdayDt(moment().format(response.data.userInfo[0].birthday.substr(0, 4) + "-" + response.data.userInfo[0].birthday.substr(4, 2) + "-" + response.data.userInfo[0].birthday.substr(6, 2)));
                }
                if (response.data.userInfo[0].gender.length === 2) {
                    console.log(response.data.userInfo[0].gender);
                    if (response.data.userInfo[0].gender === '남자') {
                        setGender(1);
                    } else {
                        setGender(0);
                    }
                }
                setModeType('M');
            })
        }
    }, [dispatch, uid]);


    useEffect(() => {
        if (Insert === true) {
            let data = {};
            if (mode === 'add') {
                const emailRegex = /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/;
                setTitle('회원 등록');
                if (memberId === "" || memberId === undefined) {
                    setMessage("아이디를 입력해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(MemberInsert(false));
                    return;
                } else if (!emailRegex.test(memberId)) {
                    dispatch(MemberInsert(false));
                    setMessage("이메일 양식이 아닙니다");
                    dispatch(MessageModalFlag(true));
                    return;
                } else if (memberPass === "" || memberPass === undefined) {
                    setMessage("비밀번호를 입력해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(MemberInsert(false));
                    return;
                } else if (memberPassCheck === "" || memberPassCheck === undefined) {
                    setMessage("비밀번호 확인해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(MemberInsert(false));
                    return;
                } else if (memberPassCheck !== memberPass) {
                    setMessage("비밀번호 체크해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(MemberInsert(false));
                    return;
                }
                setMessage("회원 등록중");
                dispatch(LoadingModalFlag(true));
                if (birthday === 'O') {
                    data = { "userId": memberId, "birthday": birthdayDt, "pwd": memberPass };
                    if (gender !== 'X') {
                        data = { "userId": memberId, "birthday": birthdayDt, "pwd": memberPass, "gender": gender };
                    }
                } else {
                    data = { "userId": memberId, "pwd": memberPass };
                    if (gender !== 'X') {
                        data = { "userId": memberId, "pwd": memberPass, "gender": gender };
                    }
                }
                insertUser(data).then((response) => {
                    if (response.status === 201) {
                        setMessage("회원가입 완료");
                        dispatch(MessageModalFlag(true));
                        dispatch(MemberInsert(false));
                    } else {
                        setMessage(response.data.message);
                        dispatch(MessageModalFlag(true));
                        dispatch(MemberInsert(false));
                    }
                })
            } else {
                if (birthday === 'O') {
                    data = { "uid": uid, "birthday": birthdayDt.replaceAll('-', '') };
                    if (gender !== 'X') {
                        data = { "uid": uid, "birthday": birthdayDt.replaceAll('-', ''), "gender": gender };
                    }
                } else {
                    if (gender !== 'X') {
                        data = { "uid": uid, "gender": gender };
                    } else {
                        data = { "uid": uid };
                    }
                }
                setTitle('회원 정보 수정')
                setMessage("회원 정보 수정 중");
                dispatch(LoadingModalFlag(true));
                updateUser(data).then((response) => {
                    setMessage("회원정보 수정 완료");
                    dispatch(MessageModalFlag(true));
                    dispatch(MemberInsert(false));
                })

            }
            dispatch(LoadingModalFlag(false));
            dispatch(MemberInsert(false));
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/member/management");
        }
    }, [insertFlag, MModal, navigate, dispatch]);


    return (
        <RegistBodyC>
            <div style={{ width: '100%', height: '565px', maxHeight: '1000px', background: 'White' }}>
                {mode !== 'add' &&
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: "180px", margin: 'auto', textAlign: 'center' }}>
                            <Label type={`nomal`} required={true}>
                                Uid
                            </Label>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <TextField
                                value={memberUid || ""}
                                width="70%"
                                placeholder={memberUid}
                                disabled
                                onChange={(e) => setMemberUid(e.target.value)}
                            />
                        </div>
                    </div>
                }
                {mode === 'add' &&
                    <div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: "180px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    회원아이디
                                </Label>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <TextField
                                    value={memberId || ""}
                                    width="70%"
                                    placeholder={`아이디를 작성해주세요`}
                                    onChange={(e) => setMemberId(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: "180px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    비밀번호
                                </Label>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <TextField
                                    value={memberPass || ""}
                                    width="70%"
                                    placeholder={'password'}
                                    onChange={(e) => setMemberPass(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: "180px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    비밀번호 확인
                                </Label>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <TextField
                                    value={memberPassCheck || ""}
                                    width="70%"
                                    placeholder={'password'}
                                    onChange={(e) => setMemberPassCheck(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                }
                {mode !== 'add' &&
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: "180px", margin: 'auto', textAlign: 'center' }}>
                            <Label type={`nomal`} required={true}>
                                회원아이디
                            </Label>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <TextField
                                value={memberId || ""}
                                width="70%"
                                placeholder={memberId}
                                disabled
                                onChange={(e) => setMemberId(e.target.value)}
                            />
                        </div>
                    </div>
                }
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: "180px", margin: 'auto', textAlign: 'center' }}>
                        <Label type={`nomal`} required={true}>
                            생일
                        </Label>
                    </div>
                    {mode === 'add' &&
                        <div style={{ width: '300px' }}>
                            <Form.Select defaultValue={birthday} onChange={(e) => setBirthday(e.target.value)} className="memberSelectBox">
                                <option value={'X'}>선택안함</option>
                                <option value={'O'}>설정</option>
                            </Form.Select>&nbsp;
                        </div>
                    }
                    {mode !== 'add' &&
                        <div style={{ width: '300px' }}>
                            {modeType === 'M' &&
                                <Form.Select defaultValue={birthday} onChange={(e) => setBirthday(e.target.value)} className="memberSelectBox">
                                    <option value={'X'}>선택안함</option>
                                    <option value={'O'}>설정</option>
                                </Form.Select>
                            }
                        </div>
                    }
                    {birthday === 'O' &&
                        <div style={{ flexGrow: 1 }}>
                            <OneDayCalendarPush setDate={setBirthdayDt} splashDate={birthdayDt} />
                        </div>
                    }
                    {birthday !== 'O' &&
                        <div style={{ flexGrow: 1 }}>
                        </div>
                    }
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: "180px", margin: 'auto', textAlign: 'center' }}>
                        <Label type={`nomal`} required={true}>
                            성별
                        </Label>
                    </div>
                    {mode === 'add' &&
                        <div style={{ width: '300px' }}>
                            <Form.Select onChange={(e) => setGender(e.target.value)} className="memberSelectBox">
                                <option value={'X'}>선택안함</option>
                                <option value={1}>남자</option>
                                <option value={0}>여자</option>
                            </Form.Select>&nbsp;
                        </div>
                    }
                    {mode !== 'add' &&
                        <div style={{ width: '300px' }}>
                            {modeType === 'M' &&
                                <Form.Select defaultValue={gender} onChange={(e) => setGender(e.target.value)} className="memberSelectBox">
                                    <option value={'X'}>선택안함</option>
                                    <option value={1}>남자</option>
                                    <option value={0}>여자</option>
                                </Form.Select>
                            }
                        </div>
                    }
                    <div style={{ flexGrow: 1 }}>
                    </div>
                </div>
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={title} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={title} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}
export default RegistBody;