import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewTitle from "../../components/qna/view/ViewTitle";
import ViewBody from "../../components/qna/view/ViewBody";
import Title from "../../components/qna/Title";
import Body from "../../components/qna/Body";
import QnA from "../../templates/qna/QnA";

const QnAContainer = ({ page }) => {

    const { qna_id } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();
    const QAList = ['서비스 이용 등 관련문의', '광고 및 마케팅 제휴문의', '기타'];

    useEffect(() => {
        if (page && page === "view") {
            setTitle(<ViewTitle />);
            setBody(<ViewBody qna_id={qna_id} QAList={QAList} />);
        } else {
            setTitle(<Title />);
            setBody(<Body QAList={QAList} />);
        }
    }, [page, qna_id]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <QnA title={title} body={body} />
        </div>
    );
};

export default QnAContainer;