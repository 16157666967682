import styled from "styled-components";
import {
    BannerDetailTable,
} from "../../../style/layout";
import { useEffect, useState } from "react";
import React from "react";
import Label from "../../../common/Label";
import { getDetailNovel } from "../../../api/novel/novel";

const BasicInfoC = styled.div`
`;

export const Text = styled.div`
    display: block;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 24px;
    padding-bottom: 50px;
    word-wrap: break-word;
`;

const ViewBody = ({ nkey }) => {

    const [novelDetail, setNovelDetail] = useState();

    useEffect(() => {
        const data = nkey * 1
        getDetailNovel(data)
            .then((response) => {
                for (var i = 0; i < response.data.list.length; i++) {
                    if (response.data.list[i].status === false) {
                        response.data.list[i].statusNm = "종료"
                    } else if (response.data.list[i].status === true) {
                        response.data.list[i].statusNm = "라이브"
                    }

                    if (response.data.list[i].issuance === '0' || response.data.list[i].issuance === 0) {
                        response.data.list[i].issuanceNm = "고정"
                    } else if (response.data.list[i].issuance === '1' || response.data.list[i].issuance === 1) {
                        response.data.list[i].issuanceNm = "타임"
                    }

                    if (response.data.list[i].completionStatus === '0' || response.data.list[i].completionStatus === 0) {
                        response.data.list[i].completionStatusNm = "완결"
                    } else if (response.data.list[i].completionStatus === '1' || response.data.list[i].completionStatus === 1) {
                        response.data.list[i].completionStatusNm = "미완결"
                    }
                    if (response.data.list[i].to_dt === null) {
                        response.data.list[i].to_dt = "없음"
                    }
                }
                setNovelDetail(response.data.list[0]);
            })
    }, [nkey]);


    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {novelDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div style={{ height: '20px' }} />
                    <BannerDetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>작가명</th><td>{novelDetail.writer}</td>
                                <th>태그정보</th><td>{novelDetail.tagInfo}</td>
                            </tr>
                            <tr>
                                <th>순서</th><td>{novelDetail.n_order}</td>
                                <th>포스트번호</th><td>{novelDetail.pid}</td>
                            </tr>
                            <tr>
                                <th>제목</th><td>{novelDetail.title}</td>
                                <th>상태</th><td>{novelDetail.statusNm}</td>
                            </tr>
                            <tr>
                                <th>완결여부</th><td>{novelDetail.completionStatusNm}</td>
                                <th>회차정보</th><td>{novelDetail.episode}</td>
                            </tr>
                            <tr>
                                <th>등록일시</th><td>{novelDetail.reg_dt}</td>
                                <th>수정일시</th><td>{novelDetail.mod_dt}</td>
                            </tr>
                            <tr>
                                <th>좋아요</th><td>{novelDetail.like_cnt}</td>
                                <th>Up개수</th><td>{novelDetail.up_cnt}</td>
                            </tr>
                            <tr>
                                <th>Down 개수</th><td>{novelDetail.down_cnt}</td>
                                <th>평점</th><td>{novelDetail.rating}</td>
                            </tr>
                            <tr>
                                <th>조회수</th><td>{novelDetail.read_cnt}</td>
                            </tr>
                        </tbody>
                    </BannerDetailTable>
                </BasicInfoC>
            }
            {novelDetail &&
                <BasicInfoC>
                    <div style={{ height: '10px' }} />
                    <Label type='title'>발행정보</Label>
                    <div style={{ height: '20px' }} />
                    <BannerDetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>발행구분</th><td>{novelDetail.issuanceNm}</td>
                                <th>제휴사</th><td>{novelDetail.affiliates}</td>
                            </tr>
                            <tr>
                                <th>적용 시작일</th><td>{novelDetail.from_dt}</td>
                                <th>적용 종료일</th><td>{novelDetail.to_dt}</td>
                            </tr>
                            <tr>
                                <th>로그인 회차</th><td>{novelDetail.login}</td>
                                <th>무료 회차</th><td>{novelDetail.free}</td>
                            </tr>
                        </tbody>
                    </BannerDetailTable>
                </BasicInfoC>
            }
            {novelDetail &&
                <BasicInfoC>
                    <div style={{ height: '10px' }} />
                    <Label type='title'>표기정보</Label>
                    <div style={{ height: '20px' }} />
                    <BannerDetailTable style={{ width: '30%' }}>
                        <tbody>
                            <tr>
                                <th>미리보기</th>
                            </tr>
                            <tr>
                                <td><img alt={`미리보기 이미지`} src={novelDetail.thumbnail}></img></td>
                            </tr>
                        </tbody>
                    </BannerDetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
        </React.Fragment>
    );
};

export default ViewBody;