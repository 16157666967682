import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/secession/Body";
import Title from "../../components/secession/Title";
import ViewBody from "../../components/secession/view/ViewBody";
import ViewTitle from "../../components/secession/view/ViewTitle";
import Secession from "../../templates/secoession/Secession";

const SecessionControlContainer = ({ page }) => {

    const { regId } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'view') {
            setTitle(<ViewTitle regId={regId} />);
            setBody(<ViewBody regId={regId} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, regId])

    return (
        <Secession
            title={title}
            body={body}
        />
    )
}

export default SecessionControlContainer;