import styled from "styled-components";
import Label from "../../../common/Label";
import { useEffect, useState } from "react";
import Radio from "../../../common/Radio";
import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import OneDayCalendarPush from "../../../common/OneDayCalendarPush";
import moment from "moment";
import { getDetailPush, getPushUseData } from "../../../api/push/push";
import './push.css';
import FileAttachSplash from "../../../common/FileAttachSplash";
import { useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import Button from "../../../common/Button";
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from "../../../modal/Message";
import { PushUseDate, Multivalues, PushSendRange, PushDate, PushFromTime, PushRepeatTime, PushWeekPushType, PushInsert, PushTitle, PushContents, PushTexturl, DayPushType, PushType, DefaultPushData, Flag } from '../../../slice/pushSlice';
import { pushInsert, pushUpdate } from "../../../api/push/push";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';


const RegistBodyC = styled.div`
    width: 100%;
    margin: 50px 0px;
`;

const RegistBody = ({ mode, pseq }) => {

    const [pushDay, setPushDay] = useState(moment().format("YYYY-MM-DD"));
    const [fromDay, setFromDay] = useState(moment().format("YYYY-MM-DD"));
    const [selectMultivalues, setSelectMultivalues] = useState([]);
    const [multioption, setMultiOption] = useState([]);
    const [pushImg, setPushImg] = useState();
    const [message, setMessage] = useState('');

    const pushUseDate = useSelector((state) => state.push.value.pushUseDate);
    const multivalues = useSelector((state) => state.push.value.multivalues);
    const pushSendRange = useSelector((state) => state.push.value.pushSendRange);
    const pushDate = useSelector((state) => state.push.value.pushDate);
    const pushFromTime = useSelector((state) => state.push.value.pushFromTime);
    const pushRepeatTime = useSelector((state) => state.push.value.pushRepeatTime);
    const pushWeekPushType = useSelector((state) => state.push.value.pushWeekPushType);
    const pushTitle = useSelector((state) => state.push.value.pushTitle);
    const pushContents = useSelector((state) => state.push.value.pushContents);
    const pushTexturl = useSelector((state) => state.push.value.pushTexturl);
    const dayPushType = useSelector((state) => state.push.value.dayPushType);
    const pushType = useSelector((state) => state.push.value.pushType);
    const insertFlag = useSelector((state) => state.push.value.insertFlag);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.push.value.pushInsert);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setMultiOption([{ title: '월', value: '1' }, { title: '화', value: '2' }, { title: '수', value: '3' }, { title: '목', value: '4' }, { title: '금', value: '5' }, { title: '토', value: '6' }, { title: '일', value: '7' }])
        getPushUseData()
            .then((response) => {
                dispatch(PushUseDate(response.data));
                if (pseq === undefined) {
                    dispatch(DefaultPushData());
                    return;
                } else {
                    getDetailPush(pseq)
                        .then((response) => {
                            setPushImg(response.data.pushList[0].image);
                            dispatch(PushSendRange(response.data.pushList[0].sendRange * 1));
                            if (response.data.pushList[0].sendTp === '0') {
                                dispatch(PushDate(response.data.pushList[0].sendTp));
                            } else if (response.data.pushList[0].sendTp === '9') {
                                dispatch(PushDate(response.data.pushList[0].sendTp));
                                setFromDay(response.data.pushList[0].bookDt.substr(0, 10));
                                dispatch(PushFromTime(response.data.pushList[0].bookDt.substr(11, 5)));
                            } else {
                                dispatch(PushDate('10'));
                                dispatch(PushWeekPushType(response.data.pushList[0].sendTp));
                                dispatch(DayPushType(response.data.pushList[0].sendTp));
                                if (response.data.pushList[0].sendTp === "1234567" || response.data.pushList[0].sendTp === "12345") {
                                    dispatch(PushType('1'));
                                } else {
                                    dispatch(PushType('2'));
                                }
                                const newArr = new Array([]);
                                for (var i = 0; i < response.data.pushList[0].sendTp.length; i++) {
                                    var value = response.data.pushList[0].sendTp.substr(i, 1)
                                    if (value === '1') {
                                        newArr.push({
                                            value: value,
                                            title: '월'
                                        });
                                    } else if (value === '2') {
                                        newArr.push({
                                            value: value,
                                            title: '화'
                                        });
                                    } else if (value === '3') {
                                        newArr.push({
                                            value: value,
                                            title: '수'
                                        });
                                    } else if (value === '4') {
                                        newArr.push({
                                            value: value,
                                            title: '목'
                                        });
                                    } else if (value === '5') {
                                        newArr.push({
                                            value: value,
                                            title: '금'
                                        });
                                    } else if (value === '6') {
                                        newArr.push({
                                            value: value,
                                            title: '토'
                                        });
                                    } else if (value === '7') {
                                        newArr.push({
                                            value: value,
                                            title: '일'
                                        });
                                    }
                                }
                                newArr.splice(0, 1);
                                dispatch(Multivalues(newArr));
                                setPushDay(response.data.pushList[0].bookDt.substr(0, 10));
                                dispatch(PushRepeatTime(response.data.pushList[0].bookDt.substr(11)));
                            }
                            dispatch(PushContents(response.data.pushList[0].msg));
                            dispatch(PushTitle(response.data.pushList[0].title));
                            dispatch(PushTexturl(response.data.pushList[0].url));
                        })
                }
            })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const selector = JSON.stringify(selectMultivalues);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"send_tp":"', '');
        const deleteData3 = deleteData2.replaceAll('"}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll(',', '');
        dispatch(DayPushType(deleteData5));
    }, [selectMultivalues]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Insert === true) {
            const formData = new FormData();
            if (pushTitle === "") {
                dispatch(PushInsert(false));
                setMessage("푸시 제목을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (pushContents === "") {
                dispatch(PushInsert(false));
                setMessage("푸시 내용을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (pushTexturl !== "") {
                if (pushTexturl.indexOf('=') !== -1) {
                    if (pushTexturl.indexOf('?') === -1) {
                        dispatch(PushInsert(false));
                        setMessage("url을 확인해주세요");
                        dispatch(MessageModalFlag(true));
                        return;
                    }
                }
                if (pushTexturl.substr(0, 1) !== "/") {
                    dispatch(PushInsert(false));
                    setMessage("url을 확인해주세요");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            }
            if (pushImg === "" || pushImg === undefined) {
            } else {
                if (pushImg.name) {
                    formData.append('file', pushImg);
                } else {
                    formData.append('image', pushImg);
                }
            }

            if (pushDate === '10') {
                //반복발송
                if (pushType === '1') {
                    const bookDt = pushDay.replaceAll('-', '') + "" + pushFromTime.replaceAll(':', '') + "00";
                    formData.append('bookDt', bookDt);
                    formData.append('sendTp', pushWeekPushType);
                } else if (pushType === '2') {
                    const bookDt = pushDay.replaceAll('-', '') + "" + pushRepeatTime.replaceAll(':', '');
                    if (bookDt.length === 12) {
                        const upbootDt = bookDt + "00";
                        formData.append('bookDt', upbootDt);
                        formData.append('sendTp', dayPushType);
                    } else {
                        formData.append('bookDt', bookDt);
                        formData.append('sendTp', dayPushType);
                    }

                }
            } else if (pushDate === '9') {
                const boot_dt = fromDay.replaceAll('-', '') + "" + pushFromTime.replace(':', '') + "00";
                formData.append('bookDt', boot_dt);
                formData.append('sendTp', pushDate);
            } else if (pushDate === '0') {
                formData.append('sendTp', pushDate);
            }

            formData.append('title', pushTitle);
            formData.append('msg', pushContents);
            formData.append('url', pushTexturl);
            formData.append('sendRange', pushSendRange);

            if (mode === "modify") {
                setMessage("수정진행중");
                dispatch(LoadingModalFlag(true));
                formData.append('pSeq', pseq);
                pushUpdate(formData)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 500) {
                            dispatch(PushInsert(false));
                            setMessage("수정 오류입니다");
                            dispatch(MessageModalFlag(true));
                        } else if (response.status === 200) {
                            dispatch(PushInsert(false));
                            setMessage("수정 완료되었습니다");
                            dispatch(MessageModalFlag(true));
                        }
                    })
            } else if (mode === "add") {
                setMessage("등록진행중");
                dispatch(LoadingModalFlag(true));
                pushInsert(formData)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 500) {
                            dispatch(PushInsert(false));
                            setMessage("등록 오류입니다");
                            dispatch(MessageModalFlag(true));
                        } else if (response.status === 200) {
                            dispatch(PushInsert(false));
                            setMessage("등록 완료되었습니다");
                            dispatch(MessageModalFlag(true));
                            dispatch(Flag(true));
                        }
                    })
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    const PushTypeChange = (e) => {
        dispatch(PushWeekPushType("1234567"));
        dispatch(DayPushType("1"));
        dispatch(PushType(e));
    }

    const onRemove = (e) => {
        const eArr = e.slice();
        const newArr = new Array([]);
        newArr.splice(0, 1);
        for (const index in eArr) {
            newArr.push({
                send_tp: e[index].value,
            });
        }
        setSelectMultivalues(newArr);
    }
    const onSelect = (e) => {
        const eArr = e.slice();
        const newArr = new Array([]);
        newArr.splice(0, 1);
        for (const index in eArr) {
            newArr.push({
                send_tp: e[index].value,
            });
        }
        setSelectMultivalues(newArr);
    }

    const addImgItem = (file, type) => {
        setPushImg(file);
    };
    const Imgeclick = () => {
        setPushImg("");
    };

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/push");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RegistBodyC>
            {pushUseDate &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>사용자 범위</Label></div>
                    <div style={{ flexGrow: 1 }}>
                        {pushUseDate.map((selectitem, index) => {
                            return <Radio key={index} name='aDayAgo' value={selectitem.s_seq} checked={selectitem.s_seq === pushSendRange} onChange={(e) => dispatch(PushSendRange(selectitem.s_seq))}>{selectitem.s_desc}</Radio>
                        })}
                    </div>
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>발송 일시</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='pushDay' value="즉시발송" checked={pushDate === '0' && true} onChange={(e) => dispatch(PushDate('0'))}>즉시 발송</Radio>
                    <Radio name='pushDay' value="예약발송" checked={pushDate === '9' && true} onChange={(e) => dispatch(PushDate('9'))}>예약 발송</Radio>
                    <Radio name='pushDay' value="반복발송" checked={pushDate === '10' && true} onChange={(e) => dispatch(PushDate('10'))}>반복 발송</Radio>
                </div>
            </div>
            {pushDate === '9' &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>예약 발송 일자</Label></div>
                    <div style={{ display: 'flex' }}>
                        <OneDayCalendarPush setDate={setFromDay} splashDate={fromDay} />
                        <input
                            value={pushFromTime}
                            type="time"
                            min="00:00"
                            max="23:59"
                            style={{
                                width: "150px",
                                padding: "12px",
                                height: "16px",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: "4px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                            onChange={(e) => {
                                dispatch(PushFromTime(e.target.value));
                            }}
                        />
                    </div>
                </div>
            }
            {pushDate === '10' &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>발송 방식</Label></div>
                    <Form.Select onChange={(e) => PushTypeChange(e.target.value)} className="selectBox" value={pushType}>
                        <option value={'1'}>매일</option>
                        <option value={'2'}>매주</option>
                    </Form.Select>
                    {pushType === '1' &&
                        <Form.Select onChange={(e) => dispatch(PushWeekPushType(e.target.value))} className="selectBox" value={pushWeekPushType}>
                            <option value={'1234567'}>주말 포함</option>
                            <option value={'12345'}>주말 제외</option>
                        </Form.Select>
                    }
                    {pushType === '2' &&
                        <Multiselect
                            displayValue="title"
                            onRemove={(e) => onRemove(e)}
                            onSelect={(e) => onSelect(e)}
                            options={multioption}
                            selectedValues={multivalues}
                            style={{ width: '30px' }}
                        />
                    }
                </div>
            }
            <div style={{ height: '10px' }}></div>
            {pushDate === '10' &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>반복 종료 일자</Label></div>
                    <div style={{ display: 'flex' }}>
                        <OneDayCalendarPush setDate={setPushDay} splashDate={pushDay} />
                        <input
                            value={pushRepeatTime}
                            type="time"
                            min="00:00"
                            max="23:59"
                            style={{
                                width: "150px",
                                padding: "12px",
                                height: "16px",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: "4px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                            onChange={(e) => {
                                dispatch(PushRepeatTime(e.target.value));
                            }}
                        />
                    </div>
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>랜딩 URL</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={pushTexturl || ""} onChange={(e) => dispatch(PushTexturl(e.target.value))} width='1070px' placeholder='URL 입력' /></div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: "170px", margin: 'auto' }}>
                    <Label type={`nomal`}>
                        푸시 이미지
                    </Label>
                </div>
                <div style={{ flexGrow: 1 }}>
                    <FileAttachSplash
                        value={pushImg ? pushImg : ""}
                        width="1000px"
                        placeholder="푸시 이미지를 선택하세요"
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                addImgItem(e.target.files[0]);
                            }
                        }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: "170px", margin: 'auto' }}>
                </div>
                <div style={{ flexGrow: 1 }}>
                    <Button
                        width={'160px'}
                        name={"푸시 이미지 제거"}
                        onClick={(e) => Imgeclick()}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={pushTitle || ""} onChange={(e) => dispatch(PushTitle(e.target.value))} width='1200px' placeholder='제목을 입력하세요' /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>내용</Label></div>
                <div style={{ flexGrow: 1 }}><TextArea value={pushContents || ""} onChange={(e) => dispatch(PushContents(e.target.value))} width='1200px' placeholder='내용을 입력하세요' /></div>
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="푸시 등록" message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="푸시 등록" message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    );
};

export default RegistBody;
