import { BodyTitle, BodyTitleFont } from "../../style/layout";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button";

const Title = () => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>회원 관리</BodyTitleFont>
            <Button name={`회원 관리`} width={'160px'} onClick={(e) => navigate('/member/management/add')} />
        </BodyTitle>
    );
};
export default Title;