import { createSlice } from '@reduxjs/toolkit';
export const enterSlice = createSlice({
    name: 'enter',
    initialState: { value: { multivalues: "", dayPushType: "1", enterFromTime: "23:59", insertFlag: false, enterInsert: false } },
    reducers: {
        Multivalues: (state, action) => {
            state.value.multivalues = action.payload;
        },
        DayEvent: (state, action) => {
            state.value.dayEvnet = action.payload;
        },
        DefaultEnvetData: (state) => {
            state.value.multivalues = [];
            state.value.dayPushType = "0";
            state.value.enterFromTime = "23:59";
        },
        EnterFromTime: (state, action) => {
            state.value.enterFromTime = action.payload;
        },
        EnterInsert: (state, action) => {
            state.value.enterInsert = action.payload;
        },
        Flag: (state, action) => {
            state.value.insertFlag = action.payload;
        },

    },
});
export const { Multivalues, DayEvent, DefaultEnvetData, EnterFromTime, EnterInsert, Flag } = enterSlice.actions;
export default enterSlice.reducer;
