import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
	name: 'login',
	initialState: { value: { Info: null } },
	reducers: {
		LoginInfo: (state, action) => {
			state.value.Info = action.payload;
		},
	},
});
export const { LoginInfo } = loginSlice.actions;
export default loginSlice.reducer;
