import { BodyTitle, BodyTitleFont } from "../../style/layout";
import Button from "../../common/Button";
import { useDispatch } from 'react-redux';
import React from 'react';
import { AuthInsert } from '../../slice/memberSlice';
const Title = () => {


    const dispatch = useDispatch();

    const memberUpdate = () => {
        dispatch(AuthInsert(true));
    }

    return (
        <BodyTitle>
            <BodyTitleFont>회원 정보 수정</BodyTitleFont>
            <Button name={`회원 정보 수정`} width={'160px'} onClick={memberUpdate} />

        </BodyTitle>
    );
};
export default Title;