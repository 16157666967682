import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar, TableHeader, TableRow } from "../../../style/layout";
import Button from "../../../common/Button";
import Check from "../../../common/Check";
import Paging from "../../../common/Paging";
import Label from "../../../common/Label";
import { getList, getRoomList, getPostDeleteList, getPostNewsRoomList, getRoomFoldIdList } from "../../../api/cmspost/post";
import Form from 'react-bootstrap/Form';
import { MessageModalFlag } from '../../../slice/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import MessageModal from "../../../modal/Message";

const Body = () => {

    const navigate = useNavigate();

    const [size, setSize] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [totalCnt, setTotalCnt] = useState(0);
    const [postList, setPostList] = useState();
    const [deleteListPid, setDeleteListPid] = useState([]);
    const [realPage_no, setRealPage_no] = useState(1);

    const [selectNewsRoomRid, setSelectNewsRoomRid] = useState();
    const [selectFoldId, setSelectFoldId] = useState();
    const [newsRoomList, setNewsRoomList] = useState();
    const [roomFoldIdList, setRoomFoldIdList] = useState();
    const [roomList, setRoomList] = useState();
    const [selectRid, setSelectRid] = useState(0);
    const userInfo = localStorage.getItem('ROZ_ADMIN_USERINFO1');
    const temp = JSON.parse(userInfo);
    const [message, setMessage] = useState();
    const [messageTitle, setMessageTitle] = useState();
    const dispatch = useDispatch();
    const MModal = useSelector((state) => state.modal.value.Message);

    const handleSelectBox = (value, selectboxid) => {
        if (selectboxid === "Rid") {
            setSelectRid(value);
            setSelectFoldId("");
            setSelectNewsRoomRid("");
        } else if (selectboxid === "roomNm") {
            setSelectNewsRoomRid(value);
            setSelectFoldId("");
        } else if (selectboxid === "foldId") {
            setSelectFoldId(value);
        }
    }


    useEffect(() => {
        getRoomList(temp.uid).then((response) => {
            var arr = new Array([]);
            arr.splice(0, 1);
            for (var i = 0; i < response.data.data.length; i++) {
                arr.push({ "rid": response.data.data[i].rid, "roomNm": response.data.data[i].roomNm });
            }
            var newArr = [...new Set(arr)];
            var lastarr = new Array([]);
            lastarr.splice(0, 1);
            for (var j = 0; j < newArr.length; j++) {
                lastarr.push({ "rid": newArr[j].rid, "roomNm": newArr[j].roomNm });
            }
            if (selectRid === 0) {
                setSelectRid(newArr[0].rid);
            }
            setRoomList(lastarr);
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectRid !== 0) {
            getPostNewsRoomList(selectRid).then((response) => {
                var arr = new Array([]);
                arr.splice(0, 1);
                for (var i = 0; i < response.data.length; i++) {
                    arr.push({ "roomNm": response.data[i].room_nm, "rid": response.data[i].rid });
                }
                var newArr = [...new Set(arr)];
                var lastarr = new Array([]);
                lastarr.splice(0, 1);
                for (var j = 0; j < newArr.length; j++) {
                    lastarr.push({ "roomNm": newArr[j].roomNm, "rid": newArr[j].rid });
                }
                setNewsRoomList(lastarr);
                if (newArr[0]) {
                    setSelectNewsRoomRid(newArr[0].rid);
                }
            })
        }
    }, [selectRid]);

    useEffect(() => {
        getRoomFoldIdList(selectNewsRoomRid).then((response) => {
            var arr = new Array([]);
            arr.splice(0, 1);
            for (var i = 0; i < response.data.data.length; i++) {
                arr.push({ "foldId": response.data.data[i].foldId, "foldNm": response.data.data[i].foldNm });
            }
            var newArr = [...new Set(arr)];
            var lastarr = new Array([]);
            lastarr.splice(0, 1);
            for (var j = 0; j < newArr.length; j++) {
                lastarr.push({ "foldId": newArr[j].foldId, "foldNm": newArr[j].foldNm });
            }
            if (newArr[0]) {
                setSelectFoldId(newArr[0].foldId);
            }
            setRoomFoldIdList(lastarr);
        })
    }, [selectNewsRoomRid]);


    useEffect(() => {
        if (selectFoldId) {
            const data = { params: { "size": size, "offset": page_no, "foldId": selectFoldId } };
            getList(data).then((response) => {
                if (response) {
                    setPostList(response.data.data);
                    setTotalCnt(response.data.total);
                }
            })
        }
    }, [selectFoldId, page_no]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (selectFoldId) {
            const data = { params: { "size": size, "offset": 1, "foldId": selectFoldId } };
            getList(data).then((response) => {
                if (response) {
                    setPostList(response.data.data);
                    setTotalCnt(response.data.total);
                }
            })
        }
    }, [size]);// eslint-disable-line react-hooks/exhaustive-deps


    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }

    const deletehandle = () => {

        const selector = JSON.stringify(deleteListPid);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"pid":"', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll('"', '');
        if (deleteData5 === "") {
            setMessageTitle("CMS POST");
            setMessage("삭제할 포스트를 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData6 = { "pid": deleteData5 };
        getPostDeleteList(deleteData6)
            .then((response) => {
                if (response.status === 200) {
                    setMessageTitle("CMS POST");
                    setMessage("삭제 완료되었습니다");
                    dispatch(MessageModalFlag(true));
                    const checkboxes = document.getElementsByName('selectCheckBox');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    })
                    const data = { params: { "size": size, "offset": page_no, "foldId": selectFoldId } };
                    getList(data).then((response) => {
                        if (response) {
                            setPostList(response.data.data);
                            setTotalCnt(response.data.total);
                        }
                    })
                    return;
                } else {
                    setMessageTitle("CMS POST");
                    setMessage("삭제 실패하였습니다");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            })
    }

    const checkhandle = (e) => {
        const newArr = deleteListPid.slice();
        for (const index in deleteListPid) {
            if (`${deleteListPid[index].cid}` === e) {
                newArr.splice(index, 1);
                setDeleteListPid(newArr);
                return;
            }
        }
        newArr.push({
            pid: e,
        });
        setDeleteListPid(newArr);
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {(totalCnt && (totalCnt > 0)) &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCnt}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        </div>
                        <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                    </div>
                </div>
                <div className='title'></div>
                <div className='component'>
                </div>
                <div style={{ height: '20px' }}></div>
                <div style={{ display: 'flex' }}>
                    {newsRoomList && newsRoomList.length > 0 &&
                        <div style={{ width: '100px', textAlign: 'center', margin: 'auto' }}><Label type={`nomal`} required={true}>뉴스룸</Label></div>
                    }
                    <div style={{ width: '200px' }}>
                        {(roomList && roomList.length > 0) ?
                            <Form.Select className="selectBox" defaultValue={selectRid} onChange={(e) => handleSelectBox(e.target.value, "Rid")}>
                                {roomList.map((item, index) => {
                                    return <option key={index} value={item.rid}>{item.roomNm}</option>
                                })}
                            </Form.Select>
                            :
                            'Loding'
                        }
                    </div>
                    {newsRoomList && newsRoomList.length > 0 &&
                        <div style={{ width: '80px', textAlign: 'center', margin: 'auto' }}><Label type={`nomal`} required={true}>멤버</Label></div>
                    }
                    {newsRoomList && newsRoomList.length > 0 &&
                        <div style={{ width: '200px' }}>
                            {(newsRoomList && newsRoomList.length > 0) ?
                                <Form.Select className="selectBox" defaultValue={selectNewsRoomRid} onChange={(e) => handleSelectBox(e.target.value, "roomNm")}>
                                    {newsRoomList.map((item, index) => {
                                        return <option key={index} value={item.rid}>{item.roomNm}</option>
                                    })}
                                </Form.Select>
                                :
                                "뉴스룸선택 해주세요"
                            }
                        </div>
                    }
                    {roomFoldIdList && roomFoldIdList.length > 0 &&
                        <div style={{ width: '80px', textAlign: 'center', margin: 'auto' }}><Label type={`nomal`} required={true}>폴더</Label></div>
                    }
                    <div style={{ flexGrow: 1 }}>
                        {(roomFoldIdList && roomFoldIdList.length > 0) ?
                            <Form.Select className="selectBox" defaultValue={selectFoldId} onChange={(e) => handleSelectBox(e.target.value, "foldId")}>
                                {roomFoldIdList.map((item, index) => {
                                    return <option key={index} value={item.foldId}>{item.foldNm}</option>
                                })}
                            </Form.Select>
                            :
                            ""
                        }
                    </div>
                </div>
                <div style={{ height: '20px' }}></div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(postList && postList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>포스트 seq</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>룸이름</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>반응수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>댓글수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>조회수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>공개여부</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>등록일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>수정일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>예약일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {postList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        {item.uid * 1 === temp.uid * 1 &&
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check onChange={(e) => checkhandle(item.pid)} name='selectCheckBox' /></div>
                                        }
                                        {item.uid * 1 !== temp.uid * 1 &&
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                                        }
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.pid}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'left' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.roomNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.reactCount}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.totalCount}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.readLog}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.isUse === "1" ? '공개' : '비공개'}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.regDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.modDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/cms/postInsert/view/${item.pid}/${item.rid}`)}>{item.revDt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCnt} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
                {MModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="check" title={messageTitle} message={message} />
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;