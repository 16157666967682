import DatePicker from "react-datepicker";
import React, { forwardRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.css";
import moment from "moment";

const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
	return (
		<div
			style={{
				width: "150px",
				padding: "12px",
				border: "1px solid rgba(0, 0, 0, 0.1)",
				borderRadius: "4px",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			}}
			onClick={onClick}
			ref={ref}
		>
			<div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
			<img
				src={"/images/component/ic_calendar.png"}
				style={{ width: "16.67px", height: "15px" }}
				alt="calendar_icon"
			/>
		</div>
	);
});

function OneDayCalendar_splash({ setDate, splashDate }) {
	const [SDate, setSDate] = React.useState();
	useEffect(() => {
		if (SDate) {
			setDate(SDate);
		}
	}, [SDate]);// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<DatePicker
				selected={moment(splashDate).toDate()}
				onChange={(date) => setSDate(moment(date).format("YYYY-MM-DD"))}
				customInput={<DatePickerInput />}
				dateFormat="yyyy-MM-dd"
			/>
		</div>
	);
}

export default OneDayCalendar_splash;
