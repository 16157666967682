import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";

const ViewTitle = ({ itoId }) => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>스플레쉬 인트로 상세정보</BodyTitleFont>
            <div>
                <Button name={`수정`} onClick={(e) => navigate(`/operation/splashIntro/update/${itoId}`)} />&nbsp;
                <Button cType={`cancel`} width={'150px'} name={`목록으로 이동`} onClick={(e) => navigate('/operation/splashIntro')} />
            </div>
        </BodyTitle>
    )
}

export default ViewTitle;