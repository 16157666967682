import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import React from "react";
import { NoticeInsert } from '../../../slice/noticeSlice';

const RegistTitle = ({ mode }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleRegist = () => {
        dispatch(NoticeInsert(true));
    };

    return (
        <BodyTitle>
            <BodyTitleFont>공지사항 {mode === "add" ? "등록" : "수정"}</BodyTitleFont>
            <div>
                <Button name={mode === "add" ? "등록" : "수정"} onClick={handleRegist} />
                &nbsp;
                <Button cType={`cancel`} name={`취소`} onClick={(e) => navigate("/operation/notice/")} />
            </div>
        </BodyTitle>
    );
};

export default RegistTitle;
