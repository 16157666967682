import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const FileFieldC = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	height: 42px;
	margin-top: 8px;
`;

const InputC = styled.input`
	box-sizing: border-box;
	border: none;
	height: 100%;
	width: 100%;
	padding: 0px 12px;
	padding-right: 12px;
	border-radius: 4px;
	font-size: 14px;
	color: ${(props) =>
		!(props.validText && props.validText.length > 0) ? "#9B9C9F" : "#ED5255"};
	background: #ffffff;
	border: 1px solid
		${(props) =>
		!(props.validText && props.validText.length > 0)
			? "rgba(0, 0, 0, 0.1)"
			: "#ED5255"};
	transition: all 0.15s ease-in;
	&:hover {
		border: 1px solid
		${(props) =>
		!(props.validText && props.validText.length > 0)
			? !props.disabled
				? "#c0c0c0;"
				: "rgba(0, 0, 0, 0.1)"
			: "#ED5255"};
	}
	&:focus {
		outline: none;
		color: ${(props) =>
		!(props.validText && props.validText.length > 0) ? "#000000" : "#ED5255"};
		border: 1px solid
		${(props) =>
		!(props.validText && props.validText.length > 0)
			? "#646464;"
			: "#ED5255"};
	}
	&::placeholder {
		color: #9b9c9f;
		font-size: 14px;
	}
`;
const GuideC = styled.div`
	height: 14px;
	color: #9b9c9f;
	font-size: 10px;
`;

const ValidC = styled.div`
	height: 14px;
	color: #ed5255;
	font-size: 10px;
`;

const ButtonC = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
  	padding: 0px 12px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s ease-in;
	font-family: Noto Sans KR;
	color: #ffffff;
	background: #ed5255;
	border: 0px;
	width: 100px;
	height: 42px;
	:hover {
		background: #d73336;
	}
	:active {
		background: #b82528;
	}
`;

const FileAttach_splash = ({
	width,
	value,
	placeholder,
	disabled,
	onChange,
	guideText,
	validText,
}) => {

	const [inValue, setInValue] = useState();
	const [preValue, setPreValue] = useState(null);
	const fileInput = useRef();

	useEffect(() => {
		if (value) {
			if (typeof value === "string") {
				if (value.length > 0) {
					setInValue(value);
					setPreValue(value);
					// onChange && onChange(value);
				} else {
					if (preValue === null) {
						setInValue("");
						setPreValue(null);
					} else {
						setInValue(preValue);
					}
				}
			} else {
				if (value.length > 0) {
					setInValue(value[0].name);
					setPreValue(value[0].name);
				} else {
					if (preValue === null) {
						setInValue("");
						setPreValue(null);
					} else {
						setInValue(preValue);
					}
				}
			}
		} else {
			setInValue("");
			setPreValue(null);
		}
	}, [value]);// eslint-disable-line react-hooks/exhaustive-deps

	const handleChange = (e) => {
		if (e.target.files.length > 0) {
			setInValue(e.target.files[0].name);
			setPreValue(e.target.files[0].name);
			onChange && onChange(e);
		} else {
			if (preValue === null) {
				setInValue("");
				setPreValue(null);
				onChange && onChange(e);
			} else {
				setInValue(preValue);
				onChange && onChange(e);
			}
		}
	};

	return (
		<div
			style={{
				display: "inline-block",
				maxHeight: "58px",
				width: width && width !== "" ? width : "200px",
			}}
		>
			<FileFieldC validText={validText} disabled={disabled}>
				<input
					type="file"
					style={{ display: "none" }}
					ref={fileInput}
					onChange={handleChange}
				/>
				<InputC
					type={"text"}
					placeholder={placeholder}
					value={inValue || ""}
					readOnly={true}
					onChange={onChange}
					guideText={guideText}
					validText={validText}
					onClick={(e) => fileInput.current.click()}
				/>
				&nbsp;&nbsp;
				<div style={{ display: "inline-block" }}>
					<ButtonC>
						<div
							style={{ width: "100%" }}
							onClick={(e) => fileInput.current.click()}
						>
							파일선택
						</div>
					</ButtonC>
				</div>
			</FileFieldC>
			{!validText && validText !== "" ? (
				<GuideC>{guideText}</GuideC>
			) : (
				<ValidC>{validText}</ValidC>
			)}
		</div>
	);
};

export default FileAttach_splash;
