import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../style/layout";
import Button from "../../common/Button";

const Title = () => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>시스템푸시 알람 설정</BodyTitleFont>
            <Button name={`시스템 푸시 생성하기`} width={'180px'} onClick={(e) => navigate('/operation/push/add')} />
        </BodyTitle>
    )
}

export default Title;