import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import TextField from "../../common/TextField";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import { getList } from "../../api/terms/terms";
const Body = () => {

    const navigate = useNavigate();
    const [termsList, setTermsList] = useState();// eslint-disable-line no-unused-vars
    const [totalCount, setTotalCount] = useState();// eslint-disable-line no-unused-vars

    useEffect(() => {
        getList()
            .then((response) => {
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].is_use === 1 || response.data[i].is_use === "1") {
                        response.data[i].isUseNm = '사용'
                    } else {
                        response.data[i].isUseNm = '미사용'
                    }
                }
                setTermsList(response.data);

            })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {totalCount &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCount}</span>개)
                            </React.Fragment>
                        }
                    </div>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(termsList && termsList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>사용여부</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }}>등록일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }}>수정일</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {termsList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }} onClick={(e) => navigate(`/operation/termsofservice/view/${item.tid}`)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }} onClick={(e) => navigate(`/operation/termsofservice/view/${item.tid}`)}>{item.isUseNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }} onClick={(e) => navigate(`/operation/termsofservice/view/${item.tid}`)}>{item.reg_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }} onClick={(e) => navigate(`/operation/termsofservice/view/${item.tid}`)}>{item.mod_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;