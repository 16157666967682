import { BodyTitle, BodyTitleFont } from "../../style/layout";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button";

const Title = () => {
  const navigate = useNavigate();
  return (
    <BodyTitle>
      <BodyTitleFont>Banner 설정 관리</BodyTitleFont>
      <Button name={`배너 설정 등록`} width={'160px'} onClick={(e) => navigate('/operation/bannerList/add')} />
    </BodyTitle>
  );
};

export default Title;
