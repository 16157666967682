import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/newsBrief/Body";
import RegistBody from "../../components/newsBrief/regist/RegistBody";
import RegistTitle from "../../components/newsBrief/regist/RegistTitle";
import Title from "../../components/newsBrief/Title";
import ViewBody from "../../components/newsBrief/view/ViewBody";
import ViewTitle from "../../components/newsBrief/view/ViewTitle";
import NewsBriefing from "../../templates/newsBriefing/NewsBriefing";

const NewsBriefingContainer = ({ page }) => {

    const { pid } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={(pid && pid !== '') ? 'modify' : 'add'} pid={pid} />);
            setBody(<RegistBody mode={(pid && pid !== '') ? 'modify' : 'add'} pid={pid} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle pid={pid} />);
            setBody(<ViewBody pid={pid} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, pid])

    return (
        <div>
            <NewsBriefing title={title} body={body} />
        </div>
    )
}

export default NewsBriefingContainer;