import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Label from "../../../common/Label";
import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import RegistSearchBody from "./RegistSearchBody";
import { getDetailInNewsList } from "../../../api/inNews/inNews";
import { getAnalyze } from "../../../api/news/news";
import { useSelector, useDispatch } from 'react-redux';
import { InNewsInsert, Flag } from '../../../slice/inNewsSlice';
import { inNewsInsert, inNewsUpdate } from "../../../api/inNews/inNews";
import { useNavigate } from "react-router-dom";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import MessageModal from "../../../modal/Message";

const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const RegistBody = ({ cid, mode }) => {

    const [title, setTitle] = useState('');
    const [msgTitle, setMsgTitle] = useState('');

    const [searchKeyword, setSearchKeyword] = useState('');
    const [updateSearchKeyword, setUpdateSearchKeyword] = useState('');
    const [exceptKeyword, setExceptKeyword] = useState('');
    const [updateExceptKeyword, setUpdateExceptKeyword] = useState('');
    const [message, setMessage] = useState('');
    const [description, setDescription] = useState('');
    const [analy, setAnaly] = useState();
    const [searchDt, setSearchDt] = useState(new Date());

    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.inNews.value.inNewsInsert);
    const insertFlag = useSelector((state) => state.inNews.value.insertFlag);
    const inNewsDate = useSelector((state) => state.inNews.value.inNewsDate);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (cid) {
            const data = { params: { "cid": cid } };
            getDetailInNewsList(data)
                .then((response) => {
                    const dt = response.data.data[0].searchDt;
                    const dt2 = dt.replaceAll("-", "");
                    const dt3 = dt2.replaceAll(" ", "");
                    const dt4 = dt3.replaceAll(":", "");
                    setSearchDt(dt4);
                    setTitle(response.data.data[0].title);
                    setExceptKeyword(response.data.data[0].exceptKeyword);
                    setUpdateExceptKeyword(response.data.data[0].exceptKeyword);
                    setUpdateSearchKeyword(response.data.data[0].searchKeyword);
                    setSearchKeyword(response.data.data[0].searchKeyword);
                    setDescription(response.data.data[0].description);
                    getAnalyze(response.data.data[0].searchKeyword)
                        .then((response) => {
                            const target = response.data.data[0].tokens.map(item => item.token);
                            setAnaly(target);
                        })
                })
        }
    }, [cid]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Insert === true) {
            const searchTodt = new Date();
            var startDay = String(searchTodt.getDate()).padStart(2, "0");
            var startMonth = String(searchTodt.getMonth() + 1).padStart(2, "0");
            var startYear = searchTodt.getFullYear();
            const searchToDt2 = startYear + "" + startMonth + "" + startDay + "235900";
            if (title === "") {
                setMsgTitle("인뉴스 등록");
                setMessage("제목을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(InNewsInsert(false));
                return;
            } else if (searchKeyword === "") {
                setMsgTitle("인뉴스 등록");
                setMessage("검색어를 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(InNewsInsert(false));
                return;
            } else if (description === "") {
                setMsgTitle("인뉴스 등록");
                setMessage("내용을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(InNewsInsert(false));
                return;
            } else if (inNewsDate * 1 > searchToDt2 * 1) {
                setMsgTitle("인뉴스 등록");
                setMessage("검색 시작일은 현재일보다 작아야됩니다");
                dispatch(MessageModalFlag(true));
                dispatch(InNewsInsert(false));
                return;
            }
            dispatch(InNewsInsert(false));
            if (mode === "add") {
                setMsgTitle("인뉴스 등록");
                setMessage("등록 진행중");
                dispatch(LoadingModalFlag(true));
                const data = { "title": title, "searchKeyword": searchKeyword, "exceptKeyword": exceptKeyword, "description": description, "searchDt": inNewsDate };
                inNewsInsert(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 200) {
                            dispatch(InNewsInsert(false));
                            setMessage("등록 완료되었습니다");
                            dispatch(MessageModalFlag(true));
                            dispatch(Flag(true));
                        } else {
                            dispatch(InNewsInsert(false));
                            setMessage("등록 실패되었습니다");
                            dispatch(MessageModalFlag(true));
                        }
                    })
                    .catch((err) => {
                        dispatch(LoadingModalFlag(false));
                        dispatch(InNewsInsert(false));
                        setMessage("등록실패");
                        dispatch(MessageModalFlag(true));
                    });
            } else if (mode === "modify") {
                setMsgTitle("인뉴스 수정");
                setMessage("수정 진행중");
                dispatch(LoadingModalFlag(true));
                const data = { "cid": cid, "title": title, "searchKeyword": searchKeyword, "exceptKeyword": exceptKeyword, "description": description, "searchDt": inNewsDate };
                inNewsUpdate(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 200) {
                            dispatch(InNewsInsert(false));
                            setMessage("수정 성공");
                            dispatch(MessageModalFlag(true));
                        } else {
                            dispatch(InNewsInsert(false));
                            setMessage("수정실패");
                            dispatch(MessageModalFlag(true));
                        }
                    })
                    .catch((err) => {
                        dispatch(LoadingModalFlag(false));
                        dispatch(InNewsInsert(false));
                        setMessage("수정실패");
                        dispatch(MessageModalFlag(true));
                    });
            }

        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    const onSetTitle = (e) => {
        setTitle(e.target.value);
    };

    const SearchKeyword = (e) => {
        setSearchKeyword(e);
        getAnalyze(e)
            .then((response) => {
                const target = response.data.data[0].tokens.map(item => item.token);
                setAnaly(target);
            })
    }

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/inNews");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RegistBodyC>
            <div>InNews 등록 정보</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={title || ""} width='100%' placeholder='In News 제목을 입력하세요' onChange={onSetTitle} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>키워드</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={searchKeyword || ""} width='100%' placeholder='검색 Keyword를 입력하세요'
                    onChange={(e) => {
                        SearchKeyword(e.target.value);
                    }} />
                </div>
                <div style={{ width: '170px' }}></div>
                <div style={{ width: '170px' }}><Label type={`nomal`}>배제어</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={exceptKeyword || ""} width='100%' placeholder='배제 Keyword를 입력하세요'
                    onChange={(e) => {
                        setExceptKeyword(e.target.value);
                    }} />
                </div>
            </div>
            {analy &&
                <React.Fragment>
                    <div style={{ display: 'flex' }}>
                        <div>추천 검색어</div>
                        <div style={{ width: '100px' }}></div>
                        <div>
                            {analy.map((item, index) => {
                                return <span key={index}>{item}&nbsp;</span>;
                            })}
                        </div>
                    </div>
                </React.Fragment>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>내용</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <TextArea
                        value={description && description}
                        width={"100vh"}
                        height={"150px"}
                        placeholder="내용을 입력해주세요"
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div style={{ height: '20px' }}></div>
            <div style={{ borderTop: '4px solid #C4C4C4' }}></div>
            <div style={{ marginTop: '50px' }}>검색어 테스트</div>
            {updateSearchKeyword &&
                <RegistSearchBody cid={cid} searchKeyword={updateSearchKeyword} exceptKeyword={updateExceptKeyword} searchDt={searchDt} mode={mode}>
                </RegistSearchBody>
            }
            {!updateSearchKeyword &&
                <RegistSearchBody cid={cid} mode={mode}>
                </RegistSearchBody>
            }
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}

export default RegistBody;