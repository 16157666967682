import { instance } from "..";

export const getAnother = async (startDate, endDate, page, size) => {
    // const url = 'http://13.125.32.8:19002/rback/news/another-news';
    const url = '/news/another-news';
    const params = `?sentiTp=WR&start=${startDate}&end=${endDate}&page=${page}&size=${size}`
    const response = await instance.get(url + params, null, null)
        .then((response) => {
            return response;
        })
    return response;
}


export const updateAnother = async (data) => {
    // const url = `http://13.125.32.8:19002/rback/news/another-news/WR`;
    const url = `/news/another-news/WR`;
    const response = await instance.post(
        url,
        { data: data },
        {
            headers: {
                'content-type': 'application/json'
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const defaultLoad = async (nid, noNid) => {
    const url = `//som.rozeus.com/sombra/news/same-news?nid=${nid}&notInIds=${nid}${noNid.length > 0 ? ',' + noNid : ''}`;
    // const url = `/news/same-news?nid=${nid}&notInIds=${nid}${noNid.length>0 ? ','+noNid:''}`;
    const response = await instance.get(
        url,
        null,
        null
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}