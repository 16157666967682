import { createSlice } from '@reduxjs/toolkit';

export const novelManagementSlice = createSlice({
	name: 'novelManagement',
	initialState: { value: { novelManagementInsert: false, insertFlag: false } },
	reducers: {
		NovelManagementInsert: (state, action) => {
			state.value.novelManagementInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { NovelManagementInsert, Flag } = novelManagementSlice.actions;
export default novelManagementSlice.reducer;
