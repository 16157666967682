import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/issue/Body";
import RegistBody from "../../components/issue/regist/RegistBody";
import RegistTitle from "../../components/issue/regist/RegistTitle";
import Title from "../../components/issue/Title";
import ViewBody from "../../components/issue/view/ViewBody";
import ViewTitle from "../../components/issue/view/ViewTitle";
import Issue from "../../templates/issue/Issue";

const IssueContainer = ({ page }) => {

    const { pid, rid } = useParams();// eslint-disable-line no-unused-vars
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={pid && pid !== "" ? "modify" : "add"} pid={pid} rid={rid} />);
            setBody(<RegistBody mode={pid && pid !== "" ? "modify" : "add"} pid={pid} rid={rid} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle pid={pid} rid={rid} />);
            setBody(<ViewBody pid={pid} rid={rid} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }

    }, [page, pid])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Issue
            title={title}
            body={body}
        />
    )
}

export default IssueContainer;