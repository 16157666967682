import styled from "styled-components";
import { BannerDetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import React, { useState, useEffect } from "react";
import { bannerSelect } from "../../../api/bannerList/bannerList";

const BasicInfoC = styled.div`
`;

const ViewBody = ({ bLocation }) => {


    const [bannerListDetail, setBannerListDetail] = useState();


    useEffect(() => {
        const data = { params: { "b_location": bLocation } };
        bannerSelect(data)
            .then((response) => {
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].b_type === "0" || response.data[i].b_type === 1) {
                        response.data[i].b_type_name = "롤링";
                    } else if (response.data[i].b_type === "1" || response.data[i].b_type === 1) {
                        response.data[i].b_type_name = "리스트";
                    } else if (response.data[i].b_type === "2" || response.data[i].b_type === 2) {
                        response.data[i].b_type_name = "웹소설";
                    }
                }
                setBannerListDetail(response.data)
            })
    }, [bLocation])

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {bannerListDetail &&
                <BasicInfoC>
                    <Label type='title'>Banner List 정보</Label>
                    <div style={{ height: '20px' }} />
                    <BannerDetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>배너경로</th><td>{bannerListDetail[0].b_location}</td>
                                <th>배너타입</th><td>{bannerListDetail[0].b_type_name}</td>
                            </tr>
                        </tbody>
                    </BannerDetailTable>
                    <div style={{ height: '20px' }} />
                    <Label type='title'>Banner List 상세 정보</Label>
                    {bannerListDetail.map((item, index) => {
                        return (
                            <div key={index}>
                                <div style={{ height: '20px' }} />
                                <BannerDetailTable style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>bSeq</th><td>{item.b_seq}</td>
                                            <th>배너이름</th><td>{item.b_desc}</td>
                                        </tr>
                                        <tr>
                                            <th>시작일</th><td>{item.from_dt}</td>
                                            <th>종료일</th><td>{item.to_dt}</td>
                                        </tr>
                                        <tr>
                                            <th>순서</th><td>{item.b_order}</td>
                                            <th>사용여부</th><td>{item.is_use === 1 ? "사용" : "미사용"}</td>
                                        </tr>
                                    </tbody>
                                </BannerDetailTable>
                            </div>
                        )
                    })
                    }
                    <div style={{ height: '20px' }} />
                </BasicInfoC>
            }
        </React.Fragment>
    )
}

export default ViewBody;