import styled from 'styled-components';
import theme from '../components/cms/theme';

export const MoreList = styled.a`
    display: flex;
    min-height: 48px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: none;
    background: white;
    font-family: 'NS me';
    font-size: 14px;
    letter-spacing: 0.28px;
    border-top: 1px solid ${theme.color.GrayColor};
    border-bottom: 2px solid ${theme.color.GrayColor};
    outline: none;
`;
