import { createSlice } from '@reduxjs/toolkit';
export const modalSlice = createSlice({
    name: 'modal',
    initialState: { value: { Message: false, Loading: false, NewsSearch: false, EditOpenGraphModal: false, EditNewsSearchModal: false, EditMessage: false, EditRoomModal: false } },
    reducers: {
        MessageModalFlag: (state, action) => {
            state.value.Message = action.payload;
        },
        LoadingModalFlag: (state, action) => {
            state.value.Loading = action.payload;
        },
        NewsSearchModalFlag: (state, action) => {
            state.value.NewsSearch = action.payload;
        },
        ExcelLoadingModalFlag: (state, action) => {
            state.value.ExcelLoading = action.payload;
        },
        EditOpenGraphModalFlag: (state, action) => {
            state.value.EditOpenGraphModal = action.payload;
        },
        EditNewsSearchModalFlag: (state, action) => {
            state.value.EditNewsSearchModal = action.payload;
        },
        EditMessageModalFlag: (state, action) => {
            state.value.EditMessage = action.payload;
        },
        EditRoomModalFlag: (state, action) => {
            state.value.EditRoomModal = action.payload;
        },

    },
});
export const { MessageModalFlag, LoadingModalFlag, NewsSearchModalFlag, ExcelLoadingModalFlag, EditOpenGraphModalFlag, EditNewsSearchModalFlag, EditMessageModalFlag, EditRoomModalFlag } = modalSlice.actions;
export default modalSlice.reducer;
