import React, { useEffect, useState } from "react";
import { getAnalyze, getSearchNews } from "../../../api/news/news";
import { SearchBar, TableHeader, TableRow } from "../../../style/layout";
import Paging from "../../../common/Paging";
import '../../../style/common.css';
import NewsSearchModal from "../../../modal/NewsSearch";
import MessageModal from "../../../modal/Message";
import { useDispatch, useSelector } from 'react-redux';
import { NewsSearchModalFlag, MessageModalFlag } from '../../../slice/modalSlice';


const ViewSearchBody = ({ searchKeyword, exceptKeyword, fromDt }) => {

    const [pagesInfo, setPagesInfo] = useState();
    const [totalCnt, setTotalCnt] = useState();
    const [page_no, setPage_no] = useState(1);
    const [newsMessage, setNewsMessage] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [analy, setAnaly] = useState();
    const NModal = useSelector((state) => state.modal.value.NewsSearch);
    const MModal = useSelector((state) => state.modal.value.Message);
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle("뉴스 검색 테스트");
        setMessage("검색중");
        dispatch(MessageModalFlag(true));
        goSearch();
        goAnaly();
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
    }

    const goAnaly = () => {
        getAnalyze(searchKeyword)
            .then((response) => {
                const target = response.data.data[0].tokens.map(item => item.token);
                setAnaly(target);
                dispatch(MessageModalFlag(false));
            })
    }

    const goSearch = () => {
        getSearchNews(
            {
                from_dt: fromDt,
                to_dt: "",
                page_no: page_no,
                size: 10,
                sort: 1,
                search_opt: 2,
                keyword: searchKeyword,
                exKeyword: exceptKeyword
            }
        ).then((response) => {
            setTotalCnt(response.data.total_row);
            setPagesInfo(response.data.news_list);
            dispatch(MessageModalFlag(false));
        })
    }

    const newsSearchModal = (e) => {
        setNewsMessage(e);
        dispatch(NewsSearchModalFlag(true));
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {(totalCnt && (totalCnt > 0)) &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCnt}</span>개)
                            </React.Fragment>
                        }
                    </div>
                </div>
                {analy &&
                    <React.Fragment>
                        <div className='title'>형태소 분석 결과</div>
                        <div className='component'>
                            <div>
                                {analy.map((item, index) => {
                                    return <span key={index}>{item}&nbsp;&nbsp;</span>;
                                })}
                            </div>
                        </div>
                    </React.Fragment>
                }
                {!analy &&
                    <React.Fragment>
                        <div className='title'></div>
                        <div className='component'></div>
                    </React.Fragment>
                }
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                {(pagesInfo && pagesInfo.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} >제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} >매체명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} >뉴스 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} >등록 일시</div>
                        </TableHeader>
                        <div >
                            {pagesInfo.map((item, index) => {
                                return (
                                    <TableRow key={index} onClick={(e) => newsSearchModal(item.contents)}>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} >{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} >{item.med_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} >{item.cat_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} >{item.news_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                    </React.Fragment>
                }
                <Paging count={totalCnt} pageSize={10} viewSize={page_no} onClick={handlePageClick} />
            </div>
            {NModal &&
                <div className='NewsSearch_Modal_Container' onClick={() => dispatch(NewsSearchModalFlag(false))}>
                    <div className='NewsSearch_Modal'>
                        <NewsSearchModal message={newsMessage} analy={analy} />
                    </div>
                </div>
            }
            {MModal &&
                <div className='Message_Modal_Container' onClick={() => dispatch(MessageModalFlag(false))}>
                    <div className='Message_Modal'>
                        <MessageModal title={title} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default ViewSearchBody;