import { instance } from '..';

export const getAuthMenu = async (data) => {
    const response = await instance.get(`/auth/getAuthMenuList`, data, null)
        .then((response) => {
            return response;
        })
    return response;

}

export const getSelectAuthMenu = async (data) => {
    const response = await instance.get(`/auth/getSelectAuthMenuList`, data, null)
        .then((response) => {
            return response;
        })
    return response;

}

export const postMemberMenuInsert = async (post) => {
    const url = '/auth';
    const response = await instance.put(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json multipart/form-data",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

