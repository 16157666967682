import { instance } from "..";

export const getList = async (postTp, page, size, sort) => {

    const url = `/post?postTp=${postTp}&page=${page}&size=${size}&sort=${sort}&isDesc=true`
    const response = await instance.get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;

}

export const getPostByPid = async (pid) => {
    const url = `/post/view?pid=${pid}`
    const response = await instance.get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
}


export const updatePost = async (post) => {
    const url = `/post`;
    const response = await instance.put(
        url,
        post,
        {
            headers: {
                'content-type': 'application/json'
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const updateMoreNews = async (pid) => {
    const url = `/more-opinions-analyzer/${(pid && pid !== '') ? pid : 0}`;
    const response = await instance.post(
        url,
        null,
        null
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}