import styled from "styled-components";
import Label from "../../../common/Label";
import Radio from "../../../common/Radio";
import Button from "../../../common/Button";
import TextField from "../../../common/TextField";
import { useEffect, useState } from "react";
import OneDayCalendarSplash from "../../../common/OneDayCalendar";
import FileAttachSplash from "../../../common/FileAttachSplash";
import { getSplashInfoByItold, getSplashModify, getUploadImageReturnUrl, getSplashRegist } from "../../../api/splash/splash";
import { useSelector, useDispatch } from 'react-redux';
import { SplashTitle, SplashCategoryCd, SplashStartDay, SplashStartTime, SplashFromDay, SplashFromTime, SplashIntroInsert } from '../../../slice/splashIntroSlice';
import moment from "moment";
import MessageModal from "../../../modal/Message";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import { useNavigate } from "react-router-dom";
import { Flag } from '../../../slice/splashIntroSlice';

const RegistBodyC = styled.div`
  width: 100%;
  margin: 50px 0px;
`;

const RegistBody = ({ itoId, mode }) => {

	const splashTitle = useSelector((state) => state.splash.value.splashTitle);
	const splashCategoryCd = useSelector((state) => state.splash.value.splashCategoryCd);
	const splashStartTime = useSelector((state) => state.splash.value.splashStartTime);
	const splashFromTime = useSelector((state) => state.splash.value.splashFromTime);
	const MModal = useSelector((state) => state.modal.value.Message);
	const LModal = useSelector((state) => state.modal.value.Loading);
	const Insert = useSelector((state) => state.splash.value.splashIntroInsert);
	const insertFlag = useSelector((state) => state.splash.value.insertFlag);

	const [message, setMessage] = useState('');
	const [imgList, setImgList] = useState([]);
	const [startDay, setStartDay] = useState(moment().format("YYYY-MM-DD"));
	const [fromDay, setFromDay] = useState(moment().format("YYYY-MM-DD"));
	const [type, setType] = useState("insert");

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (itoId && itoId.length > 0) {
			getSplashInfoByItold(itoId).then((response) => {
				dispatch(SplashTitle(response.data.results.title));
				dispatch(SplashCategoryCd(response.data.results.categoryCd));
				dispatch(SplashStartTime(response.data.results.startDt.split("T")[1].slice(0, 5)));
				dispatch(SplashFromTime(response.data.results.fromDt.split("T")[1].slice(0, 5)));
				setStartDay(response.data.results.startDt.split("T")[0]);
				setFromDay(response.data.results.fromDt.split("T")[0]);
				setImgList(response.data.results.splashImages);
				setType("update");
			});
		} else {
			dispatch(SplashTitle(""));
			dispatch(SplashCategoryCd("SEASON"));
			dispatch(SplashStartDay(moment().format("YYYY-MM-DD")));
			dispatch(SplashStartTime("00:00"));
			dispatch(SplashFromDay(moment().format("YYYY-MM-DD")));
			dispatch(SplashFromTime("00:00"));
			setType("insert");
		}
	}, [itoId]);// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(SplashStartDay(startDay));
		dispatch(SplashFromDay(fromDay));
	}, [startDay, fromDay]);// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (Insert === true) {
			if (splashTitle === "") {
				dispatch(SplashIntroInsert(false));
				setMessage("스플래시 제목을 입력해주세요");
				dispatch(MessageModalFlag(true));
				return;
			} else if (imgList.length === 0) {
				dispatch(SplashIntroInsert(false));
				setMessage("스플래시 이미지를 추가해주세요");
				dispatch(MessageModalFlag(true));
				return;
			}
			for (let i = 0; i < imgList.length; i++) {
				const j = i + 1;
				if (imgList[i].splashImgUrl === "") {
					dispatch(SplashIntroInsert(false));
					setMessage(j + "번째 스플래시 메인 이미지를 추가해주세요");
					dispatch(MessageModalFlag(true));
					return;
				}
				if (imgList[i].titleImgUrl === "") {
					dispatch(SplashIntroInsert(false));
					setMessage(j + "번째 스플래시 타이틀 이미지를 추가해주세요");
					dispatch(MessageModalFlag(true));
					return;
				}
			}
			imgListSort(imgList).then((imgList) => {
				const PostData = { title: splashTitle, categoryCd: splashCategoryCd, imgList, startDt: startDay + " " + splashStartTime, fromDt: fromDay + " " + splashFromTime };
				if (mode === "add") {
					setMessage("등록진행중");
					dispatch(LoadingModalFlag(true));
					getSplashRegist(PostData)
						.then((Result) => {
							dispatch(LoadingModalFlag(false));
							if (Result.status === 200) {
								dispatch(SplashIntroInsert(false));
								setMessage("등록성공");
								dispatch(MessageModalFlag(true));
								dispatch(Flag(true));
							} else {
								dispatch(SplashIntroInsert(false));
								setMessage("등록실패");
								dispatch(MessageModalFlag(true));
							}
						})
						.catch((err) => {
							dispatch(SplashIntroInsert(false));
							setMessage("등록실패");
							dispatch(MessageModalFlag(true));
						});
				} else if (mode === "modify") {
					setMessage("수정진행중");
					dispatch(LoadingModalFlag(true));
					getSplashModify(PostData, itoId)
						.then((Result) => {
							dispatch(LoadingModalFlag(false));
							if (Result.status === 200) {
								dispatch(SplashIntroInsert(false));
								setMessage("수정성공");
								dispatch(MessageModalFlag(true));
							} else {
								dispatch(SplashIntroInsert(false));
								setMessage("수정실패");
								dispatch(MessageModalFlag(true));
							}
						})
						.catch((err) => {
							dispatch(SplashIntroInsert(false));
							setMessage("수정실패");
							dispatch(MessageModalFlag(true));
						});
				}
			});

		}
	}, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (insertFlag === true && MModal === false) {
			dispatch(Flag(false));
			navigate("/operation/splashIntro");
		}
	}, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps

	const imgListSort = async (List) => {
		const resultArr = [];
		for (const item of List) {
			if (item.splashImgUrl && item.titleImgUrl) {
				let result = {
					splashImgUrl: await returnImgUrl(item.splashImgUrl),
					titleImgUrl: await returnImgUrl(item.titleImgUrl),
					logoImgUrl: await returnImgUrl(item.logoImgUrl),
					dateImgUrl: await returnImgUrl(item.dateImgUrl),
				};
				resultArr.push(result);
			} else {
				return "";
			}
		}
		return resultArr;
	};

	const returnImgUrl = async (file) => {
		if (file) {
			if (typeof file === "string") {
				return file;
			} else {
				const result = await getUploadImageReturnUrl(file).then((response) => {
					if (response) {
						if (response.data.status === 200) {
							return response.data.image_urls[0];
						} else {
							return "";
						}
					} else {
						return "";
					}
				});

				return result;
			}
		} else {
			return "";
		}
	};

	const addImgItem = (index, file, type) => {
		const newArr = imgList.slice();
		if (type === "splashImgUrl") {
			newArr[index].splashImgUrl = file;
		} else if (type === "titleImgUrl") {
			newArr[index].titleImgUrl = file;
		} else if (type === "logoImgUrl") {
			newArr[index].logoImgUrl = file;
		} else if (type === "dateImgUrl") {
			newArr[index].dateImgUrl = file;
		}
		setImgList(newArr);
	};

	const addInputImgList = () => {
		const newArr = imgList.slice();
		newArr.push({
			splashImgUrl: "",
			titleImgUrl: "",
			logoImgUrl: "",
			dateImgUrl: "",
		});
		setImgList(newArr);
	};

	const delInputImgList = (i) => {
		const newArr = imgList.slice();
		newArr.splice(i, 1);
		setImgList(newArr);
	};

	const addImgItemDate = (e, type) => {
		if (type === 'startTime') {
			dispatch(SplashStartTime(e));
		} else if (type === 'endTime') {
			dispatch(SplashFromTime(e));
		}
	};

	return (
		<RegistBodyC>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ width: "170px" }}>
					<Label type={`nomal`} required={true}>
						제목
					</Label>
				</div>
				<div style={{ flexGrow: 1, borderBottom: "1px solid #C4C4C4" }}>
					<TextField
						value={splashTitle || ""}
						width="100%"
						placeholder="스플래시 인트로 세트 제목을 입력하세요"
						onChange={(e) => dispatch(SplashTitle(e.target.value))}
					/>
				</div>
			</div>
			{imgList.map((item, index) => {
				if (type === "update") {
					return (
						<div style={{ display: "flex", alignItems: "center" }} key={index}>
							<div
								style={{
									width: "170px",
									display: "flex",
									alignItems: "center",
								}}
							>
								<Label type={`nomal`} required={true}>
									이미지{index + 1}
								</Label>
								<img
									src={"/images/component/ic_closed_black.png"}
									style={{
										width: "15px",
										height: "15px",
										alignt: "center",
										marginLeft: "10px",
									}}
									alt={'이미지'}
									onClick={(e) => delInputImgList(index)}
								/>
							</div>
							<div
								style={{
									flexGrow: 1,
									borderBottom: "1px dashed #BDBDBD",
								}}
							>
								<FileAttachSplash
									value={item.splashImgUrl ? item.splashImgUrl : ""}
									width="100%"
									placeholder="스플래시 메인 이미지를 선택하세요"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "splashImgUrl");
										}
									}}
								/>
								<FileAttachSplash
									value={item.titleImgUrl ? item.titleImgUrl : ""}
									width="100%"
									placeholder="타이틀 이미지를 선택하세요"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "titleImgUrl");
										}
									}}
								/>
								<FileAttachSplash
									value={item.logoImgUrl ? item.logoImgUrl : ""}
									width="100%"
									placeholder="로고 이미지를 선택하세요 (선택)"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "logoImgUrl");
										}
									}}
								/>
								<FileAttachSplash
									value={item.dateImgUrl ? item.dateImgUrl : ""}
									width="100%"
									placeholder="날짜 이미지를 선택하세요 (선택)"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "dateImgUrl");
										}
									}}
								/>
							</div>
						</div>
					)
				} else {
					return (
						<div style={{ display: "flex", alignItems: "center" }} key={index}>
							<div
								style={{
									width: "170px",
									display: "flex",
									alignItems: "center",
								}}
							>
								<Label type={`nomal`} required={true}>
									이미지{index + 1}
								</Label>
								<img
									src={"/images/component/ic_closed_black.png"}
									style={{
										width: "15px",
										height: "15px",
										alignt: "center",
										marginLeft: "10px",
									}}
									alt={'이미지'}
									onClick={(e) => delInputImgList(index)}
								/>
							</div>
							<div
								style={{
									flexGrow: 1,
									borderBottom: "1px dashed #BDBDBD",
								}}
							>
								<FileAttachSplash
									value={item.splashImgUrl.name ? item.splashImgUrl.name : ""}
									width="100%"
									placeholder="스플래시 메인 이미지를 선택하세요"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "splashImgUrl");
										}
									}}
								/>
								<FileAttachSplash
									value={item.titleImgUrl.name ? item.titleImgUrl.name : ""}
									width="100%"
									placeholder="타이틀 이미지를 선택하세요"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "titleImgUrl");
										}
									}}
								/>
								<FileAttachSplash
									value={item.logoImgUrl.name ? item.logoImgUrl.name : ""}
									width="100%"
									placeholder="로고 이미지를 선택하세요 (선택)"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "logoImgUrl");
										}
									}}
								/>
								<FileAttachSplash
									value={item.dateImgUrl.name ? item.dateImgUrl.name : ""}
									width="100%"
									placeholder="날짜 이미지를 선택하세요 (선택)"
									onChange={(e) => {
										if (e.target.files.length > 0) {
											addImgItem(index, e.target.files[0], "dateImgUrl");
										}
									}}
								/>
							</div>
						</div>
					)
				}
			})}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flex: "row",
					height: "70px",
				}}
			>
				<Button
					cType="b_line"
					name="추가하기"
					onClick={(e) => {
						addInputImgList();
					}}
				/>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flex: "row",
					height: "70px",
				}}
			>
				<div style={{ width: "170px" }}>
					<Label type={`nomal`} required={true}>
						구분
					</Label>
				</div>
				<div
					style={{
						display: "flex",
						flexGrow: 1,
						borderTop: "1px solid #C4C4C4",
						flex: "row",
						height: "70px",
						alignItems: "center",
					}}
				>
					<Radio
						name="categoryCd"
						value="SEASON"
						checked={splashCategoryCd === "SEASON" && true}
						onChange={(e) => dispatch(SplashCategoryCd("SEASON"))}
					>
						시즌
					</Radio>
					<Radio
						name="categoryCd"
						value="DEFAULT"
						checked={splashCategoryCd === "DEFAULT" && true}
						onChange={(e) => dispatch(SplashCategoryCd("DEFAULT"))}
					>
						디폴트
					</Radio>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flex: "row",
					height: "70px",
				}}
			>
				<div style={{ width: "170px" }}>
					<Label type={`nomal`} required={true}>
						적용 시작일
					</Label>
				</div>
				<div
					style={{
						display: "flex",
						flexGrow: 1,
						borderTop: "1px solid #C4C4C4",
						flex: "row",
						height: "70px",
						alignItems: "center",
					}}
				>
					<OneDayCalendarSplash setDate={setStartDay} splashDate={startDay} />
					<input
						value={splashStartTime || "00:00"}
						type="time"
						min="00:00"
						max="23:59"
						style={{ marginLeft: "10px", height: "40px" }}
						onChange={(e) => {
							addImgItemDate(e.target.value, "startTime");
						}}
					/>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flex: "row",
					height: "70px",
				}}
			>
				<div style={{ width: "170px" }}>
					<Label type={`nomal`} required={true}>
						적용 종료일
					</Label>
				</div>
				<div
					style={{
						display: "flex",
						flexGrow: 1,
						borderTop: "1px solid #C4C4C4",
						flex: "row",
						height: "70px",
						alignItems: "center",
					}}
				>
					<OneDayCalendarSplash setDate={setFromDay} splashDate={fromDay} />
					<input
						value={splashFromTime || "00:00"}
						type="time"
						min="00:00"
						max="23:59"
						style={{ marginLeft: "10px", height: "40px" }}
						onChange={(e) => {
							addImgItemDate(e.target.value, "endTime");
						}}
					/>
				</div>
			</div>
			{MModal &&
				<div className='Message_Modal_Container'>
					<div className='Message_Modal'>
						<MessageModal type="check" title="스플레시 인트로 등록" message={message} />
					</div>
				</div>
			}
			{LModal &&
				<div className='Message_Modal_Container'>
					<div className='Message_Modal'>
						<MessageModal type="loading" title="스플레시 인트로 등록" message={message} />
					</div>
				</div>
			}
		</RegistBodyC>
	);
};

export default RegistBody;
