import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../cms/theme';
import { useParams } from 'react-router';
import { decodeUrl } from './decoder';

const Layout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 65vh;
    letter-spacing: -0.64px;
    ${(props) => props.theme.fontSize.body1};
    .box {
        display: flex;
        text-align: center;
        line-height: 26px;
        .red {
            color: ${(props) => props.theme.color.MainPrimary};
        }
        margin-top:34px;
        margin-bottom:50px;
    }
`;
/**검색 뉴스 결과 없을 경우**/

const NoneData = ({ edit }) => {
    let { name } = useParams();
    const keyword = decodeUrl(name);

    return (
        <ThemeProvider theme={theme}>
            {edit ? null : (
                <Layout>
                    <div className={'box'}>
                        <span>
                            <span className={'red'}>'{keyword}'</span>
                            에<br />
                            대한 검색결과가 없습니다.
                        </span>
                    </div>
                </Layout>
            )}
        </ThemeProvider>
    );
};
export default NoneData;
