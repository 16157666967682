import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Button from "../../common/Button";
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import { getList } from "../../api/issue/issue";
import { getPostDeleteList } from "../../api/cmspost/post";
import { useSelector, useDispatch } from 'react-redux';
import { MessageModalFlag } from '../../slice/modalSlice';
import Form from 'react-bootstrap/Form';
import MessageModal from "../../modal/Message";

const Body = () => {

    const navigate = useNavigate();

    const [size, setSize] = useState(10);// eslint-disable-line no-unused-vars
    const [pagesInfo, setPagesInfo] = useState();// eslint-disable-line no-unused-vars

    const [page_no, setPage_no] = useState(1);
    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');
    const [realPage_no, setRealPage_no] = useState(1);
    const MModal = useSelector((state) => state.modal.value.Message);
    const dispatch = useDispatch();
    const [deleteListPid, setDeleteListPid] = useState([]);

    const [postList, setPostList] = useState();

    useEffect(() => {
        const data = { "size": size, "offset": page_no }
        getList(data).then((response) => {
            if (response) {
                const thumbnails = "";//eslint-disable-line
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].thumbnails) {
                        const thumbnailsReplace = response.data[i].thumbnails.replaceAll("[", "");
                        const thumbnailsReplace2 = thumbnailsReplace.replaceAll('"', "");
                        const thumbnails = thumbnailsReplace2.replace(']', "");
                        response.data[i].thumbnails = thumbnails;
                    }
                }
                setPagesInfo(response.data[0].count);
                setPostList(response.data);
            }
        })
    }, [page_no])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPage_no(1);
        const data = { "size": size, "offset": 1 }
        getList(data).then((response) => {
            if (response) {
                const thumbnails = "";//eslint-disable-line
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].thumbnails) {
                        const thumbnailsReplace = response.data[i].thumbnails.replaceAll("[", "");
                        const thumbnailsReplace2 = thumbnailsReplace.replaceAll('"', "");
                        const thumbnails = thumbnailsReplace2.replace(']', "");
                        response.data[i].thumbnails = thumbnails;
                    }
                }
                setPagesInfo(response.data[0].count);
                setPostList(response.data);
            }
        })
    }, [size])


    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
        setRealPage_no(pageNum);
    }


    const checkhandle = (e) => {

        const newArr = deleteListPid.slice();
        for (const index in deleteListPid) {
            if (`${deleteListPid[index].nkey}` === e) {
                newArr.splice(index, 1);
                setDeleteListPid(newArr);
                return;
            }
        }
        newArr.push({
            pid: e,
        });
        setDeleteListPid(newArr);
    }
    const deletehandle = () => {

        const selector = JSON.stringify(deleteListPid);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"pid":', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');

        if (deleteData4 === "") {
            setMsgTitle("이슈따라잡기");
            setMessage("삭제할 이슈 따라잡기를 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData5 = { "pid": deleteData4 };
        getPostDeleteList(deleteData5)
            .then((response) => {
                if (response.status === 200) {
                    setMsgTitle("이슈따라잡기");
                    setMessage("삭제 완료되었습니다");
                    dispatch(MessageModalFlag(true));
                    const checkboxes = document.getElementsByName('selectCheckBox');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    })
                    setDeleteListPid([]);
                    setting();
                    return;
                } else {
                    setMsgTitle("이슈따라잡기");
                    setMessage("삭제 실패하였습니다");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            })
    }
    const setting = () => {
        const data = { "size": size, "offset": page_no }
        getList(data).then((response) => {
            if (response) {
                const thumbnails = "";//eslint-disable-line
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].thumbnails) {
                        const thumbnailsReplace = response.data[i].thumbnails.replaceAll("[", "");
                        const thumbnailsReplace2 = thumbnailsReplace.replaceAll('"', "");
                        const thumbnails = thumbnailsReplace2.replace(']', "");
                        response.data[i].thumbnails = thumbnails;
                    }
                }
                setPostList(response.data);
            }
        })
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {pagesInfo &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{pagesInfo}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select className="selectBox" onChange={(e) => setSize(e.target.value)}>
                            <option value='10' >10개씩보기</option>
                            <option value='20' >20개씩보기</option>
                            <option value='20' >30개씩보기</option>
                        </Form.Select>&nbsp;
                        <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                    </div>
                </div>
                <div className='title'></div>
                <div className='component'></div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(postList && postList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '36px' }}>순서</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>이슈따라잡기 아이디</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>공개여부</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>예약일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>등록일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>수정일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>썸네일</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {postList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }} onChange={(e) => checkhandle(item.pid)}><Check name='selectCheckBox' /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '36px' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>a</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>{item.pid}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'left' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>{item.open_st === "1" ? '공개' : '비공개'}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>{item.rev_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>{item.reg_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>{item.mod_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/issue/view/${item.pid}/${item.rid}`)}>
                                            <img src={item.thumbnails} alt='THUMB' style={{ width: '120px', height: '50px' }} />
                                        </div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={pagesInfo} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Body;