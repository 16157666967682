import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Label from "../../../common/Label";
import TextField from "../../../common/TextField";
import FileAttachSplash from "../../../common/FileAttachSplash";
import { getBanner } from "../../../api/banner/banner";
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import { BannerInsert, Flag } from '../../../slice/bannerSlice';
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import MessageModal from "../../../modal/Message";
import { bannerInsert, bannerUpdate } from "../../../api/banner/banner";
import { useNavigate } from 'react-router-dom';

const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const RegistBody = ({ bSeq, mode }) => {
    const [bannerrDetailUrl, setBannerrDetailUrl] = useState();
    const [bannerDetailWebUrl, setBannerDetailWebUrl] = useState();
    const [bannerDetailMobileUrl, setBannerDetailMobileUrl] = useState();
    const [bannerDetailDESC, setBannerDetailDESC] = useState();
    const [bannerDetailName, setBannerDetailName] = useState();
    const [bannerDetailLink, setBannerDetailLink] = useState();
    const [bannerDetailType, setBannerDetailType] = useState();

    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');
    const [webImg, setWebImg] = useState();
    const [mobileImg, setMobileImg] = useState();
    const Insert = useSelector((state) => state.banner.value.bannerInsert);
    const insertFlag = useSelector((state) => state.banner.value.insertFlag);

    const dispatch = useDispatch();
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);

    const navigate = useNavigate();

    const addImgItem = (file, type) => {
        if (type === "webImgUrl") {
            setWebImg(file);
        } else if (type === "mobileImgUrl") {
            setMobileImg(file);
        }
    };

    useEffect(() => {
        if (bSeq) {
            const data = { params: { "b_seq": bSeq } };
            getBanner(data)
                .then((response) => {
                    setBannerDetailDESC(response.data.data[0].b_desc);
                    setBannerDetailName(response.data.data[0].b_name);
                    setBannerDetailLink(response.data.data[0].b_link);
                    setBannerDetailType(response.data.data[0].b_link_type);
                    setBannerrDetailUrl(response.data.data[0].b_url);
                    setBannerDetailWebUrl(response.data.data[0].b_url + "_w." + response.data.data[0].b_extension);
                    setBannerDetailMobileUrl(response.data.data[0].b_url + "_m." + response.data.data[0].b_extension);
                })
        }
    }, [bSeq]);

    useEffect(() => {
        if (Insert === true) {
            if (bannerDetailDESC === "" || bannerDetailDESC === undefined) {
                setMsgTitle("베너 등록");
                setMessage("BANNER_DESC는 필수 입력 값 입니다. BANNER_DESC를 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(BannerInsert(false));
                return;
            } else if (bannerDetailName === "" || bannerDetailName === undefined) {
                setMsgTitle("베너 등록");
                setMessage("베너명을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(BannerInsert(false));
                return;
            } else if (bannerDetailLink === "" || bannerDetailLink === undefined) {
                setMsgTitle("베너 등록");
                setMessage("BANNER_LINK 필수 입력 값 입니다. BANNER_LINK 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(BannerInsert(false));
                return;
            } else if (bannerDetailType === "" || bannerDetailType === undefined) {
                setMsgTitle("베너 등록");
                setMessage("BANNER_TYPE 필수 입력 값 입니다. BANNER_TYPE 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(BannerInsert(false));
                return;
            }
            setMsgTitle("베너 등록");
            setMessage("진행중");
            dispatch(LoadingModalFlag(true));
            const formData = new FormData();

            formData.append('b_link_type', bannerDetailType);
            formData.append('b_desc', bannerDetailDESC);
            formData.append('b_name', bannerDetailName);
            formData.append('b_link', bannerDetailLink);


            if (mode === 'add') {
                if (webImg === undefined) {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("WebImg 파일을 선택해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                } else if (mobileImg === undefined) {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("MobileImg 파일을 선택해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                }
                const webfileNm = webImg.name;
                var webImgFile = webfileNm.slice(webfileNm.lastIndexOf(".") + 1).toLowerCase();

                const mobilefileNm = mobileImg.name;
                var mobileImgFile = mobilefileNm.slice(mobilefileNm.lastIndexOf(".") + 1).toLowerCase();

                const testwebNm = webfileNm.slice(-6);
                const testmobileNm = mobilefileNm.slice(-6);
                if (webImg.length === 0) {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("WebImg 파일을 선택해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                } else if (testwebNm !== "_w.png") {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("WebImg의 마지막 파일명은 w 로 하셔야됩니다");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                } else if (!(webImgFile === "gif" || webImgFile === "jpg" || webImgFile === "png")) {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("webImg는 이미지파일 (.jpg, .png, .gif ) 만 업로드 가능합니다.");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                } else if (mobileImg.length === 0) {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("MobileImg 파일을 선택해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                } else if (testmobileNm !== "_m.png") {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("MobileImg의 마지막 파일명은 m 로 하셔야됩니다");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                } else if (!(mobileImgFile === "gif" || mobileImgFile === "jpg" || mobileImgFile === "png")) {
                    dispatch(LoadingModalFlag(false));
                    setMsgTitle("베너 등록");
                    setMessage("mobileImg는 이미지파일 (.jpg, .png, .gif ) 만 업로드 가능합니다.");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerInsert(false));
                    return;
                }
                formData.append('image', webImg);
                formData.append('image', mobileImg);
                bannerInsert(formData)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        dispatch(BannerInsert(false));
                        setMsgTitle("베너 등록");
                        setMessage("등록 되었습니다");
                        dispatch(MessageModalFlag(true));
                        dispatch(Flag(true));
                    })
            } else if (mode === 'modify') {
                formData.append('b_seq', bSeq);

                if (webImg) {
                    if (mobileImg) {
                    } else {
                        dispatch(LoadingModalFlag(false));
                        dispatch(BannerInsert(false));
                        setMsgTitle("베너 등록");
                        setMessage("이미지는 동시에 등록하셔야 됩니다");
                        dispatch(MessageModalFlag(true));
                        return;
                    }
                }
                if (mobileImg) {
                    if (webImg) {
                        formData.append('image', webImg);
                        formData.append('image', mobileImg);
                    } else {
                        dispatch(LoadingModalFlag(false));
                        dispatch(BannerInsert(false));
                        setMsgTitle("베너 등록");
                        setMessage("이미지는 동시에 등록하셔야 됩니다");
                        dispatch(MessageModalFlag(true));
                        return;
                    }
                }

                bannerUpdate(formData)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        dispatch(BannerInsert(false));
                        setMsgTitle("베너 등록");
                        setMessage("수정 되었습니다");
                        dispatch(MessageModalFlag(true));
                    })

            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/banner");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RegistBodyC>
            <div style={{ width: '100%', height: '100%', maxHeight: '1000px', background: 'White', overflowY: 'scroll' }}>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            베너명
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={bannerDetailName || ""}
                            width="50%"
                            placeholder={bannerDetailName}
                            onChange={(e) => setBannerDetailName(e.target.value)}
                        />
                    </div>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            베너 설명
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={bannerDetailDESC || ""}
                            width="50%"
                            placeholder={bannerDetailDESC}
                            onChange={(e) => setBannerDetailDESC(e.target.value)}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true} >
                            베너 링크
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={bannerDetailLink || ""}
                            width="50%"
                            placeholder={bannerDetailLink}
                            onChange={(e) => setBannerDetailLink(e.target.value)}
                        />
                    </div>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true} >
                            베너 타입
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        {bannerDetailType &&
                            <Form.Select className="bannerSelectBox" defaultValue={bannerDetailType} onChange={(e) => setBannerDetailType(e.target.value)}>
                                <option value={""}>선택해주세요</option>
                                <option value={"0"}>내부</option>
                                <option value={"1"}>외부</option>
                                <option value={"2"}>펑션</option>
                            </Form.Select>
                        }
                        {!bannerDetailType &&
                            <Form.Select className="bannerSelectBox" onChange={(e) => setBannerDetailType(e.target.value)}>
                                <option value={""}>선택해주세요</option>
                                <option value={"0"}>내부</option>
                                <option value={"1"}>외부</option>
                                <option value={"2"}>펑션</option>
                            </Form.Select>
                        }
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            Url
                        </Label>
                    </div>
                    <div style={{ width: "1320px", margin: 'auto' }}>
                        <TextField
                            value={bannerrDetailUrl || ""}
                            width="100%"
                            placeholder={bannerrDetailUrl}
                            disabled
                        />
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            웹 Url
                        </Label>
                    </div>
                    <div style={{ width: "1320px", margin: 'auto' }}>
                        <TextField
                            value={bannerDetailWebUrl || ""}
                            width="100%"
                            placeholder={bannerDetailWebUrl}
                            disabled
                        />
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '21%' }}></div>
                    <img src={bannerDetailWebUrl} alt='Web Img' />
                    <div style={{ width: '10%' }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            모바일 Url
                        </Label>
                    </div>
                    <div style={{ width: "1320px", margin: 'auto' }}>
                        <TextField
                            value={bannerDetailMobileUrl || ""}
                            width="100%"
                            placeholder={bannerDetailMobileUrl}
                            disabled
                            onChange={(e) => setBannerDetailMobileUrl(e.target.value)}
                        />
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '21%' }}></div>
                    <img src={bannerDetailMobileUrl} alt='Mobile Img' />
                    <div style={{ width: '10%' }}></div>
                </div>
                <div style={{ display: 'flex' }} id="web">
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            웹 이미지
                        </Label>
                    </div>
                    <div style={{ width: "1320px", margin: 'auto' }}>
                        <FileAttachSplash
                            width="100%"
                            placeholder="웹이미지를 선택하세요"
                            onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    addImgItem(e.target.files[0], "webImgUrl");
                                }
                            }}
                        />
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            모바일 Img
                        </Label>
                    </div>
                    <div style={{ width: "1320px", margin: 'auto' }}>
                        <FileAttachSplash
                            width="100%"
                            placeholder="모바일 이미지를 선택하세요"
                            onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    addImgItem(e.target.files[0], "mobileImgUrl");
                                }
                            }}
                        />
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}

export default RegistBody;