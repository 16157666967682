
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";

const ViewTitle = ({ uid }) => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>회원 상세 정보</BodyTitleFont>
            <div>
                <Button name={`수정`} onClick={(e) => navigate(`/member/authority/update/${uid}`)} />
                &nbsp;
                <Button width={'150px'} cType={`cancel`} name={`목록으로 이동`} onClick={(e) => navigate(`/member/authority`)} />
            </div>
        </BodyTitle>
    )
}

export default ViewTitle;