import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme, { NonBorderHeader, PageStyle } from '../../cms/theme';
import GreyLine from '../../../common/GreyLine';
import { Margin } from '../../../style/leftImgLayout';
import moment from 'moment';
import { getSearchRoom } from '../../../api/newsRoom/post';
import NewsRoomList from './NewsRoomList';
import { EditRoomModalFlag, EditMessageModalFlag } from '../../../slice/modalSlice';
import EditMessage from "../../../modal/EditMessage";
import { useDispatch, useSelector } from 'react-redux';
const Layout = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 10;
    background-color: white;
    overflow-y: auto;
`;

const SearchInput = styled.input`
    position: relative;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #0000001a;
    padding: 0 0 0 12px;
    ${(props) => props.theme.fontSize.body2};
    -webkit-appearance: none;
    border-radius: 4px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #9b9c9f;
    }
`;
const SearchIcon = styled.div`
    display: flex;
    position: absolute;
    right: 28px;
    top: 65px;
    img {
        width: 24px;
        height: 24px;
    }
`;
const Content = styled.div`
    display: flex;
    width: 100%;
    margin-top: 16px;
    justify-content: center;
`;
const EditRoom = ({ props, editorRef }) => {
    const [keyword, setKeyword] = useState('');

    const past = moment().subtract(1, 'year').format('YYYYMMDD');
    const now = moment().format('YYYYMMDD');
    const [selectData, setSelectData] = useState(null);
    const from_dt = past + '000000';
    const to_dt = now + '235959';
    const dispatch = useDispatch();
    const [message, setMessage] = useState();
    const [messageTitle, setMessageTitle] = useState();
    const MModal = useSelector((state) => state.modal.value.EditMessage);
    const onChange = (e) => {
        setKeyword(e.target.value);
    };

    useEffect(() => {
        getSearchRoom(from_dt, to_dt, 1, keyword);
    }, [keyword]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (selectData === null) return;
        editorRef.current.contentWindow.postMessage(
            {
                type: 'SET',
                param: {
                    type: 'mention',
                    action: {
                        rid: selectData.rid,
                        name: selectData.room_nm,
                    },
                },
            },
            '*'
        );
        setMessageTitle("기사 추가");
        setMessage(selectData.room_nm + " 추가되었습니다");
        dispatch(EditMessageModalFlag(true));

    }, [selectData, editorRef]);// eslint-disable-line react-hooks/exhaustive-deps

    const close = (e) => {
        dispatch(EditRoomModalFlag(false));
    }

    return (
        <Layout>
            <ThemeProvider theme={theme}>
                <PageStyle>
                    <NonBorderHeader>
                        <div>
                        </div>
                        뉴스룸 선택
                        <button className={'back_btn'} style={{ width: 'unset', marginLeft: 'auto', background: 'none' }}>
                            <img
                                onClick={close}
                                src="/images/icon/ic_closed_black.svg"
                                alt="1234"
                            />
                        </button>
                    </NonBorderHeader>
                </PageStyle>
                <PageStyle>
                    <SearchInput placeholder={'검색어를 입력하세요.'} value={keyword} onChange={onChange} />
                    <SearchIcon>
                        <img src={'/images/icon/ic_search.png'} alt="" />
                    </SearchIcon>
                    <Margin height={'8px'} />
                </PageStyle>
                <GreyLine height={'2px'} />
                <Margin height={'8px'} />
                {keyword ? (
                    <NewsRoomList
                        props={props}
                        keyword={keyword}
                        title={'뉴스룸 결과'}
                        edit={true}
                        setSelectData={setSelectData}
                    />
                ) : (
                    <Content>뉴스룸을 검색해 보세요.</Content>
                )}
                {MModal && messageTitle && message &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <EditMessage type="check" title={messageTitle} message={message} />
                        </div>
                    </div>
                }
            </ThemeProvider>
        </Layout>
    );
};

export default EditRoom;
