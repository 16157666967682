import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/another/Body";
import Title from "../../components/another/Title";
import Another from "../../templates/another/Another";


const AnotherContainer = ({ page }) => {

    const { pid } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        setTitle(<Title />);
        setBody(<Body />);
    }, [page, pid])

    return (
        <div>
            <Another title={title} body={body} />
        </div>
    )
}

export default AnotherContainer;