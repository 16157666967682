import React from "react";
import './IssueRegistModal.css';

const IssueRegistModal = () => {

    return (
        <div className="IssueRegist_Modal_dal">
            <h3 className="IssueRegist_Modal_title">
                에디터
            </h3>
            <div className="IssueRegist_Modal_context">
                제목은 60자를 초과할 수 없습니다.
            </div>
        </div>
    )
}

export default IssueRegistModal;