import styled from "styled-components";
import { DetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import React, { useState, useEffect } from "react";
import { getUserDetails , deleteUsers, recoveryUsers } from "../../../api/member/member";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingModalFlag, MessageModalFlag } from '../../../slice/modalSlice';
import MessageModal from '../../../modal/Message';
import Button from "../../../common/RightButton";
import Tabs from "../../../common/Tabs";
import { TableHeader, TableRow } from "../../../style/layout";
import Paging from "../../../common/Paging";
import TextField from "../../../common/TextField";
const BasicInfoC = styled.div`
`;

const ViewBody = ({ uid }) => {

    const [memberDetail, setMemberDetail] = useState();
    const [newsRoomList, setNewsRoomList] = useState({});

    const tabList = ['뉴스룸', '맴버뉴스룸', '1:1문의', '활동기록'];
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [totalCount, setTotalCount] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [realPage_no, setRealPage_no] = useState(1);
    const [page_no, setPage_no] = useState(1);
    const [type, setType] = useState(0);
    const dispatch = useDispatch();
    const LModal = useSelector((state) => state.modal.value.Loading);
    const MModal = useSelector((state) => state.modal.value.Message);

    useEffect(() => {
        if (type === 0) {
            //realPage_no
            setTotalCount(1);
            setNewsRoomList([{ "nid": 1, "nname": "뉴스룸 테스트1", "regDt": "2022-11-30" }, { "nid": 2, "nname": "뉴스룸 테스트2", "regDt": "2022-11-30" }, { "nid": 3, "nname": "뉴스룸 테스트3", "regDt": "2022-11-30" }, { "nid": 4, "nname": "뉴스룸 테스트4", "regDt": "2022-11-30" }]);
        } else if (type === 1) {
            setTotalCount(10);
            setNewsRoomList([{ "nid": 1, "nname": "맴버 테스트1", "regDt": "2022-11-30" }, { "nid": 2, "nname": "맴버 테스트2", "regDt": "2022-11-30" }, { "nid": 3, "nname": "맴버 테스트3", "regDt": "2022-11-30" }, { "nid": 4, "nname": "맴버 테스트4", "regDt": "2022-11-30" }]);
        } else if (type === 2) {
            setTotalCount(15);
            setNewsRoomList([{ "qid": 1, "qtype": "광고", "content": "내용1" }, { "qid": 2, "qtype": "광고", "content": "내용2" }, { "qid": 3, "qtype": "광고", "content": "내용3" }, { "qid": 4, "qtype": "광고", "content": "내용4" }]);
        } else if (type === 3) {
            setTotalCount(20);
            setNewsRoomList([{ "content": "내용1" }, { "content": "내용2" }, { "content": "내용3" }, { "content": "내용4" }]);
        }
    }, [type]);

    useEffect(() => {
        if (type === 0) {
            //page_no
            setTotalCount(1);
            setNewsRoomList([{ "nid": 1, "nname": "뉴스룸 테스트1", "regDt": "2022-11-30" }, { "nid": 2, "nname": "뉴스룸 테스트2", "regDt": "2022-11-30" }, { "nid": 3, "nname": "뉴스룸 테스트3", "regDt": "2022-11-30" }, { "nid": 4, "nname": "뉴스룸 테스트4", "regDt": "2022-11-30" }]);
            //api호출
            //setNewsRoomList , setTotalCount
        } else if (type === 1) {
            setTotalCount(10);
            setNewsRoomList([{ "nid": 1, "nname": "맴버 테스트1", "regDt": "2022-11-30" }, { "nid": 2, "nname": "맴버 테스트2", "regDt": "2022-11-30" }, { "nid": 3, "nname": "맴버 테스트3", "regDt": "2022-11-30" }, { "nid": 4, "nname": "맴버 테스트4", "regDt": "2022-11-30" }]);
        } else if (type === 2) {
            setTotalCount(15);
            setNewsRoomList([{ "qid": 1, "qtype": "광고", "content": "내용1" }, { "qid": 2, "qtype": "광고", "content": "내용2" }, { "qid": 3, "qtype": "광고", "content": "내용3" }, { "qid": 4, "qtype": "광고", "content": "내용4" }]);
        } else if (type === 3) {
            setTotalCount(20);
            setNewsRoomList([{ "content": "내용1" }, { "content": "내용2" }, { "content": "내용3" }, { "content": "내용4" }]);
        }
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (uid) {
            setTitle('회원 관리 상세')
            setMessage("회원 정보 조회중");
            dispatch(LoadingModalFlag(true));
            const data = {
                params: { "uid": uid }
            }
            getUserDetails(data).then((response) => {
                dispatch(LoadingModalFlag(false));
                setMemberDetail(response.data.userInfo[0]);
                setStatus(response.data.userInfo[0].accessTpStatus);
            })
        }
    }, [uid, dispatch]);


    const MemberRecovery = () => {
        setTitle("회원 복구");
        setMessage("복구중");
        dispatch(LoadingModalFlag(true));
        const reData = { "uidList": uid };
        recoveryUsers(reData).then((response) => {
            if(response.status===200 || response.status===201){
                const data = {
                    params: { "uid": uid }
                }
                getUserDetails(data).then((response) => {
                    dispatch(LoadingModalFlag(false));
                    setMemberDetail(response.data.userInfo[0]);
                    setStatus(response.data.userInfo[0].accessTpStatus);
                    setTitle("회원 복구");
                    setMessage("복구 완료");
                    dispatch(MessageModalFlag(true));
                })
            }else{
                dispatch(LoadingModalFlag(false));
                setTitle("회원 복구");
                setMessage("복구 실패 개발팀에 문의하세요");
                dispatch(MessageModalFlag(true));
            }
        })
    }

    const MemberDelete = () => {

        setTitle("회원 탈퇴");
        setMessage("탈퇴중");
        dispatch(LoadingModalFlag(true));
        const delData = { params:{ "uidList": uid }};
        deleteUsers(delData).then((response) => {
            if(response.status===200 || response.status===201){
                const data = {
                    params: { "uid": uid }
                }
                getUserDetails(data).then((response) => {
                    dispatch(LoadingModalFlag(false));
                    setMemberDetail(response.data.userInfo[0]);
                    setStatus(response.data.userInfo[0].accessTpStatus);
                    setTitle("회원 탈퇴");
                    setMessage("탈퇴 완료");
                    dispatch(MessageModalFlag(true));
                })
            }else{
                dispatch(LoadingModalFlag(false));
                setTitle("회원 탈퇴");
                setMessage("탈퇴 실패 개발팀에 문의하세요");
                dispatch(MessageModalFlag(true));
            }
        })
    }


    const search = () => {
        //api 검색어 추가해서 보내면 끝
        console.log(searchText);
        setTotalCount(15);
        setNewsRoomList([{ "qid": 1, "qtype": "광고", "content": "내용4" }, { "qid": 2, "qtype": "광고", "content": "내용3" }, { "qid": 3, "qtype": "광고", "content": "내용2" }, { "qid": 4, "qtype": "광고", "content": "내용14" }]);
        // setTotalCount(20);
        // setNewsRoomList([{ "content": "내용1" }, { "content": "내용2" }, { "content": "내용3" }, { "content": "내용4" }]);
    }


    const handleNewsTabClick = (e, i) => {
        if (i === 0) {
            setRealPage_no(1);
            setType(i);
        } else if (i === 1) {
            setRealPage_no(1);;
            setType(i);
        } else if (i === 2) {
            setRealPage_no(1);;
            setType(i);
        } else if (i === 3) {
            setRealPage_no(1);;
            setType(i);
        }
    }

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {memberDetail &&
                <BasicInfoC>
                    <Label type='title'>기본 정보</Label>
                    {status === "탈퇴 대기" &&
                        <Button width={'150px'} sType={`sm`} cType={`cancel`} name={`복구`} onClick={(e) => MemberRecovery()} />
                    }
                    {status === "정상" &&
                        <Button width={'150px'} sType={`sm`} name={`탈퇴`} onClick={(e) => MemberDelete()} />
                    }
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>UID</th><td>{memberDetail.uid}</td>
                            </tr>
                            <tr>
                                <th>회원 아이디</th><td>{memberDetail.userId}</td>
                                <th>회원유형</th><td>{memberDetail.accessTp}</td>
                            </tr>
                            <tr>
                                <th>생일</th><td>{memberDetail.birthday}</td>
                                <th>성별</th><td>{memberDetail.gender}</td>
                            </tr>
                            <tr>
                                <th>계정상태</th><td>{memberDetail.accessTpStatus}</td>
                                <th>회원가입일시</th><td>{memberDetail.regDt}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
            <Tabs items={tabList} onClick={handleNewsTabClick} />
            {(newsRoomList && newsRoomList.length > 0) &&
                <React.Fragment>
                    {type === 0 &&
                        <div>
                            <TableHeader>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>뉴스룸ID</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>뉴스룸 이름</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>뉴스룸 생성일시</div>
                            </TableHeader>
                            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                {newsRoomList.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>{item.nid}</div>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>{item.nname}</div>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>{item.regDt}</div>
                                        </TableRow>
                                    )
                                })
                                }
                            </div>
                            <Paging count={totalCount} pageSize={5} viewSize={realPage_no} onClick={handlePageClick} />
                        </div>
                    }
                    {type === 1 &&
                        <div>
                            <TableHeader>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>뉴스룸ID</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>뉴스룸 이름</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>뉴스룸 생성일시</div>
                            </TableHeader>
                            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                {newsRoomList.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>{item.nid}</div>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>{item.nname}</div>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>{item.regDt}</div>
                                        </TableRow>
                                    )
                                })
                                }
                            </div>
                            <Paging count={totalCount} pageSize={5} viewSize={realPage_no} onClick={handlePageClick} />
                        </div>
                    }
                    {type === 2 &&
                        <div>
                            <div style={{display:'flex'}}>
                                <TextField value={searchText || ""} placeholder={`검색어를 입력해주세요`} onChange={(e) => setSearchText(e.target.value)} />&nbsp;
                                <Button cType='b_line' name='검색' width='65px' onClick={search} />
                            </div>
                            <TableHeader>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>문의번호</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>문의 유형</div>
                                <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>문의 내용</div>
                            </TableHeader>
                            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                {newsRoomList.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100px' }}>{item.qid}</div>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>{item.qtype}</div>
                                            <div style={{ display: 'flex', flexGrow: 1 }}>{item.content}</div>
                                        </TableRow>
                                    )
                                })
                                }
                            </div>
                            <Paging count={totalCount} pageSize={5} viewSize={realPage_no} onClick={handlePageClick} />
                        </div>
                    }
                    {type === 3 &&
                        <div>
                            <TableHeader>
                                <div style={{ width: '100%', marginLeft: '10px' }}>활동내용</div>
                            </TableHeader>
                            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                {newsRoomList.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <div style={{ display: 'flex', width: '100%', marginLeft: '10px' }}>{item.content}</div>
                                        </TableRow>
                                    )
                                })
                                }
                            </div>
                            <Paging count={totalCount} pageSize={5} viewSize={realPage_no} onClick={handlePageClick} />
                        </div>
                    }
                </React.Fragment>
            }

            <div style={{ height: '50px' }} />
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={title} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={title} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default ViewBody;