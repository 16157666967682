import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const color = '#7875c9';

const RelationIconStyled = styled(Link)`
    display: ${(props) => (props.count === 0 ? 'none' : 'flex')};
    text-decoration-line: none;
    color: black;
    // z-index: 100;
    height: 10px;
    box-sizing: border-box;
    width: 19px;
    .icon {
        font-family: 'NS me';
        font-weight: 500;
        font-size: 10px;
        width: 100%;
        letter-spacing: -0.4px;
        color: ${color};
        position: relative;
        padding: 0 0 4px 0px;
        border: solid 1px ${color};
        border-radius: 3px;
        height: 12px;
        text-align: center;
    }
    .icon::after {
        position: absolute;
        content: '';
        top: 3px;
        left: 3px;
        width: 100%;
        height: 100%;
        border-right: solid 1px ${color};
        border-bottom: solid 1px ${color};
        border-radius: 6px 0;
    }
`;
const RelationIcon = ({ nid, count }) => {
    return (
        <RelationIconStyled
            exact={'true'}
            to={`/news/${nid}/RelatedNews`}
            count={count}
            onClick={(e) => e.stopPropagation()}
        >
            <div className="icon">{count}</div>
        </RelationIconStyled>
    );
};

export default RelationIcon;
