import { createSlice } from '@reduxjs/toolkit';

export const tremsSlice = createSlice({
	name: 'trems',
	initialState: { value: { tremsInsert: false, insertFlag: false } },
	reducers: {
		TremsInsert: (state, action) => {
			state.value.tremsInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { TremsInsert, Flag } = tremsSlice.actions;
export default tremsSlice.reducer;
