import React from "react";
import { EditMessageModalFlag } from '../slice/modalSlice';
import { useDispatch } from 'react-redux';
import './Message.css';

const MessageModal = ({ type, title, message }) => {

    const dispatch = useDispatch();
    return (
        <div className="Message_Modal_dal">
            <h3 className="Message_Modal_title">
                {title}
            </h3>
            <div className="Message_Modal_context">
                {message}
            </div>
            <div className="Message_Modal_height"></div>
            {type === "check" &&
                <div className="Message_Modal_check" onClick={() => dispatch(EditMessageModalFlag(false))}>
                    확인
                </div>
            }
        </div>
    )
}
export default MessageModal;