import styled from "styled-components";
import { SplashDetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";
import { getSplashInfoByItold } from "../../../api/splash/splash";

const BasicInfoC = styled.div``;

const DetailInfoC = styled.div``;

const ViewBody = ({ itoId }) => {
	const [splashDetail, setSplashDetail] = useState();

	useEffect(() => {
		if (itoId && itoId.length > 0) {
			getSplashInfoByItold(itoId).then((response) => {
				setSplashDetail(response.data.results);
			});
		}
	}, [itoId]);

	return (
		<React.Fragment>
			<div style={{ height: "50px" }} />
			{splashDetail && (
				<BasicInfoC>
					<Label type="title">기본정보</Label>
					<div style={{ height: "20px" }} />
					<SplashDetailTable style={{ width: "100%" }}>
						<tbody>
							<tr>
								<th style={{ width: "15%" }}>인트로 아이디</th>
								<td style={{ width: "35%" }}>{splashDetail.itoId}</td>
								<th style={{ width: "15%" }}>구분</th>
								<td style={{ width: "35%" }}>{splashDetail.categoryNm}</td>
							</tr>
							<tr>
								<th style={{ width: "15%" }}>작성자 계정</th>
								<td style={{ width: "35%" }}>{splashDetail.userId}</td>
								<th style={{ width: "15%" }}>상태</th>
								<td style={{ width: "35%" }}>상태값 없음</td>
							</tr>
							<tr>
								<th style={{ width: "15%" }}>제목</th>
								<td>{splashDetail.title}</td>
							</tr>
							<tr>
								<th style={{ width: "15%" }}>적용 시작일</th>
								<td style={{ width: "35%" }}>{splashDetail.startDt}</td>
								<th style={{ width: "15%" }}>적용 종료일</th>
								<td style={{ width: "35%" }}>{splashDetail.fromDt}</td>
							</tr>
							<tr>
								<th style={{ width: "15%" }}>등록일시</th>
								<td style={{ width: "35%" }}>{splashDetail.regDt}</td>
								<th style={{ width: "15%" }}>수정일시</th>
								<td style={{ width: "35%" }}>{splashDetail.modDt}</td>
							</tr>
						</tbody>

					</SplashDetailTable>
				</BasicInfoC>
			)}
			<div style={{ height: "50px" }} />
			{splashDetail && (
				<DetailInfoC>
					<Label type="title">본문정보</Label>
					<div style={{ height: "20px" }} />
					<div>
						<React.Fragment>
							<div style={{ width: "100%" }}>
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
										background: "#f8f8f8",
										height: "48px",
									}}
								>
									{splashDetail.splashImages.map((item, index) => {
										return (
											<div
												key={index}
												style={{
													fontSize: "12px",
													width: "16.6%",
													padding: "10px",
													textAlign: "center",
													alignSelf: "center",
												}}
											>
												미리보기
											</div>
										);
									})}
								</div>
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
										background: "#ffffff",
									}}
								>
									{splashDetail.splashImages.map((item, index) => {
										return item ? (
											<div style={{ width: "16.6%", padding: "10px" }} key={index}>
												{item.titleImgUrl && (
													<div style={{ position: "absolute", bottom: "70px" }}>
														<img
															style={{
																position: "relative",
																left: "30px",
																width: "150px"
															}}
															alt="이미지"
															src={item.titleImgUrl}
														/>
													</div>
												)}
												{item.logoImgUrl ? (
													<div style={{ position: "absolute", bottom: "50px" }}>
														<img
															style={{
																position: "relative",
																left: "60px",
																width: "80px"
															}}
															alt="이미지"
															src={item.logoImgUrl}
														/>
													</div>
												) : (
													<div style={{ position: "absolute", bottom: "50px" }}>
														<img
															style={{
																position: "relative",
																left: "60px",
																width: "80px"
															}}
															alt="이미지"
															src="/images/component/splash_logo_img.png"
														/>
													</div>
												)}
												{item.dateImgUrl && (
													<div style={{ position: "absolute" }}>
														<img
															style={{
																position: "relative",
																top: "20px",
																left: "90px",
																width: "80px"
															}}
															alt="이미지"
															src={item.dateImgUrl}
														/>
													</div>
												)}
												<img
													src={item.splashImgUrl}
													style={{ width: "100%" }}
													alt="이미지"
												/>
											</div>
										) : (
											<div key={index}>"없음"</div>

										);
									})}
								</div>
							</div>
						</React.Fragment>
					</div>
				</DetailInfoC>
			)}
		</React.Fragment>
	);
};

export default ViewBody;