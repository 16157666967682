import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SplashIntroInsert } from '../../../slice/splashIntroSlice';

const RegistTitle = ({ mode }) => {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleRegist = () => {
		dispatch(SplashIntroInsert(true));
	};

	return (
		<BodyTitle>
			<BodyTitleFont>
				스플래시 인트로 {mode === "add" ? "등록" : "수정"}
			</BodyTitleFont>
			<div>
				<Button
					name={mode === "add" ? "등록" : "수정"}
					onClick={handleRegist}
				/>
				&nbsp;
				<Button
					cType={`cancel`}
					name={`취소`}
					onClick={
						(e) =>
							navigate("/operation/splashIntro/")
					}
				/>
			</div>
		</BodyTitle>
	);
};

export default RegistTitle;
