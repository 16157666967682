import { instance } from ".."

export const getUserList = async (fromDt, toDt) => {
    const path = `/center/getUserId?from_dt=${fromDt}&to_dt=${toDt}`;
    const retVal = await instance.get(path)
        .then(function (response) {
            return response.data;
        }).catch(err => {
            return null;
        });
    return retVal;

}
