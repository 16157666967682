
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../style/layout";
import Button from "../../common/Button";


const Title = () => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>이슈 따라잡기 관리</BodyTitleFont>
            <Button name={`이슈따라잡기 등록`} width={'160px'} onClick={(e) => navigate('/operation/issue/add/')} />
        </BodyTitle>
    )
}

export default Title;