import { instance } from ".."

export const signIn = async (id, password) => {
    const response = await instance.post(
        `/user/sign-in`,
        { login_id: id, password: password },
        {
            headers: {
                'content-type': 'application/json'
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getUserInfo = async () => {

    const userInfo = localStorage.getItem('ROZ_ADMIN_USERINFO1')
    if (userInfo) {
        const jsonUser = JSON.parse(userInfo)
        const header = {
            headers: {
                'Authorization': 'Bearer ' + jsonUser.accessToken
            },
        }
        const response = await instance.get('/user/' + jsonUser.uid, header, null)
            .then((response) => {
                return response.status === 200 && response.data;
            })
            .catch(err => {
                return null;
            })

        return response;
    }
    return null;

}