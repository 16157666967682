import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import { useSelector } from 'react-redux';
const ViewTitle = ({ notice_id, }) => {
    const navigate = useNavigate();
    const EditerTp = useSelector((state) => state.notice.value.EditerTp);

    return (
        <BodyTitle>
            <BodyTitleFont>공지사항 상세정보</BodyTitleFont>
            <div>
                <Button name={`수정`} onClick={(e) => {
                    EditerTp === 2 || EditerTp === "2" ?
                        navigate(`/operation/notice/update/${notice_id}`)
                        :
                        alert("변경된 입력창(에디터창)에선 수정할 수 없습니다.")
                }} />
                &nbsp;
                <Button width={'150px'} cType={`cancel`} name={`목록으로 이동`} onClick={(e) => navigate(`/operation/notice`)} />
            </div>
        </BodyTitle>
    );
};

export default ViewTitle;
