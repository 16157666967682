import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import uploadinstance from '../../../api/index';

export const DefaultLogo = styled.div`
    width: 100%;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center; 
    font-family: 'NanumSquare';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.52px;
    color: #000000;
`;


const ThumnailVer2 = ({ url, width, height, medCd, medNm, post }) => {
    const [baseUrl, setBaseUrl] = useState();
    const [LoadImg, setLoadImg] = useState(true);
    const [base, setBase] = useState(false);
    const [attr, setAttr] = useState({});
    useEffect(() => {
        if (post) {
            setBaseUrl(url);
            return;
        }
        uploadinstance.get(`/service/api/v1/news/geImage?url=${url}`, null, null)
            .then((response) => {
                setBaseUrl(`data:image/png;base64,${response.data}`);
            })
    }, [url])// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        const img = new Image();
        img.src = baseUrl;
        img.onload = () => {
            const attr = {};

            let tWidth = img.width;
            let tHeight = img.height;

            let maxWidth = width;
            let maxHeight = height;

            // 가로나 세로의 길이가 최대 사이즈보다 크면 실행  
            if (tWidth > maxWidth || tHeight > maxHeight) {
                // 가로가 세로보다 크면 가로는 최대사이즈로, 세로는 비율 맞춰 리사이즈
                if (Math.round(tWidth / tHeight) > 1.1) {//width > height &&  0.8 <= Math.round(width/height)
                    let ratio = (tWidth / tHeight).toFixed(2);
                    attr.width = (maxHeight * ratio).toFixed(2);
                    attr.height = maxHeight;

                    // 세로가 가로보다 크면 세로는 최대사이즈로, 가로는 비율 맞춰 리사이즈
                } else if (Math.floor(tWidth / tHeight) > 0.8) {
                    let ratio = (tWidth / tHeight).toFixed(2);
                    attr.width = maxWidth;
                    attr.height = (maxWidth / ratio).toFixed(2);
                    //attr.style = { position: 'absolute', top: attr.height*-0.1,}; //이미지 초점 조정

                } else {
                    // let ratio = (tWidth/tHeight).toFixed(2);
                    // attr.width = maxWidth;
                    // attr.height = (maxWidth/ratio).toFixed(2);
                    // attr.style = { position: 'absolute', top: attr.height*-0.1,};
                }
                // 최대사이즈보다 작으면 원본 그대로
            } else {
                attr.width = img.tWidth;
                attr.height = img.tHeight;
            }
            setAttr(attr);
        };

    }, [height, width, baseUrl]);

    return (
        <React.Fragment>
            {base ?
                <DefaultLogo>
                    {medNm}
                </DefaultLogo>
                : url && (LoadImg && baseUrl) ?
                    <>
                        <img src={baseUrl} alt='pictures' width={width} height={height} style={{ filter: 'blur(1px) brightness(0.5)', position: 'absolute', border: '#e6e6e6' }} />
                        <img src={baseUrl} alt='pictures' width={width} height={height} onLoad={(e) => { setBase(false); setLoadImg(true); }} onError={(e) => { setLoadImg(false); }}
                            style={{ borderTopLeftRadius: '4px', borderTopRightRadius: '4px', objectFit: 'contain', position: 'absolute' }} {...attr} />
                    </>
                    : post ?
                        <DefaultLogo>
                            {medNm}
                        </DefaultLogo>
                        :
                        <img src={`/medLogo/${medCd.toLowerCase()}.gif`} alt="" onLoad={(e) => { setBase(false) }} onError={(e) => { setBase(true); }}
                            style={{ width: '90%', height: 'auto' }} />
            }

        </React.Fragment>
    )
}

export default ThumnailVer2;