import React from 'react';
import LoginTemplate from "../../templates/LoginTemplate";

const LoginContainer = () => {

    return (
        <React.Fragment>
            <LoginTemplate />
        </React.Fragment>
    )
}

export default LoginContainer;