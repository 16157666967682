import styled from "styled-components";
import { DetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import React, { useState, useEffect } from "react";
import { getMemberDetail } from "../../../api/member/member";
import { getSelectAuthMenu } from "../../../api/member/member";
const BasicInfoC = styled.div`
`;

const ViewBody = ({ uid }) => {

    const [memberDetail, setMemberDetail] = useState();
    const [menuDetail, setMenuDetail] = useState();

    useEffect(() => {
        if (uid) {
            const data = { params: { "uid": uid } };
            getMemberDetail(data)
                .then((response) => {
                    setMemberDetail(response.data);
                    const data = {params : { "loginId": response.data.login_id } };
                    getSelectAuthMenu(data)
                    .then((response) => {
                        if(response.data.length!==0){
                            setMenuDetail(response.data);
                        }
                        
                    })
                })
        }
    }, [uid]);

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {memberDetail &&
                <BasicInfoC>
                    <Label type='title'>Member 정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>아이디</th><td>{memberDetail.login_id}</td>
                                <th>이름</th><td>{memberDetail.name}</td>
                            </tr>
                            <tr>
                                <th>이메일</th><td>{memberDetail.email}</td>
                                <th>핸드폰 번호</th><td>{memberDetail.phone}</td>
                            </tr>
                            <tr>
                                <th>회사</th><td>{memberDetail.company}</td>
                                <th>소속</th><td>{memberDetail.division}</td>
                                <th>부속</th><td>{memberDetail.department}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
            {menuDetail &&
                <div>
                <Label type='title'>권한 메뉴 리스트 정보</Label>
                <div style={{ height: '20px' }} />
                {menuDetail.map((item,index)=>{
                    return (
                        <div>
                            <DetailTable key={index} style={{width:'100%'}}>
                                <tr>
                                    <th>권한 메뉴</th><td>{item.name}</td>
                                </tr>
                            </DetailTable>
                        </div>
                    )})
                }
                </div>
            }
        </React.Fragment>
    )
}

export default ViewBody;