import styled from "styled-components";
import { TableHeader, TableRow } from "../../../style/layout";
import Label from "../../../common/Label";
import Button from "../../../common/Button";
import TextField from "../../../common/TextField";
import { useState, useEffect } from "react";
import News from "../../../common/search/News";
import FileAttachSplash from "../../../common/FileAttachSplash";
import OneDayCalendarPush from "../../../common/OneDayCalendarPush";
import { getPostByPid } from "../../../api/post/common";
import moment from "moment";
import React from "react";
import Check from "../../../common/Check";
import MenuIcon from '@material-ui/icons/Menu';
import { getNewsByNids } from "../../../api/news/newsBriefing";
import { getCdnUploadImageReturnUrl } from "../../../api/issue/issue";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { NewsBriefingInsert, Flag } from '../../../slice/newsBriefingSlice';
import { briefingInser, briefingUpdate } from "../../../api/briefing/briefing";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import MessageModal from "../../../modal/Message";

const MenuToggle = styled.div`
    display: flex;
    align-items: center;
`;


const RegistBody = ({ mode, pid }) => {

    const [postDetail, setPostDetail] = useState([]);
    const [nidList, setNidList] = useState([]);
    const [newsList, setNewsList] = useState([]);
    const [detailList, setDetailList] = useState([]);// eslint-disable-line no-unused-vars
    const [thumb, setThumb] = useState();// eslint-disable-line no-unused-vars
    const [searchOpen, setSearchOpen] = useState(false);

    const [delTempList, setDelTempList] = useState([]);

    const [title, setTitle] = useState();
    const [fromTime, setFromTime] = useState("23:59");
    const [image, setImage] = useState();
    const [reservDate, setReservDate] = useState(moment().format("YYYY-MM-DD"));
    const dispatch = useDispatch();
    const Insert = useSelector((state) => state.newsBriefing.value.newsBriefingInsert);
    const insertFlag = useSelector((state) => state.newsBriefing.value.insertFlag);
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));// eslint-disable-line no-unused-vars
    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');

    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);

    const navigate = useNavigate();

    useEffect(() => {
        if (pid && pid.length > 0) {
            getPostByPid(pid)
                .then((response) => {
                    setPostDetail(response.data);
                    setTitle(response.data.title);
                    const date = response.data.revDt;
                    const revdate = date.substr(0, 10);
                    setReservDate(revdate);
                    const revTime = date.substr(11, 5);
                    setFromTime(revTime);
                    const contents = JSON.parse(response.data.contents);
                    if (contents.cont_1) {
                        setThumb(contents.cont_1.cont_imgs)
                        setNidList(contents.cont_1.nids_1.list);
                        setDetailList(contents.cont_1.nids_1.details);
                    }

                })
        }
    }, [pid])

    useEffect(() => {
        if (nidList && nidList.length > 0) {
            getNewsByNids(nidList)
                .then((response) => {
                    setNewsList(response.data.news_list);
                })
        }
    }, [nidList]);

    const SortableDrag = SortableHandle(() => (
        <MenuToggle>
            <MenuIcon />
        </MenuToggle>
    ));

    const SortableList = SortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    const SortableItem = SortableElement(({ item, num }) => (
        <TableRow >
            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check onChange={(e) => handleDelCheckChange(e, item.nid)} /></div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><SortableDrag /></div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.nid}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>
                <img src={item.img_urls} style={{ height: '42px' }} alt='news thumbnail' />
            </div>
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>{item.title}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.med_nm}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.cat_nm}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>-</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>추가자</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>뉴스등록일시</div>
        </TableRow>
    ));

    const arrayMove = (array, oldIndex, newIndex) => {
        let arr = [...(array ?? [])]
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const list = arrayMove(newsList, oldIndex, newIndex);
        setNewsList(list);
    };

    const onAdd = (list) => {
        const temp = newsList.concat(list);
        const nidListTemp = temp.map((item, index) => {
            return item.nid
        });
        setNewsList(list);
        setNidList(nidListTemp);
    }

    const handleDelCheckChange = (e, nid) => {
        var temp = delTempList;
        if (e.target.checked === true) {
            temp.push(nid)
            setDelTempList(temp);
        } else if (e.target.checked === false) {
            temp = temp.filter(function (item) { return item !== nid })
            setDelTempList(temp);
        }
    }

    const handleDeleteClick = () => {
        var temp = newsList;
        delTempList.map((target) => {//eslint-disable-line
            temp = temp.filter(function (item) { return item.nid !== target })
        })
        const nidListTemp = temp.map((item, index) => {
            return item.nid
        });
        setNewsList(temp);
        setNidList(nidListTemp);
    }

    const onSetTitle = (e) => {
        if (e.target.value.length > 60) {

            return;
        } else {
            setTitle(e.target.value);
        }
    };

    const addImgItem = (file) => {
        setImage(file);
    };

    useEffect(() => {
        if (Insert === true) {
            setMsgTitle("뉴스 브리핑");
            setMessage("등록 / 수정중");
            dispatch(LoadingModalFlag(true));
            if (title === undefined || title === '') {
                dispatch(LoadingModalFlag(false));
                setMessage("제목을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NewsBriefingInsert(false));
                return;
            } else if (image === undefined || image === '') {
                dispatch(LoadingModalFlag(false));
                setMessage("썸네일을 선택해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NewsBriefingInsert(false));
                return;
            } else if (newsList.length === 0) {
                dispatch(LoadingModalFlag(false));
                setMessage("뉴스 리스트를 추가해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NewsBriefingInsert(false));
                return;
            }

            const webfileNm = image.name;
            var webImgFile = webfileNm.slice(webfileNm.lastIndexOf(".") + 1).toLowerCase();
            if (!(webImgFile === "png" || webImgFile === "jpg")) {
                dispatch(LoadingModalFlag(false));
                setMessage("webImg는 이미지파일 (png , jpg) 만 업로드 가능합니다.");
                dispatch(MessageModalFlag(true));
                dispatch(NewsBriefingInsert(false));
                return;
            }

            const rev_dt = reservDate + " " + fromTime;

            getCdnUploadImageReturnUrl(image)
                .then((response) => {
                    const contents = {};
                    contents.cont_1 = {};
                    contents.cont_1.nids_1 = {};
                    contents.cont_1.nids_1.list = {};
                    contents.cont_1.nids_1.list = nidList;
                    contents.cont_1.nids_1.title = {};
                    contents.cont_1.nids_1.title = '뉴스';
                    contents.cont_1.nids_1.details = {};
                    contents.cont_1.nids_2 = {};
                    contents.cont_1.nids_2.list = [];
                    contents.cont_1.nids_2.title = '방송';
                    contents.cont_1.nids_3 = {};
                    contents.cont_1.nids_3.list = [];
                    contents.cont_1.nids_3.title = '';
                    for (var i = 0; i < newsList.length; i++) {
                        newsList[i].summary = null;
                        contents.cont_1.nids_1.details[newsList[i].nid] = newsList[i];
                    }
                    contents.cont_1.cont_date = {};
                    contents.cont_1.cont_date = date;
                    contents.cont_1.cont_imgs = [];
                    const arr = new Array([]);
                    arr.splice(0, 1);
                    arr.push(response.data.image_urls[0]);
                    contents.cont_1.cont_imgs = arr;
                    const realContents = JSON.stringify(contents);
                    if (mode === 'add') {
                        const data = { "rid": 1, "post_tp": 4, "post_st": "N", "rev_dt": rev_dt, "title": title, "contents": realContents, "fold_id": 2, "open_st": 1, "is_use": 1 }
                        briefingInser(data)
                            .then((response) => {
                                dispatch(LoadingModalFlag(false));
                                if (response.status === 200) {
                                    setMessage("등록이 완료되었습니다.");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(NewsBriefingInsert(false));
                                    dispatch(Flag(true));
                                } else {
                                    setMessage("등록이 실패되었습니다.");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(NewsBriefingInsert(false));
                                }
                            })
                    } else if (mode === 'modify') {
                        const data = { "rid": 1, "post_tp": 4, "post_st": "N", "rev_dt": rev_dt, "title": title, "contents": realContents, "fold_id": 2, "open_st": 1, "is_use": 1, "pid": pid }
                        briefingUpdate(data)
                            .then((response) => {
                                dispatch(LoadingModalFlag(false));
                                if (response.status === 200) {
                                    setMessage("수정이 완료되었습니다");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(NewsBriefingInsert(false));
                                } else {
                                    setMessage("수정이 실패되었습니다");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(NewsBriefingInsert(false));
                                }
                            })
                    }

                })

        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/newsBriefing/");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={title || ""} width='50%' placeholder='뉴스 브리핑 제목을 입력하세요' onChange={onSetTitle} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>썸네일</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <FileAttachSplash width='50%' placeholder='파일을 선택하세요' guideText={`최적크기 328*246`}
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                addImgItem(e.target.files[0]);
                            }
                        }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>예약등록일시</Label></div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '180px' }}>
                        <OneDayCalendarPush setDate={setReservDate} splashDate={reservDate} />
                    </div>
                    <input
                        value={fromTime}
                        type="time"
                        min="00:00"
                        max="23:59"
                        style={{
                            width: "150px",
                            padding: "12px",
                            height: "19px",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                        onChange={(e) => {
                            setFromTime(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div style={{ height: '50px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '35px' }}>
                <Label type='title'>뉴스 리스트</Label>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '25px' }}>
                    <Button name='추가' sType='sm' onClick={(e) => setSearchOpen(true)} />&nbsp;
                    <Button name='삭제' cType='b_line' sType='sm' onClick={handleDeleteClick} />
                    <News open={searchOpen} close={(e) => setSearchOpen(false)} onAdd={onAdd} />
                </div>
            </div>
            {postDetail &&
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {(newsList && newsList.length > 0) &&
                        <React.Fragment>
                            <TableHeader>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}>순서</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 아이디</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>이미지</div>
                                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스타입</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>감성키워드 타입</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>추가자</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>뉴스등록일시</div>
                            </TableHeader>
                            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                <SortableList useDragHandle onSortEnd={onSortEnd}>
                                    {newsList.map((item, index) => {
                                        return (
                                            <SortableItem index={index} key={`item-${index}`} item={item} num={index} />
                                        )
                                    })
                                    }
                                </SortableList>
                            </div>

                        </React.Fragment>
                    }
                </div>
            }
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default RegistBody;