import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/novelManagement/Body";
import RegistBody from "../../components/novelManagement/regist/RegistBody";
import RegistTitle from "../../components/novelManagement/regist/RegistTitle";
import Title from "../../components/novelManagement/Title";
import ViewBody from "../../components/novelManagement/view/ViewBody";
import ViewTitle from "../../components/novelManagement/view/ViewTitle";
import NovelManagement from "../../templates/novelManagement/NovelManagement";

const NovelContainer = ({ page }) => {

    const { nkey } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={nkey && nkey !== "" ? "modify" : "add"} nkey={nkey} />);
            setBody(<RegistBody mode={nkey && nkey !== "" ? "modify" : "add"} nkey={nkey} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle nkey={nkey} />);
            setBody(<ViewBody nkey={nkey} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, nkey])

    return (
        <NovelManagement
            title={title}
            body={body}
        />
    )
}

export default NovelContainer;