import { instance } from '..';
export const getMenu = async (data) => {
    const response = await instance.get(`/menu/menus`, data, null)
        .then((response) => {
            return response;
        })
    return response;

}

export const getAuthMenu = async (data) => {
    const url = `/menu/menus`;
    const response = await instance.post(url, data,
        {
            headers: {
                'content-type': 'application/json'
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getSelectAuthMenu = async (data) => {
    const response = await instance.get(`/auth/getSelectAuthMenuList`, data, null)
        .then((response) => {
            return response;
        })
    return response;

}

export const getMenusInfo = async(data) => {
    const url = `/menu/menusInfo`;
    const response = await instance.post(url, data,  
        {
        headers: {
            'content-type': 'application/json'
        }
    })
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}