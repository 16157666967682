import { useEffect, useState } from "react";
import Body from "../../components/newsSearchTest/Body";
import Title from "../../components/newsSearchTest/Title";
import NewsSearchTest from "../../templates/newsSearchTest/NewsSearchTest";


const NewsSearchTestContainer = () => {

    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        setTitle(<Title />);
        setBody(<Body />);
    }, [])

    return (
        <div>
            <NewsSearchTest title={title} body={body} />
        </div>
    )
}

export default NewsSearchTestContainer;