import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from "../../common/login/Button";

const intToString = (num) => {
	return String(num).padStart(2, "0");
};

const Timer = ({ hh, mm, ss }) => {
	const HH = hh ? parseInt(hh) : 0;
	const MM = mm ? parseInt(mm) : 0;
	const SS = ss ? parseInt(ss) : 0;

	const count = useRef(HH * 60 * 60 + MM * 60 + SS);
	const interval = useRef(null);

	const [hour, setHour] = useState(intToString(HH));
	const [minute, setMinute] = useState(intToString(MM));
	const [second, setSecond] = useState(intToString(SS));
	const navigate = useNavigate();

	useEffect(() => {
		interval.current = setInterval(() => {
			count.current -= 1;
			setHour(intToString(parseInt(count.current / 3600)));
			setMinute(intToString(parseInt((count.current % 3600) / 60)));
			setSecond(intToString((count.current % 60)));
		}, 1000);
	}, []);

	useEffect(() => {
		if (second === "00" & hour === "00" & minute === "00") {
			localStorage.clear();
			localStorage.setItem('loginInfo', 'unconnect')
			navigate('/');
		}
		if (count.current <= 0) {
			clearInterval(interval.current);
		}
	}, [second, hour, minute, navigate]);

	const timeUpdate = () => {
		count.current = 3600;
		setHour(intToString(parseInt(count.current / 3600)));
		setMinute(intToString(parseInt((count.current % 3600) / 60)));
		setSecond(intToString((count.current % 60)));
	}

	return (
		<div style={{ textAlign: 'center', justifyContent: 'center' }}>
			{hour} : {minute} : {second} &nbsp;&nbsp;<Button name='세션 시간 초기화' sType='sm' onClick={timeUpdate} width='120px' />
		</div>
	);
};

export default Timer;