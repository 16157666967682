import styled from "styled-components";
import { BannerDetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import { useState, useEffect } from "react";
import React from "react";
import { getSecossionList, getSecossionPV } from "../../../api/secossionList/secossionList";
import { LoadingModalFlag } from '../../../slice/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from "../../../modal/Message";

const BasicInfoC = styled.div``;

const ViewBody = ({ regId }) => {

	const [secessionDetail, setSecessionDetail] = useState();
	const [secossionPV, setSecossionPV] = useState();
	const dispatch = useDispatch();
	const [message, setMessage] = useState('');
	const LModal = useSelector((state) => state.modal.value.Loading);

	useEffect(() => {
		if (regId) {
			setMessage("탈퇴 내역 접속정보 검색중");
			dispatch(LoadingModalFlag(true));
			const data = { params: { "regId": regId } };
			getSecossionList(data)
				.then((response) => {
					for (var i = 0; i < response.data.data.length; i++) {
						const quedata = response.data.data[0].question.split('end');
						response.data.data[0].questionData = quedata[0];
						const equedata = response.data.data[i].userId.indexOf('@');
						response.data.data[i].userIdNm = response.data.data[i].userId.replace(response.data.data[i].userId.substr((equedata * 1) - 3, 3), "***")
					}
					setSecessionDetail(response.data.data[0]);
					const data = { params: { "regId": regId, "regDt": response.data.data[0].regDt, "secossionDt": response.data.data[0].secossionDt } };
					getSecossionPV(data)
						.then((response) => {
							setSecossionPV(response.data.data)
							dispatch(LoadingModalFlag(false));
						})
				})
		}
	}, [regId]);// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<React.Fragment>
			<div style={{ height: "50px" }} />
			{secessionDetail &&
				<BasicInfoC>
					<Label type="title">탈퇴 내역 정보</Label>
					<div style={{ height: "20px" }} />
					<BannerDetailTable style={{ width: "100%" }}>
						<tbody>
							<tr>
								<th>가입일</th><td>{secessionDetail.regDt}</td>
								<th>탈퇴일</th><td>{secessionDetail.secossionDt}</td>
							</tr>
							<tr>
								<th>탈퇴사유</th><td>{secessionDetail.questionData}</td>
								<th>가입경로</th><td>{secessionDetail.accessTp}</td>
							</tr>
							<tr>
								<th>대표룸이름</th><td>{secessionDetail.roomNm}</td>
								<th>사용자 아이디</th><td>{secessionDetail.userIdNm}</td>
							</tr>
						</tbody>
					</BannerDetailTable>
				</BasicInfoC>
			}
			{secossionPV &&
				<BasicInfoC>
					<Label type='title'>접속 정보</Label>
					<div style={{ height: '20px' }} />
					<BannerDetailTable style={{ width: '100%' }}>

						{secossionPV && secossionPV.map((item, i) => (
							<tbody key={i}>
								<tr>
									<th>접속 페이지</th><td>{item.logMsg}</td>
									<th>접속 횟수</th><td>{item.cnt}</td>
								</tr>
							</tbody>
						))}

					</BannerDetailTable>
				</BasicInfoC>
			}
			{LModal &&
				<div className='Message_Modal_Container'>
					<div className='Message_Modal'>
						<MessageModal type="loading" title="탈퇴 내역 상세 정보" message={message} />
					</div>
				</div>
			}
		</React.Fragment>
	);
};

export default ViewBody;
