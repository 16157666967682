
import { BodyTitle, BodyTitleFont } from "../../style/layout";


const Title = () => {
    return (
        <BodyTitle>
            <BodyTitleFont>이슈 VS 이슈 관리</BodyTitleFont>
        </BodyTitle>
    )
}

export default Title;