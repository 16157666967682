import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/terms/Body";
import RegistBody from "../../components/terms/regist/RegistBody";
import RegistTitle from "../../components/terms/regist/RegistTitle";
import Title from "../../components/terms/Title";
import ViewBody from "../../components/terms/view/ViewBody";
import ViewTitle from "../../components/terms/view/ViewTitle";
import Terms from "../../templates/terms/Terms";

const TermsContainer = ({ page }) => {

    const { tid } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle tid={tid} />);
            setBody(<RegistBody tid={tid} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle tid={tid} />);
            setBody(<ViewBody tid={tid} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, tid])

    return (
        <Terms
            title={title}
            body={body}
        />
    )
}

export default TermsContainer;