import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNoticeList } from "../../api/notice/notice";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import { LoadingModalFlag } from '../../slice/modalSlice';
import Paging from "../../common/Paging";
import Button from "../../common/Button";
import { useDispatch, useSelector } from 'react-redux';
import TextField from "../../common/TextField";
import Form from 'react-bootstrap/Form';
import MessageModal from '../../modal/Message';

const Body = () => {
    const navigate = useNavigate();

    const [size, setSize] = useState(10);
    const [pagesInfo, setPagesInfo] = useState();
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [noticeList, setNoticeList] = useState();
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    const LModal = useSelector((state) => state.modal.value.Loading);

    useEffect(() => {
        setMessage("검색중");
        dispatch(LoadingModalFlag(true));
        searchhandle();
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setMessage("검색중");
        setRealPage_no(1);
        getNoticeList(1, size).then((response) => {
            dispatch(LoadingModalFlag(false));
            setPagesInfo(response.data.pages);
            setNoticeList(response.data.results);
        });
    }, [size]);// eslint-disable-line react-hooks/exhaustive-deps


    const search = (e) => {
        setPage_no(1);
        setRealPage_no(1);
        searchhandle();
    }


    const searchhandle = (e) => {
        getNoticeList(page_no, size).then((response) => {
            dispatch(LoadingModalFlag(false));
            setPagesInfo(response.data.pages);
            setNoticeList(response.data.results);
        });
    }

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    };

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {(pagesInfo && (pagesInfo.total_rows > 0)) &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{pagesInfo.total_rows}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select className="selectBox">
                            <option value={1}>제목</option>
                            <option value={2}>내용</option>
                        </Form.Select>&nbsp;
                        <TextField placeholder={`검색어를 입력해주세요`} />
                        <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        <Button cType='b_line' name='검색' width='65px' onClick={search} />&nbsp;
                    </div>
                </div>
                <div className='title'></div>
                <div className='component'></div>
            </SearchBar>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {noticeList && noticeList.length > 0 && (
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: "flex", justifyContent: "center", width: "100px" }}>공지사항 아이디</div>
                            <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>제목</div>
                            <div style={{ display: "flex", justifyContent: "center", width: "140px" }}>공지사항 유형</div>
                            <div style={{ display: "flex", justifyContent: "center", width: "80px" }}>공개여부</div>
                            <div style={{ display: "flex", justifyContent: "center", width: "120px", }}>등록일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: "auto" }}>
                            {noticeList.map((item, index) => {
                                return (
                                    <TableRow key={index} onClick={(e) => navigate(`/operation/notice/view/${item.notice_id}`)}>
                                        <div style={{ display: "flex", justifyContent: "center", width: "100px" }}>{item.notice_id}</div>
                                        <div style={{ display: "inline-block", flexGrow: 1, justifyContent: "center", width: "calc(100vh - 816px)", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{item.title}</div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "140px", }}>{item.notice_tp}</div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "80px", }}>{item.is_use === 1 ? "공개" : "비공개"}</div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "120px", }}>{item.reg_dt}</div>
                                    </TableRow>
                                );
                            })}
                        </div>
                        <Paging count={pagesInfo.total_rows} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                )}
            </div>
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="공지사항 관리" message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

export default Body;
