import axios from "axios";
import moment from "moment";
const server_addr = 'https://api.rozeus.com/admin';


export const getTracker = async (type, fromDate, toDate) => {
    let from_dt = moment().add(-1, 'd').format('YYYY-MM-DD');
    let to_dt = moment().format('YYYY-MM-DD');
    // let to_dt = moment(toDate).format('YYYY-MM-DD');

    // if(option === 0){
    //     from_dt = moment(fromDate).add(-20, 'd').format('YYYY-MM-DD');
    // }else if(option === 1){
    //     from_dt = moment(fromDate).add(-20, 'w').format('YYYY-MM-DD');
    // }else{
    //     from_dt = moment(fromDate).add(-1, 'y').format('YYYY-MM-DD');
    // }

    const path = `/api/v1/tracker/getTracker`;
    const retVal = await axios.get(server_addr + path + `?type=${type}&from_dt=${from_dt}&to_dt=${to_dt}`)
        .then(function (response) {
            return response.data;
        }).catch(err => {
            return null;
        });

    return retVal;
};