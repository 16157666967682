import styled from "styled-components";
import { DetailTable } from "../../../../style/layout";
import Label from "../../../../common/Label";
import { useState } from "react";
import { useEffect } from "react";
import { getPostDetailList, getPostreplyList } from "../../../../api/cmspost/post";
import Edit from '../edit/Edit'
import React from "react";


const BasicInfoC = styled.div`
`;

const ViewBody = ({ pid, rid }) => {

    const [postDetail, setPostDetail] = useState();
    const [text, setText] = useState();// eslint-disable-line no-unused-vars
    const [editData, setEditData] = useState();// eslint-disable-line no-unused-vars
    const [pollList, setPollList] = useState([]);
    const [replyList, setReplyList] = useState([]);


    useEffect(() => {
        if (pid && pid.length > 0) {
            getPostDetailList(pid, rid)
                .then((response) => {
                    if (response.data[0]) {
                        setPostDetail(response.data[0].get_post[0]);
                        if (JSON.parse(response.data[0].get_post[0].contents).raw) {
                            const textList = new Array([]);
                            textList.slice(0, 1)
                            for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).raw.blocks.length; i++) {
                                textList.push(JSON.parse(response.data[0].get_post[0].contents).raw.blocks[i].text);
                                textList.push(<br />);
                            }
                            setText(textList);
                            setPollList(response.data[7].choices);
                            getPostreplyList(pid, rid)
                                .then((response) => {
                                    setReplyList(response.data.reply_list);
                                })
                        } else {
                        }
                    } else {
                    }
                })
        }
    }, [pid])// eslint-disable-line react-hooks/exhaustive-deps

    const handleNowData = (editData) => {
        setEditData(editData.contents);
    };

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {postDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>포스트 seq</th><td>{postDetail.pid}</td>
                                <th>뉴스룸 카테고리</th><td>포스트</td>
                            </tr>
                            <tr>
                                <th>공개여부</th><td>{postDetail.open_st === "1" ? '공개' : '비공개'}</td>
                                <th>예약일시</th><td>{postDetail.rev_dt}</td>
                            </tr>
                            <tr>
                                <th>등록일시</th><td>{postDetail.reg_dt}</td>
                                <th>수정일시</th><td>{postDetail.mod_dt}</td>
                            </tr>
                            <tr>
                                <th>내용</th><td><Edit onChange={handleNowData} rid={rid} pid={pid} editMode="false"></Edit></td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '20px' }} />
            {replyList && replyList.length !== 0 &&
                <BasicInfoC>
                    <div style={{ height: '20px' }} />
                    <Label type='title'>댓글 정보</Label>
                    <DetailTable style={{ width: '100%' }}>
                        {replyList && replyList.map((item, i) => (
                            <tbody>
                                <tr>
                                    <th>댓글 작성자</th><td>{item.room_nm}</td>
                                    <th>댓글 내용</th><td>{item.comment}</td>
                                    <th>댓글 좋아요</th><td>{item.hate_count && item.hate_count !== "" ? item.hate_count : 0}</td>
                                    <th>댓글 싫어요</th><td>{item.like_count && item.like_count !== "" ? item.like_count : 0}</td>
                                </tr>
                            </tbody>
                        ))}
                    </DetailTable>
                    <div style={{ height: '20px' }} />
                </BasicInfoC>
            }
            {pollList &&
                <BasicInfoC>
                    <Label type='title'>픽클 정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        {pollList && pollList.map((item, i) => (
                            <tr>
                                <th>{i + 1}번째 항목</th><td>{item.choiceOption}</td>
                                <th>선택</th><td>{item.choiceCount}</td>
                            </tr>
                        ))}
                    </DetailTable>
                </BasicInfoC>
            }
        </React.Fragment>
    )
}

export default ViewBody;