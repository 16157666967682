import React from 'react';
import styled from 'styled-components';

const AdminTagC = styled.div`
    width: 100%;
    height: 44px;
    min-height: 44px;
    background: #555151;
    vertical-align:middle;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items : center;
`;

const AdminTag = () => {
    return (
        <React.Fragment>
            <AdminTagC>
                SYSTEM OFFICE
            </AdminTagC>
            <div style={{ height: '12px' }} />
        </React.Fragment>
    )
}

export default AdminTag;