import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/splashIntro/Body";
import RegistBody from "../../components/splashIntro/regist/RegistBody";
import RegistTitle from "../../components/splashIntro/regist/RegistTitle";
import Title from "../../components/splashIntro/Title";
import SplashIntro from "../../templates/spalash/SpalashIntro";
import ViewTitle from "../../components/splashIntro/view/ViewTitle";
import ViewBody from "../../components/splashIntro/view/ViewBody";


const SplashIntroContainer = ({ page }) => {
    const { itoId } = useParams();

    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === "add") {
            setTitle(<RegistTitle mode={itoId && itoId !== "" ? "modify" : "add"} />);
            setBody(<RegistBody itoId={itoId} mode={itoId && itoId !== "" ? "modify" : "add"} />);
        } else if (page && page === "view") {
            setTitle(<ViewTitle itoId={itoId} />);
            setBody(<ViewBody itoId={itoId} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, itoId]);

    return (
        <div>
            <SplashIntro title={title} body={body} />
        </div>
    );
};

export default SplashIntroContainer;
