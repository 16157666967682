import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme, { PageStyle } from '../../cms/theme';
import { Title } from './NewsList';
import NoneData from './NoneData';
import { MoreList } from '../../../style/searchStyle';
import { handleImgError } from '../../../style/leftImgLayout';
import { getSearchRoomMore } from '../../../api/newsRoom/post';
import { getSearchRoom } from '../../../api/newsRoom/post';
import moment from 'moment';

const LayoutRoom = styled.div`
    display: flex;
    margin-bottom: ${(props) => (props.length === props.num + 1 ? '16px' : '0')};
    width: 100%;
    .profile_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        border: 1px solid #f4f4f4;
        border-radius: 50%;

        .profile {
            width: 56px;
            height: 56px;
            border-radius: 50%;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 90%;
        .name {
            margin: 7px 0 2px 16px;
            ${(props) => props.theme.fontSize.body2bold};
            height: 20px;
        }
        .comment {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 85%;
            height: 20px;
            margin: 0 0 2px 16px;
            ${(props) => props.theme.fontSize.body2};
        }
    }
`;
const Line = styled.div`
    display: ${(props) => (props.length === props.num + 1 ? 'none' : 'flex')};
    width: 100%;
    height: 1px;
    margin: 7px 0 8px 0;
    background: #000000;
    opacity: 0.1;
`;
/**검색 뉴스룸 결과 **/

const NewsRoomList = ({ keyword, title, edit, selectMenu, setSelectData }) => {

    const regExpKey = /[%^]/gi;
    const [roomTotal, setRoomTotal] = useState(0);
    const [page_no, setPage_no] = useState(1);
    const [roomList, setRoomList] = useState([]);
    var realFrom_dt = moment().subtract(1, 'months').format('YYYYMMDDHHmmss');
    var realTo_dt = moment().format('YYYYMMDDHHmmss');

    useEffect(() => {
        var searchKeyword = keyword.replace(regExpKey, '');
        setPage_no(1);
        getSearchRoom(realFrom_dt, realTo_dt, 1, searchKeyword)
            .then((response) => {
                setRoomList(response.data.newsrooms);
                setRoomTotal(response.data.total_row);
            });
    }, [keyword]);// eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = () => {
        var searchKeyword = keyword.replace(regExpKey, '');
        if (page_no !== 1) {
            getSearchRoomMore(realFrom_dt, realTo_dt, page_no, 0, searchKeyword)
                .then((response) => {
                    setRoomList(roomList.concat(response.data.newsrooms))
                    setRoomTotal(response.data.total_row);
                });
        }
    };

    const numberHandler = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    useEffect(() => {
        loadMore();
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider theme={theme}>
            <PageStyle>
                <Title>
                    {title} ({numberHandler(roomTotal)})
                </Title>
                {roomList.length !== 0 ? (
                    <div>
                        {roomList &&
                            roomList.map((item, index) => {
                                return (
                                    (selectMenu === 0 ? index < 5 : true) && (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                if (edit) {
                                                    setSelectData(item);
                                                } else {
                                                }
                                            }}
                                        >
                                            <LayoutRoom length={roomList.length} num={index} key={index}>
                                                <div className={'profile_box'}>
                                                    <img
                                                        className={'profile'}
                                                        src={item.img_profile}
                                                        onError={handleImgError}
                                                        alt="profileimage"
                                                    />
                                                </div>
                                                <div className={'content'}>
                                                    <span className={'name'}>{item.room_nm}</span>
                                                    <span className={'comment'}>{item.comment}</span>
                                                </div>
                                            </LayoutRoom>
                                            <Line length={roomList.length} num={index} />
                                        </div>
                                    )
                                );
                            })}
                    </div>
                ) : (
                    <>{edit ? null : <NoneData name={keyword} />}</>
                )}
            </PageStyle>
            {roomList.length >= roomTotal ? null : (
                <MoreList
                    onClick={async () => {
                        setPage_no(page_no + 1);
                    }}
                >
                    <span>뉴스룸 결과 더보기</span> <img src="/images/icon/ic_expand_more.svg" alt="detail page move icon" />
                </MoreList>
            )}
        </ThemeProvider>
    );
};
export default NewsRoomList;
