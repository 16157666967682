import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Label from "../../../common/Label";
import TextField from "../../../common/TextField";
import { getMemberDetail } from "../../../api/member/member";
import { useSelector, useDispatch } from 'react-redux';
import { LoginId, Email, Name, Phone, Company, Division, Department, Password, Uid, AuthInsert } from '../../../slice/memberSlice';
import { postMemberInsert, postMemberUpdate } from "../../../api/member/member";
import {  postMemberMenuInsert ,    } from "../../../api/auth/auth";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import { Flag } from '../../../slice/memberSlice';
import { useNavigate } from "react-router-dom";
import MessageModal from "../../../modal/Message";
import Multiselect from 'multiselect-react-dropdown';
import { getAuthMenu, getSelectAuthMenu } from './../../../api/auth/auth';

const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const RegistBody = ({ uid, mode }) => {
    const memberLoginId = useSelector((state) => state.member.value.memberLoginId);
    const memberEmail = useSelector((state) => state.member.value.memberEmail);
    const memberName = useSelector((state) => state.member.value.memberName);
    const memberPhone = useSelector((state) => state.member.value.memberPhone);
    const memberPassword = useSelector((state) => state.member.value.memberPassword);
    const memberCompany = useSelector((state) => state.member.value.memberCompany);
    const memberDivision = useSelector((state) => state.member.value.memberDivision);
    const memberDepartment = useSelector((state) => state.member.value.memberDepartment);
    const memberUid = useSelector((state) => state.member.value.memberUid);
    const insertFlag = useSelector((state) => state.member.value.insertFlag);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.member.value.authInsert);

    const [message, setMessage] = useState('');
    const [selectMultivalues , setSelectMultivalues] = useState([]);
    const [memberMid, setMemberMid] = useState(); // eslint-disable-line no-unused-vars
    const [multioption , setMultiOption] = useState([]);
    const [multivalues , setMultivalues] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getAuthMenu()
        .then((response) => {
            setMultiOption(response.data)
        })

        if (uid) {
            const data = { params: { "uid": uid } };
            getMemberDetail(data)
                .then((response) => {
                    dispatch(Uid(response.data.uid));
                    dispatch(LoginId(response.data.login_id));
                    dispatch(Email(response.data.email));
                    dispatch(Name(response.data.name));
                    dispatch(Phone(response.data.phone));
                    dispatch(Company(response.data.company));
                    dispatch(Division(response.data.division));
                    dispatch(Department(response.data.department));
                    dispatch(Password(response.data.Password));

                    const data = {params : { "loginId": response.data.login_id } };
                    getSelectAuthMenu(data)
                    .then((response) => {
                        setMultivalues(response.data);
                    })
                })
        } else {
            dispatch(LoginId(""));
            dispatch(Email(""));
            dispatch(Name(""));
            dispatch(Phone(""));
            dispatch(Company("비플라이소프트"));
            dispatch(Division("로제우스"));
            dispatch(Department(""));
            dispatch(Password(""));
        }

    }, [dispatch, uid]);


    useEffect(() => {
        if (Insert === true) {
            const emailRegex = /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/;
            const phoneRegex = /^\d{3}\d{3,4}\d{4}$/;
            if (memberLoginId === "" || memberLoginId === undefined) {
                dispatch(AuthInsert(false));
                setMessage("아이디를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberPassword === "" || memberPassword === undefined) {
                dispatch(AuthInsert(false));
                setMessage("비밀번호를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberEmail === "" || memberEmail === undefined) {
                dispatch(AuthInsert(false));
                setMessage("이메일을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (!emailRegex.test(memberEmail)) {
                dispatch(AuthInsert(false));
                setMessage("이메일 양식이 아닙니다");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberName === "" || memberName === undefined) {
                dispatch(AuthInsert(false));
                setMessage("이름을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberPhone === "" || memberPhone === undefined) {
                dispatch(AuthInsert(false));
                setMessage("핸드폰 번호를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (!phoneRegex.test(memberPhone)) {
                dispatch(AuthInsert(false));
                setMessage("핸드폰 번호 양식이 아닙니다");
                dispatch(MessageModalFlag(true));
                return;
            }
            setMessage("등록진행중");
            dispatch(LoadingModalFlag(true));
            if (mode === "add") {
                const data = {
                    "login_id": memberLoginId, "name": memberName, "password": memberPassword, "email": memberEmail,
                    "phone": memberPhone, "department": memberDepartment, "division": memberDivision, "company": memberCompany
                }
                postMemberInsert(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 400) {
                            setMessage(JSON.stringify(response.data.message));
                            dispatch(AuthInsert(false));
                            dispatch(MessageModalFlag(true));
                        } else if (response.status === 200) {
                            const formData = new FormData();
                            formData.append('loginId',memberLoginId);
                            formData.append('name',memberName);
                            formData.append('mid',memberMid);
                            postMemberMenuInsert(formData)
                            .then((response)=>{
                                setMessage("등록이 완료되었습니다");
                                dispatch(MessageModalFlag(true));
                                dispatch(AuthInsert(false));
                                dispatch(Flag(true));
                        })
                        }
                    })
            } else if (mode === "modify") {
                const data = {
                    "login_id": memberLoginId, "name": memberName, "password": memberPassword, "email": memberEmail,
                    "phone": memberPhone, "department": memberDepartment, "division": memberDivision, "company": memberCompany, "uid": memberUid
                }
                postMemberUpdate(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 400) {
                            setMessage(JSON.stringify(response.data.message));
                            dispatch(MessageModalFlag(true));
                            dispatch(AuthInsert(false));
                        } else if (response.status === 200) {
                            const formData = new FormData();
                            formData.append('loginId',memberLoginId);
                            formData.append('name',memberName);
                            formData.append('mid',memberMid);
                            postMemberMenuInsert(formData)
                            .then((response)=>{
                                setMessage("수정이 완료되었습니다");
                                dispatch(MessageModalFlag(true));
                                dispatch(AuthInsert(false));
                            })
                        }
                    })
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/member/authority");
        }
    }, [insertFlag, MModal, navigate, dispatch]);


    // 권한 박스 소스
    useEffect(() => {
        const selector = JSON.stringify(selectMultivalues);
        const deleteData = selector.replaceAll('[','');  
        const deleteData2 = deleteData.replaceAll('{"mid":','');
        const deleteData3 = deleteData2.replaceAll('}','');
        const deleteData4 = deleteData3.replaceAll(']','');  
        setMemberMid(deleteData4);
    }, [selectMultivalues]);

    const onRemove = (e) =>{
        const eArr = e.slice();
        const newArr = new Array(); //eslint-disable-line
        for(const index in eArr) {
            newArr.push({
                mid: e[index].mid,
            });
        }
        setSelectMultivalues(newArr);
    }
    const onSelect = (e) =>{
        const eArr = e.slice();
        const newArr = new Array(); //eslint-disable-line
        for(const index in eArr) {
            newArr.push({
                mid: e[index].mid,
            });
        }
        setSelectMultivalues(newArr);
    }

    return (
        <RegistBodyC>
            <div style={{ width: '100%', height: '565px', maxHeight: '1000px', background: 'White' }}>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            아이디
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberLoginId || ""}
                            width="50%"
                            placeholder={memberLoginId || "아이디를 입력해주세요"}
                            onChange={(e) => dispatch(LoginId(e.target.value))}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            비밀번호
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberPassword || ""}
                            width="50%"
                            type={'password'}
                            placeholder={memberPassword || "비밀번호를 입력해주세요"}
                            onChange={(e) => dispatch(Password(e.target.value))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            이메일
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberEmail || ""}
                            width="50%"
                            placeholder={memberEmail || "이메일을 입력해주세요"}
                            onChange={(e) => dispatch(Email(e.target.value))}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true} >
                            핸드폰 번호
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberPhone || ""}
                            width="50%"
                            placeholder={memberPhone || "핸드폰번호를 입력해주세요"}
                            onChange={(e) => dispatch(Phone(e.target.value))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true} >
                            회사
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberCompany || ""}
                            width="50%"
                            disabled
                            onChange={(e) => dispatch(Company(e.target.value))}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            부서
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberDivision || ""}
                            width="50%"
                            disabled
                            onChange={(e) => dispatch(Division(e.target.value))}

                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            소속
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberDepartment || ""}
                            width="50%"
                            placeholder={memberDepartment || "소속을 입력해주세요"}
                            onChange={(e) => dispatch(Department(e.target.value))}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            이름
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberName || ""}
                            width="50%"
                            placeholder={memberName || "이름을 입력해주세요"}
                            onChange={(e) => dispatch(Name(e.target.value))}
                        />
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
                <div style={{ display: 'flex'}}>

                    <div style={{ width: "120px" ,margin:'auto'}}>
                        <Label type={`nomal`} required={true}>
                            권한 페이지
                        </Label>
                    </div>
                    <div style={{ width: "600px" , margin:'auto'}}>
                        <Multiselect
                            displayValue="name"
                            onRemove={(e) => onRemove(e)}
                            onSelect={(e) => onSelect(e)}
                            options={multioption}
                            selectedValues={multivalues}
                            style={{width:'50%'}}
                        />
                    </div>
                    <div style={{ width: "120px" ,margin:'auto'}}>
                    </div>
                    <div style={{ width: "600px" , margin:'auto'}}>
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="회원 정보 등록" message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="회원 정보 수정" message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}
export default RegistBody;