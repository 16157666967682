import { createSlice } from '@reduxjs/toolkit';

export const issueSlice = createSlice({
	name: 'issue',
	initialState: { value: { issueInsert: false, insertFlag: false } },
	reducers: {
		IssueInsert: (state, action) => {
			state.value.issueInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { IssueInsert, Flag } = issueSlice.actions;
export default issueSlice.reducer;
