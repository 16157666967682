import { useEffect, useState } from "react";
import Title from "../../components/bannerList/Title";
import Body from "../../components/bannerList/Body";
import BannerList from "../../templates/bannerList/BannerList";
import RegistBody from "../../components/bannerList/regist/RegistBody";
import RegistTitle from "../../components/bannerList/regist/RegistTitle";
import ViewBody from "../../components/bannerList/view/ViewBody";
import ViewTitle from "../../components/bannerList/view/ViewTitle";
import { useParams } from "react-router-dom";


const BannerListContainer = ({ page }) => {

    const { bLocation } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={bLocation && bLocation !== "" ? "modify" : "add"} bLocation={bLocation} />);
            setBody(<RegistBody mode={bLocation && bLocation !== "" ? "modify" : "add"} bLocation={bLocation} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle bLocation={bLocation} />);
            setBody(<ViewBody bLocation={bLocation} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, bLocation]);

    useEffect(() => {

    }, [page]);

    return (
        <BannerList title={title} body={body} />
    );
};

export default BannerListContainer;