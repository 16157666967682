import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/push/Title";
import Body from "../../components/push/Body";
import RegistBody from "../../components/push/regist/RegistBody";
import RegistTitle from "../../components/push/regist/RegistTitle";
import ViewBody from "../../components/push/view/ViewBody";
import ViewTitle from "../../components/push/view/ViewTitle";
import Push from "../../templates/push/Push";

const PushContainer = ({ page }) => {

    const { pseq } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={pseq && pseq !== "" ? "modify" : "add"} />);
            setBody(<RegistBody mode={pseq && pseq !== "" ? "modify" : "add"} pseq={pseq} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle />);
            setBody(<ViewBody pseq={pseq} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, pseq]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Push title={title} body={body} />
    );
};

export default PushContainer;