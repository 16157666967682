import styled from "styled-components";
import { BannerDetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import { useState } from "react";
import { useEffect } from "react";
import DetailNewsList from "../common/DetailNewsList";
import { getDetailList } from "../../../api/issue/issue";
import React from "react";


const BasicInfoC = styled.div`
`;

const DetailInfoC = styled.div`
`;


const ViewBody = ({ pid, rid }) => {

    const [postDetail, setPostDetail] = useState();
    const [thumb, setThumb] = useState();
    const [newsList, setNewsList] = useState([]);
    const [text, setText] = useState();

    useEffect(() => {
        if (pid && pid.length > 0) {
            getDetailList(pid, rid)
                //getDetailList(6768386,1)
                .then((response) => {
                    if (response.data[0]) {
                        setPostDetail(response.data[0].get_post[0]);
                        if (JSON.parse(response.data[0].get_post[0].contents).raw) {
                            setThumb(JSON.parse(response.data[0].get_post[0].contents).cont_1.thumbnails)
                            const nidsList = new Array([]);
                            nidsList.splice(0, 1);
                            if (JSON.parse(response.data[0].get_post[0].contents).NewsList) {
                                for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_1.list.length; i++) {
                                    nidsList.push(JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_1.list[i]);
                                }
                                for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_2.list.length; i++) {
                                    nidsList.push(JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_2.list[i]);
                                }
                                for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_3.list.length; i++) {
                                    nidsList.push(JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_3.list[i]);
                                }
                            }
                            setNewsList(nidsList);
                            const textList = new Array([]);
                            textList.splice(0, 1);
                            for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).raw.blocks.length; i++) {
                                textList.push(JSON.parse(response.data[0].get_post[0].contents).raw.blocks[i].text);
                                textList.push(<br key={i} />);
                            }
                            setText(textList);
                        } else {
                            setThumb(JSON.parse(response.data[0].get_post[0].contents).cont_1.thumbnails[0])
                        }
                    } else {
                    }

                })
        }
    }, [pid])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {postDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div key={postDetail.pid} style={{ height: '20px' }} />
                    <BannerDetailTable style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>이슈따라잡기 아이디</th><td>{postDetail.pid}</td>
                                <th>뉴스룸 카테고리</th><td>이슈 따라잡기</td>
                            </tr>
                            <tr>
                                <th>공개여부</th><td>{postDetail.open_st === "1" ? '공개' : '비공개'}</td>
                                <th>예약일시</th><td>{postDetail.rev_dt}</td>
                            </tr>
                            <tr>
                                <th>등록일시</th><td>{postDetail.reg_dt}</td>
                                <th>수정일시</th><td>{postDetail.mod_dt}</td>
                            </tr>
                            <tr>
                                <th>썸네일 이미지</th><td><img src={thumb} alt='THUMB' style={{ width: '328px', height: '246px' }} /></td>
                            </tr>
                            <tr>
                                <th>텍스트</th><td>{text}</td>
                            </tr>
                        </thead>
                    </BannerDetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
            {(newsList && newsList.length > 0) &&
                <DetailInfoC>
                    <Label type='title'>뉴스 리스트</Label>
                    <DetailNewsList newsList={newsList} setNewsList={setNewsList} />
                </DetailInfoC>
            }
        </React.Fragment>
    )
}

export default ViewBody;