import { useNavigate, useParams } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";


const ViewTitle = () => {
	const navigate = useNavigate();
	const { tid } = useParams();

	return (
		<BodyTitle>
			<BodyTitleFont>약관 관리 상세 정보</BodyTitleFont>
			<div>
				<Button
					name={`수정`}
					onClick={(e) => {
						navigate(`/operation/termsofservice/add/${tid}`)
					}}
				/>
				&nbsp;
				<Button
					cType={`cancel`}
					width={'150px'}
					name={`목록으로 이동`}
					onClick={(e) => navigate(`/operation/termsofservice`)}
				/>
			</div>
		</BodyTitle>
	);
};

export default ViewTitle;
