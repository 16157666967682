import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";

const ViewTitle = ({ nid }) => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>In News 설정 관리 상세정보</BodyTitleFont>
            <div>
                <Button name={`수정`} onClick={(e) => navigate(`/operation/inNewsList/update/${nid}`)} />
                &nbsp;
                <Button cType={`cancel`} width={'150px'} name={`목록으로 이동`} onClick={(e) => navigate(`/operation/inNewsList`)} />
            </div>
        </BodyTitle>
    )
}

export default ViewTitle;