import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

export const splashIntroSlice = createSlice({
	name: 'splash',
	initialState: { value: { splashTitle: "", splashCategoryCd: "SEASON", splashStartDay: moment().format("YYYY-MM-DD"), splashStartTime: "00:00", splashFromDay: moment().format("YYYY-MM-DD"), splashFromTime: "00:00", splashIntroInsert: false, insertFlag: false } },
	reducers: {
		SplashTitle: (state, action) => {
			state.value.splashTitle = action.payload;
		},
		SplashCategoryCd: (state, action) => {
			state.value.splashCategoryCd = action.payload;
		},
		SplashStartDay: (state, action) => {
			state.value.splashStartDay = action.payload;
		},
		SplashStartTime: (state, action) => {
			state.value.splashStartTime = action.payload;
		},
		SplashFromDay: (state, action) => {
			state.value.splashFromDay = action.payload;
		},
		SplashFromTime: (state, action) => {
			state.value.splashFromTime = action.payload;
		},
		SplashIntroInsert: (state, action) => {
			state.value.splashIntroInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},

	},
});
export const { SplashTitle, SplashCategoryCd, SplashStartDay, SplashStartTime, SplashFromDay, SplashFromTime, SplashIntroInsert, Flag } = splashIntroSlice.actions;
export default splashIntroSlice.reducer;