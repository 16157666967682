import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../../style/layout";
import Button from "../../../../common/Button";
import React from "react";
import { CmsPostListInsert } from '../../../../slice/cmsPostSlice';
import { useDispatch } from 'react-redux';


const RegistTitle = ({ mode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleRegist = (e) => {
        dispatch(CmsPostListInsert(true));
    }

    return (
        <BodyTitle>
            <BodyTitleFont>
                포스트 {mode === "add" ? "등록" : "수정"}
            </BodyTitleFont>
            <div>
                <Button
                    name={mode === "add" ? "등록" : "수정"}
                    onClick={handleRegist}
                />
                &nbsp;
                <Button
                    cType={`cancel`}
                    name={`취소`}
                    onClick={
                        (e) =>
                            navigate("/cms/postInsert")
                    }
                />
            </div>
        </BodyTitle>
    )
}

export default RegistTitle;