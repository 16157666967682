import styled from "styled-components";
import { DetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import React, { useState, useEffect } from "react";
import { getBanner } from "../../../api/banner/banner";

const BasicInfoC = styled.div`
`;

const ViewBody = ({ bSeq }) => {


    const [bannerDetail, setBannerDetail] = useState();


    useEffect(() => {

        const data = { params: { "b_seq": bSeq } };
        getBanner(data)
            .then((response) => {
                setBannerDetail(response.data.data[0]);
            })
    }, [bSeq])

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {bannerDetail &&
                <BasicInfoC>
                    <Label type='title'>Banner 정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>bDesc</th><td>{bannerDetail.b_desc}</td>
                                <th>bSeq</th><td>{bannerDetail.b_seq}</td>
                            </tr>
                            <tr>
                                <th>bLink</th><td>{bannerDetail.b_link}</td>
                                <th>bLinkType</th><td>{bannerDetail.b_link_type}</td>
                            </tr>
                            <tr>
                                <th>Url</th><td>{bannerDetail.b_url}</td>
                                <th>bName</th><td>{bannerDetail.b_name}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
            {bannerDetail &&
                <BasicInfoC>
                    <Label type='title'>미리보기</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>웹 미리보기</th><td><img src={bannerDetail.b_url + "_w." + bannerDetail.b_extension} alt='Web Img' /></td>
                            </tr>
                            <tr>
                                <th>모바일 미리보기</th><td><img src={bannerDetail.b_url + "_m." + bannerDetail.b_extension} alt='Web Img' /></td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
        </React.Fragment>
    )
}

export default ViewBody;