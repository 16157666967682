import styled from "styled-components";
import Label from "../../../common/Label";
import { useEffect, useState, forwardRef } from "react";
import Radio from "../../../common/Radio";
import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import DatePicker from "react-datepicker";
import { getDetailNovel } from "../../../api/novel/novel";
import NovelManagementModal from "./modal/NovelManagementModal"
import { useSelector, useDispatch } from 'react-redux';
import { NovelManagementInsert } from '../../../slice/novelManagementSlice';
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import MessageModal from '../../../modal/Message';
import { putNovel } from "../../../api/novel/novel";

const RegistBodyC = styled.div`
    width: 100%;
    margin: 50px 0px;
`;

const RegistBody = ({ nkey }) => {

    const [pid, setPid] = useState(0);
    const [tagInfo, setTagInfo] = useState("");
    const [contents, setContents] = useState("");
    const [writer, setWriter] = useState("");
    const [n_order, setNOrder] = useState(9999);
    const [title, setTitle] = useState("");
    let [affiliates, setAffiliates] = useState(0);
    const [login, setLogin] = useState(5);
    let [free, setFree] = useState(10);
    let realfrom_dt = "";
    let realto_dt = "";
    const [fromTime, setFromTime] = useState("00:00");
    const [toTime, setToTime] = useState("23:59");
    const [startDate, setStartDate] = useState(new Date());
    const [isRegistModal, setIsRegistModal] = useState(false);
    const EndDateWeek = new Date();
    const nowDay = EndDateWeek.getDate();
    const nowMonth = EndDateWeek.getMonth();
    const nowYear = EndDateWeek.getFullYear();
    const [endDate, setEndDate] = useState(new Date(new Date(nowYear, nowMonth + 1, nowDay)));
    const [ageLimit, setAgeLimit] = useState(0);
    const [issuance, setIssuance] = useState(0);
    const [completionStatus, setCompletionStatus] = useState(0);
    const [message, setMessage] = useState('');
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.novelManagement.value.novelManagementInsert);
    const dispatch = useDispatch();
    useEffect(() => {
        if (Insert === true) {
            dispatch(LoadingModalFlag(true));
            const FROM_DT_Month = String(startDate.getMonth() + 1).padStart(2, "0");
            const FROM_DT_Date = String(startDate.getDate()).padStart(2, "0");
            const from_dt = startDate.getFullYear() + "-" + FROM_DT_Month + "-" + FROM_DT_Date;
            const TO_DT_Month = String(endDate.getMonth() + 1).padStart(2, "0");
            const TO_DT_Date = String(endDate.getDate()).padStart(2, "0");
            const to_dt = endDate.getFullYear() + "-" + TO_DT_Month + "-" + TO_DT_Date;
            if (affiliates === "0" || affiliates === 0) {
                affiliates = "노벨피아";//eslint-disable-line
            } else if (affiliates === "1" || affiliates === 1) {
                affiliates = "라이크노벨";//eslint-disable-line
                free = 9999;//eslint-disable-line
            }
            if (from_dt.length === 10) {
                realfrom_dt = from_dt + " " + fromTime + ":00";//eslint-disable-line
            }
            if (to_dt.length === 10) {
                realto_dt = to_dt + " " + toTime + ":00";//eslint-disable-line
            }
            if (n_order === undefined) {
                n_order = 9999;//eslint-disable-line
            }
            let data={};
            if(issuance===1||issuance==="1"){
                data = {
                    "pid": pid, "n_order": n_order, "nkey": nkey, "affiliates": affiliates, "ageLimit": ageLimit,
                    "completionStatus": completionStatus, "contents": contents, "free": free, "from_dt": realfrom_dt, "to_dt": realto_dt,
                    "issuance": issuance, "tagInfo": tagInfo, "writer": writer, "n_login": login
                };
            }else{
                data = {
                    "pid": pid, "n_order": n_order, "nkey": nkey, "affiliates": affiliates, "ageLimit": ageLimit,
                    "completionStatus": completionStatus, "contents": contents, "free": free, "from_dt": realfrom_dt, "to_dt": null,
                    "issuance": issuance, "tagInfo": tagInfo, "writer": writer, "n_login": login
                };
            }
            
            putNovel(data)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(LoadingModalFlag(false));
                        setMessage("수정 완료 되었습니다");
                        dispatch(MessageModalFlag(true));
                        dispatch(NovelManagementInsert(false));
                    } else {
                        setMessage("수정 오류 입니다");
                        dispatch(MessageModalFlag(true));
                        dispatch(NovelManagementInsert(false));
                    }
                })
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div
                style={{
                    width: "250px",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={onClick}
                ref={ref}
            >
                <div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
                <img
                    src={"/images/component/ic_calendar.png"}
                    style={{ width: "16.67px", height: "15px" }}
                    alt="calendar_icon"
                />
            </div>
        );
    });

    useEffect(() => {
        if (nkey) {
            const data = nkey * 1
            getDetailNovel(data)
                .then((response) => {
                    if (response.data.list[0].affiliates === "노벨피아") {
                        setAffiliates(0);
                    } else if (response.data.list[0].affiliates === "라이크노벨") {
                        setAffiliates(1);
                    }
                    setAgeLimit(response.data.list[0].ageLimit);
                    setIssuance(response.data.list[0].issuance);
                    setCompletionStatus(response.data.list[0].completionStatus);
                    setTagInfo(response.data.list[0].tagInfo);
                    setContents(response.data.list[0].contents);
                    setWriter(response.data.list[0].writer);
                    setFree(response.data.list[0].free);
                    setLogin(response.data.list[0].login);
                    setTitle(response.data.list[0].title);
                    setNOrder(response.data.list[0].n_order);
                    setPid(response.data.list[0].pid);
                    const from_yy = response.data.list[0].from_dt.substr(0, 4);
                    const from_mm = response.data.list[0].from_dt.substr(5, 2);
                    const from_dd = response.data.list[0].from_dt.substr(8, 2);
                    const from_time = response.data.list[0].from_dt.substr(11, 5);
                    setStartDate(new Date(new Date(from_yy, from_mm - 1, from_dd)));
                    setFromTime(from_time);
                    if (response.data.list[0].to_dt === null) {
                    } else {
                        const to_yy = response.data.list[0].to_dt.substr(0, 4);
                        const to_mm = response.data.list[0].to_dt.substr(5, 2);
                        const to_dd = response.data.list[0].to_dt.substr(8, 2);
                        const to_time = response.data.list[0].to_dt.substr(11, 5);
                        setEndDate(new Date(new Date(to_yy, to_mm - 1, to_dd)));
                        setToTime(to_time);
                    }
                })
        }
    }, [nkey]);

    const onSetPid = (e) => {
        setPid(e.target.value.replace(/[^0-9]/g, ""));
    }

    const onSetNOrder = (e) => {
        setNOrder(e.target.value.replace(/[^0-9]/g, ""));
    }

    const onSetFreeEpisode = (e) => {
        setFree(e.target.value.replace(/[^0-9]/g, ""));
    };

    const onSetLoginEpisode = (e) => {
        const number = e.target.value.replace(/[^0-9]/g, "");
        if (affiliates !== 1) {
            if (free * 1 >= number * 1) {
                setLogin(number);
            } else {
                alert("로그인 회차가 무료 회차보다 클수 없습니다.");
            }
        } else {
            setLogin(number);
        }
    };

    const onSetTage = (e) => {
        setTagInfo(e.target.value);
    };

    const onSetWriter = (e) => {
        setWriter(e.target.value);
    };
    const onSetTitle = (e) => {
        if (e.target.value.length > 60) {
            setIsRegistModal(true);
            return;
        } else {
            setTitle(e.target.value);
        }
    };


    const NovelManagementModalhandleClose = (e) => {
        setIsRegistModal(false);
    }

    const addItem = (value, type) => {
        if (type === "TO_DT") {
            setEndDate(value);
        } else if (type === "FROM_DT") {
            setStartDate(value);
        }
    };

    return (
        <RegistBodyC>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={title || ""} width='1000px' placeholder='웹소설 작품 제목을 입력하세요' onChange={onSetTitle} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>내용</Label></div>
                <div style={{ flexGrow: 1 }}><TextArea value={contents || ""} onChange={(e) => setContents(e.target.value)} width='1000px' placeholder='내용을 입력하세요' /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`}>포스트 번호</Label></div>
                <div style={{ width: '500px' }}><TextField value={pid || ""} width='330px' placeholder='포스트 번호를 입력하세요' onChange={onSetPid} /></div>
                <div style={{ width: '170px' }}><Label type={`nomal`}>순서</Label></div>
                <div style={{ width: '350px' }}><TextField value={n_order || ""} onChange={onSetNOrder} width='330px' placeholder='순서를 입력하세요' /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>태그 정보</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={tagInfo || ""} width='1000px' placeholder='#미소년 #베트남 #전문직 #이세계' onChange={onSetTage} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>작가 이름</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={writer || ""} width='100px' placeholder='작가 이름' onChange={onSetWriter} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>로그인 회차</Label></div>
                <div style={{ flexGrow: 1 }}><TextField type="number" value={login || ""} width='100px' placeholder='로그인 회차' onChange={onSetLoginEpisode} /></div>
            </div>
            {affiliates !== 1 &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>무료 회차</Label></div>
                    <div style={{ flexGrow: 1 }}><TextField type="number" value={free || ""} width='100px' placeholder='무료 회차' onChange={onSetFreeEpisode} /></div>
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제휴사</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='affiliates' value="노벨피아" checked={affiliates === 0 && true} onChange={(e) => setAffiliates(0)}>노벨피아</Radio>
                    <Radio name='affiliates' value="라이크노벨" checked={affiliates === 1 && true} onChange={(e) => setAffiliates(1)}>라이크노벨</Radio>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>완결여부</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='completionStatus' value="완결" checked={completionStatus === 0 && true} onChange={(e) => setCompletionStatus(0)}>완결</Radio>
                    <Radio name='completionStatus' value="미완결" checked={completionStatus === 1 && true} onChange={(e) => setCompletionStatus(1)}>미완결</Radio>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>나이제한</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='ageLimit' value="전체연령" checked={ageLimit === 0 && true} onChange={(e) => setAgeLimit(0)}>전체연령</Radio>
                    <Radio name='ageLimit' value="성인용" checked={ageLimit === 1 && true} onChange={(e) => setAgeLimit(1)}>성인용</Radio>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>발행구분</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='issuance' value="고정" checked={issuance === 0 && true} onChange={(e) => setIssuance(0)}>고정</Radio>
                    <Radio name='issuance' value="타임" checked={issuance === 1 && true} onChange={(e) => setIssuance(1)}>타임</Radio>
                </div>
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: "170px" }}>
                        <Label type={`nomal`} required={true}>
                            적용 시작일
                        </Label>
                    </div>
                    <div style={{ flexGrow: 1, display: 'flex' }}>
                        <DatePicker
                            customInput={<DatePickerInput />}
                            selected={startDate}
                            onChange={(e) => {
                                addItem(e, "FROM_DT");
                            }}
                            dateFormat="yyyy-MM-dd"
                        />
                        <input
                            value={fromTime}
                            type="time"
                            min="00:00"
                            max="23:59"
                            style={{
                                width: "150px",
                                padding: "12px",
                                height: "19px",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: "4px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                            onChange={(e) => {
                                setFromTime(e.target.value);
                            }}
                        />
                    </div>
                </div>
                {issuance === 1 &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: "170px" }}>
                            <Label type={`nomal`} required={true}>
                                적용 종료일
                            </Label>
                        </div>
                        <div style={{ flexGrow: 1, display: 'flex' }}>
                            <DatePicker
                                customInput={<DatePickerInput />}
                                selected={endDate}
                                onChange={(e) => {
                                    addItem(e, "TO_DT");
                                }}
                                dateFormat="yyyy-MM-dd"
                            />
                            <input
                                value={toTime}
                                type="time"
                                min="00:00"
                                max="23:59"
                                style={{
                                    width: "150px",
                                    padding: "12px",
                                    height: "19px",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderRadius: "4px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                                onChange={(e) => {
                                    setToTime(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
            {isRegistModal &&
                <div className='NovelManagementModal_Modal_Container'>
                    <div className='NovelManagementModal_Modal'>
                        <div onClick={NovelManagementModalhandleClose} className="NovelManagementModal_Modal_close">확인</div>
                        <NovelManagementModal />
                    </div>
                </div>
            }
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="웹소설 수정" message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="웹소설 수정" message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    );
};

export default RegistBody;
