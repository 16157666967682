
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../style/layout";
import Button from "../../common/Button";


const Title = () => {

    const navigate = useNavigate();

    return (
        <BodyTitle>
            <BodyTitleFont>공지사항 관리</BodyTitleFont>
            <Button name={`공지사항 등록`} width={'160px'} onClick={(e) => navigate('/operation/notice/add')} />
        </BodyTitle>
    )
}

export default Title;