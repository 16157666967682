import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Label from "../../common/Label";
import TextField from "../../common/TextField";
import { getMemberDetail } from "../../api/member/member";
import { useSelector, useDispatch } from 'react-redux';
import { LoginId, Email, Name, Phone, Company, Division, Department, Password, Uid, AuthInsert } from '../../slice/memberSlice';
import MessageModal from '../../modal/Message';
import { postMemberUpdate } from "../../api/member/member";
import { MessageModalFlag } from '../../slice/modalSlice';

const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const Body = () => {
    const memberLoginId = useSelector((state) => state.member.value.memberLoginId);
    const memberEmail = useSelector((state) => state.member.value.memberEmail);
    const memberName = useSelector((state) => state.member.value.memberName);
    const memberPhone = useSelector((state) => state.member.value.memberPhone);
    const memberPassword = useSelector((state) => state.member.value.memberPassword);
    const memberCompany = useSelector((state) => state.member.value.memberCompany);
    const memberDivision = useSelector((state) => state.member.value.memberDivision);
    const memberDepartment = useSelector((state) => state.member.value.memberDepartment);
    const memberUid = useSelector((state) => state.member.value.memberUid);
    const Insert = useSelector((state) => state.member.value.authInsert);
    const MModal = useSelector((state) => state.modal.value.Message);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const userInfo = localStorage.getItem('ROZ_ADMIN_USERINFO1');
        const temp = JSON.parse(userInfo);
        const data = { params: { "uid": temp.uid } };
        getMemberDetail(data)
            .then((response) => {
                dispatch(Uid(response.data.uid));
                dispatch(LoginId(response.data.login_id));
                dispatch(Email(response.data.email));
                dispatch(Name(response.data.name));
                dispatch(Phone(response.data.phone));
                dispatch(Company(response.data.company));
                dispatch(Division(response.data.division));
                dispatch(Department(response.data.department));
                dispatch(Password(response.data.Password));
            })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Insert === true) {
            const emailRegex = /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/;
            const phoneRegex = /^\d{3}\d{3,4}\d{4}$/;
            if (memberLoginId === "" || memberLoginId === undefined) {
                dispatch(AuthInsert(false));
                setMessage("아이디를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberPassword === "" || memberPassword === undefined) {
                dispatch(AuthInsert(false));
                setMessage("비밀번호를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberEmail === "" || memberEmail === undefined) {
                dispatch(AuthInsert(false));
                setMessage("이메일을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (!emailRegex.test(memberEmail)) {
                dispatch(AuthInsert(false));
                setMessage("이메일 양식이 아닙니다");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberName === "" || memberName === undefined) {
                dispatch(AuthInsert(false));
                setMessage("이름을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (memberPhone === "" || memberPhone === undefined) {
                dispatch(AuthInsert(false));
                setMessage("핸드폰 번호를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (!phoneRegex.test(memberPhone)) {
                dispatch(AuthInsert(false));
                setMessage("핸드폰 번호 양식이 아닙니다");
                dispatch(MessageModalFlag(true));
                return;
            }
            const data = {
                "login_id": memberLoginId, "name": memberName, "password": memberPassword, "email": memberEmail,
                "phone": memberPhone, "department": memberDepartment, "division": memberDivision, "company": memberCompany, "uid": memberUid
            }
            postMemberUpdate(data)
                .then((response) => {
                    if (response.status === 400) {
                        dispatch(AuthInsert(false));
                        setMessage(JSON.stringify(response.data.message));
                        dispatch(MessageModalFlag(true));
                    } else if (response.status === 200) {
                        dispatch(AuthInsert(false));
                        setMessage("수정이 완료 되었습니다");
                        dispatch(MessageModalFlag(true));
                    }
                })
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RegistBodyC>
            <div style={{ width: '100%', height: '565px', maxHeight: '1000px', background: 'White' }}>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            아이디
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberLoginId || ""}
                            width="50%"
                            disabled
                            placeholder={memberLoginId || "아이디를 입력해주세요"}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            비밀번호
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberPassword || ""}
                            width="50%"
                            type={'password'}
                            placeholder={memberPassword || "비밀번호를 입력해주세요"}
                            onChange={(e) => dispatch(Password(e.target.value))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            이메일
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberEmail || ""}
                            width="50%"
                            placeholder={memberEmail || "이메일을 입력해주세요"}
                            onChange={(e) => dispatch(Email(e.target.value))}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true} >
                            핸드폰 번호
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberPhone || ""}
                            width="50%"
                            placeholder={memberPhone || "핸드폰번호를 입력해주세요"}
                            onChange={(e) => dispatch(Phone(e.target.value))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true} >
                            회사
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberCompany || ""}
                            width="50%"
                            disabled
                            onChange={(e) => dispatch(Company(e.target.value))}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            부서
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberDivision || ""}
                            width="50%"
                            disabled
                            onChange={(e) => dispatch(Division(e.target.value))}

                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            소속
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberDepartment || ""}
                            width="50%"
                            placeholder={memberDepartment || "소속을 입력해주세요"}
                            onChange={(e) => dispatch(Department(e.target.value))}
                        />
                    </div>
                    <div style={{ width: "20px" }} />
                    <div style={{ width: "120px", margin: 'auto' }}>
                        <Label type={`nomal`} required={true}>
                            이름
                        </Label>
                    </div>
                    <div style={{ width: "600px", margin: 'auto' }}>
                        <TextField
                            value={memberName || ""}
                            width="50%"
                            placeholder={memberName || "이름을 입력해주세요"}
                            onChange={(e) => dispatch(Name(e.target.value))}
                        />
                    </div>
                    <div style={{ width: '10%' }}></div>
                </div>
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="회원 정보 수정" message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}
export default Body;