import { instance } from '..';

export const getMemberList = async (data) => {
    const url = `/member/getMemberList`
    const response = await instance.get(url, data, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getMemberDetail = async (data) => {
    const url = `/member/getMemberDetail`
    const response = await instance.get(url, data, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const postMemberInsert = async (post) => {
    const url = `/user/sign-up`;
    const response = await instance.post(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const postMemberUpdate = async (data) => {
    const url = `/user/sign-update`;
    const response = await instance.post(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const deleteMember = async (data) => {
    const url = '/member/deleteMemberDetail';
    const response = await instance.delete(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getSelectAuthMenu = async (data) => {
    const response = await instance.get(`/auth/getSelectAuthMenuList`, data, null)
        .then((response) => {
            return response;
        })
    return response;

}


export const getUserList = async (data) => {
    const url = `/member/getUserList`
    const response = await instance.get(url, data, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getUserList2 = async (data) => {
    const url = `/member/getUserList2`
    const response = await instance.get(url, data, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getUserCount = async (data) => {
    const url = `/member/getUserCount`
    const response = await instance.get(url, data, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getUserDetails = async (data) => {
    const url = `/member/getUserDetails`
    const response = await instance.get(url, data, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const insertUser = async (data) => {
    const url = `/member/insertUser`;
    const response = await instance.post(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const updateUser = async (data) => {
    const url = `/member/updateUser`;
    const response = await instance.patch(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const deleteUsers = async (data) => {
    console.log(data);
    const url = '/member/deleteUsers';
    const response = await instance.delete(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const recoveryUsers = async (data) => {
    const url = `/member/recoveryUsers`;
    const response = await instance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}
