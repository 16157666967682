import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getQnAList } from "../../api/qna/qna";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Paging from "../../common/Paging";
import Form from 'react-bootstrap/Form';
import TextField from "../../common/TextField";
import { useDispatch, useSelector } from 'react-redux';
import { MessageModalFlag } from '../../slice/modalSlice';
import MessageModal from "../../modal/Message";
import moment from "moment";
import Calendar from "../../common/Calendar";

const Body = ({ QAList }) => {

    const navigate = useNavigate();
    const [size, setSize] = useState(10);
    const [pagesInfo, setPagesInfo] = useState();
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [qnaList, setQnAList] = useState();
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const MModal = useSelector((state) => state.modal.value.Message);
    const dispatch = useDispatch();
    const [startDt, setStartDt] = useState(moment('2021-01-01').format("YYYY-MM-DD"));
    const [fromDt, setFromDt] = useState(moment().format("YYYY-MM-DD"));
    const handleStartDt = (post) => {
        setStartDt(moment(post).format("YYYY-MM-DD"));
    };
    const handleFromDt = (post) => {
        setFromDt(moment(post).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        setTitle("QnA");
        setMessage("검색중");
        dispatch(MessageModalFlag(true));
        getQnAList(page_no, size, startDt, fromDt)
            .then((response) => {
                setPagesInfo(response.data.pages);
                setQnAList(response.data.results);
                dispatch(MessageModalFlag(false));
            })
    }, [page_no, QAList]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPage_no(1);
        setTitle("QnA");
        setMessage("검색중");
        dispatch(MessageModalFlag(true));
        getQnAList(1, size, startDt, fromDt)
            .then((response) => {
                setPagesInfo(response.data.pages);
                setQnAList(response.data.results);
                dispatch(MessageModalFlag(false));
            })
    }, [size]);// eslint-disable-line react-hooks/exhaustive-deps


    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {(pagesInfo && (pagesInfo.total_rows > 0)) &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{pagesInfo.total_rows}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select className="selectBox">
                            <option value={1}>제목</option>
                            <option value={2}>내용</option>
                        </Form.Select>&nbsp;
                        <TextField placeholder={`검색어를 입력해주세요`} />
                        <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                    </div>
                </div>
                <div className='title'>기간</div>
                <div className='component'>
                    <span>기간설정</span>
                    <Calendar startDt={startDt} setStartDt={handleStartDt} endDt={fromDt} setEndDt={handleFromDt} />
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(qnaList && qnaList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>QnA 아이디</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', }}>QnA 내용</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px', padding: '0px 5px 0px 5px' }}>QnA 유저</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>QnA 유형</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '70px' }}>답변여부</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>QnA 날짜</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {qnaList.map((item, index) => {
                                return (
                                    <TableRow key={index} onClick={(e) => navigate(`/operation/qnaIntro/view/${item.qna_id}`)}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>{item.qna_id}</div>
                                        <div style={{ display: 'inline', flexGrow: 1, justifyContent: 'center', width: "calc(100vh - 975px)", overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.title}</div>
                                        <div style={{ display: "inline-block", justifyContent: 'center', width: '200px', overflow: 'hidden', padding: '0px 5px 0px 5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.user_id}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>{QAList[item.qna_tp - 1]}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '70px', color: item.ans_id ? '#ED5255' : '#000000' }}>{item.ans_id ? '답변' : '미답변'}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.reg_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={pagesInfo.total_rows} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>
            {MModal &&
                <div className='Message_Modal_Container' onClick={() => dispatch(MessageModalFlag(false))}>
                    <div className='Message_Modal'>
                        <MessageModal title={title} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Body;