import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import theme, { PageStyle } from '../../cms/theme';
import { Title } from './NewsList';
import NoneData from './NoneData';
import TimeGap from './timeGap';
import moment from 'moment';
import {
    checkLength,
    getTextLength,
    GridView,
    LeftImgBox,
    ListStyle,
} from '../../../style/leftImgLayout';
import { MoreList } from '../../../style/searchStyle';
import { getSearchPostMore, getSearchPost } from '../../../api/newsRoom/post';
import Thumnail from './Thumnail';


const PostingList = ({ props, keyword, title, edit, setSelectData, selectMenu }) => {
    const [page_no, setPage_no] = useState(1);
    const [postList, setPostList] = useState([]);
    const [postTotal, setPostTotal] = useState(0);
    const [active, setActive] = useState([]);
    const regExpKey = /[%^]/gi;
    var searchKeyword = keyword.replace(regExpKey, '');
    var realFrom_dt = moment().subtract(1, 'days').format('YYYYMMDDHHmmss');
    var realTo_dt = moment().format('YYYYMMDDHHmmss');

    const loadMore = () => {
        if (page_no !== 1) {
            getSearchPostMore(realFrom_dt, realTo_dt, page_no, 0, searchKeyword)
                .then((response) => {
                    setPostList(postList.concat(response.data.posts))
                    setPostTotal(response.data.total_row);
                });
        }
    };

    useEffect(() => {
        getSearchPost(realFrom_dt, realTo_dt, 0, searchKeyword)
            .then((response) => {
                setPostList(response.data.posts);
                setPostTotal(response.data.total_row);
            });
    }, [keyword])// eslint-disable-line react-hooks/exhaustive-deps

    const numberHandler = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const postTypeHandler = (item) => {
        let img = JSON.parse(item.contents_props);
        try {
            //일반 포스트
            if (item.post_tp === 1 || item.post_tp === 5) {
                img = img.newsList[0].img_urls;
            }
            //로제우스 이슈따라잡기
            else if (item.post_tp === 3) {
                if (img?.cont_1) {
                    img = img.cont_1.cont_imgs[0];
                } else if (!img?.cont_1 && img?.cont_imgs) {
                    img = img.cont_imgs[0];
                }
            }
            //로제우스 브리핑
            else if (item.post_tp === 4) {
                img = img.cont_imgs[0];
            }
        } catch (err) {
            img = null;
        }
        return img;
    };

    useEffect(() => {
        loadMore();
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPage_no(1);
    }, [postTotal]);


    return (
        <ThemeProvider theme={theme}>
            <PageStyle>
                <Title>
                    {title} ({numberHandler(postTotal)})
                </Title>
                {postList.length !== 0 ? (
                    <div>
                        {postList &&
                            postList.map((item, index) => {
                                return (
                                    (selectMenu === 0 ? index < 5 : true) && (
                                        <div key={index} style={{
                                            background: active.includes(index) ? '#F1F1FA' : null
                                        }}>
                                            <GridView
                                                onClick={() => {
                                                    if (edit) {
                                                        if (!active.includes(index)) {
                                                            setActive([...active, index]);
                                                            setSelectData(item);
                                                        }
                                                    }
                                                    else {
                                                        props.history.push(`/post/${item.pid}`);
                                                    }
                                                }}
                                            >
                                                <LeftImgBox>
                                                    <Thumnail url={postTypeHandler(item)} width={108} height={72} medCd={item.room_nm} medNm={item.room_nm} size={100} post={true} />
                                                </LeftImgBox>
                                                <ListStyle index={index} length={postList.length - 1}>
                                                    <div className="list_title">{item.title}</div>
                                                    <div className="list_sub">
                                                        <div className={'first'}>
                                                            <span className={'category'}>
                                                                {checkLength(item.room_nm, 10) ? (
                                                                    <span>{item.room_nm}</span>
                                                                ) : (
                                                                    <span> {getTextLength(item.room_nm, 10)}...</span>
                                                                )}
                                                            </span>
                                                            <div className="right_border">|</div>
                                                            <span className={'center'}>
                                                                {checkLength(item.fold_nm, 6) ? (
                                                                    <span>{item.fold_nm}</span>
                                                                ) : (
                                                                    <span> {getTextLength(item.fold_nm, 6)}...</span>
                                                                )}
                                                            </span>
                                                            <div className="right_border">|</div>
                                                            <span className={'time'}>
                                                                <TimeGap
                                                                    data={moment(item.reg_dt, 'YYYY-MM-DD HH:mm:ss')}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className={'line'} />
                                                </ListStyle>
                                            </GridView>
                                        </div>
                                    )
                                );
                            })}
                    </div>
                ) : (
                    <NoneData name={keyword} edit={edit} />
                )}
            </PageStyle>
            {postList.length >= postTotal ? null : (
                <MoreList
                    onClick={async () => {
                        if (postList.length / Number(postTotal) <= 5) setPage_no(page_no + 1);
                    }}
                >
                    <span>포스트 결과 더보기</span> <img src="/images/icon/ic_expand_more.svg" alt="detail page move icon" />
                </MoreList>
            )}
        </ThemeProvider>
    );
};
export default PostingList;
