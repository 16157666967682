import moment from "moment";
import { instance } from "..";

export const getNews = async ({ from_dt, to_dt, page_no, size, sort, search_opt, keyword, cat_cd, exKeyword }) => {

    const rfrom_dt = (from_dt && from_dt.length > 0) ? from_dt + '000000' : moment().subtract(24, 'hours').format('YYYYMMDDHHmmss')
    const rto_dt = (to_dt && to_dt.length > 0) ? to_dt + moment().format('HHmmss') : moment().format('YYYYMMDDHHmmss')
    const url = `https://api.rozeus.com/service/api/v1/news/contents?from_dt=${rfrom_dt}&to_dt=${rto_dt}&page_no=${page_no}&size=${size}&sort=${sort}&search_opt=${search_opt}`;
    const lastUrl = url + ((keyword && keyword.length > 0) ? `&keyword=${keyword}` : '') + ((cat_cd && cat_cd.length > 0) ? `&cat_cds=${cat_cd}` : '') + ((exKeyword && exKeyword.length > 0) ? `&except_keyword=${exKeyword}` : '')

    const response = await instance.get(lastUrl, null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getNewsSearch = async ({ from_dt, to_dt, page_no, size, sort, search_opt, keyword, cat_cd, exKeyword, grp_cd }) => {

    const rfrom_dt = (from_dt && from_dt.length > 0) ? from_dt + '000000' : moment().subtract(24, 'hours').format('YYYYMMDDHHmmss')
    const rto_dt = (to_dt && to_dt.length > 0) ? to_dt + '235959' : moment().subtract(24, 'hours').format('YYYYMMDDHHmmss')
    const url = `https://api.rozeus.com/service/api/v1/news/contents?from_dt=${rfrom_dt}&to_dt=${rto_dt}&page_no=${page_no}&size=${size}&sort=${sort}&search_opt=${search_opt}&med_cds=${grp_cd}`;
    const lastUrl = url + ((keyword && keyword.length > 0) ? `&keyword=${keyword}` : '') + ((cat_cd && cat_cd.length > 0) ? `&cat_cds=${cat_cd}` : '') + ((exKeyword && exKeyword.length > 0) ? `&except_keyword=${exKeyword}` : '')

    const response = await instance.get(lastUrl, null, null)
        .then((response) => {
            return response;
        })
    return response;

}

export const getMainMedia = async (data) => {

    if (data === undefined) {
        data = ''
    }
    const response = await instance.get(`https://api.rozeus.com/service/api/v1/code/medias?grp_cd=${data}`, null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getMedia = async () => {

    const response = await instance.get('https://api.rozeus.com/service/api/v1/code/media-grps', null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getSearchNews = async ({ from_dt, to_dt, page_no, size, sort, search_opt, keyword, cat_cd, exKeyword }) => {

    const rto_dt = (to_dt && to_dt.length > 0) ? to_dt + moment().format('HHmmss') : moment().format('YYYYMMDDHHmmss')
    const url = `https://api.rozeus.com/service/api/v1/news/contents?from_dt=${from_dt}&to_dt=${rto_dt}&page_no=${page_no}&size=${size}&sort=${sort}&search_opt=${search_opt}`;
    const lastUrl = url + ((keyword && keyword.length > 0) ? `&keyword=${keyword}` : '') + ((cat_cd && cat_cd.length > 0) ? `&cat_cds=${cat_cd}` : '') + ((exKeyword && exKeyword.length > 0) ? `&except_keyword=${exKeyword}` : '')

    const response = await instance.get(lastUrl, null, null)
        .then((response) => {
            return response;
        })
    return response;

}

export const getMainCat = async () => {

    const response = await instance.get('https://api.rozeus.com/service/api/v1/code/category-grps', null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getNewsByNids = async (nids) => {

    const response = await instance.get(`https://api.rozeus.com/service/api/v1/news/briefing-news?nids=${nids.toString()}`, null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getAnalyze = async (text) => {

    const response = await instance.get(`https://api.rozeus.com/manager/api/v1/es/index/analyze?text=${text}`, null, null)
        .then((response) => {
            return response;
        })
    return response;
}