import styled from "styled-components";
import { DetailTable } from "../../../style/layout";
import { useEffect, useState } from "react";
import React from "react";
import Label from "../../../common/Label";
import { getEnterList } from "../../../api/enter/enter";

const BasicInfoC = styled.div`
`;

export const Text = styled.div`
    display: block;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 24px;
    padding-bottom: 50px;
    word-wrap: break-word;
`;

const ViewBody = ({ enterId }) => {

    const [enterDetail, setEnterDetail] = useState();// eslint-disable-line no-unused-vars

    useEffect(() => {
        const data = { params: { "enterId" : enterId , "programDays": 7} };
        getEnterList(data)
            .then((response) => {
                response.data.enterList[0].programDays = response.data.enterList[0].programDays.replace("0", "월");
                response.data.enterList[0].programDays = response.data.enterList[0].programDays.replace("1", "화");
                response.data.enterList[0].programDays = response.data.enterList[0].programDays.replace("2", "수");
                response.data.enterList[0].programDays = response.data.enterList[0].programDays.replace("3", "목");
                response.data.enterList[0].programDays = response.data.enterList[0].programDays.replace("4", "금");
                response.data.enterList[0].programDays = response.data.enterList[0].programDays.replace("5", "토");
                response.data.enterList[0].programDays = response.data.enterList[0].programDays.replace("6", "일");
                setEnterDetail(response.data.enterList[0]);
            });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {enterDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>엔터 ID</th><td>{enterDetail.enterId}</td>
                                <th>방송 타입</th><td>{enterDetail.enterTypeDesc}</td>
                            </tr>
                            <tr>
                                <th>방송사</th><td>{enterDetail.broadCasters}</td>
                                <th>기사 수</th><td>{enterDetail.docCount}</td>
                                <th>프로그램 명</th><td>{enterDetail.programNm}</td>
                            </tr>
                            <tr>
                                <th>해당요일</th><td>{enterDetail.programDays}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
        </React.Fragment>
    );
};

export default ViewBody;