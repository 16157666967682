import styled, { createGlobalStyle, css } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
    html{
        height: 100%;
    }
    body{
        margin : 0px;
        height: 100%;
    }
`;

export const MainLayout = css`
    width: 100%;
    min-width: 1310px;
    height: 100%;
    font-family: Noto Sans KR;
`;

export const BodyTitle = styled.div`
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 100%;
    height: 136px;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
`;

export const BodyTitleFont = styled.div`
    font-size: 40px;
    line-height: 58px;
`;

export const SearchBar = styled.div`
    width: 100%;
    background: #FAFAFA;
    .filter {
        background: #ffffff;
        width: 100%;
        display: flex;
        justify-content:space-between;
        align-items: center;
        height: 50px;
    }
    .title {
        height:44px;
        font-size: 16px;
        font-weight:bold;
        padding-left:24px;
        display: flex;
        align-items: flex-end;
    }
    .component {
        height:64px;
        padding-left:24px;
        display: flex;
        justify-content:flex-start;
        align-items: center;
    }
    .component > span{
        font-size: 14px;
        width: 140px;
    }
`;

export const SelectBox = styled.div`
    width:100%;
    height:42px;
    background:#F2F2F2;
    display:flex;
    justify-content: flex-start;
    align-items:center;
    font-size: 12px;
`;


export const EditorLayout = styled.iframe`
    width:100%;
    height:630px;
    border-radius: 4px;
    border:1px solid rgba(0, 0, 0, 0.1);
`;

export const TableHeader = styled.div`
    width:100%;
    height:42px;
    background:#F2F2F2;
    display:flex;
    justify-content: flex-start;
    align-items:center;
    font-size: 12px;
`;

export const TableRow = styled.div`
    width:100%;
    height:42px;
    background:#ffffff;
    display:flex;
    justify-content: flex-start;
    align-items:center;
    font-size: 12px;
    cursor:pointer;
`;

export const DetailTable = styled.table`
    border:0px;
    font-size : 12px;
    border-collapse: collapse;
    tr {
        border:none;
        border-top: 1px solid #E9E9E9;
        border-bottom: 1px solid #E9E9E9;
    };
    th {
        background : #F8F8F8;
        color : #494949;
        width : 126px;
        height : 42px;
        vertical-align: middle;
        text-align:left;
        padding: 0px 12px;
        font-weight:normal;
    };
    td {
        background:#ffffff;
        color : #000000;
        font-weight:normal;
        padding: 0px 12px;
        vertical-align: middle;
    };
`

export const BannerDetailTable = styled.table`
    border:0px;
    font-size : 12px;
    border-collapse: collapse;
    tr {
        border:none;
        border-top: 1px solid #E9E9E9;
        border-bottom: 1px solid #E9E9E9;
    };
    th {
        background : #F8F8F8;
        color : #494949;
        width : 126px;
        height : 42px;
        vertical-align: middle;
        text-align:left;
        padding: 0px 12px;
        font-weight:normal;
    };
    td {
        background:#ffffff;
        color : #000000;
        width : 500px;
        font-weight:normal;
        padding: 0px 12px;
        vertical-align: middle;
    };
`
export const InNewsListDetailTable = styled.table`
    border:0px;
    font-size : 12px;
    border-collapse: collapse;
    tr {
        border:none;
        border-top: 1px solid #E9E9E9;
        border-bottom: 1px solid #E9E9E9;
    };
    th {
        background : #F8F8F8;
        color : #494949;
        width : 126px;
        height : 42px;
        vertical-align: middle;
        text-align:left;
        padding: 0px 12px;
        font-weight:normal;
    };
    td {
        background:#ffffff;
        color : #000000;
        width : 500px;
        font-weight:normal;
        padding: 0px 12px;
        vertical-align: middle;
    };
`


export const DetailTableRow = styled.div`
    border:0px;
    font-size : 12px;
    border-collapse: collapse;
    tr {
        border:none;
        border-bottom: 1px solid #E9E9E9;
    };
    th {
        background : #F8F8F8;
        color : #494949;
        width : 126px;
        height : 42px;
        vertical-align: middle;
        text-align:left;
        padding: 0px 12px;
        font-weight:normal;
    };
    td {
        background:#ffffff;
        color : #000000;
        font-weight:normal;
        padding: 12px;
        vertical-align: middle;
    };
`

export const SplashDetailTable = styled.table`
    border:0px;
    font-size : 12px;
    border-collapse: collapse;
    tr {
        border:none;
        border-top: 1px solid #E9E9E9;
        border-bottom: 1px solid #E9E9E9;
    };
    th {
        background : #F8F8F8;
        color : #494949;
        width : 126px;
        height : 42px;
        vertical-align: middle;
        text-align:left;
        padding: 0px 12px;
        font-weight:normal;
    };
    td {
        background:#ffffff;
        color : #000000;
        font-weight:normal;
        padding: 0px 12px;
        vertical-align: middle;
    };
`