import styled from "styled-components";
import { useEffect, useState } from "react";
import Label from "../../../common/Label";
import Radio from "../../../common/Radio";
import OneDayCalendarPush from "../../../common/OneDayCalendarPush";
import { useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from "../../../modal/Message";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import TextField from "../../../common/TextField";
import { getEnterList, putEnter, postEnter } from "../../../api/enter/enter";
import { Multivalues, DayEvent, DefaultEnvetData, EnterFromTime, EnterInsert, Flag } from '../../../slice/enterSlice';
import moment from "moment";

const RegistBodyC = styled.div`
    width: 100%;
    margin: 50px 0px;
`;

const RegistBody = ({ mode, enterId }) => {

    const [selectMultivalues, setSelectMultivalues] = useState([]);
    const [multioption, setMultiOption] = useState([]);
    const [message, setMessage] = useState('');// eslint-disable-line no-unused-vars
    const [fromDay, setFromDay] = useState(moment().format("YYYY-MM-DD"));
    const [toDay, setToDay] = useState(moment().format("YYYY-MM-DD"));

    const multivalues = useSelector((state) => state.enter.value.multivalues);
    const Insert = useSelector((state) => state.enter.value.enterInsert);
    const dayEvnet = useSelector((state) => state.enter.value.dayEvnet);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);

    const [exceptKeyword, setExceptKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [title, setTitle] = useState('');
    
    const [programName, setProgramName] = useState('');
    const [programDesc, setProgramDesc] = useState('');
    const [broadCasters, setBroadCasters] = useState('');
    const [enterType, setEnterType] = useState('D');
    const [docCount, setDocCount] = useState(0);
    const enterFromTime = useSelector((state) => state.enter.value.enterFromTime);
    const insertFlag = useSelector((state) => state.enter.value.insertFlag);

    const dispatch = useDispatch();
    const navigate = useNavigate();// eslint-disable-line no-unused-vars

    useEffect(() => {
        setMultiOption([{ title: '월', value: '0' }, { title: '화', value: '1' }, { title: '수', value: '2' }, { title: '목', value: '3' }, { title: '금', value: '4' }, { title: '토', value: '5' }, { title: '일', value: '6' }])
        if (mode === 'modify') {
            const data = { params: { "enterId": enterId, "programDays": 7 } };
            getEnterList(data)
                .then((response) => {
                    const newArr = new Array([]);
                    newArr.splice(0, 1);
                    var value2 = response.data.enterList[0].programDays.replaceAll(",", "");
                    for (var i = 0; i < value2.length; i++) {
                        var value = value2.substr(i, 1);
                        if (value === '0' || value === 0) {
                            newArr.push({
                                value: value,
                                title: '월'
                            });
                        } else if (value === '1' || value === 1) {
                            newArr.push({
                                value: value,
                                title: '화'
                            });
                        } else if (value === '2' || value === 2) {
                            newArr.push({
                                value: value,
                                title: '수'
                            });
                        } else if (value === '3' || value === 3) {
                            newArr.push({
                                value: value,
                                title: '목'
                            });
                        } else if (value === '4' || value === 4) {
                            newArr.push({
                                value: value,
                                title: '금'
                            });
                        } else if (value === '5' || value === 5) {
                            newArr.push({
                                value: value,
                                title: '토'
                            });
                        } else if (value === '6' || value === 6) {
                            newArr.push({
                                value: value,
                                title: '일'
                            });
                        }
                    }
                    dispatch(Multivalues(newArr));
                    dispatch(DayEvent(response.data.enterList[0].programDays));
                    setEnterType(response.data.enterList[0].enterType);
                    setProgramName(response.data.enterList[0].programNm);
                    setProgramDesc(response.data.enterList[0].description);
                    setBroadCasters(response.data.enterList[0].broadCasters);
                    setDocCount(response.data.enterList[0].docCount);
                    dispatch(EnterFromTime(response.data.enterList[0].programStartTime));
                    setExceptKeyword(response.data.enterList[0].exceptKeyword);
                    setSearchKeyword(response.data.enterList[0].searchKeyword);
                });
        } else if (mode === 'add') {
            dispatch(DefaultEnvetData());
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const selector = JSON.stringify(selectMultivalues);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"programDays":"', '');
        const deleteData3 = deleteData2.replaceAll('"}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        dispatch(DayEvent(deleteData4));
    }, [selectMultivalues]);// eslint-disable-line react-hooks/exhaustive-deps

    const onRemove = (e) => {
        const eArr = e.slice();
        const newArr = new Array([]);
        newArr.splice(0, 1);
        for (const index in eArr) {
            newArr.push({
                programDays: e[index].value,
            });
        }
        setSelectMultivalues(newArr);
    }

    const onSelect = (e) => {
        const eArr = e.slice();
        const newArr = new Array([]);
        newArr.splice(0, 1);
        for (const index in eArr) {
            newArr.push({
                programDays: e[index].value,
            });
        }
        setSelectMultivalues(newArr);
    }

    useEffect(() => {
        if (Insert === true) {
            setTitle("엔터 관리");
            setMessage("등록중 입니다");
            dispatch(LoadingModalFlag(true));
            if (programName === "") {
                dispatch(LoadingModalFlag(false));
                dispatch(EnterInsert(false));
                setMessage("프로그램 명을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (programDesc === "") {
                dispatch(LoadingModalFlag(false));
                dispatch(EnterInsert(false));
                setMessage("프로그램 설명을 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (broadCasters === "") {
                dispatch(LoadingModalFlag(false));
                dispatch(EnterInsert(false));
                setMessage("방송사를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (broadCasters === "") {
                dispatch(LoadingModalFlag(false));
                dispatch(EnterInsert(false));
                setMessage("방송사를 입력해주세요");
                dispatch(MessageModalFlag(true));
                return;
            } else if (dayEvnet === "") {
                dispatch(LoadingModalFlag(false));
                dispatch(EnterInsert(false));
                setMessage("방영 요일을 최소 1개이상 선택해주세요");
                dispatch(MessageModalFlag(true));
                return;
            }
            if (mode === 'add') {
                const data = {
                    "programNm": programName, "description": programDesc, "broadcasters": broadCasters, "exceptKeyword": exceptKeyword, "searchKeyword": searchKeyword,
                    "enterType": enterType, "programStartDt": fromDay, "programStartTime": enterFromTime, "programEndDt": toDay, "programDays": dayEvnet
                };
                postEnter(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 200 || response.status === 201) {
                            dispatch(EnterInsert(false));
                            setTitle("엔터 관리 등록");
                            setMessage("등록완료");
                            dispatch(MessageModalFlag(true));
                            dispatch(Flag(true));
                        } else {
                            dispatch(EnterInsert(false));
                            setTitle("엔터 관리 등록");
                            setMessage("등록오류");
                            dispatch(MessageModalFlag(true));
                        }
                    });
            } else if (mode === 'modify') {
                const data = {
                    "enterId": enterId * 1, "programNm": programName, "description": programDesc, "broadcasters": broadCasters, "exceptKeyword": exceptKeyword, "searchKeyword": searchKeyword,
                    "enterType": enterType, "programStartDt": fromDay, "programStartTime": enterFromTime, "programEndDt": toDay, "programDays": dayEvnet, "docCount": docCount
                };
                putEnter(data)
                    .then((response) => {
                        dispatch(LoadingModalFlag(false));
                        if (response.status === 200) {
                            dispatch(EnterInsert(false));
                            setTitle("엔터 관리 수정");
                            setMessage("수정완료");
                            dispatch(MessageModalFlag(true));
                        } else {
                            dispatch(EnterInsert(false));
                            setTitle("엔터 관리 수정");
                            setMessage("수정오류");
                            dispatch(MessageModalFlag(true));
                        }
                    });
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/enter");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RegistBodyC>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>프로그램 명</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={programName || ""} onChange={(e) => setProgramName(e.target.value)} width='50%' placeholder='제목을 입력하세요' /></div>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>프로그램 설명</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={programDesc || ""} onChange={(e) => setProgramDesc(e.target.value)} width='50%' placeholder='프로그램 설명을 입력하세요' /></div>
            </div>

            {mode === 'add' &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>방송사</Label></div>
                    <div style={{ flexGrow: 1 }}><TextField value={broadCasters || ""} onChange={(e) => setBroadCasters(e.target.value)} width='50%' placeholder='제목을 입력하세요' /></div>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>기사 수</Label></div>
                    <div style={{ flexGrow: 1 }}><TextField disabled value={docCount} onChange={(e) => setDocCount(e.target.value)} width='50%' placeholder='기사수를 입력하세요' /></div>
                </div>
            }
            {mode === 'modify' &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>방송사</Label></div>
                    <div style={{ flexGrow: 1 }}><TextField value={broadCasters || ""} onChange={(e) => setBroadCasters(e.target.value)} width='50%' placeholder='제목을 입력하세요' /></div>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>기사 수</Label></div>
                    <div style={{ flexGrow: 1 }}><TextField value={docCount} onChange={(e) => setDocCount(e.target.value)} width='50%' placeholder='기사수를 입력하세요' /></div>
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>검색어</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={searchKeyword || ""} onChange={(e) => setSearchKeyword(e.target.value)} width='50%' placeholder='검색어을 입력하세요' /></div>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>배제어</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={exceptKeyword || ""} onChange={(e) => setExceptKeyword(e.target.value)} width='50%' placeholder='배제어를 입력하세요' /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>방송 시작일</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <OneDayCalendarPush setDate={setFromDay} splashDate={fromDay} />
                </div>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>방송 시작시간</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <input
                        value={enterFromTime}
                        type="time"
                        min="00:00"
                        max="23:59"
                        style={{
                            width: "150px",
                            padding: "12px",
                            height: "16px",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                        onChange={(e) => {
                            dispatch(EnterFromTime(e.target.value));
                        }}
                    />
                </div>
            </div>
            <div style={{ height: '10px' }}></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>방송 종료일</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <OneDayCalendarPush setDate={setToDay} splashDate={toDay} />
                </div>
                <div style={{ width: '170px' }}></div>
                <div style={{ flexGrow: 1 }}>
                </div>
            </div>
            <div style={{ height: '10px' }}></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>프로그램 방영 요일</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <div style={{ width: '65%' }}>
                        <Multiselect
                            displayValue="title"
                            onRemove={(e) => onRemove(e)}
                            onSelect={(e) => onSelect(e)}
                            options={multioption}
                            selectedValues={multivalues}
                        />
                    </div>
                </div>
                <div style={{ width: '170px' }}></div>
                <div style={{ flexGrow: 1 }}></div>
            </div>
            <div style={{ height: '10px' }}></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>엔터 타입</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='enterType' value="드라마" checked={enterType === 'D' && true} onChange={(e) => setEnterType('D')}>드라마</Radio>
                    <Radio name='enterType' value="엔터" checked={enterType === 'E' && true} onChange={(e) => setEnterType('E')}>엔터</Radio>
                    <Radio name='enterType' value="영화" checked={enterType === 'M' && true} onChange={(e) => setEnterType('M')}>영화</Radio>
                </div>
            </div>
            {
                MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={title} message={message} />
                    </div>
                </div>
            }
            {
                LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={title} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC >
    );
};

export default RegistBody;
