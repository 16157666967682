import { createSlice } from '@reduxjs/toolkit';

export const cmsPostSlice = createSlice({
	name: 'cmspost',
	initialState: { value: { cmsPostListInsert: false, insertFlag: false } },
	reducers: {
		CmsPostListInsert: (state, action) => {
			state.value.cmsPostListInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { CmsPostListInsert, Flag } = cmsPostSlice.actions;
export default cmsPostSlice.reducer;
