import { createSlice } from '@reduxjs/toolkit';

export const novelSlice = createSlice({
	name: 'novel',
	initialState: { value: { novelInsert: false, insertFlag: false } },
	reducers: {
		NovelInsert: (state, action) => {
			state.value.novelInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { NovelInsert, Flag } = novelSlice.actions;
export default novelSlice.reducer;
