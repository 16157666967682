import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { TableHeader, TableRow } from "../../../style/layout";
import { useState, useEffect } from "react";


const DetailNewsList = ({ newsList, setNewsList }) => {


    const [detailnewsList, setDetailNewsList] = useState([]);

    useEffect(() => {
        for (var i = 0; i < newsList.length; i++) {
            if (newsList[i].type === '1') {
                newsList[i].typeName = '부정'
            } else if (newsList[i].type === '2') {
                newsList[i].typeName = '중립'
            } else {
                newsList[i].typeName = '긍정'
            }
        }
        setDetailNewsList(newsList);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const SortableList = SortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    const SortableItem = SortableElement(({ item, num }) => (
        <TableRow >
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.nid}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>
                <img src={item.img_urls} style={{ height: '42px' }} alt='news thumbnail' />
            </div>
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>{item.title}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.med_nm}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.cat_nm}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '185px' }}>
                {item.typeName}
            </div>
        </TableRow>
    ));

    const arrayMove = (array, oldIndex, newIndex) => {
        let arr = [...(array ?? [])]
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    };


    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newList = arrayMove(newsList, oldIndex, newIndex);
        setNewsList(newList);
    };

    return (
        <React.Fragment>
            <TableHeader>
                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 아이디</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>이미지</div>
                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스타입</div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '185px' }}>감성키워드 타입</div>
            </TableHeader>
            <div style={{ flexGrow: 1, overflow: 'auto' }}>

                <SortableList useDragHandle onSortEnd={onSortEnd}>
                    {detailnewsList.map((item, index) => {
                        return (
                            <SortableItem index={index} key={`item-${index}`} item={item} num={index} />
                        )
                    })
                    }
                </SortableList>
            </div>
        </React.Fragment>
    )

}

export default DetailNewsList;