import styled from "styled-components";
import { DetailTable, TableHeader, TableRow } from "../../../style/layout";
import Label from "../../../common/Label";
import { useState } from "react";
import { useEffect } from "react";
import { getPostByPid } from "../../../api/post/common";
import React from "react";
import { getNewsByNids } from "../../../api/news/newsBriefing";


const BasicInfoC = styled.div`
`;

const DetailInfoC = styled.div`
`;

const ViewBody = ({ pid }) => {

    const [postDetail, setPostDetail] = useState();
    const [nidList, setNidList] = useState();
    const [newsList, setNewsList] = useState();
    // const [detailList, setDetailList] = useState();
    const [thumb, setThumb] = useState();

    useEffect(() => {
        if (pid && pid.length > 0) {
            getPostByPid(pid)
                .then((response) => {
                    setPostDetail(response.data);
                    const contents = JSON.parse(response.data.contents);
                    if (contents.cont_1) {
                        setThumb(contents.cont_1.cont_imgs);
                        setNidList(contents.cont_1.nids_1.list);
                        // setDetailList(contents.cont_1.nids_1.details);
                    }

                })
        }
    }, [pid])

    useEffect(() => {
        if (nidList && nidList.length > 0) {
            getNewsByNids(nidList)
                .then((response) => {
                    setNewsList(response.data.news_list);
                })
        }
    }, [nidList])

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {postDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>뉴스 브리핑 아이디</th><td>{postDetail.pid}</td>
                                <th>상태</th><td>{postDetail.isUse === 1 ? '적용' : '미적용'}</td>
                            </tr>
                            <tr>
                                <th>제목</th><td>{postDetail.title}</td>
                                <th>작성자</th><td>로제우스</td>
                            </tr>
                            <tr>
                                <th>적용 시작일</th><td>{postDetail.revDt}</td>
                                <th>기사수</th><td>{(nidList && nidList.length > 0) ? nidList.length : '-'}</td>
                            </tr>
                            <tr>
                                <th>등록일시</th><td>{postDetail.regDt}</td>
                                <th>수정일시</th><td>{postDetail.modDt}</td>
                            </tr>
                            <tr>
                                <th>썸네일 이미지</th><td><img src={thumb} alt='THUMB' style={{ width: '328px', height: '246px' }} /></td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
            {postDetail &&
                <DetailInfoC>
                    <Label type='title'>본문정보</Label>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {(newsList && newsList.length > 0) &&
                            <React.Fragment>
                                <TableHeader>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 아이디</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>이미지</div>
                                    <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스타입</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>감성키워드 타입</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>추가자</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>뉴스등록일시</div>
                                </TableHeader>
                                <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                    {newsList.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.nid}</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>
                                                    <img src={item.img_urls} style={{ height: '42px' }} alt='news thumbnail' />
                                                </div>
                                                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>{item.title}</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.med_nm}</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.cat_nm}</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>-</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>추가자</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>뉴스등록일시</div>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </div>

                            </React.Fragment>
                        }
                    </div>
                </DetailInfoC>
            }
        </React.Fragment>
    )
}

export default ViewBody;