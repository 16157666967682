
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { BannerInsert } from '../../../slice/bannerSlice';


const RegistTitle = ({ mode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleRegist = (e) => {

        dispatch(BannerInsert(true));

    }

    return (
        <BodyTitle>
            <BodyTitleFont>
                배너 {mode === "add" ? "등록" : "수정"}
            </BodyTitleFont>
            <div>
                <Button
                    name={mode === "add" ? "등록" : "수정"}
                    onClick={handleRegist}
                />
                &nbsp;
                <Button
                    cType={`cancel`}
                    name={`취소`}
                    onClick={
                        (e) =>
                            navigate("/operation/banner")
                    }
                />
            </div>
        </BodyTitle>
    )
}

export default RegistTitle;