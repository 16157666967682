import { BodyTitle, BodyTitleFont } from "../../style/layout";

const Title = () => {
	return (
		<BodyTitle>
			<BodyTitleFont>QnA 관리</BodyTitleFont>
		</BodyTitle>
	);
};

export default Title;
