import styled from "styled-components";
import { DetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import { getDetailInNewsList } from "../../../api/inNews/inNews";
import React, { useEffect, useState } from "react";
import ViewSearchBody from "./ViewSearchBody";
import { InNewsDate } from '../../../slice/inNewsSlice';
import { useDispatch } from 'react-redux';
const BasicInfoC = styled.div`
`;


const ViewBody = ({ cid }) => {

    const [inNewsDetail, setInNewsDetail] = useState();
    const [inNewsDtDetail, setInNewsDtDetail] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        const data = { params: { "cid": cid } };
        getDetailInNewsList(data)
            .then((response) => {
                const dt = response.data.data[0].searchDt;
                const dt2 = dt.replaceAll("-", "");
                const dt3 = dt2.replaceAll(" ", "");
                const dt4 = dt3.replaceAll(":", "");
                setInNewsDtDetail(dt4);
                dispatch(InNewsDate(dt4));
                setInNewsDetail(response.data.data[0]);
            })

    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {inNewsDetail &&
                <BasicInfoC>
                    <Label type='title'>기본정보</Label>
                    <div style={{ height: '20px' }} />
                    <DetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>제목</th><td>{inNewsDetail.title}</td>
                                <th>검색 기간</th><td>{inNewsDetail.searchDt}</td>
                            </tr>
                            <tr>
                                <th>검색 키워드</th><td>{inNewsDetail.searchKeyword}</td>
                                <th>배제어</th><td>{inNewsDetail.exceptKeyword}</td>
                            </tr>
                            <tr>
                                <th>등록일시</th><td>{inNewsDetail.regDt}</td>
                                <th>수정일시</th><td>{inNewsDetail.modDt}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            }
            <div></div>
            {inNewsDetail &&
                <ViewSearchBody searchKeyword={inNewsDetail.searchKeyword} fromDt={inNewsDtDetail} exceptKeyword={inNewsDetail.exceptKeyword}></ViewSearchBody>
            }
        </React.Fragment>
    )
}

export default ViewBody;