import { createSlice } from '@reduxjs/toolkit';

export const serviceMemberSlice = createSlice({
    name: 'serviceMember',
    initialState: { value: { insertFlag: false, memberInsert: false } },
    reducers: {
        Flag: (state, action) => {
            state.value.insertFlag = action.payload;
        },
        MemberInsert: (state, action) => {
            state.value.memberInsert = action.payload;
        },
    },
});
export const { Flag, MemberInsert } = serviceMemberSlice.actions;
export default serviceMemberSlice.reducer;
