import styled from "styled-components";
import {
    DetailTable,
} from "../../../style/layout";
import Label from "../../../common/Label";
import { useState, useEffect, useRef } from "react";
import React from "react";
import { getNoticeInfoBynotice_id } from "../../../api/notice/notice";
import Parser from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { EditerTp } from '../../../slice/noticeSlice';

const BasicInfoC = styled.div``;

const DetailInfoC = styled.div``;

const Block = styled.div`
    width: "100%";
    height: "375px";
    border: "1px solid #E9E9E9";
    padding: "5px";
    overflow: "scroll";
`;

export const Text = styled.div`
    display: block;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 24px;
    padding-bottom: 50px;
    word-wrap: break-word;
`;

const demoLocation = `${process.env.REACT_APP_EDITOR_URL}`;

const ViewBody = ({ notice_id }) => {
    ;

    const [loadStatus, setLoadStatus] = useState(false);
    const [noticeDetail, setNoticeDetail] = useState();
    const [height, setHeight] = useState(null);
    const editor = useRef(null);
    const dispatch = useDispatch();

    const onLoad = () => {
        setLoadStatus(true);
    };

    useEffect(() => {
        const editFn = (e) => {
            switch (e.data.name) {
                case "postHeight": {
                    setHeight(e.data.height + 100 + "px");
                    break;
                }

                default: {
                    break;
                }
            }
        };
        window.addEventListener("message", editFn);
        return () => {
            window.removeEventListener("message", editFn);
        };
    }, []);

    useEffect(() => {
        if (notice_id && notice_id.length > 0) {
            getNoticeInfoBynotice_id(notice_id).then((response) => {
                setNoticeDetail(response.data);
                dispatch(EditerTp(response.data.editor_tp));
            });
        }
    }, [notice_id]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (noticeDetail) {
            if (noticeDetail.contents.startsWith('{"raw"')) {
                if (loadStatus && editor.current) {
                    setTimeout(() => {
                        if (editor.current) {
                            editor.current.contentWindow.postMessage(
                                {
                                    type: "SET",
                                    param: {
                                        type: "data",
                                        data: JSON.parse(noticeDetail.contents).raw,
                                    },
                                },
                                "*"
                            );
                        }
                    }, 300);
                    editor.current.contentWindow.postMessage(
                        { name: "postHeight", height: "height" },
                        "*"
                    );
                }
            } else {
            }
        }
    }, [noticeDetail, loadStatus]);

    return (
        <React.Fragment>
            <div style={{ height: "50px" }} />

            {noticeDetail && (
                <BasicInfoC>
                    <Label type="title">기본정보</Label>
                    <div style={{ height: "20px" }} />
                    <DetailTable style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <th style={{ width: "15%" }}>공지사항 아이디</th>
                                <td style={{ width: "35%" }}>{noticeDetail.notice_id}</td>
                                <th style={{ width: "15%" }}>상태</th>
                                <td style={{ width: "35%" }}>적용중</td>
                            </tr>
                            <tr>
                                <th style={{ width: "15%" }}>등록일시</th>
                                <td style={{ width: "35%" }}>{"2020-02-01"}</td>
                                <th style={{ width: "15%" }}>작성자</th>
                                <td style={{ width: "35%" }}>로제우스</td>
                            </tr>
                            <tr>
                                <th>제목</th>
                                <td>{noticeDetail.title}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            )}
            <div style={{ height: "50px" }} />
            {noticeDetail ? noticeDetail.contents.startsWith('{"raw') ? (
                <DetailInfoC>
                    <Label type="title">본문정보</Label>
                    <div style={{ height: "20px" }} />
                    <Block>
                        <iframe
                            ref={editor}
                            title="EDITOR"
                            src={
                                demoLocation +
                                `?toolbar=false&editMode=false&isApp=${!!window.ReactNativeWebView}`
                            }
                            onLoad={(e) => onLoad(e)}
                            style={{
                                height,
                                minHeight: "200px",
                                width: "100%",
                                border: 0,
                            }}
                            frameBorder="0"
                            framespacing="0"
                            marginHeight="0"
                            marginWidth="0"
                        />
                    </Block>
                    <div style={{ height: "10px" }} />
                </DetailInfoC>
            ) : (
                <DetailInfoC>
                    <Label type="title">본문정보</Label>
                    <div style={{ height: "20px" }} />
                    <Block>
                        <Text>{Parser(noticeDetail.contents)}</Text>
                    </Block>
                    <div style={{ height: "10px" }} />
                </DetailInfoC>
            ) : ''}
        </React.Fragment>
    );
};

export default ViewBody;
