import { createSlice } from '@reduxjs/toolkit';

export const inNewsSlice = createSlice({
    name: 'inNews',
    initialState: { value: { inNewsInsert: false, insertFlag: false, updateExceptKeyword: "", updateSearchKeyword: "" } },
    reducers: {
        InNewsInsert: (state, action) => {
            state.value.inNewsInsert = action.payload;
        },
        Flag: (state, action) => {
            state.value.insertFlag = action.payload;
        },
        InNewsDate: (state, action) => {
            state.value.inNewsDate = action.payload;
        },
        UpdateExceptKeyword: (state, action) => {
            state.value.updateExceptKeyword = action.payload;
        },
        UpdateSearchKeyword: (state, action) => {
            state.value.updateSearchKeyword = action.payload;
        },
        inFlag: (state, action) => {
            state.value.flag = action.payload;
        },

    },
});
export const { InNewsInsert, Flag, InNewsDate, UpdateExceptKeyword, UpdateSearchKeyword, inFlag } = inNewsSlice.actions;
export default inNewsSlice.reducer;
