import styled from 'styled-components';
import theme from '../components/cms/theme';

export const GridView = styled.div`
    display: grid;
    width: 100%;
    height: 88px;
    grid-gap: 16px;
    grid-template-columns: 108px 5fr;
    align-items: center;
    margin-bottom: 8px;
`;

export const PostGridView = styled.div`
    display: grid;
    width: 100%;
    height: 30px;
    grid-gap: 16px;
    align-items: center;
    margin-bottom: 8px;
`;
export const LeftImgBox = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 72px;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    margin-bottom: 4px;
    .video {
        top: 0;
        left: 0;
        max-width: 108px;
        height: 72px;
        filter: brightness(70%);
    }
    .video_icon {
        position: absolute;
    }
    .img {
        top: 0;
        left: 0;
        max-width: 108px;
        height: 72px;
    }
    .error {
        top: 0;
        left: 0;
        max-width: 108px;
        height: 72px; 
    }
    
`;
export const Margin = styled.div`
    height: ${(props) => props.height};
`;
export const ListStyle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    cursor: pointer;
    // -webkit-tap-highlight-color: transparent;
    border-bottom: ${(props) => (props.index === props.length ? 'none' : `1px solid ${theme.color.GrayColor}`)};
    .list_title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        width: 100%;
        ${theme.fontSize.body2};
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 2px;
        // margin-bottom: 14px;
        // height: 40px;
        line-height: 1.3em;
        // max-height: 2.4em;
    }
    .list_sub {
        display: flex;
        ${theme.fontSize.caption};
        justify-items: center;
        letter-spacing: -0.48px;
        color: gray;
        width: 100%;
        height: 17px;
        padding-top: 2px;
        padding-bottom: 10px;
        
        .first {
            display: flex;
            flex-direction: row;
            width: 85%;
            .card_category {
                display: flex;
                justify-content: flex-start;
            }
            .room_nm {
                display: flex;
                justify-content: center;
            }

            .right_border {
                display: flex;
                height: 100%;
                color: ${theme.color.GrayColor};
                margin: 0 8px 0 8px;
                box-sizing: border-box;
                align-content: center;
                justify-content: center;
            }
            .category {
                display: flex;
                justify-content: flex-start;
                word-break-cjk: normal;
                white-space: nowrap;
            }
            .center {
                display: flex;
                justify-content: center;
                word-break-cjk: normal;
                white-space: nowrap;
            }
            .time {
                display: flex;
                justify-content: flex-start;
                word-break-cjk: normal;
                white-space: nowrap;
            }
            .rel_cnt {
                display: flex;
                margin-left: 10px;
            }
        }
        .second {
            display: flex;
            width: 15%;
            height: 24px;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }
`;
export const handleImgError = (e) => {
    //e.target.className = isImg ? 'img' : 'logo';
    e.target.src = '/img/rozeus_default.png';
    e.target.className = 'error';
    e.target.width = 0;
    // e.target.className = isImg ? 'img' : 'default';
};

export const handleMainError = (e) => {
    e.target.className = 'error_img';
    e.target.src = '/img/rozeus_default.png';


};
export const getTextLength = (str, max) => {
    let totalByte = 0;
    let len = 0;
    let str2 = '';
    if (str && str.length !== 0) {
        for (let i = 0; i < str.length; i++) {
            let oneChar = str.charAt(i);
            if (escape(oneChar).length > 4) {
                totalByte += 2;
            } else totalByte++;
            if (totalByte <= max) {
                len = i + 1;
            }
        }
        if (totalByte > max) {
            str2 = str.substr(0, len);
        }
        return str2;
    }
};
export const checkLength = (str, max) => {
    let totalByte = 0;
    let len = 0;
    if (str && str.length !== 0) {
        for (let i = 0; i < str.length; i++) {
            let oneChar = str.charAt(i);
            if (escape(oneChar).length > 4) {
                totalByte += 2;
            } else totalByte++;
            if (totalByte <= max) {
                len = i + 1; // eslint-disable-line no-unused-vars
            }
        }
        return totalByte <= max;
    } else {
        return '';
    }
};
