import React, { useEffect, useState } from "react";
import { TableHeader, TableRow, SearchBar } from "../../style/layout";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import { getMemberList, deleteMember } from "../../api/member/member";
import { MessageModalFlag, LoadingModalFlag } from '../../slice/modalSlice';
import MessageModal from '../../modal/Message';
import { useDispatch, useSelector } from 'react-redux';
import TextField from "../../common/TextField";
import Form from 'react-bootstrap/Form';
import '../../style/common.css';

const Body = () => {

    const [membertList, setMemberList] = useState();
    const [searchKey, setSearchKey] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState('');

    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const navigate = useNavigate();
    const [size, setSize] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [totalCnt, setTotalCnt] = useState();
    const [pagesInfo, setPagesInfo] = useState();
    const [deleteUid, setDeleteUid] = useState([]);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        setMessage("검색중");
        dispatch(LoadingModalFlag(true));
        searchhandle();
    }, [size, page_no, dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
    }

    const searchhandle = (e) => {
        const data = { params: { "size": size, "offset": page_no, "searchKey": searchKey, "searchKeyword": searchKeyword } };
        getMemberList(data)
            .then((response) => {
                dispatch(LoadingModalFlag(false));
                setMemberList(response.data.data);
                setTotalCnt(response.data.total);
                setPagesInfo(response.data.total);
            })
    }

    const checkhandle = (e) => {
        const newArr = deleteUid.slice();
        for (const index in deleteUid) {
            if (`${deleteUid[index].uid}` === e) {
                newArr.splice(index, 1);
                setDeleteUid(newArr);
                return;
            }
        }
        newArr.push({
            uid: e,
        });
        setDeleteUid(newArr);
    }

    const deletehandle = () => {
        const selector = JSON.stringify(deleteUid);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"uid":', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll('"', '');
        const deleteData6 = "," + deleteData5;

        if (deleteData5 === "") {
            setTitle("회원 삭제");
            setMessage("삭제할 계정을 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData7 = { params: { "uid": deleteData6 } };

        deleteMember(deleteData7)
            .then((response) => {
                if (response.status === 200) {
                    setTitle("회원 삭제");
                    setMessage("삭제를 완료되었습니다");
                    dispatch(MessageModalFlag(true));
                    const checkboxes = document.getElementsByName('selectCheckBox');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    })
                    setPage_no(1);
                    searchhandle();
                    setDeleteUid([]);
                    return;
                } else {
                    setTitle("회원 삭제");
                    setMessage("삭제를 실패하였습니다");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            })
    }

    const enterkey = (e) => {
        if (window.event.keyCode === 13) {
            setPage_no(1);
            searchhandle();
        }
    }

    const search = (e) => {
        setPage_no(1);
        searchhandle();
    }


    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '20px' }}></div>
                <SearchBar>
                    <div className='filter'>
                        <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                            {pagesInfo &&
                                <React.Fragment>
                                    (<span style={{ color: '#ED5255' }}>{pagesInfo}</span>개)
                                </React.Fragment>
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Select onChange={(e) => setSearchKey(e.target.value)} className="selectBox">
                                <option value={1}>아이디</option>
                                <option value={2}>이름</option>
                                <option value={3}>이메일</option>
                            </Form.Select>&nbsp;
                            <TextField placeholder={`검색어를 입력해주세요`} onChange={(e) => setSearchKeyword(e.target.value)}
                                onKeydown={enterkey} />&nbsp;
                            <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                                <option value={10}>10개씩보기</option>
                                <option value={20}>20개씩보기</option>
                                <option value={30}>30개씩보기</option>
                            </Form.Select>&nbsp;
                            <Button cType='b_line' name='검색' width='65px' onClick={search} />&nbsp;
                            <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                        </div>
                    </div>
                    <div className='title'></div>
                    <div className='component'></div>
                </SearchBar>
                {(membertList && membertList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>아이디</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>이름</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>이메일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>핸드폰 번호</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>회사</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>소속</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>부서</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>등록일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>수정일</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {membertList.map((item, index) => {
                                return (
                                    <TableRow key={index} style={{ height: '42px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }} onChange={(e) => checkhandle(item.uid)}><Check name='selectCheckBox' /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.login_id}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.name}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.email}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.phone}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.company}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.division}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.department}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.reg_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/authority/view/${item.uid}`)}>{item.upt_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCnt} pageSize={size} viewSize={10} onClick={handlePageClick} />
                    </React.Fragment>
                }
                {MModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="check" title={title} message={message} />
                        </div>
                    </div>
                }
                {LModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="loading" title="회원 및 권한" message={message} />
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;