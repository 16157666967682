
import React, { useEffect, useState } from "react";
import { getTracker } from "../../api/homeClick/clickTracker";
import Tabs from "../../common/Tabs";

const Body = () => {

    const [type, setType] = useState('M')
    const handleTabClick = (e, index) => {
        switch (index) {
            case 0:
                setType('M');
                break;
            case 1:
                setType('MT');
                break;
            case 2:
                setType('T');
                break;
            case 3:
                setType('P');
                break;
            default:
                setType('M');
                break;
        };
    }

    return (
        <div>
            <Tabs items={[`모바일`, `준 태블릿`, `태블릿`, `PC`]} onClick={handleTabClick} />
            <br />
            <Main type={type} />
        </div>
    )
}

const Main = ({ type }) => {

    const [width, setWidth] = useState('370px');
    const [clickList, setClickList] = useState();
    // const history = useHistory();

    useEffect(() => {
        switch (type) {
            case 'M':
                setWidth('370px');
                break;
            case 'MT':
                setWidth('450px');
                break;
            case 'T':
                setWidth('550px');
                break;
            case 'P':
                setWidth('700px');
                break;
            default:
                setWidth('370px');
                break;
        };
        getTracker(type, '', '')
            .then((res) => {
                setClickList(res);
            })

    }, [type])

    return (
        <div style={{ position: 'relative' }}>
            <img

                src={`https://api.rozeus.com/admin/api/v1/banner/static/image/body_${type}.jpeg`}
                style={{
                    width: width,
                }}
                alt='BACK'
            />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: width,
                    height: '100%',
                }}
            >
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#000000', opacity: '0.3' }} />
                {clickList && clickList.map((item, index) => {
                    return (
                        <div key={index} style={{ borderRadius: '50%', position: 'absolute', top: 'calc(' + item.top + 'px - 12.25px)', left: 'calc(' + item.left + '% - 12.5px)', width: '25px', height: '25px', background: '#FF0000', opacity: '0.15' }} />
                    )
                })}
            </div>
        </div>
    )
}



export default Body;