// 임시 대시보드 코드 start
import React from "react";
import Body from "../../components/homeClick/Body";
import HomeClick from "../../templates/homeClick/HomeClick";

const HomeClickContainer = () => {

    return (
        <HomeClick
            body={<Body />}
        />
    )
}

export default HomeClickContainer;