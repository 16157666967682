
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";


const Title = () => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>포스트 등록</BodyTitleFont>
            <Button name={`포스트 등록`} width={'160px'} onClick={(e) => navigate('/cms/postInsert/add')} />
        </BodyTitle>
    )
}

export default Title;