import { createSlice } from '@reduxjs/toolkit';

export const noticeSlice = createSlice({
	name: 'notice',
	initialState: { value: { noticeTitle: "", insertFlag: false, noticeNoticeTp: "1", noticeIsUse: "1", noticeEditIsReady: false, noticeEditOverlay: false, noticeData: "", nId: 0, EditorTp: "2", noticeInsert: false } },
	reducers: {
		Title: (state, action) => {
			state.value.noticeTitle = action.payload;
		},
		NoticeTp: (state, action) => {
			state.value.noticeNoticeTp = action.payload;
		},
		IsUse: (state, action) => {
			state.value.noticeIsUse = action.payload;
		},
		EditIsReady: (state, action) => {
			state.value.noticeEditIsReady = action.payload;
		},
		EditOverlay: (state, action) => {
			state.value.noticeEditOverlay = action.payload;
		},
		EditNoticeData: (state, action) => {
			state.value.noticeData = action.payload;
		},
		NoticeId: (state, action) => {
			state.value.nId = action.payload;
		},
		EditerTp: (state, action) => {
			state.value.EditerTp = action.payload;
		},
		NoticeInsert: (state, action) => {
			state.value.noticeInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { Title, noticeTitle, NoticeTp, noticeNoticeTp, IsUse, noticeIsUse, EditIsReady, noticeEditIsReady, EditOverlay, noticeEditOverlay, noticeData, EditNoticeData, nId, NoticeId, EditerTp, NoticeInsert, Flag } = noticeSlice.actions;
export default noticeSlice.reducer;
