import React from 'react';
import styled from 'styled-components';
import Menu from './Menu'
const AdminMenuC = styled.div`
    width : 100%;
    height: 100%;
    color : #ffffff;
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    align-items : center;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0px;
    }
`;

const AdminMenu = ({ menuList }) => {
    return (
        <React.Fragment>
            <AdminMenuC>
                <div style={{ flexGrow: 1, width: '100%' }}>
                    {menuList && menuList.map((item, idx) =>
                        <Menu key={idx} menuInfo={item} subList={item.children} />
                    )}
                </div>
                <div style={{ width: '100%', height: '1px', background: '#ffffff' }} />
                <div style={{ height: '132px' }}>
                    <br />
                    Help Center<br />
                    로제우스 사업본부로<br />
                    문의 할 수 있습니다.
                </div>
            </AdminMenuC>
        </React.Fragment>
    )
}

export default AdminMenu;