import styled from "styled-components";
import { InNewsListDetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import { getNewsInNewsSetting } from "../../../api/inNewsList/inNewsList";
import React, { useState, useEffect } from "react";


const BasicInfoC = styled.div`
`;

const ViewBody = ({ nid }) => {

    const [inNewsList, setInNewsList] = useState();

    useEffect(() => {
        const data = { params: { "nid": nid } };
        getNewsInNewsSetting(data)
            .then((response) => {
                setInNewsList(response.data.data[0]);
            })
    }, [nid])

    return (
        <React.Fragment>
            <div style={{ height: '50px' }} />
            {inNewsList &&
                <BasicInfoC>
                    <Label type='title'>List 정보</Label>
                    <div style={{ height: '20px' }} />
                    <InNewsListDetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>InNews 제목</th><td>{inNewsList.title}</td>
                                <th>Nid</th><td>{inNewsList.nid}</td>
                            </tr>
                            <tr>
                                <th>시작일시</th><td>{inNewsList.fromDt}</td>
                                <th>종료일시</th><td>{inNewsList.toDt}</td>
                            </tr>
                            <tr>
                                <th>카드 경로</th><td>{inNewsList.location}</td>
                                <th>사용 여부</th><td>{inNewsList.isUse === "1" ? '사용' : '미사용'}</td>
                            </tr>
                        </tbody>
                    </InNewsListDetailTable>
                </BasicInfoC>
            }
            <div style={{ height: '50px' }} />
            {inNewsList &&
                <BasicInfoC>
                    <Label type='title'>카드 정보</Label>
                    <div style={{ height: '20px' }} />
                    <InNewsListDetailTable style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th>InNews 제목</th><td>{inNewsList.title}</td>
                                <th>Cid</th><td>{inNewsList.cid}</td>
                            </tr>
                            <tr>
                                <th>카드등록일시</th><td>{inNewsList.regDt}</td>
                                <th>카드수정일시</th><td>{inNewsList.modDt}</td>
                            </tr>
                            <tr>
                                <th>검색 키워드</th><td>{inNewsList.searchKeyword}</td>
                                <th>배제어</th><td>{inNewsList.exceptKeyword}</td>
                            </tr>
                            <tr>
                                <th>내용</th><td>{inNewsList.description}</td>
                            </tr>
                        </tbody>
                    </InNewsListDetailTable>
                </BasicInfoC>
            }
        </React.Fragment>
    )
}

export default ViewBody;