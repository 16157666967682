import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Button from "../../common/Button";
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import { getNovel, deleteNovel } from "../../api/novel/novel";
import Form from 'react-bootstrap/Form';
import TextField from "../../common/TextField";
import { MessageModalFlag } from '../../slice/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from '../../modal/Message';

const Body = () => {

    const navigate = useNavigate();
    const [pageRows, setPageRows] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [searchList, setSearchList] = useState('1');
    const [searchText, setSearchText] = useState("");
    const [totalCount, setTotalCount] = useState();
    const [novelList, setNovelList] = useState();
    const [deleteListNkey, setDeleteListNkey] = useState([]);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);

    useEffect(() => {
        const data = { params: { "searchType": searchList, "searchValue": searchText, "size": pageRows, "page": page_no } };
        getNovel(data)
            .then((response) => {
                for (var i = 0; i < response.data.list.length; i++) {
                    if (response.data.list[i].status === false) {
                        response.data.list[i].statusNm = "종료"
                    } else if (response.data.list[i].status === true) {
                        response.data.list[i].statusNm = "라이브"
                    }
                    if (response.data.list[i].issuance === 0 || response.data.list[i].issuance === false) {
                        response.data.list[i].issuanceNm = "고정"
                    } else if (response.data.list[i].issuance === 1 || response.data.list[i].issuance === true) {
                        response.data.list[i].issuanceNm = "타임"
                    }
                    if (response.data.list[i].to_dt === null) {
                        response.data.list[i].to_dt = "없음"
                    }
                }
                setNovelList(response.data.list);
                setTotalCount(response.data.total);
            })
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPage_no(1);
        const data = { params: { "searchType": searchList, "searchValue": searchText, "size": pageRows, "page": 1 } };
        getNovel(data)
            .then((response) => {
                for (var i = 0; i < response.data.list.length; i++) {
                    if (response.data.list[i].status === false) {
                        response.data.list[i].statusNm = "종료"
                    } else if (response.data.list[i].status === true) {
                        response.data.list[i].statusNm = "라이브"
                    }
                    if (response.data.list[i].issuance === 0 || response.data.list[i].issuance === false) {
                        response.data.list[i].issuanceNm = "고정"
                    } else if (response.data.list[i].issuance === 1 || response.data.list[i].issuance === true) {
                        response.data.list[i].issuanceNm = "타임"
                    }
                    if (response.data.list[i].to_dt === null) {
                        response.data.list[i].to_dt = "없음"
                    }
                }
                setNovelList(response.data.list);
                setTotalCount(response.data.total);
            })
    }, [pageRows, searchText, searchList]);

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }
    const checkhandle = (e) => {

        const newArr = deleteListNkey.slice();
        for (const index in deleteListNkey) {
            if (`${deleteListNkey[index].nkey}` === e) {
                newArr.splice(index, 1);
                setDeleteListNkey(newArr);
                return;
            }
        }
        newArr.push({
            Nkey: e,
        });
        setDeleteListNkey(newArr);
    }

    const deletehandle = () => {

        const selector = JSON.stringify(deleteListNkey);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"Nkey":"', '');
        const deleteData3 = deleteData2.replaceAll('"}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll('"', '');
        const deleteData6 = "," + deleteData5;

        if (deleteData5 === "") {
            setMessage("삭제할 작품을 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData7 = { params: { "nkey": deleteData6 } };
        deleteNovel(deleteData7)
            .then((response) => {
                if (response.status === 200) {
                    setMessage("삭제 완료되었습니다");
                    dispatch(MessageModalFlag(true));
                    const checkboxes = document.getElementsByName('selectCheckBox');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    })
                    setting();
                    setDeleteListNkey([]);
                    return;
                } else {
                    setMessage("삭제를 실패하였습니다");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            })
    }

    const setting = () => {
        const data = { params: { "searchType": searchList, "searchValue": searchText, "size": pageRows, "page": page_no } };
        getNovel(data)
            .then((response) => {
                for (var i = 0; i < response.data.list.length; i++) {
                    if (response.data.list[i].status === false) {
                        response.data.list[i].statusNm = "종료"
                    } else if (response.data.list[i].status === true) {
                        response.data.list[i].statusNm = "라이브"
                    }
                    if (response.data.list[i].issuance === 0 || response.data.list[i].issuance === false) {
                        response.data.list[i].issuanceNm = "고정"
                    } else if (response.data.list[i].issuance === 1 || response.data.list[i].issuance === true) {
                        response.data.list[i].issuanceNm = "타임"
                    }
                    if (response.data.list[i].to_dt === null) {
                        response.data.list[i].to_dt = "없음"
                    }
                }
                setNovelList(response.data.list);
                setTotalCount(response.data.total);
            })
    }


    const handlePageRowsChange = (value) => {
        setPageRows(value)
        setPage_no(1);
    }


    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {totalCount &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCount}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select className="selectBox" defaultValue={searchList} onChange={(e) => setSearchList(e.target.value)}>
                            <option value='1' >제목</option>
                            <option value='2' >구분</option>
                            <option value='3' >공급사</option>
                            <option value='4' >작가명</option>
                        </Form.Select>&nbsp;
                        <TextField value={searchText || ""} placeholder={`검색어를 입력해주세요`} onChange={(e) => setSearchText(e.target.value)} />&nbsp;
                        <Form.Select className="selectBox" defaultValue={pageRows} onChange={(e) => handlePageRowsChange(e.target.value)}>
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                    </div>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(novelList && novelList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check /></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>상태</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '36px' }}>구분</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>작가명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>공급사</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>적용시작일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>적용종료일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>작품 제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>조회수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>좋아요수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>평점</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>회차수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>등록일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>수정일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {novelList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px', }} onChange={(e) => checkhandle(item.nkey)}><Check name='selectCheckBox' /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.statusNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '36px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.issuanceNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.writer}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.affiliates}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.from_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.to_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'left', flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.read_cnt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.like_cnt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.rating}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.episode}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.reg_dt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} onClick={(e) => navigate(`/operation/novel/view/${item.nkey}`)}>{item.mod_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCount} pageSize={pageRows} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="웹소설 등록/수정" message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="웹소설 등록/수정" message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Body;