
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { InNewsInsert } from '../../../slice/inNewsSlice';

const RegistTitle = ({ mode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleRegist = () => {
        dispatch(InNewsInsert(true));
    };
    return (
        <BodyTitle>
            <BodyTitleFont>
                In News 카드 {mode === "add" ? "등록" : "수정"}
            </BodyTitleFont>
            <div>
                <Button
                    name={mode === "add" ? "등록" : "수정"}
                    onClick={handleRegist}
                />
                &nbsp;
                <Button
                    cType={`cancel`}
                    name={`취소`}
                    onClick={
                        (e) =>
                            navigate("/operation/inNews")
                    }
                />
            </div>
        </BodyTitle>
    )
}

export default RegistTitle;