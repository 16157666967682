import { createSlice } from '@reduxjs/toolkit';

export const memberSlice = createSlice({
    name: 'member',
    initialState: { value: { memberLoginId: "", memberEmail: "", memberName: "", memberPhone: "", memberPassword: "", memberUid: "", memberCompany: "", memberDivision: "", memberDepartment: "", insertFlag: false, authInsert: false } },
    reducers: {
        LoginId: (state, action) => {
            state.value.memberLoginId = action.payload;
        },
        Email: (state, action) => {
            state.value.memberEmail = action.payload;
        },
        Name: (state, action) => {
            state.value.memberName = action.payload;
        },
        Phone: (state, action) => {
            state.value.memberPhone = action.payload;
        },
        Password: (state, action) => {
            state.value.memberPassword = action.payload;
        },
        Company: (state, action) => {
            state.value.memberCompany = action.payload;
        },
        Division: (state, action) => {
            state.value.memberDivision = action.payload;
        },
        Department: (state, action) => {
            state.value.memberDepartment = action.payload;
        },
        Uid: (state, action) => {
            state.value.memberUid = action.payload;
        },
        Flag: (state, action) => {
            state.value.insertFlag = action.payload;
        },
        AuthInsert: (state, action) => {
            state.value.authInsert = action.payload;
        },
    },
});
export const { LoginId, Email, Name, Phone, Password, Company, Division, Department, Uid, Flag, AuthInsert } = memberSlice.actions;
export default memberSlice.reducer;
