
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";

const ViewTitle = ({ button, pid, rid }) => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>이슈 따라잡기 상세정보</BodyTitleFont>
            <div>
                <Button name={`수정`} onClick={(e) => navigate(`/operation/issue/add/${pid}/${rid}`)} />
                &nbsp;
                <Button cType={`cancel`} name={`목록으로 이동`} width={'150px'} onClick={(e) => navigate(`/operation/issue/`)} />
            </div>
        </BodyTitle>
    )
}

export default ViewTitle;