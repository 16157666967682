import styled from 'styled-components';

const RadioWrapper = styled.div`
  display: inline-block;
`;

const Mark = styled.span`
  display: inline-block;
  position: relative;
  border: 2px solid #CBCBCB;
  width: 14px;
  height: 14px;
  left: 0;
  border-radius: 50%;
  margin-right: 7px;
  vertical-align: middle;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #ED5255;
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

const Input = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${Mark} {
    border: 2px solid #ED5255;
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
      left: 2px;
      top: 2px;
    }
  }
`;

const Label = styled.label`
  display: flex;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  ${props =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: 0.4;
    `}
`;

// const temp = `국내 증시의 집계 기준은 ` + koTime + `, 해외 증시의 집계 기준은 ` + usaTime + ` 입니다. 또한 등록한 내 가상화폐를 해당 거래소에서 취급하지 않을 경우 '정보 없음'으로 나타내고 있습니다.`

const Radio = ({ name, value, children, checked, onChange }) => {

  return (
    <RadioWrapper>
      <Label style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          name={name}
          value={value}
          type="radio"
          checked={checked}
          onChange={(e) => onChange && onChange()}
        />
        <Mark />
        {children}
      </Label>
    </RadioWrapper>
  )
};

export default Radio;
