import styled from "styled-components";
import Label from "../../../common/Label";
import Radio from "../../../common/Radio";
import TextField from "../../../common/TextField";
import { useState, useEffect } from "react";
import Button from "../../../common/Button";
import News from "../../../common/search/News";
import FileAttachSplash from "../../../common/FileAttachSplash";
import MenuIcon from '@material-ui/icons/Menu';
import IssueRegistModal from "./modal/IssueRegistModal";
import './modal/IssueRegistModal.css';
import Edit from '../edit/Edit';
import React from "react";
import Check from "../../../common/Check";
import OneDayCalendarPush from "../../../common/OneDayCalendarPush";
import {  SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { getCdnUploadImageReturnUrl, postIssueInsert, postIssueUpdate } from "../../../api/issue/issue";//eslint-disable-line
import { TableHeader, TableRow } from "../../../style/layout";
import moment from "moment";
import { getDetailList } from "../../../api/issue/issue";
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import { IssueInsert, Flag } from '../../../slice/issueSlice';//eslint-disable-line
import MessageModal from "../../../modal/Message";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';//eslint-disable-line
import { useNavigate } from "react-router-dom";


const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const MenuToggle = styled.div`
    display: flex;
    align-items: center;
`;
const RegistBody = ({ mode, pid, rid }) => {

    const [searchOpen, setSearchOpen] = useState(false);
    const [reservDate, setReservDate] = useState(moment().format("YYYY-MM-DD"));
    const [isRegistModal, setIsRegistModal] = useState(false);
    const [title, setTitle] = useState('');
    const [newsList, setNewsList] = useState([]);
    const [publicFlag, setPublicFlag] = useState(1);
    const [editData, setEditData] = useState();// eslint-disable-line no-unused-vars
    const [image, setImage] = useState();
    const [fromTime, setFromTime] = useState("23:59");
    const [nidList, setNidList] = useState([]);// eslint-disable-line no-unused-vars
    const [delTempList, setDelTempList] = useState([]);
    const [postTp, setPostTp] = useState('3');
    const [delList, setDelList] = useState([]);// eslint-disable-line no-unused-vars
    const [newsType, setNewsType] = useState([{ NEWS_TYPE: '긍정', NEWS_TYPE_IS_USE: 0 }, { NEWS_TYPE: '부정', NEWS_TYPE_IS_USE: 1 }, { NEWS_TYPE: '중립', NEWS_TYPE_IS_USE: 2 }]);// eslint-disable-line no-unused-vars

    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');

    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.issue.value.issueInsert);
    const insertFlag = useSelector((state) => state.issue.value.insertFlag);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (Insert === true) {
            setMsgTitle("이슈 따라잡기 등록/수정");
            setMessage("진행중");
            dispatch(LoadingModalFlag(true));
            if (title === undefined || title === '') {
                dispatch(LoadingModalFlag(false));
                setMsgTitle("이슈 따라잡기");
                setMessage("제목을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(IssueInsert(false))
            } else if (editData === undefined) {
                dispatch(LoadingModalFlag(false));
                setMsgTitle("이슈 따라잡기");
                setMessage("내용을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(IssueInsert(false))
                return;
            } else if (image === undefined || image === '') {
                dispatch(LoadingModalFlag(false));
                setMsgTitle("이슈 따라잡기");
                setMessage("썸네일을 선택해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(IssueInsert(false))
                return;
            }

            getCdnUploadImageReturnUrl(image)
                .then((response) => {
                    editData.cont_1 = {};
                    const thumbnailsArr = new Array([]);
                    thumbnailsArr.splice(0, 1);
                    thumbnailsArr.push(response.data.image_urls[0]);
                    editData.cont_1 = { "thumbnails": thumbnailsArr };
                    const positiveNewsList = new Array([]);
                    positiveNewsList.splice(0, 1);
                    const denialNewsList = new Array([]);
                    denialNewsList.splice(0, 1);
                    const neutralityNewsList = new Array([]);
                    neutralityNewsList.splice(0, 1);
                    const positiveNewsViewList = new Array([]);
                    positiveNewsViewList.splice(0, 1);
                    const denialNewsViewList = new Array([]);
                    denialNewsViewList.splice(0, 1);
                    const neutralityNewsViewList = new Array([]);
                    neutralityNewsViewList.splice(0, 1);
                    for (var i = 0; i < newsList.length; i++) {
                        if (newsList[i].type === 0 || newsList[i].type === undefined) {
                            positiveNewsList.push(newsList[i].nid);
                            positiveNewsViewList.push(newsList[i]);
                        } else if (newsList[i].type === 1) {
                            denialNewsList.push(newsList[i].nid);
                            denialNewsViewList.push(newsList[i]);
                        } else if (newsList[i].type === 2) {
                            neutralityNewsList.push(newsList[i].nid);
                            neutralityNewsViewList.push(newsList[i]);
                        }
                    }
                    editData.cont_1.nids_1 = { "list": positiveNewsList };
                    editData.cont_1.nids_2 = { "list": denialNewsList };
                    editData.cont_1.nids_3 = { "list": neutralityNewsList };
                    editData.NewsList = {};
                    editData.NewsList.nids_1 = { "list": positiveNewsViewList };
                    editData.NewsList.nids_2 = { "list": denialNewsViewList };
                    editData.NewsList.nids_3 = { "list": neutralityNewsViewList };
                    const realcontents = JSON.stringify(editData);
                    if (mode === "add") {

                        //const data = { "rid": 1217049, "post_tp": 3, "post_st": "N", "rev_dt": reservDate + " " + fromTime, "title": title, "contents": realcontents, "fold_id": 1252368, "open_st": 1 }
                        const data = { "rid": 1, "post_tp": 3, "post_st": "N", "rev_dt": reservDate + " " + fromTime, "title": title, "contents": realcontents, "fold_id": 3, "open_st": 1 }
                        postIssueInsert(data)
                            .then((response) => {
                                dispatch(LoadingModalFlag(false));
                                if (response.status === 200 || response.status === 201) {
                                    setMsgTitle("이슈 따라잡기");
                                    setMessage("등록 완료되었습니다");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(IssueInsert(false))
                                    dispatch(Flag(true));;
                                    return;
                                } else {
                                    setMsgTitle("이슈 따라잡기");
                                    setMessage("등록 실패되었습니다");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(IssueInsert(false));
                                    return;
                                }
                            })
                    }
                    if (mode === "modify") {
                        //const data = { "rid": 1217049, "post_tp": 3, "post_st": "N", "rev_dt": reservDate + " " + fromTime, "title": title, "contents": realcontents, "fold_id": 1252368, "open_st": 1, "pid": pid }
                        const data = { "rid": 1, "post_tp": 3, "post_st": "N", "rev_dt": reservDate + " " + fromTime, "title": title, "contents": realcontents, "fold_id": 3, "open_st": 1, "pid": pid }
                        postIssueUpdate(data)
                            .then((response) => {
                                dispatch(LoadingModalFlag(false));
                                if (response.status === 200 || response.status === 201) {
                                    setMsgTitle("이슈 따라잡기");
                                    setMessage("수정 완료되었습니다");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(IssueInsert(false));
                                    return;
                                } else {
                                    setMsgTitle("이슈 따라잡기");
                                    setMessage("수정 실패되었습니다");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(IssueInsert(false));
                                    return;
                                }
                            })
                    }
                });
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/issue/");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps


    const handleNowData = (editData) => {// eslint-disable-line no-unused-vars
        setEditData(editData.contents);
    };

    useEffect(() => {
        if (pid && pid.length > 0) {
            getDetailList(pid, rid).then((response) => {
                setEditData(response.data[0].get_post[0].contents);
                setTitle(response.data[0].get_post[0].title);
                setPostTp(response.data[0].get_post[0].post_tp);
                setReservDate(response.data[0].get_post[0].rev_dt.substr(0, 10));
                setFromTime(response.data[0].get_post[0].rev_dt.substr(11, 5));
                const nidsList = new Array([]);
                nidsList.splice(0, 1);
                if (JSON.parse(response.data[0].get_post[0].contents).NewsList) {
                    for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_1.list.length; i++) {
                        nidsList.push(JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_1.list[i]);
                    }
                    for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_2.list.length; i++) {
                        nidsList.push(JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_2.list[i]);
                    }
                    for (let i = 0; i < JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_3.list.length; i++) {
                        nidsList.push(JSON.parse(response.data[0].get_post[0].contents).NewsList.nids_3.list[i]);
                    }
                }
                setNewsList(nidsList);
            })
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const SortableItem = SortableElement(({ item, num }) => (
        <TableRow >
            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check onChange={(e) => handleDelCheckChange(e, item.nid)} /></div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><SortableDrag /></div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.nid}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>
                <img src={item.img_urls} style={{ height: '42px' }} alt='news thumbnail' />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '750px' }}>{item.title}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.med_nm}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.cat_nm}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>
                {(newsList && newsList.length > 0) ?
                    <Form.Select className="selectBox" defaultValue={item.type} onChange={(e) => addTypeItem(num, e.target.value)}>
                        {newsType.map((selectitem, index) => {
                            return <option key={index} value={selectitem.NEWS_TYPE_IS_USE}>{selectitem.NEWS_TYPE}</option>
                        })}
                    </Form.Select>
                    :
                    'Loding'
                }
            </div>
        </TableRow>
    ));

    const SortableDrag = SortableHandle(() => (
        <MenuToggle>
            <MenuIcon />
        </MenuToggle>
    ));

    const onAdd = (list) => {
        const temp = newsList.concat(list);
        setNewsList(temp);
        const nidListTemp = temp.map((item, index) => {
            return item.nid
        });
        setNidList(nidListTemp);
    }

    const arrayMove = (array, oldIndex, newIndex) => {
        let arr = [...(array ?? [])]
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    };



    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newList = arrayMove(newsList, oldIndex, newIndex);
        setNewsList(newList);
    };

    const onSetTitle = (e) => {
        if (e.target.value.length > 60) {
            setIsRegistModal(true);
            return;
        } else {
            setTitle(e.target.value);
        }
    };

    const IssueRegistModalhandleClose = (e) => {
        setIsRegistModal(false);
    }

    const addImgItem = (file) => {
        setImage(file);
    };

    const addTypeItem = (index, value) => {
        const newArr = newsList.slice();
        newArr[index].type = value;
        setNewsList(delList);
        setNewsList(newArr);
    };


    const handleDelCheckChange = (e, nid) => {
        var temp = delTempList;
        if (e.target.checked === true) {
            temp.push(nid)
            setDelTempList(temp);
        } else if (e.target.checked === false) {
            temp = temp.filter(function (item) { return item !== nid })
            setDelTempList(temp);
        }
    }

    const handleDeleteClick = (e, nid) => {
        var temp = newsList;
        delTempList.map((target) => {//eslint-disable-line
            temp = temp.filter(function (item) { return item.nid !== target })
        })
        const nidListTemp = temp.map((item, index) => {
            return item.nid
        });
        setNewsList(temp);
        setNidList(nidListTemp);
    }
    const SortableList = SortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    return (
        <RegistBodyC>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={title || ""} width='100%' placeholder='이슈 따라잡기 제목을 입력하세요' onChange={onSetTitle} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>내용</Label></div>
                <div style={{ flexGrow: 1, border: '1px solid rgba(0,0,0,0.1)' }}>
                    <Edit onChange={handleNowData} pid={pid} rid={rid}></Edit>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>썸네일</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <FileAttachSplash width='100%' placeholder='파일을 선택하세요' guideText={`최적크기 328*246`}
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                addImgItem(e.target.files[0]);
                            }
                        }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`}>공개설정</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='publicFlag' value="공개" checked={publicFlag === 1 && true} onChange={(e) => setPublicFlag(1)}>공개</Radio>
                    <Radio name='publicFlag' value="비공개" checked={publicFlag === 0 && true} onChange={(e) => setPublicFlag(0)}>비공개</Radio>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`}>포스트 설정</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='devFlag' value="service" checked={postTp === "3"} onChange={(e) => setPostTp("3")}>service</Radio>
                    <Radio name='devFlag' value="dev2" checked={postTp === "9"} onChange={(e) => setPostTp("9")}>dev2</Radio>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`}>예약등록</Label></div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '180px' }}>
                        <OneDayCalendarPush setDate={setReservDate} splashDate={reservDate} />
                    </div>
                    <input
                        value={fromTime}
                        type="time"
                        min="00:00"
                        max="23:59"
                        style={{
                            width: "150px",
                            padding: "12px",
                            height: "19px",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                        onChange={(e) => {
                            setFromTime(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        (<span style={{ color: '#ED5255' }}>{newsList ? newsList.length : 0}</span>개)
                    </div>
                    <div>
                        <Button name='추가' sType='sm' onClick={(e) => setSearchOpen(true)} />&nbsp;
                        <Button name='삭제' cType='b_line' sType='sm' onClick={handleDeleteClick} />
                        <News open={searchOpen} close={(e) => setSearchOpen(false)} onAdd={onAdd} />
                    </div>
                </div>
                <div style={{ marginTop: '8px' }}>
                    {(newsList && newsList.length > 0) &&
                        <React.Fragment>
                            <TableHeader>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}><Check /></div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}>순서</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 아이디</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>이미지</div>
                                <div style={{ display: 'flex', width: '750px', justifyContent: 'center' }}>제목</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스타입</div>
                            </TableHeader>
                            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                <SortableList useDragHandle onSortEnd={onSortEnd}>
                                    {newsList.map((item, index) => {
                                        return (
                                            <SortableItem index={index} key={`item-${index}`} item={item} num={index} />
                                        )
                                    })
                                    }
                                </SortableList>
                            </div>

                        </React.Fragment>
                    }
                </div>
            </div>
            {isRegistModal &&
                <div className='IssueRegist_Modal_Container'>
                    <div className='IssueRegist_Modal'>
                        <div onClick={IssueRegistModalhandleClose} className="IssueRegist_Modal_close">확인</div>
                        <IssueRegistModal />
                    </div>
                </div>
            }
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}

export default RegistBody;