import { createSlice } from '@reduxjs/toolkit';

export const newsBriefingSlice = createSlice({
	name: 'newsBriefing',
	initialState: { value: { newsBriefingInsert: false, insertFlag: false } },
	reducers: {
		NewsBriefingInsert: (state, action) => {
			state.value.newsBriefingInsert = action.payload;
		},
		Flag: (state, action) => {
			state.value.insertFlag = action.payload;
		},
	},
});
export const { NewsBriefingInsert, Flag } = newsBriefingSlice.actions;
export default newsBriefingSlice.reducer;
