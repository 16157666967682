import { useEffect, useState } from "react";
import styled from "styled-components";

const TextFieldC = styled.div`
    display:flex;
    align-items: center;
    width:100%;
    box-sizing: border-box;
    height:42px;
    margin-top:8px;
`;

const InputC = styled.input`
    box-sizing: border-box;
    border:none;
    height:100%;
    width:100%;
    padding:0px 12px;
    padding-right:${(props) => (props.orgType === 'password') ? '36px' : '12px'};
    border-radius: 4px;
    font-size: ${(props) => props.textShowMode ? '14px' : '24px'};
    color: ${(props) => !(props.validText && props.validText.length > 0) ? '#9B9C9F' : '#ED5255'};
    background: ${(props) => !props.disabled ? '#ffffff' : '#f0f0f0'};
    border:1px solid ${(props) => !(props.validText && props.validText.length > 0) ? 'rgba(0, 0, 0, 0.1)' : '#ED5255'};
    transition: all 0.15s ease-in;
    &:hover{
        border:1px solid ${(props) => !(props.validText && props.validText.length > 0) ? (!props.disabled ? '#c0c0c0;' : 'rgba(0, 0, 0, 0.1)') : '#ED5255'};
    };
    &:focus{
        outline:none;
        color: ${(props) => !(props.validText && props.validText.length > 0) ? '#000000' : '#ED5255'};
        border:1px solid ${(props) => !(props.validText && props.validText.length > 0) ? '#646464;' : '#ED5255'};
    };
    &::placeholder{
        color: #9B9C9F;
        font-size: 14px;
    }
`;
const GuideC = styled.div`
    height:14px;
    color:#9B9C9F;
    font-size:10px
`;

const ValidC = styled.div`
    height:14px;
    color:#ED5255;
    font-size:10px
`;

const PasswordIconC = styled.div`
    width:20px;
    display: flex;
    justify-content:center;
    align-items: center;
`;


const TextField = ({ type, width, value, placeholder, disabled, onChange, onKeydown, guideText, validText }) => {
    const [textShowMode, setTextShowMode] = useState(true);

    useEffect(() => {
        type === 'password' && setTextShowMode(false)
    }, [type, value, placeholder, disabled, onChange, onKeydown, guideText, validText])
    return (
        <div style={{ display: 'inline-block', maxHeight: '58px', width: (width && width !== '') ? width : '200px' }}>
            <TextFieldC validText={validText} disabled={disabled} >
                <InputC
                    orgType={type}
                    type={!textShowMode ? 'password' : 'text'}
                    textShowMode={textShowMode}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    onKeyDown={onKeydown}
                    guideText={guideText}
                    validText={validText}
                />
                {(type && type === 'password') &&
                    <PasswordIconC>
                        <img src={`/images/common/ic_password_${(!textShowMode) ? 'no_' : ''}show.png`} alt='password Icon' style={{ width: '20px' }} onClick={(e) => setTextShowMode(!textShowMode)} />
                    </PasswordIconC>
                }
            </TextFieldC>
            {(!validText && validText !== '') ?
                <GuideC>
                    {guideText}
                </GuideC>
                :
                <ValidC>
                    {validText}
                </ValidC>
            }
        </div>
    )
}

export default TextField;