import styled from 'styled-components';
import React from "react";

const LeftMenuLayout = styled.div`
    width : 254px;
    min-width: 254px;
    height : calc(100% - 80px);
    background : #2B2626;
    color : #ffffff;
    display: flex;
    flex-direction : column;
    align-items : center;
`;

const LeftMenuTemplate = ({ userTag, menu }) => {

    return (
        <LeftMenuLayout>
            {userTag && userTag}
            {menu && menu}
        </LeftMenuLayout>
    )
}

export default LeftMenuTemplate;