import { useEffect, useState } from "react";
import Body from "../../components/member/Body";
import Title from "../../components/member/Title";
import MemberUpdate from "../../templates/member/MemberUpdate";

const MemberUpdateContainer = ({ page }) => {

    const [title, setTitle] = useState();
    const [body, setBody] = useState();


    useEffect(() => {
        setTitle(<Title />);
        setBody(<Body />);
    }, [page])

    return (
        <MemberUpdate
            title={title}
            body={body}
        />
    )
}

export default MemberUpdateContainer;