import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../style/layout";
import Button from "../../common/Button";


const Title = () => {
    const navigate = useNavigate();
    return (
        <BodyTitle>
            <BodyTitleFont>In News 설정 관리</BodyTitleFont>
            <Button name={`In News 설정 등록`} width={'160px'} onClick={(e) => navigate('/operation/inNewsList/add')} />
        </BodyTitle>
    )
}

export default Title;