import MainTemplate from "../../templates/MainTemplate";
import MenuContainer from "../menu/MenuContainer";
import Header from "../../common/main/Header";
import { useNavigate, useLocation  } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getUserInfo } from "../../api/login/login";
import { getMenusInfo } from "../../api/menu/menu";


const MainContainer = ({ navText, targetComp }) => {
    const mode = 'admin';
    const navigate = useNavigate();
    const location = useLocation();
    const [userInfo, setUserInfo] = useState();
    let urlData = false;

    useEffect(() => {
        const userInfo = localStorage.getItem('ROZ_ADMIN_USERINFO1');

        const menuInfo = localStorage.getItem('menu');
        if (userInfo && userInfo !== null) {
                getUserInfo()
                    .then((response) => {
                        setUserInfo(response);
                    })
        } else {
                navigate('/');
                return;
        }
        if(menuInfo===null || menuInfo === undefined){
            const data = { "userId": JSON.parse(userInfo).loginId };
            getMenusInfo(data)
            .then((response) => {
                localStorage.setItem('menu' , JSON.stringify(response.data.result));
            })
        }else{
            const noAuthMenuRegList = [                
                /^\/member\/update.*/,  // 계정 설정 (회원정보 변경)
            ]

            const addr = location.pathname;
            if(noAuthMenuRegList.some(reg => reg.test(addr))) {
                console.info('pass by reg')
                return
            }

            let menuInfoZ = localStorage.getItem('menu');
            let menuZ = JSON.parse(menuInfoZ);
            for(let i = 0; i<menuZ.length ; i++ ){
                if(addr.indexOf(menuZ[i])===0){
                    urlData = true; //eslint-disable-line
                }
            }

            if(urlData===false){
                if(menuInfoZ.length === 0) {
                    navigate('/')
                }
                navigate('/dashboard')
            }
        }
    }, [navigate]);

    return (
        <div>
            {userInfo !==null &&
                <MainTemplate
                header={userInfo && <Header navInfo={navText} userInfo={userInfo} />}
                leftMenu={<MenuContainer mode={mode} userInfo={userInfo} />}
                rightBody={targetComp}
                />
            }
        </div>

    )
}

export default MainContainer;