import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme, { NonBorderHeader, PageStyle } from '../../cms/theme';
import GreyLine from '../../../common/GreyLine';
import { useNavigate } from 'react-router';
import { EditOpenGraphModalFlag } from '../../../slice/modalSlice';
import { useDispatch } from 'react-redux';

const Layout = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 10;
    background: rgb(0,0,0,0.85);
    overflow-y: auto;
`;

const SearchInput = styled.input`
    position: relative;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #0000001a;
    padding: 0 0 0 12px;
    ${(props) => props.theme.fontSize.body2};
    -webkit-appearance: none;
    border-radius: 4px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #9b9c9f;
    }
`;
const Margin = styled.div`
    height: ${(props) => props.height};
`;
const EditOpenGraph = ({ editorRef }) => {
    const defaultMsg = 'URL 링크를 입력해주세요.';
    const errorMsg = '잘못된 URL 형식입니다.';
    const emptyMsg = 'URL 링크를 입력해주세요.';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const [message, setMessage] = useState(defaultMsg);
    const [isError, setIsError] = useState(false);

    const goBack = () => {
        const navi = navigator.userAgent;
        if (navi.indexOf('Android') >= 0 || navi.indexOf('iPhone') >= 0 || navi.indexOf('iPad') >= 0) {
            navigate(`/cms/postInsert`);
        }
        navigate(`/cms/postInsert`);
    }
    const onChanged = (e) => {
        setMessage(defaultMsg);
        setIsError(false);
        setUrl(e.target.value);
        if (e.key === 'Enter') searchClick();
    };
    const searchClick = () => {
        const regex = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);//eslint-disable-line
        if (regex.test(url)) {
            setMessage('');
            setIsError(false);
            editorRef.current.contentWindow.postMessage({
                type: 'SET',
                param: {
                    type: 'openGraph',
                    data: { url: url },
                },
            }, '*');
            dispatch(EditOpenGraphModalFlag(false));
        }
        else if (url !== '') {
            setMessage(errorMsg);
            setIsError(true);
        }
        else {
            setMessage(emptyMsg);
            setIsError(true);
        }
    };
    useEffect(() => {
        if (url === '') return;
        setTimeout(() => {
            goBack();
        }, 400);
    }, [editorRef, navigate]);// eslint-disable-line react-hooks/exhaustive-deps

    const close = (e) => {
        dispatch(EditOpenGraphModalFlag(false))
    }

    return (
        <Layout>
            <ThemeProvider theme={theme}>
                <PageStyle>
                    <NonBorderHeader style={{ gridTemplateColumns: 'none', background: 'none' }}>
                        <div style={{ margin: '16px', width: '100%', display: 'flex' }}>
                            <span style={{ marginRight: 'auto', fontSize: '16px', letterSpacing: '-0.64px', fontFamily: 'Noto Sans KR', color: '#F9F9F9' }}>URL 링크 첨부</span>
                            <button className={'back_btn'} style={{ width: 'unset', marginLeft: 'auto', background: 'none' }}>
                                <img
                                    onClick={close}
                                    src="/images/icon/ic_closed_white.png"
                                    alt="1234"
                                />
                            </button>
                        </div>
                    </NonBorderHeader>
                </PageStyle>
                <PageStyle style={{ marginTop: '56px' }} >
                    <SearchInput
                        style={{
                            background: 'none',
                            borderBottom: isError ? '1px solid #EE3535' : '1px solid white',
                            color: 'white', padding: '0px', borderRadius: '0px', height: '48px', fontSize: '14px', fontFamily: 'Noto Sans KR'
                        }}
                        placeholder={'http://'}
                        value={url}
                        onChange={onChanged}
                        onKeyPress={onChanged}
                    />
                    <Margin height={'14px'} />
                    <span style={{
                        color: isError ? '#EE3535' : 'white',
                        opacity: isError ? '1' : '0.6',
                        letterSpacing: '-0.24px',
                        fontSize: '12px',
                        fontFamily: 'Noto Sans KR',
                    }}>{message}</span>
                    <Margin height={'8px'} />
                    <Margin height={'90px'} />
                    <div style={{ background: 'white', height: '48px', width: '100%', display: 'flex', borderRadius: '5px' }} onClick={searchClick}>
                        <div style={{ margin: 'auto', display: 'block' }}>
                            <span style={{ fontSize: '16px', fontFamily: 'Noto Sans KR' }}><b>확인</b></span>
                        </div>
                    </div>
                </PageStyle>
                <GreyLine height={'2px'} />
                <Margin height={'8px'} />
            </ThemeProvider>
        </Layout>
    );
};

export default EditOpenGraph;
