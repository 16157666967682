import { instance, uploadinstance } from "../index";

export const getEnterList = async (data) => {
    const url = `/api/v1/enter/list`
    const response = await instance.get(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}


export const putEnterDelete = async (data) => {
    const url = `/api/v1/enter/delete`
    const response = await instance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const putEnter = async (data) => {
    const url = `/api/v1/enter/update`
    const response = await instance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const postEnter = async (data) => {
    const url = `/api/v1/enter/insert`
    const response = await instance.post(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}


export const putEnterCountUpdate = async (data) => {
    const url = `/service/api/v1/enter/info/count`
    const response = await uploadinstance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}