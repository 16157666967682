import { useEffect, useState } from "react";
import Title from "../../components/banner/Title";
import Body from "../../components/banner/Body";
import Banner from "../../templates/banner/Banner";
import RegistBody from "../../components/banner/regist/RegistBody";
import RegistTitle from "../../components/banner/regist/RegistTitle";
import ViewBody from "../../components/banner/view/ViewBody";
import ViewTitle from "../../components/banner/view/ViewTitle";
import { useParams } from "react-router-dom";

const BannerContainer = ({ page }) => {

    const { bSeq } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={bSeq && bSeq !== "" ? "modify" : "add"} bSeq={bSeq} />);
            setBody(<RegistBody mode={bSeq && bSeq !== "" ? "modify" : "add"} bSeq={bSeq} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle bSeq={bSeq} />);
            setBody(<ViewBody bSeq={bSeq} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, bSeq]);

    return (
        <Banner title={title} body={body} />
    );
};

export default BannerContainer;