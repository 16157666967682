import { configureStore } from '@reduxjs/toolkit';
import loginSlice from '../slice/loginSlice';
import modalSlice from '../slice/modalSlice';
import memberSlice from '../slice/memberSlice';
import noticeSlice from '../slice/noticeSlice';
import splashIntroSlice from '../slice/splashIntroSlice';
import pushSlice from '../slice/pushSlice';
import inNewsSlice from '../slice/inNewsSlice';
import inNewsListSlice from '../slice/inNewsListSlice'
import bannerListSlice from '../slice/bannerListSlice'
import bannerSlice from '../slice/bannerSlice'
import cmsPostSlice from '../slice/cmsPostSlice'
import newsBriefingSlice from '../slice/newsBriefingSlice'
import issueSlice from '../slice/issueSlice'
import novelSlice from '../slice/novelSlice'
import novelManagementSlice from '../slice/novelManagementSlice'
import termsSlice from '../slice/termsSlice';
import enterSlice from '../slice/enterSlice';
import serviceMemberSlice from '../slice/serviceMemberSlice';
export const store = configureStore({
	reducer: {
		login: loginSlice,
		modal: modalSlice,
		member: memberSlice,
		notice: noticeSlice,
		splash: splashIntroSlice,
		push: pushSlice,
		inNews: inNewsSlice,
		inNewsList: inNewsListSlice,
		bannerList: bannerListSlice,
		banner: bannerSlice,
		cmspost: cmsPostSlice,
		newsBriefing: newsBriefingSlice,
		issue: issueSlice,
		novel: novelSlice,
		novelManagement: novelManagementSlice,
		terms: termsSlice,
		enter:enterSlice,
		serviceMember:serviceMemberSlice
	},
});
