import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../../components/cms/post/Body";
import RegistBody from "../../../components/cms/post/regist/RegistBody";
import RegistTitle from "../../../components/cms/post/regist/RegistTitle";
import Title from "../../../components/cms/post/Title";
import ViewBody from "../../../components/cms/post/view/ViewBody";
import ViewTitle from "../../../components/cms/post/view/ViewTitle";
import PostControl from "../../../templates/cms/post/PostControl";

const PostControlContainer = ({ page }) => {

    const { pid, rid } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={pid && pid !== "" ? "modify" : "add"} pid={pid} rid={rid} />);
            setBody(<RegistBody mode={pid && pid !== "" ? "modify" : "add"} pid={pid} rid={rid} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle pid={pid} rid={rid} />);
            setBody(<ViewBody pid={pid} rid={rid} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }

    }, [page, pid, rid])

    return (
        <PostControl
            title={title}
            body={body}
        />
    )
}

export default PostControlContainer;