import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import { NewsBriefingInsert } from '../../../slice/newsBriefingSlice';
import Button from "../../../common/Button";
import { useDispatch } from 'react-redux';

const RegistTitle = ({ mode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleRegist = () => {
        dispatch(NewsBriefingInsert(true));
    }

    return (
        <BodyTitle>
            <BodyTitleFont>뉴스브리핑 {mode === 'add' ? '등록' : '수정'}</BodyTitleFont>
            <div>
                <Button name={mode === "add" ? "등록" : "수정"} onClick={handleRegist} />&nbsp;
                <Button cType={`cancel`} width={`150px`} name={`목록으로 이동`} onClick={(e) => navigate('/operation/newsBriefing')} />
            </div>
        </BodyTitle>
    )
}

export default RegistTitle;