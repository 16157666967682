import { GlobalStyle, MainLayout } from "../style/layout";
import styled from 'styled-components';
import React from "react";

const Main = styled.div`
    ${MainLayout}
    display : flex;
    position : relative;
    top:86px;
    height: calc(100% - 86px);
`;

const MainTemplate = ({ header, leftMenu, rightBody }) => {
    return (
        <React.Fragment>
            <GlobalStyle />
            <div style={{ position: 'fixed', width: '100%', top: 0, zIndex: 1000 }}>
                {header}
            </div>
            <Main>
                <div style={{ position: 'fixed', width: '254px', height: '100%' }}>
                    {leftMenu}
                </div>
                <div style={{ marginLeft: '254px', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '40px' }} />
                        <div style={{ width: '100%', minWidth: '1104px', maxWidth: '1586px', marginTop: '37px' }}>
                            {rightBody}
                        </div>
                        <div style={{ width: '40px' }} />
                    </div>
                </div>
            </Main>
        </React.Fragment>
    )
}

export default MainTemplate;