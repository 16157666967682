import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Form from 'react-bootstrap/Form';
import Tabs from "../../common/Tabs";
import { getSplashList } from "../../api/splash/splash";
import Paging from "../../common/Paging";


const Body = () => {

    const tabList = ['전체', '디폴트', '시즌'];
    const navigate = useNavigate();

    const [size, setSize] = useState(10);
    const [pagesInfo, setPagesInfo] = useState();
    const [page_no, setPage_no] = useState(1);
    const [postList, setPostList] = useState();
    const [categoryCd, setCategoryCd] = useState();
    const [realPage_no, setRealPage_no] = useState(1);

    const handleNewsTabClick = (e, i) => {
        if (i === 1) {
            setRealPage_no(1);
            setPage_no(1);
            setCategoryCd('DEFAULT');
        } else if (i === 2) {
            setPage_no(1);
            setRealPage_no(1);;
            setCategoryCd('SEASON');
        } else {
            setPage_no(1);
            setRealPage_no(1);
            setCategoryCd(null);
        }
    }

    useEffect(() => {
        getSplashList(page_no, categoryCd, size)
            .then((response) => {
                setPagesInfo(response.data.pages);
                setPostList(response.data.results);
            })
    }, [page_no, categoryCd])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPage_no(1);
        getSplashList(1, categoryCd, size)
            .then((response) => {
                setPagesInfo(response.data.pages);
                setPostList(response.data.results);
            })
    }, [size])// eslint-disable-line react-hooks/exhaustive-deps

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }


    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {(pagesInfo && (pagesInfo.total_rows > 0)) &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{pagesInfo.total_rows}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/**<Form.Select className="selectBox">
                                <option value={1}>제목</option>
                                <option value={2}>내용</option>
                            </Form.Select>&nbsp; **/}
                        <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                    </div>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Tabs items={tabList} onClick={handleNewsTabClick} />
                {(postList && postList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>인트로 아이디</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>구분</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>상태</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>적용 시작일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>적용 종료일</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }}>파일 수</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>작성자 계정</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>등록일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>수정일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {postList.map((item, index) => {
                                return (
                                    <TableRow key={index} onClick={(e) => navigate(`/operation/splashIntro/view/${item.itoId}`)}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>{item.itoId}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>{item.categoryNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>값이없음</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.startDt.replace("T", " ")}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.fromDt.replace("T", " ")}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '140px' }}>{item.fileCount}</div>
                                        <div style={{ display: 'inline-block', justifyContent: 'center', width: '120px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.userId}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.regDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>{item.modDt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={pagesInfo.total_rows} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>

        </React.Fragment>
    )
}

export default Body;