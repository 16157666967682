import { uploadinstance, instance } from "..";


export const getList = async (data) => {
    const url = `/adminpost`
    const response = await instance.get(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getRoomList = async (data) => {
    const url = `/adminpost/roomList?uid=${data}`
    const response = await instance.get(
        url,
        null,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getRoomFoldIdList = async (data) => {
    const url = `/adminpost/roomFoldIdList?rid=${data}`
    const response = await instance.get(
        url,
        null,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const cmsPostInsert = async (post) => {
    const url = `/newsroom/api/v1/post`;
    const response = await uploadinstance.post(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const cmsPostUpdate = async (data) => {
    const url = `/newsroom/api/v1/post`;
    const response = await uploadinstance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getPostDetailList = async (pid, rid) => {
    const url = `/newsroom/api/v1/post/view/${pid}/${rid}`
    const response = await uploadinstance.get(
        url,
        null,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        })
    return response;
}

export const getPostDeleteList = async (pid) => {
    const url = `/newsroom/api/v1/post/del/`
    const response = await uploadinstance.put(
        url,
        pid,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        })
    return response;
}

export const getPostNewsRoomList = async (rid) => {
    const url = `/newsroom/api/v1/post/newsroom-list?rid=${rid}`
    const response = await uploadinstance.get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getPostreplyList = async (pid, rid) => {
    const url = `/newsroom/api/v1/reply/list/${pid}/${rid}/?orderType=3&orderSort=2&page_no=1&size=3`
    const response = await uploadinstance.get(url, null,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        })
    return response;
}



