import React from 'react';

const UserTag = ({ userInfo }) => {

    return (
        <React.Fragment>
            <div style={{ height: '16px' }} />
            <div style={{ display: 'flex' }}>
                &lt; <div>잼잼<br />{userInfo.flowCnt}</div> &gt;
            </div>
            <div style={{ height: '53px' }} />
        </React.Fragment>
    )
}

export default UserTag;