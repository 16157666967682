import { instance } from '../index';

export const novetInsert = async (data) => {
    const url = `/novel`;
    const response = await instance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
};


export const putNovel = async (data) => {
    const url = `/novel/setInfo`;
    const response = await instance.put(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
};

export const novetUpdate = async (data) => {
    const url = `/novel`;
    const response = await instance.post(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
};

export const getNovel = async (data) => {

    let url = `/novel`;
    const response = await instance
        .get(url, data, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getDetailNovel = async (data) => {
    const url = `/novel?nkey=${data}`;
    const response = await instance
        .get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
};


export const deleteNovel = async (data) => {
    const url = '/novel';
    const response = await instance.delete(
        url,
        data,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const getNovelDetail = async (data) => {

    let url = `/novel/detail?nkey=${data}`;
    const response = await instance
        .get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
}
