import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/authority/Body";
import RegistBody from "../../components/authority/regist/RegistBody";
import RegistTitle from "../../components/authority/regist/RegistTitle";
import Title from "../../components/authority/Title";
import ViewBody from "../../components/authority/view/ViewBody";
import ViewTitle from "../../components/authority/view/ViewTitle";
import Authority from "../../templates/authority/Authority";

const AuthorityContainer = ({ page }) => {

    const { uid } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={uid && uid !== "" ? "modify" : "add"} uid={uid} />);
            setBody(<RegistBody mode={uid && uid !== "" ? "modify" : "add"} uid={uid} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle uid={uid} />);
            setBody(<ViewBody uid={uid} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, uid]);

    return (
        <Authority
            title={title}
            body={body}
        />
    )
}

export default AuthorityContainer;