import styled, { css } from "styled-components";

const LabelC = styled.div`
    height:20px;
    font-size: 14px;
    ${(props) => props.type === 'title' && title}
`;

const title = css`
    font-size: 16px;
    font-weight: bold;
`;

const Label = (props) => {
    const { type, required } = props
    return (
        <div style={{ display: 'inline-block' }}>
            <LabelC type={type}>
                {props.children}
                {required === true && <span style={{ height: '100%', color: '#ED5255' }}>*</span>}
            </LabelC>
        </div>
    )
}

export default Label;