import styled from "styled-components";
import { DetailTable } from "../../../style/layout";
import Label from "../../../common/Label";
import { useState, useEffect } from "react";
import React from "react";
import { getAnswerModify, getQnAInfoByqna_id, Secession, Recovery } from "../../../api/qna/qna";
import TextArea from "../../../common/TextArea";
import Button from "../../../common/Button";


const BasicInfoC = styled.div``;

const DetailInfoC = styled.div``;

const ViewBody = ({ qna_id, QAList }) => {

    const userInfo = JSON.parse(localStorage.getItem("ROZ_ADMIN_USERINFO1"));
    const [editing, setEditing] = useState(false);
    const [qnaDetail, setQnaDetail] = useState();
    const [answer, setAnswer] = useState();
    const [refresh, setRefresh] = useState(false);
    const [withdrawal, setWithdrawal] = useState();

    const qnadelete = (e) => {
        if (window.confirm("탈퇴 신청 하겠습니까?")) {
            if (e.reg_id === 0) {
                return;
            } else {
                Secession(e.reg_id)
                    .then((response) => {
                        if (response.data.data[0].responseFg === 1 || response.data.data[0].responseFg === "1") {
                            alert("탈퇴신청이 처리되었습니다");
                            setWithdrawal(1);
                        } else if (response.data.data[0].responseFg === 0 || response.data.data[0].responseFg === "0") {
                            alert("탈퇴신청이 실패되었습니다");
                        }
                    })
            }
        } else {
        }
    }

    const qnaRecover = (e) => {
        if (window.confirm("탈퇴 복구 신청 하겠습니까?")) {
            if (e.reg_id === 0) {
                return;
            } else {
                Recovery(e.reg_id)
                    .then((response) => {
                        if (response.data.data[0].responseFg === 3 || response.data.data[0].responseFg === "3") {
                            alert("탈퇴 복구 성공");
                            setWithdrawal(0);
                        } else if (response.data.data[0].responseFg === 2 || response.data.data[0].responseFg === "2") {
                            alert("탈퇴 복구 실패");
                        }
                    }).catch(err => {
                        return null;
                    });
            }
        } else {
        }
    }

    const putAnswer = () => {
        let post = {
            qna_id: qnaDetail.qna_id,
            qna_tp: qnaDetail.qna_tp,
            reg_id: qnaDetail.reg_id,
            is_use: qnaDetail.is_use ? 1 : 0,
            title: qnaDetail.title,
            question: qnaDetail.question,
            ans_id: userInfo.uid,
            answer: answer,
        };
        getAnswerModify(post).then((response) => {
            setRefresh(true);
        });
    };

    useEffect(() => {
        if (qna_id && qna_id.length > 0) {
            getQnAInfoByqna_id(qna_id).then((response) => {
                setWithdrawal(response.data.withdrawal_user);
                setQnaDetail(response.data);
                if (response.data.ans_id) {
                    setEditing(false);
                    setAnswer(response.data.answer);
                } else {
                    setEditing(true);
                }
            });
        }
    }, [qna_id, refresh, QAList]);

    return (
        <React.Fragment>
            <div style={{ height: "50px" }} />
            {qnaDetail && (
                <BasicInfoC>
                    <Label type="title">QnA정보</Label>
                    <div style={{ height: "20px" }} />
                    <DetailTable style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <th style={{ width: "10%" }}>QnA 아이디</th>
                                <td style={{ width: "20%" }}>{qnaDetail.qna_id}</td>
                                <th style={{ width: "10%" }}>QnA 유형</th>
                                <td style={{ width: "20%" }}>{QAList[qnaDetail.qna_tp - 1]}</td>
                                <th style={{ width: "10%" }}>QnA 유저</th>
                                <td style={{ width: "20%" }}>{qnaDetail.user_id}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "10%" }}>QnA 날짜</th>
                                <td style={{ width: "20%" }}>{qnaDetail.reg_dt}</td>
                                <th style={{ width: "10%" }}>답변여부</th>
                                <td
                                    style={{
                                        width: "20%",
                                        color: qnaDetail.ans_id ? "#ED5255" : "#000000",
                                    }}
                                >
                                    {qnaDetail.ans_id ? "답변" : "미답변"}
                                </td>
                                <th style={{ width: "10%" }}>답변일</th>
                                <td style={{ width: "20%" }}>{qnaDetail.ans_dt}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "10%" }}>QnA 제목</th>
                                <td style={{ width: "80%", padding: "12px" }} colSpan={5}>{qnaDetail.title}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "10%" }}>QnA 내용</th>
                                <td style={{ width: "80%", padding: "12px" }} colSpan={5}>{qnaDetail.question}</td>
                            </tr>
                        </tbody>
                    </DetailTable>
                </BasicInfoC>
            )}
            <div style={{ height: "30px" }} />
            <DetailInfoC>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                    <Label type="title">답변내용</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                        {withdrawal === '0' &&
                            <Button width="120px" height="42px" name={"회원 탈퇴"} onClick={(e) => { qnadelete(qnaDetail) }} />
                        }
                        {withdrawal === '1' &&
                            <Button width="120px" height="42px" name={"탈퇴 복구"} onClick={(e) => { qnaRecover(qnaDetail) }} />
                        }
                        <div style={{ width: '20px' }}></div>
                        <Button width="120px" height="42px"
                            cType={qnaDetail && !qnaDetail.ans_id ? "default" : editing ? "default" : "line"}
                            name={qnaDetail && !qnaDetail.ans_id ? "등록" : editing ? "등록" : "수정"}
                            onClick={(e) => { qnaDetail && !qnaDetail.ans_id ? putAnswer() : editing ? putAnswer() : setEditing(true) }}
                        />
                        <div style={{ width: "5px" }} />
                        {!(qnaDetail && !qnaDetail.ans_id) && editing && (
                            <Button width="120px" height="42px" cType={`cancel`} name={`취소`} onClick={(e) => { setEditing(false) }} />
                        )}
                    </div>
                </div>
                <div style={{ height: "30px" }} />
                {editing ? (
                    <div>
                        <TextArea value={answer && answer} width={"100vh"} height={"300px"} onChange={(e) => { setAnswer(e.target.value) }} />
                    </div>
                ) : (
                    <div style={{ width: "100%", height: "375px", border: "1px solid #E9E9E9", padding: "5px", overflow: "scroll" }}>
                        {answer && answer}
                    </div>
                )}
                <div style={{ height: "10px" }} />
            </DetailInfoC>
        </React.Fragment>
    );
};

export default ViewBody;
