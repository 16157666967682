import styled from 'styled-components';

const colors = {
    default: {
        background: '#ED5255',
        border: '0px',
        color: '#ffffff',
        '&:hover': {
            background: '#D73336'
        },
        '&:active': {
            background: '#B82528'
        }
    },
    cancel: {
        background: '#E9E9E9',
        border: '0px',
        color: '#000000',
        '&:hover': {
            background: '#DADADA'
        },
        '&:active': {
            background: '#CACACA'
        }
    },
    disable: {
        background: '#EEEEEE',
        border: '0px',
        color: '#A7A7A7',
    },
    dark: {
        background: '#585858',
        border: '0px',
        color: '#ffffff',
        '&:hover': {
            background: '#434343'
        },
        '&:active': {
            background: '#292929'
        }
    },
    line: {
        background: '#ffffff',
        border: '1px solid #D73336',
        color: '#ED5255',
        '&:hover': {
            background: '#FFEFEF',
            border: '1px solid #CF484A',
            color: '#D73336',
        },
        '&:active': {
            background: '#FFC8C8',
            border: '1px solid #B82528',
            color: '#B82528',
        }
    },
    b_line: {
        background: '#ffffff',
        border: '1px solid #E6E6E6',
        color: '#000000',
        '&:hover': {
            background: '#F9F9F9',
            border: '1px solid #C4C4C4',
        },
        '&:active': {
            background: '#EAEAEA',
            border: '1px solid #A3A3A3',
        }
    }
}

const sizes = {
    lg: {
        width: '150px',
        minWidth: '50px',
        height: '48px',
        fontSize: '16px',
        fontWeight: '400',
    },
    md: {
        width: '120px',
        minWidth: '50px',
        height: '42px',
        fontSize: '16px',
        fontWeight: '400',
    },
    sm: {
        width: '52px',
        minWidth: '52px',
        height: '28px',
        fontSize: '12px',
        fontWeight: '300',
    },
}

const ButtonC = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    font-family: Noto Sans KR;
    ${props => colors[props.color]};
    ${props => sizes[props.size]};
    ${props => (props.width && props.width !== '') && `width : ${props.width};`}
`;

const Button = ({ width, name, sType, cType, icon, onClick }) => {
    const showText = name && name !== '' ? name : 'Button';
    const size = sType && sType !== '' ? sType : 'md';
    const color = cType && cType !== '' ? cType : 'default';

    return (
        <div style={{ display: 'inline-block' }}>
            <ButtonC size={size} color={color} width={width}>
                <div style={{ width: '100%' }} onClick={(e) => cType !== 'disable' && (onClick && onClick())}>
                    {showText}
                </div>
            </ButtonC>
        </div>
    )
}

export default Button;