import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { NovelManagementInsert } from '../../../slice/novelManagementSlice';
import { useDispatch } from 'react-redux';

const RegistTitle = ({ mode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleRegist = () => {
        dispatch(NovelManagementInsert(true));
    };

    return (
        <BodyTitle>
            <BodyTitleFont>웹소설 작품 {mode === "add" ? "등록" : "수정"}</BodyTitleFont>
            <div>
                <Button
                    name={mode === "add" ? "등록" : "수정"}
                    onClick={handleRegist}
                />
                &nbsp;
                <Button
                    cType={`cancel`}
                    name={`취소`}
                    onClick={
                        (e) =>
                            navigate("/operation/novelManagement/")
                    }
                />
            </div>
        </BodyTitle>
    );
};

export default RegistTitle;