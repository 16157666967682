import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/enter/Body";
import RegistBody from "../../components/enter/regist/RegistBody";
import RegistTitle from "../../components/enter/regist/RegistTitle";
import Title from "../../components/enter/Title";
import ViewBody from "../../components/enter/view/ViewBody";
import ViewTitle from "../../components/enter/view/ViewTitle";
import Enter from "../../templates/enter/Enter";

const TermsContainer = ({ page }) => {

    const { enterId } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle enterId={enterId} mode={enterId && enterId !== "" ? "modify" : "add"} />);
            setBody(<RegistBody enterId={enterId} mode={enterId && enterId !== "" ? "modify" : "add"}/>);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle enterId={enterId} />);
            setBody(<ViewBody enterId={enterId} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, enterId])

    return (
        <Enter
            title={title}
            body={body}
        />
    )
}

export default TermsContainer;