import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../style/layout";
import Button from "../../common/Button";


const Title = () => {

    const navigate = useNavigate();

    return (
        <BodyTitle>
            <BodyTitleFont>뉴스 브리핑 관리</BodyTitleFont>
            <Button name={`뉴스브리핑 등록`} width={'160px'} onClick={(e) => navigate('/operation/newsBriefing/add')} />
        </BodyTitle>
    )
}

export default Title;