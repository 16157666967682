import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BodyTitle, BodyTitleFont } from "../../../style/layout";
import Button from "../../../common/Button";
import { useEffect, useState } from "react";
import { getDetailPush } from "../../../api/push/push";


const ViewTitle = () => {
	const navigate = useNavigate();

	const { pseq } = useParams();

	const [sendTp, setSendTp] = useState(0);

	useEffect(() => {
		const data = pseq * 1
		getDetailPush(data)
			.then((response) => {
				setSendTp(response.data.pushList[0].sendTp);
			})
	}, []);// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<BodyTitle>
			<BodyTitleFont>시스템 푸시 상세정보</BodyTitleFont>
			<div>
				{sendTp !== 0 &&
					<Button
						name={`수정`}
						onClick={(e) => {
							navigate(`/operation/push/update/${pseq}`)
						}}
					/>
				}
				&nbsp;
				<Button
					cType={`cancel`}
					name={`목록으로 이동`}
					width={'150px'}
					onClick={(e) => navigate(`/operation/push`)}
				/>
			</div>
		</BodyTitle>
	);
};

export default ViewTitle;
