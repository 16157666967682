import DatePicker from "react-datepicker";
import React, { useState, forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.css";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import moment from "moment";

const Month = [
	"January",
	"Februry",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const DatePickerHeader = (
	date,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
	decreaseMonth,
	increaseMonth
) => (
	<div
		style={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			flexDirection: "row",
			marginBottom: "4px",
		}}
	>
		<div>
			{Month[getMonth(date)]} {getYear(date)}
		</div>

		<div style={{ display: "flex", flexDirection: "row" }}>
			<div onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
				<img src="/images/component/ic_up_icon.png" alt={'이미지'} />
			</div>
			<div onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
				<img src="/images/component/ic_down_icon.png" alt={'이미지'} />
			</div>
		</div>
	</div>
);

const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
	return (
		<div
			style={{
				width: "200px",
				padding: "12px",
				border: "1px solid rgba(0, 0, 0, 0.1)",
				borderRadius: "4px",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			}}
			onClick={onClick}
			ref={ref}
		>
			<div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
			<img
				src={"/images/component/ic_calendar.png"}
				style={{ width: "16.67px", height: "15px" }}
				alt="calendar_icon"
			/>
		</div>
	);
});

function Calendar(props) {


	const [dateRange, setDateRange] = useState([
		props.startDt ? moment(props.startDt).toDate() : null,
		props.endDt ? moment(props.endDt).toDate() : null,
	]);
	const [startDate, endDate] = dateRange;

	const isWeekday = (date) => {
		const day = date.getDay();
		return day === 0; // only sunday check
	};
	return (
		<div>
			<DatePicker
				customInput={<DatePickerInput />}
				selectsRange={true}
				startDate={startDate}
				endDate={endDate}
				onChange={(update) => {
					if (update[0] != null) {
						props.setStartDt(update[0].toString());
					}
					if (update[1] != null) {
						props.setEndDt(update[1].toString());
					}

					setDateRange(update);
				}}
				dayClassName={
					(date) => (isWeekday(date) ? "sunday " : "") //day class add
				}
				dateFormat="yyyy-MM-dd"
				renderCustomHeader={({
					date,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled,
					decreaseMonth,
					increaseMonth,
				}) =>
					DatePickerHeader(
						date,
						prevMonthButtonDisabled,
						nextMonthButtonDisabled,
						decreaseMonth,
						increaseMonth
					)
				}
			/>
		</div>
	);
}

export default Calendar;
