import React, { useEffect, useState } from "react";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Button from "../../common/Button";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import TextField from "../../common/TextField";
import { bannerDelete, getBanner } from "../../api/banner/banner";
import { useSelector, useDispatch } from 'react-redux';
import { MessageModalFlag } from '../../slice/modalSlice';
import MessageModal from "../../modal/Message";

const Body = () => {

    const [bannerList, setBannerList] = useState();
    const navigate = useNavigate();
    const [size, setSize] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [totalCnt, setTotalCnt] = useState();
    const [deleteBseq, setDeleteBseq] = useState([]);
    const [message, setMessage] = useState('');
    const [msgTitle, setMsgTitle] = useState('');

    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const dispatch = useDispatch();

    const setting = () => {
        const data = { params: { "size": size, "offset": page_no } };
        getBanner(data)
            .then((response) => {
                setBannerList(response.data.data);
                setTotalCnt(response.data.total);
            }).catch(err => {
                return null;
            });
    }

    useEffect(() => {
        setRealPage_no(1);
        const data = { params: { "size": size, "offset": 1 } };
        getBanner(data)
            .then((response) => {
                setBannerList(response.data.data);
                setTotalCnt(response.data.total);
            }).catch(err => {
                return null;
            });
    }, [size]);

    useEffect(() => {
        const data = { params: { "size": size, "offset": page_no } };
        getBanner(data)
            .then((response) => {
                setBannerList(response.data.data);
                setTotalCnt(response.data.total);
            }).catch(err => {
                return null;
            });
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }

    const checkhandle = (e) => {
        const newArr = deleteBseq.slice();
        for (const index in deleteBseq) {
            if (`${deleteBseq[index].b_seq}` === e) {
                newArr.splice(index, 1);
                setDeleteBseq(newArr);
                return;
            }
        }
        newArr.push({
            b_seq: e,
        });
        setDeleteBseq(newArr);
    }

    const deletehandle = () => {

        const selector = JSON.stringify(deleteBseq);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"b_seq":', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll('"', '');
        const deleteData6 = "," + deleteData5;

        if (deleteData5 === "") {
            setMsgTitle("베너");
            setMessage("삭제할 Banner을 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData7 = { params: { "b_seq": deleteData6 } };
        bannerDelete(deleteData7)
            .then((response) => {
                if (response.status === 200) {
                    setMsgTitle("베너");
                    setMessage("삭제가 완료 되었습니다");
                    dispatch(MessageModalFlag(true));
                    const checkboxes = document.getElementsByName('selectCheckBox');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    })
                    setting();
                    return;
                } else {
                    setMsgTitle("베너");
                    setMessage("삭제 실패");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            })
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {totalCnt &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCnt}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField placeholder={`검색어를 입력해주세요`} />&nbsp;
                        <Form.Select className="selectBox" onChange={(e) => setSize(e.target.value)}>
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                    </div>
                </div>
                <div className='title'></div>
                <div className='component'>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(bannerList && bannerList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>베너 SEQ</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>배너 URL</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}>베너 링크</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}>베너 링크 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}>베너 이름</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}>베너 DESC</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {bannerList.map((item, index) => {
                                return (
                                    <TableRow key={index} style={{ height: '42px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }} onChange={(e) => checkhandle(item.b_seq)}><Check name='selectCheckBox' /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}
                                            onClick={(e) => navigate(`/operation/banner/view/${item.b_seq}`)}>{item.b_seq}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'left' }}
                                            onClick={(e) => navigate(`/operation/banner/view/${item.b_seq}`)}>{item.b_url}</div>
                                        <div style={{ justifyContent: 'center', width: '220px', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            onClick={(e) => navigate(`/operation/banner/view/${item.b_seq}`)}>{item.b_link}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}
                                            onClick={(e) => navigate(`/operation/banner/view/${item.b_seq}`)}>{item.b_link_type}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}
                                            onClick={(e) => navigate(`/operation/banner/view/${item.b_seq}`)}>{item.b_name}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '220px' }}
                                            onClick={(e) => navigate(`/operation/banner/view/${item.b_seq}`)}>{item.b_desc}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCnt} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
                {MModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="check" title={msgTitle} message={message} />
                        </div>
                    </div>
                }
                {LModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="loading" title={msgTitle} message={message} />
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;