import { BodyTitle, BodyTitleFont } from "../../style/layout";

const Title = () => {
  return (
    <BodyTitle>
      <BodyTitleFont>탈퇴 내역</BodyTitleFont>
    </BodyTitle>
  );
};

export default Title;
