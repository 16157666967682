import React from "react";

const Banner = ({ title, body }) => {
    return (
        <React.Fragment>
            {title}
            {body}
        </React.Fragment>
    )
}

export default Banner;