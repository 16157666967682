import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
import Button from "../../common/Button";
import TextField from "../../common/TextField";
import Form from 'react-bootstrap/Form';
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import { getNewsInNews, inNewsDelete } from "../../api/inNews/inNews";
import { MessageModalFlag } from '../../slice/modalSlice';
import { useSelector, useDispatch } from 'react-redux';
import MessageModal from "../../modal/Message";

const Body = () => {

    const navigate = useNavigate();
    const [size, setSize] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [inNewsList, setInNewsList] = useState();
    const [totalCnt, setTotalCnt] = useState();
    const [deleteListcid, setDeleteListcid] = useState([]);
    const [message, setMessage] = useState('');
    const [msgTitle, setMsgTitle] = useState('');
    const [realPage_no, setRealPage_no] = useState(1);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);

    const dispatch = useDispatch();

    useEffect(() => {
        const data = { params: { "size": size, "offset": page_no } };
        getNewsInNews(data)
            .then((response) => {
                setInNewsList(response.data.data);
                setTotalCnt(response.data.total);
            });
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPage_no(1);
        const data = { params: { "size": size, "offset": 1 } };
        getNewsInNews(data)
            .then((response) => {
                setInNewsList(response.data.data);
                setTotalCnt(response.data.total);
            });
    }, [size]);

    const setting = (e) => {
        const data = { params: { "size": size, "offset": page_no } };
        getNewsInNews(data)
            .then((response) => {
                setInNewsList(response.data.data);
                setTotalCnt(response.data.total);
            });
    }

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }

    const checkhandle = (e) => {
        const newArr = deleteListcid.slice();
        for (const index in deleteListcid) {
            if (`${deleteListcid[index].cid}` === e) {
                newArr.splice(index, 1);
                setDeleteListcid(newArr);
                return;
            }
        }
        newArr.push({
            cid: e,
        });
        setDeleteListcid(newArr);
    }

    const deletehandle = () => {

        const selector = JSON.stringify(deleteListcid);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"cid":', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll('"', '');
        const deleteData6 = "," + deleteData5;

        if (deleteData5 === "") {
            setMsgTitle("인뉴스");
            setMessage("삭제할 인뉴스를 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData7 = { params: { "cid": deleteData6 } };
        inNewsDelete(deleteData7)
            .then((response) => {
                if (response.status === 200) {
                    setMsgTitle("인뉴스");
                    setMessage("삭제 완료되었습니다");
                    dispatch(MessageModalFlag(true));
                    const checkboxes = document.getElementsByName('selectCheckBox');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    })
                    setting();
                    return;
                } else {
                    setMsgTitle("인뉴스");
                    setMessage("삭제 실패하였습니다");
                    dispatch(MessageModalFlag(true));
                    return;
                }
            })
    }

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {totalCnt &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCnt}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Select className="selectBox">
                            <option value={1}>제목</option>
                            <option value={2}>내용</option>
                            <option value={3}>제목+내용</option>
                        </Form.Select>&nbsp;
                        <TextField placeholder={`검색어를 입력해주세요`} />
                        <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        <Button cType='b_line' name='삭제' width='65px' onClick={deletehandle} />
                    </div>
                </div>
                <div className='title'></div>
                <div className='component'></div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(inNewsList && inNewsList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'left', width: '750px' }}>내용</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '180px' }}>검색키워드</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '130px' }}>배제어</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '130px' }}>검색기간</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '130px' }}>등록일시</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '130px' }}>수정일시</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {inNewsList.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }} onChange={(e) => checkhandle(item.cid)}><Check name='selectCheckBox' /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '200px' }} onClick={(e) => navigate(`/operation/inNews/view/${item.cid}`)}>{item.title}</div>
                                        <div style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', justifyContent: 'center', width: '750px' }} onClick={(e) => navigate(`/operation/inNews/view/${item.cid}`)}>{item.description}</div>
                                        <div style={{ display: 'flex', justifyContent: 'left', width: '180px' }} onClick={(e) => navigate(`/operation/inNews/view/${item.cid}`)}>{item.searchKeyword}</div>
                                        <div style={{ display: 'flex', justifyContent: 'left', width: '130px' }} onClick={(e) => navigate(`/operation/inNews/view/${item.cid}`)}>{item.exceptKeyword}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '130px' }} onClick={(e) => navigate(`/operation/inNews/view/${item.cid}`)}>{item.searchDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '130px' }} onClick={(e) => navigate(`/operation/inNews/view/${item.cid}`)}>{item.regDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '130px' }} onClick={(e) => navigate(`/operation/inNews/view/${item.cid}`)}>{item.modDt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCnt} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Body;