import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSecossionList } from "../../api/secossionList/secossionList";
import { SearchBar, TableHeader, TableRow } from "../../style/layout";
// import Button from "../../common/Button";
import Paging from "../../common/Paging";
// import TextField from "../../common/TextField";
import Form from 'react-bootstrap/Form';

const Body = () => {

    const navigate = useNavigate();

    const [size, setSize] = useState(10);
    const [pagesInfo, setPagesInfo] = useState();
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [secossionList, setSecossionList] = useState();

    useEffect(() => {
        const data = { params: { "size": size, "offset": page_no } };
        getSecossionList(data)
            .then((response) => {
                for (var i = 0; i < response.data.data.length; i++) {
                    const quedata = response.data.data[i].question.split('end');
                    response.data.data[i].questionData = quedata[0]
                    const equedata = response.data.data[i].userId.indexOf('@');
                    response.data.data[i].userIdNm = response.data.data[i].userId.replace(response.data.data[i].userId.substr((equedata * 1) - 3, 3), "***")
                }
                setSecossionList(response.data.data);
                setPagesInfo(response.data.total);
            })
    }, [page_no])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRealPage_no(1);
        const data = { params: { "size": size, "offset": 1 } };
        getSecossionList(data)
            .then((response) => {
                for (var i = 0; i < response.data.data.length; i++) {
                    const quedata = response.data.data[i].question.split('end');
                    response.data.data[i].questionData = quedata[0]
                    const equedata = response.data.data[i].userId.indexOf('@');
                    response.data.data[i].userIdNm = response.data.data[i].userId.replace(response.data.data[i].userId.substr((equedata * 1) - 3, 3), "***")
                }
                setSecossionList(response.data.data);
                setPagesInfo(response.data.total);
            })
    }, [size])


    const handlePageClick = (pageNum) => {
        setPage_no(pageNum);
        setRealPage_no(pageNum);
    }
    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {(pagesInfo && (pagesInfo > 0)) &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{pagesInfo}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Form.Select className="selectBox">
                            <option value={1}>제목</option>
                            <option value={2}>내용</option>
                        </Form.Select>&nbsp; */}
                        {/* <TextField placeholder={`검색어를 입력해주세요`} /> */}
                        <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                            <option value={10}>10개씩보기</option>
                            <option value={20}>20개씩보기</option>
                            <option value={30}>30개씩보기</option>
                        </Form.Select>&nbsp;
                        {/* <Button cType='b_line' name='삭제' width='65px' /> */}
                    </div>
                </div>
                <div className='title'></div>
                <div className='component'>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(secossionList && secossionList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>가입일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>탈퇴일</div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', }}>탈퇴 사유</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>대표룸이름</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>가입경로</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }}>사용자 아이디</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto' }}>
                            {secossionList.map((item, index) => {
                                return (
                                    <TableRow key={index} onClick={(e) => navigate(`/operation/secession/${item.regId}`)}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>{item.regDt}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>{item.secossionDt}</div>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'left' }}>{item.questionData}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>{item.roomNm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '150px' }}>{item.accessTp}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }}>{item.userIdNm}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={pagesInfo} pageSize={size} viewSize={realPage_no} onClick={handlePageClick} />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;