import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/InNews/Body";
import RegistBody from "../../components/InNews/regist/RegistBody";
import RegistTitle from "../../components/InNews/regist/RegistTitle";
import Title from "../../components/InNews/Title";
import ViewBody from "../../components/InNews/view/ViewBody";
import ViewTitle from "../../components/InNews/view/ViewTitle";
import InNews from "../../templates/inNews/InNews";

const InNewsContainer = ({ page }) => {

    const { cid } = useParams();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();


    useEffect(() => {
        if (page && page === 'add') {
            setTitle(<RegistTitle mode={cid && cid !== "" ? "modify" : "add"} cid={cid} />);
            setBody(<RegistBody mode={cid && cid !== "" ? "modify" : "add"} cid={cid} />);
        } else if (page && page === 'view') {
            setTitle(<ViewTitle cid={cid} />);
            setBody(<ViewBody cid={cid} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, cid]);

    return (
        <InNews
            title={title}
            body={body}
        />
    )
}

export default InNewsContainer;