import styled from 'styled-components';

const RadioWrapper = styled.div`
  	display: inline-block;
`;

const Mark = styled.span`
	display: inline-block;
	background: #ffffff;
	border: 2px solid #CBCBCB;
	width: 14px;
	height: 14px;
	left: 0;
	vertical-align: middle;
	&::after {
		content: "";
		display: block;
		background: url('/images/component/ic_checkbox.png');
		background-size : 12px auto;
		background-position: center;
		background-repeat : no-repeat;
		transition: all 110ms;
	}
`;

const Input = styled.input`
	position: absolute;
	visibility: hidden;
	display: none;
	&:checked + ${Mark} {
		border: 2px solid #ED5255;
		&::after {
		width: 100%;
		height: 100%;
		opacity: 1;
		}
	}
`;

const Label = styled.label`
	display: flex;
	cursor: pointer;
	padding: 0px 0px 0px 0;
	${props =>
		props.disabled &&
		`
			cursor: not-allowed;
			opacity: 0.4;
	`}
`;

const Check = ({ name, children, onChange }) => {

	const handleChange = (e) => {
		onChange && onChange(e);
	}

	return (
		<RadioWrapper>
			<Label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Input name={name} type="checkbox" onChange={handleChange} />
				<Mark />
				{children &&
					<span style={{ marginLeft: '7px' }}>{children}</span>
				}
			</Label>
		</RadioWrapper>
	)
};

export default Check;
