import { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../common/login/Button";
import TextField from "../common/login/TextField";
import { signIn } from "../api/login/login";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { MessageModalFlag } from '../slice/modalSlice';
import MessageModal from '../modal/Message';

const MainC = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:100%;
    height:100vh;
    background:url('/images/login/back_login.png');
    background-size:100% auto;
    background-repeat:no-repeat;
`;

const LoginHeader = styled.div`
    padding:30px;
`;

const LoginBody = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
`;

const LoginFooter = styled.div`
    padding-bottom:40px;
    text-align:center;
    color: #ffffff;
    font-size:12px;
`;

const LoginTemplate = () => {
    const [id, setId] = useState();
    const [pw, setPw] = useState();
    const navigate = useNavigate();
    const MModal = useSelector((state) => state.modal.value.Message);
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const handleKeyDown = (e) => {
        e.keyCode === 13 && handleLoginClick();
    }
    useEffect(() => {
        if (localStorage.getItem('loginInfo') === "unconnect") {
            setTitle("로그인 세션 만료");
            setMessage("다시 로그인 해주세요");
            dispatch(MessageModalFlag(true));
            localStorage.clear();
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleLoginClick = () => {
        signIn(id, pw)
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('ROZ_ADMIN_USERINFO1', JSON.stringify(response.data));
                    navigate('/dashboard')
                } else if (response.status === 400) {
                    setTitle("로그인 실패");
                    setMessage(JSON.stringify(response.data.message));
                    dispatch(MessageModalFlag(true));
                }
            })
    }

    return (
        <MainC>
            <LoginHeader>
                <img src={`/images/login/logo.png`} alt='LOGIN PAGE LOGO' style={{ width: '150px' }} />
            </LoginHeader>
            <LoginBody>
                <div style={{ fontSize: '30px', color: '#ffffff' }}>
                    로제우스가 로제우스 했다.<br />
                    뉴스는 로제우스
                </div>
                <div style={{ height: '50px' }} />
                <TextField placeholder={`아이디를 입력해주세요`} width='340px' onChange={(e) => setId(e.target.value)} />
                <TextField type='password' placeholder={`패스워드를 입력해주세요`} width='340px' onChange={(e) => setPw(e.target.value)} onKeydown={handleKeyDown} />
                <Button size='lg' width='340px' name='LOGIN' onClick={handleLoginClick} />
            </LoginBody>
            <LoginFooter >
                Copyrightⓒ Rozeus Corp. All rights reserved.
            </LoginFooter>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={title} message={message} />
                    </div>
                </div>
            }
        </MainC>
    )
}

export default LoginTemplate;