import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme, { PageStyle } from '../../cms/theme';
import { MoreList } from '../../../style/searchStyle';
import TimeGap from './timeGap';
import moment from 'moment';
import {
    checkLength,
    getTextLength,
    GridView,
    LeftImgBox,
    ListStyle,
} from '../../../style/leftImgLayout';
import RelationIcon from './RelationIcon';
import { getSearchNewsMore } from '../../../api/newsRoom/post';
import { getSearchNews } from '../../../api/newsRoom/post';
import NoneData from './NoneData';
import Thumnail from './Thumnail';
import { newsLinkAction } from './openNews';

export const Title = styled.div`
    height: 24px;
    ${(props) => props.theme.fontSize.subTitle3};
    margin: 16px 0 6px;
`;
export const Filter = styled.div`
    display: flex;
    height: 48px;
    padding: 0 1em 0 1em;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    opacity: 0.6;
    letter-spacing: -0.28px;
    .result {
        ${(props) => props.theme.fontSize.body2}
    }
    .option {
        display: flex;
        align-items: center;
        flex-direction: row;
        .icon {
            margin-left: 8px;
            padding: 5px 0 0 0;
        }
    }
`;
/**검색 뉴스 결과 **/

const NewsList = ({ props, keyword, title, edit, setSelectData, selectMenu }) => {

    const { data } = useState();
    const [page_no, setPage_no] = useState(1);
    const [newsList, setNewsList] = useState([]);
    const [newsTotal, setNewsTotal] = useState(0);
    const regExpKey = /[%^]/gi;
    var searchKeyword = keyword.replace(regExpKey, '');
    const [search_opt, setSearch_opt] = useState(2);// eslint-disable-line no-unused-vars
    var realFrom_dt = moment().subtract(1, 'months').format('YYYYMMDDHHmmss');
    var realTo_dt = moment().format('YYYYMMDDHHmmss');
    const [active, setActive] = useState([]);
    const [sort, setSort] = useState(0);// eslint-disable-line no-unused-vars

    const loadMore = () => {
        if (page_no !== 1) {
            getSearchNewsMore(realFrom_dt, realTo_dt, page_no, sort, search_opt, searchKeyword)
                .then((response) => {
                    setNewsList(newsList.concat(response.data.news_list))
                    setNewsTotal(response.data.total_row);
                });
        }
    };

    const numberHandler = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    useEffect(() => {
        loadMore();
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPage_no(1)
    }, [newsTotal])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSearchNews(realFrom_dt, realTo_dt, sort, search_opt, searchKeyword)
            .then((response) => {
                setNewsList(response.data.news_list);
                setNewsTotal(response.data.total_row);
            });
    }, [keyword])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ThemeProvider theme={theme}>
            <PageStyle>
                <Title>
                    {title} ({numberHandler(newsTotal)})
                </Title>
                {newsList.length !== 0 ? (
                    <React.Fragment>
                        {newsList && newsList.map((item, index) => {
                            return (
                                (selectMenu === 0 ? index < 5 : true) && (
                                    <div key={index}
                                        style={{
                                            background: active.includes(index) ? '#F1F1FA' : null
                                        }}
                                        onClick={() => {
                                            if (edit) {
                                                if (!active.includes(index)) {
                                                    setActive([...active, index]);
                                                    setSelectData(item);
                                                }
                                            }
                                            else {
                                                newsLinkAction(item, data, props.history);
                                            }
                                        }}
                                    >
                                        <GridView>
                                            <LeftImgBox>
                                                <Thumnail url={item.img_urls} width={108} height={72} medCd={item.med_cd} medNm={item.med_nm} size={100} />
                                            </LeftImgBox>
                                            <ListStyle index={index} length={newsList.length - 1}>
                                                <div className="list_title">
                                                    {item.title}
                                                </div>
                                                <div className="list_sub">
                                                    <div className={'first'}>
                                                        <span className={'category'}>
                                                            {checkLength(item.med_nm, 10) ? (
                                                                <span>{item.med_nm}</span>
                                                            ) : (
                                                                <span> {getTextLength(item.med_nm, 10)}...</span>
                                                            )}
                                                        </span>
                                                        <div className="right_border">|</div>
                                                        <span className={'center'}>
                                                            {checkLength(item.cat_nm, 10) ? (
                                                                <span>{item.cat_nm}</span>
                                                            ) : (
                                                                <span> {getTextLength(item.cat_nm, 10)}...</span>
                                                            )}
                                                        </span>
                                                        <div className="right_border">|</div>
                                                        <span className={'time'}>
                                                            <TimeGap
                                                                data={moment(item.news_dt, 'YYYY-MM-DD HH:mm:ss')}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className={'second'}>
                                                        {!!item.rel_cnt && (
                                                            <RelationIcon nid={item.nid} count={item.rel_cnt || 0} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={'line'} />
                                            </ListStyle>
                                        </GridView>
                                    </div>
                                )
                            );
                        })}
                    </React.Fragment>
                ) : (
                    <NoneData name={keyword} edit={edit} />
                )}
            </PageStyle>
            {newsList.length >= newsTotal ? null : (
                <MoreList
                    onClick={async () => {
                        setPage_no(page_no + 1);
                    }}
                >
                    <span>뉴스 결과 더보기</span> <img src="/images/icon/ic_expand_more.svg" alt="detail page move icon" />
                </MoreList>
            )}
        </ThemeProvider>
    );
};
export default NewsList;
