import styled from "styled-components";
import Label from "../../../common/Label";
import { useEffect, useState } from "react";
import TextField from "../../../common/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { TremsInsert } from '../../../slice/termsSlice';
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import MessageModal from '../../../modal/Message';
import Edit from '../edit/Edit'
import { getList , updateTerms } from "../../../api/terms/terms";

const RegistBodyC = styled.div`
    width: 100%;
    margin: 50px 0px;
`;

const RegistBody = ({ tid }) => {

    const [message, setMessage] = useState('');
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.terms.value.tremsInsert);
    const dispatch = useDispatch();
    const [title, setTitle] = useState();
    const [editData, setEditData] = useState();// eslint-disable-line no-unused-vars

    useEffect(() => {
        if (Insert === true) {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('contents', JSON.stringify(editData));
            formData.append('Tid', tid);
            dispatch(LoadingModalFlag(true));
            updateTerms(formData)
            .then((response) => {
                dispatch(LoadingModalFlag(false));
                if (response.status === 200) {
                    setMessage("수정 성공 되었습니다");
                    dispatch(MessageModalFlag(true));
                    dispatch(TremsInsert(false));
                } else {
                    setMessage("수정 실패 되었습니다");
                    dispatch(MessageModalFlag(true));
                    dispatch(TremsInsert(false));
                }
            })
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const data = { params: { "Tid": tid } };
        getList(data)
            .then((response) => {
                setTitle(response.data[0].title);
            })
    }, [tid]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleNowData = (editData) => {
        setEditData(editData.contents);
    };

    return (
        <RegistBodyC>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px', textAlign: 'center' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={title || ""} width='1000px' disabled /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ width: '170px', textAlign: 'center' }}><Label type={`nomal`} required={true}>내용</Label></div>
                <div style={{ flexGrow: 1, border: '1px solid rgba(0,0,0,0.1)' }}>
                    <Edit onChange={handleNowData} tid={tid}></Edit>
                </div>
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="약관 수정" message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="약관 수정" message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    );
};

export default RegistBody;
