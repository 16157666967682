import { instance } from "..";

export const bannerSelectBox = async () => {
    const url = '/banner/list';
    const response = await instance.get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getBanner = async () => {
    const url = '/banner/getBanner';
    const response = await instance.get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const bannerSelect = async (location) => {
    const url = '/banner/list';
    const response = await instance.get(url, location, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const bannerListInsert = async (post) => {
    const url = '/banner/list';
    const response = await instance.put(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json multipart/form-data",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const bannerListDelete = async (post) => {
    const url = '/banner/list';
    const response = await instance.delete(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}