import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/notice/Title";
import ViewTitle from "../../components/notice/view/ViewTitle";
import ViewBody from "../../components/notice/view/ViewBody";
import Body from "../../components/notice/Body";
import Notice from "../../templates/notice/Notice";
import RegistTitle from "../../components/notice/regist/RegistTitle";
import RegistBody from "../../components/notice/regist/RegistBody";

const NoticeContainer = ({ page }) => {
    const { notice_id } = useParams();

    const [title, setTitle] = useState();
    const [body, setBody] = useState();

    useEffect(() => {
        if (page && page === "add") {
            setTitle(<RegistTitle mode={notice_id && notice_id !== "" ? "modify" : "add"} />);
            setBody(<RegistBody notice_id={notice_id} mode={notice_id && notice_id !== "" ? "modify" : "add"} />);
        } else if (page && page === "view") {
            setTitle(<ViewTitle notice_id={notice_id} />);
            setBody(<ViewBody notice_id={notice_id} />);
        } else {
            setTitle(<Title />);
            setBody(<Body />);
        }
    }, [page, notice_id]);

    return (
        <div>
            <Notice title={title} body={body} />
        </div>
    );
};

export default NoticeContainer;
