import styled from "styled-components";
import Label from "../../../common/Label";
import { useEffect, useState, forwardRef } from "react";
import Radio from "../../../common/Radio";
import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import FileAttachNovel from "../../../common/FileAttachNovel";
import NovelRegistModal from "./modal/NovelRegistModal";
import DatePicker from "react-datepicker";
import { getDetailNovel, getNovelDetail } from "../../../api/novel/novel";
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import MessageModal from '../../../modal/Message';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { NovelInsert, Flag } from '../../../slice/novelSlice';
import { novetInsert, novetUpdate } from "../../../api/novel/novel";

const RegistBodyC = styled.div`
    width: 100%;
    margin: 50px 0px;
`;

const RegistBody = ({ nkey, mode }) => {

    const [title, setTitle] = useState("");
    const [tagInfo, setTagInfo] = useState("");
    const [isRegistModal, setIsRegistModal] = useState(false);
    const [contents, setContents] = useState("");
    const [writer, setWriter] = useState("");
    const [free, setFree] = useState(10);
    const [login, setLogin] = useState(5);

    const [thumbnail, setThumbnail] = useState();
    const [upload, setUpload] = useState();
    const [novelList, setNovelList] = useState([]);

    const [affiliates, setAffiliates] = useState(0);
    const [completionStatus, setCompletionStatus] = useState(0);
    const [ageLimit, setAgeLimit] = useState(0);
    const [issuance, setIssuance] = useState(0);
    const [fromTime, setFromTime] = useState("00:00");
    const [toTime, setToTime] = useState("23:59");
    const [startDate, setStartDate] = useState(new Date());
    const EndDateWeek = new Date();
    const nowDay = EndDateWeek.getDate();
    const nowMonth = EndDateWeek.getMonth();
    const nowYear = EndDateWeek.getFullYear();
    const [endDate, setEndDate] = useState(new Date(new Date(nowYear, nowMonth + 1, nowDay)));
    var UpdatenewArr = new Array([]);
    UpdatenewArr.splice(0, 1);

    const insertFlag = useSelector((state) => state.novel.value.insertFlag);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const Insert = useSelector((state) => state.novel.value.novelInsert);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (Insert === true) {
            setMessage("등록 / 수정 중");
            dispatch(LoadingModalFlag(true));
            let FROM_DT_Month = String(startDate.getMonth() + 1).padStart(2, "0");
            let FROM_DT_Date = String(startDate.getDate()).padStart(2, "0");
            let from_dt = startDate.getFullYear() + "-" + FROM_DT_Month + "-" + FROM_DT_Date;
            let TO_DT_Month = String(endDate.getMonth() + 1).padStart(2, "0");
            let TO_DT_Date = String(endDate.getDate()).padStart(2, "0");
            let to_dt = endDate.getFullYear() + "-" + TO_DT_Month + "-" + TO_DT_Date;
            const dataContent = contents;
            if (title === "" || title === undefined) {
                dispatch(LoadingModalFlag(false));
                setMessage("제목을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NovelInsert(false));
                return;
            } else if (dataContent === "" || dataContent === undefined) {
                dispatch(LoadingModalFlag(false));
                setMessage("내용을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NovelInsert(false));
                return;
            } else if (dataContent.length > 2000) {
                dispatch(LoadingModalFlag(false));
                setMessage("내용은 공백포함 2000자를 넘을수 없습니다");
                dispatch(MessageModalFlag(true));
                dispatch(NovelInsert(false));
                return;
            } else if (tagInfo === undefined || tagInfo === "") {
                dispatch(LoadingModalFlag(false));
                setMessage("태그 정보를 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NovelInsert(false));
                return;
            } else if (login === undefined || login === "") {
                dispatch(LoadingModalFlag(false));
                setMessage("로그인 회차를 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NovelInsert(false));
                return;
            } else if (writer === undefined || writer === "") {
                dispatch(LoadingModalFlag(false));
                setMessage("작가명을 입력해주세요");
                dispatch(MessageModalFlag(true));
                dispatch(NovelInsert(false));
                return;
            }
            const formData = new FormData();
            //제목
            formData.append('title', title);
            //내용
            formData.append('contents', dataContent);
            //태그정보 #
            formData.append('tagInfo', tagInfo);
            //완결 여부 0 완결 1 미완결
            formData.append('completionStatus', completionStatus);
            //나이제한 0 전체 1 성인
            formData.append('ageLimit', ageLimit);
            //제휴사 0 노벨피아 1 라이크노벨
            if (affiliates === "0" || affiliates === 0) {
                formData.append('affiliates', "노벨피아");
                //
                formData.append('free', free);
            } else if (affiliates === "1" || affiliates === 1) {
                formData.append('affiliates', "라이크노벨");
                //무료회차
                formData.append('free', 9999);
            }
            //로그인 회차
            formData.append('login', login);
            //pid
            formData.append('pid', 0);
            //순서
            formData.append('n_order', 9999);
            //작가명
            formData.append('writer', writer);
            //발행구분 0고정 1 타임
            formData.append('issuance', issuance);
            //적용 시작일
            formData.append('from_dt', from_dt + " " + fromTime);

            if (mode === 'add') {
                if (thumbnail === undefined) {
                    dispatch(LoadingModalFlag(false));
                    setMessage("표지 이미지를 선택해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(NovelInsert(false));
                    return;
                } else if (thumbnail !== undefined) {
                    var _URL = window.URL || window.webkitURL;
                    var img = new Image();
                    img.src = _URL.createObjectURL(thumbnail);
                    img.onload = function () {
                        if (img.width !== 480 || img.height !== 682) {
                            dispatch(LoadingModalFlag(false));
                            setMessage(img.width + "x" + img.height + "이미지 사이즈는 480 x 682 이여야 됩니다");
                            dispatch(MessageModalFlag(true));
                            dispatch(NovelInsert(false));
                            return;
                        } else {
                            const thumbnailNm = thumbnail.name;
                            var thumbnailNmFile = thumbnailNm.slice(thumbnailNm.lastIndexOf(".") + 1).toLowerCase();
                            if (!(thumbnailNmFile === "png" || thumbnailNmFile === "jpg")) {
                                dispatch(LoadingModalFlag(false));
                                setMessage("웹소설 표지 이미지파일은 .png , .jpg 만 업로드 가능합니다.");
                                dispatch(MessageModalFlag(true));
                                dispatch(NovelInsert(false));
                                return;
                            } else if (affiliates === 1) {
                                if (upload === undefined) {
                                    dispatch(LoadingModalFlag(false));
                                    setMessage("작품을 업로드해주세요");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(NovelInsert(false));
                                    return;
                                } else {
                                    formData.append('upload', upload);
                                }
                            } else {
                                for (var i = 0; i < novelList.length; i++) {
                                    if (novelList[i].upload === "") {
                                        dispatch(LoadingModalFlag(false));
                                        setMessage(i + 1 + "번째 작품을 업로드해주세요");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        return;
                                    } else {
                                        formData.append('upload', novelList[i].upload);
                                    }
                                    if (novelList[i].series_subject === "") {
                                        dispatch(LoadingModalFlag(false));
                                        setMessage(i + 1 + "번째 부제목을 입력해주세요");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        return;
                                    } else {
                                        let created_at_date = String(novelList[i].created_at.getDate()).padStart(2, "0");
                                        let created_at_month = String(novelList[i].created_at.getMonth() + 1).padStart(2, "0");
                                        let created_at = novelList[i].created_at.getFullYear() + "-" + created_at_month + "-" + created_at_date + " 00:00:00";
                                        let meta = {
                                            "nindex": i,
                                            "series_subject": novelList[i].series_subject,
                                            "created_at": created_at,
                                            "id": novelList[i].id
                                        }
                                        formData.append('meta[' + [i] + "]", JSON.stringify(meta));
                                    }
                                }
                            }
                            if (issuance === "1" || issuance === 1) {
                                formData.append('to_dt', to_dt + " " + toTime);
                            }
                            //표지이미지
                            formData.append('image', thumbnail);

                            novetInsert(formData)
                                .then((response) => {
                                    dispatch(LoadingModalFlag(false));
                                    if (response.status === 200) {
                                        setMessage("등록완료");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        dispatch(Flag(true));
                                        return;
                                    } else {
                                        setMessage("등록 실패");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        return;
                                    }
                                })
                        }
                    }
                }
            } else if (mode !== 'add') {
                if (thumbnail !== undefined) {
                    var UP_URL = window.URL || window.webkitURL;
                    var up_img = new Image();
                    up_img.src = UP_URL.createObjectURL(thumbnail);
                    up_img.onload = function () {
                        if (up_img.width !== 480 || up_img.height !== 682) {
                            dispatch(LoadingModalFlag(false));
                            setMessage(up_img.width + "x" + up_img.height + "이미지 사이즈는 480 x 682 이여야 됩니다");
                            dispatch(MessageModalFlag(true));
                            dispatch(NovelInsert(false));
                            return;
                        } else {
                            const thumbnailNm = thumbnail.name;
                            var thumbnailNmFile = thumbnailNm.slice(thumbnailNm.lastIndexOf(".") + 1).toLowerCase();
                            if (!(thumbnailNmFile === "png" || thumbnailNmFile === "jpg")) {
                                dispatch(LoadingModalFlag(false));
                                setMessage("웹소설 표지 이미지파일은 .png , .jpg 만 업로드 가능합니다.");
                                dispatch(MessageModalFlag(true));
                                dispatch(NovelInsert(false));
                                return;
                            }
                            //작품 파일
                            if (affiliates === 1) {
                                if (upload === undefined) {
                                    dispatch(LoadingModalFlag(false));
                                    setMessage("작품을 업로드해주세요");
                                    dispatch(MessageModalFlag(true));
                                    dispatch(NovelInsert(false));
                                    return;
                                } else {
                                    formData.append('upload', upload);
                                }
                            } else {
                                for (var i = 0; i < novelList.length; i++) {
                                    if (novelList[i].upload === "") {
                                        dispatch(LoadingModalFlag(false));
                                        setMessage(i + 1 + "번째 작품을 업로드해주세요");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        return;
                                    } else {
                                        formData.append('upload', novelList[i].upload);
                                    }
                                    if (novelList[i].series_subject === "") {
                                        dispatch(LoadingModalFlag(false));
                                        setMessage(i + 1 + "번째 부제목을 입력해주세요");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        return;
                                    } else {
                                        let created_at_date = String(novelList[i].created_at.getDate()).padStart(2, "0");
                                        let created_at_month = String(novelList[i].created_at.getMonth() + 1).padStart(2, "0");
                                        let created_at = novelList[i].created_at.getFullYear() + "-" + created_at_month + "-" + created_at_date + " 00:00:00";
                                        let meta = {
                                            "nindex": i,
                                            "series_subject": novelList[i].series_subject,
                                            "created_at": created_at,
                                            "id": novelList[i].id
                                        }
                                        formData.append('meta[' + [i] + "]", JSON.stringify(meta));
                                    }
                                }
                            }
                            if (issuance === "1" || issuance === 1) {
                                formData.append('to_dt', to_dt + " " + toTime);
                            }
                            //표지이미지
                            formData.append('image', thumbnail);
                            formData.append('nkey', nkey);
                            novetUpdate(formData)
                                .then((response) => {
                                    dispatch(LoadingModalFlag(false));
                                    if (response.status === 200) {
                                        setMessage("수정 완료되었습니다");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        return;
                                    } else {
                                        setMessage("수정 실패");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(NovelInsert(false));
                                        return;
                                    }

                                })
                        }
                    }
                } else {
                    //작품 파일
                    if (affiliates === 1) {
                        if (upload !== undefined) {
                            formData.append('upload', upload);
                        }
                    } else {
                        for (var i = 0; i < novelList.length; i++) {
                            if (novelList[i].upload === "") {
                                setMessage(i + 1 + "번째 작품을 업로드해주세요");
                                dispatch(MessageModalFlag(true));
                                dispatch(NovelInsert(false));
                                return;
                            } else {
                                formData.append('upload', novelList[i].upload);
                            }
                            if (novelList[i].series_subject === "") {
                                setMessage(i + 1 + "번째 부제목을 입력해주세요");
                                dispatch(MessageModalFlag(true));
                                dispatch(NovelInsert(false));
                                return;
                            } else {
                                let created_at_date = String(novelList[i].created_at.getDate()).padStart(2, "0");
                                let created_at_month = String(novelList[i].created_at.getMonth() + 1).padStart(2, "0");
                                let created_at = novelList[i].created_at.getFullYear() + "-" + created_at_month + "-" + created_at_date + " 00:00:00";
                                let meta = {
                                    "nindex": i,
                                    "series_subject": novelList[i].series_subject,
                                    "created_at": created_at,
                                    "id": novelList[i].id
                                }
                                formData.append('meta[' + [i] + "]", JSON.stringify(meta));
                            }
                        }
                    }
                    formData.append('image', thumbnail);
                    formData.append('nkey', nkey);
                    novetUpdate(formData)
                        .then((response) => {
                            dispatch(LoadingModalFlag(false));
                            if (response.status === 200) {
                                setMessage("수정 완료되었습니다");
                                dispatch(MessageModalFlag(true));
                                dispatch(NovelInsert(false));
                                return;
                            } else {
                                setMessage("수정 실패");
                                dispatch(MessageModalFlag(true));
                                dispatch(NovelInsert(false));
                                return;
                            }
                        })
                }
                dispatch(NovelInsert(false));
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/novel");
        }
    }, [insertFlag, MModal, navigate, dispatch]);

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div
                style={{
                    width: "250px",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={onClick}
                ref={ref}
            >
                <div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
                <img
                    src={"/images/component/ic_calendar.png"}
                    style={{ width: "16.67px", height: "15px" }}
                    alt="calendar_icon"
                />
            </div>
        );
    });

    const onSetTitle = (e) => {
        if (e.target.value.length > 60) {
            setIsRegistModal(true);
            return;
        } else {
            setTitle(e.target.value);
        }
    };

    const onSetTage = (e) => {
        setTagInfo(e.target.value);
    };

    const onSetWriter = (e) => {
        setWriter(e.target.value);
    };

    const onSetFreeEpisode = (e) => {
        setFree(e.target.value.replace(/[^0-9]/g, ""));
    };

    const onSetLoginEpisode = (e) => {

        const number = e.target.value.replace(/[^0-9]/g, "");
        if (free * 1 >= number * 1) {
            setLogin(number);
        } else {
            setMessage("로그인 회차가 무료 회차보다 클수 없습니다.");
            dispatch(MessageModalFlag(true));
        }
    };

    const NovelRegistModalhandleClose = (e) => {
        setIsRegistModal(false);
    }

    const addItem = (value, type) => {
        if (type === "thumbnail") {
            setThumbnail(value);
        } else if (type === "upload") {
            setUpload(value);
        } else if (type === "TO_DT") {
            setEndDate(value);
        } else if (type === "FROM_DT") {
            setStartDate(value);
        }
    };

    const addInputNovelList = () => {
        const newArr = novelList.slice();
        newArr.push({
            upload: "",
            series_subject: "",
            created_at: new Date(),
            id: ""
        });
        setNovelList(newArr);
    };
    const clearInputImgList = (i) => {
        const newArr = novelList.splice(i, 1);// eslint-disable-line no-unused-vars
        const newArr2 = novelList.slice();
        setNovelList(newArr2);
    };

    const addItemList = (value, index, type) => {
        const newArr = novelList.slice();
        if (type === "upload") {
            newArr[index].upload = value;
        } else if (type === "series") {
            newArr[index].series_subject = value;
        } else if (type === "CREATED_AT") {
            newArr[index].created_at = value;
        } else if (type === "id") {
            newArr[index].id = value;
        }
        setNovelList(newArr);
    };

    useEffect(() => {
        if (affiliates !== 1) {
            addInputNovelList();
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (nkey) {
            const data = nkey * 1
            getDetailNovel(data)
                .then((response) => {
                    if (response.data.list[0].affiliates === "노벨피아") {
                        setAffiliates(0);
                    } else if (response.data.list[0].affiliates === "라이크노벨") {
                        setAffiliates(1);
                    }
                    setAgeLimit(response.data.list[0].ageLimit);
                    setIssuance(response.data.list[0].issuance);
                    setCompletionStatus(response.data.list[0].completionStatus);
                    setTagInfo(response.data.list[0].tagInfo);
                    setContents(response.data.list[0].contents);
                    setWriter(response.data.list[0].writer);
                    setFree(response.data.list[0].free);
                    setLogin(response.data.list[0].login);
                    setTitle(response.data.list[0].title);
                    const from_yy = response.data.list[0].from_dt.substr(0, 4);
                    const from_mm = response.data.list[0].from_dt.substr(5, 2);
                    const from_dd = response.data.list[0].from_dt.substr(8, 2);
                    const from_time = response.data.list[0].from_dt.substr(11, 5);
                    setStartDate(new Date(new Date(from_yy, from_mm - 1, from_dd)));
                    setFromTime(from_time);
                    if (response.data.list[0].to_dt === null) {
                    } else {
                        const to_yy = response.data.list[0].to_dt.substr(0, 4);
                        const to_mm = response.data.list[0].to_dt.substr(5, 2);
                        const to_dd = response.data.list[0].to_dt.substr(8, 2);
                        const to_time = response.data.list[0].to_dt.substr(11, 5);
                        setEndDate(new Date(new Date(to_yy, to_mm - 1, to_dd)));
                        setToTime(to_time);
                    }
                })
            getNovelDetail(data)
                .then((response) => {
                    for (var i = 0; i < response.data.length; i++) {
                        UpdateaddInputList(i, response.data[i]);
                    }
                })
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const UpdateaddInputList = (index, selectList) => {

        const created_date = selectList.created_at;
        const created_yy = created_date.substr(0, 4);
        const created_mm = created_date.substr(5, 2);
        const created_dd = created_date.substr(8, 2);

        UpdatenewArr.push({
            upload: "",
            series_subject: selectList.series_subject,
            created_at: new Date(created_yy, created_mm - 1, created_dd),
            id: selectList.id
        });

        setNovelList(UpdatenewArr);
    };

    return (
        <RegistBodyC>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제목</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={title || ""} width='1000px' placeholder='웹소설 작품 제목을 입력하세요' onChange={onSetTitle} /></div>
            </div>
            <div style={{ height: '7px' }}></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>내용</Label></div>
                <div style={{ flexGrow: 1 }}><TextArea value={contents || ""} onChange={(e) => setContents(e.target.value)} width='1000px' placeholder='내용을 입력하세요' /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`}></Label></div>
                <span style={{ color: 'red' }}>공백포함 2000자</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: "170px" }}>
                    <Label type={`nomal`} required={true}>
                        표지 이미지
                    </Label>
                </div>
                <div style={{ width: "1000px" }}>
                    <FileAttachNovel
                        value={thumbnail ? thumbnail : ""}
                        width="100%"
                        placeholder="표지 이미지 사이즈 480 x 682 등록해주세요"
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                addItem(e.target.files[0], "thumbnail");
                            }
                        }}
                    />
                </div>
                <div style={{ width: '10%' }}></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>태그 정보</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={tagInfo || ""} width='1000px' placeholder='#미소년 #베트남 #전문직 #이세계' onChange={onSetTage} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>작가 이름</Label></div>
                <div style={{ flexGrow: 1 }}><TextField value={writer || ""} width='100px' placeholder='작가 이름' onChange={onSetWriter} /></div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>로그인 회차</Label></div>
                <div style={{ flexGrow: 1 }}><TextField type="number" value={login || ""} width='100px' placeholder='로그인 회차' onChange={onSetLoginEpisode} /></div>
            </div>
            {affiliates !== 1 &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>무료 회차</Label></div>
                    <div style={{ flexGrow: 1 }}><TextField type="number" value={free || ""} width='100px' placeholder='무료 회차' onChange={onSetFreeEpisode} /></div>
                </div>
            }

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>제휴사</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='affiliates' value="노벨피아" checked={affiliates === 0 && true} onChange={(e) => setAffiliates(0)}>노벨피아</Radio>
                    <Radio name='affiliates' value="라이크노벨" checked={affiliates === 1 && true} onChange={(e) => setAffiliates(1)}>라이크노벨</Radio>
                </div>
                {affiliates !== 1 &&
                    <div style={{ display: 'flex', width: '200px', alignItems: 'center' }}>
                        <div style={{ display: 'flex', margin: 'auto', width: "200px", alignItems: 'center' }}>
                            작품 추가 <img alt={"작품 추가 이미지"} onClick={addInputNovelList} src={`/images/component/ic_plus.png`} style={{ width: '40px', height: '40px', margin: 'auto' }}></img>
                        </div>
                    </div>
                }
                <div style={{ width: '360px' }}></div>
            </div>
            {affiliates === 1 &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: "170px" }}>
                        <Label type={`nomal`} required={true}>
                            작품 업로드
                        </Label>
                    </div>
                    <div style={{ width: "1000px" }}>
                        <FileAttachNovel
                            value={upload ? upload : ""}
                            width="100%"
                            placeholder="작품을 업로드해주세요"
                            onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    addItem(e.target.files[0], "upload");
                                }
                            }}
                        />
                    </div>
                    <div style={{ width: '10%' }}>
                    </div>
                </div>
            }
            {affiliates !== 1 &&
                <div style={{ alignItems: 'center', maxHeight: '300px', overflow: 'auto' }}>
                    {novelList && novelList.map((item, i) => (
                        <div key={i} style={{ height: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: "170px" }}>
                                    <Label type={`nomal`} required={true}>
                                        {i + 1}번째 작품 업로드
                                    </Label>
                                </div>
                                <div style={{ width: "500px" }}>
                                    <FileAttachNovel
                                        value={item.upload ? item.upload : ""}
                                        width="100%"
                                        placeholder="작품을 업로드해주세요"
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                addItemList(e.target.files[0], i, "upload");
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ width: '150px' }}><TextField value={item.series_subject || ""} width='150px' placeholder='부제목'
                                    onChange={(e) => { addItemList(e.target.value, i, "series") }}
                                /></div>
                                <div style={{ width: '100px' }}><TextField value={item.id || ""} width='100px' placeholder='회차 아이디'
                                    onChange={(e) => { addItemList(e.target.value, i, "id") }}
                                /></div>
                                <div style={{ margin: 'auto', width: "250px" }}>
                                    <DatePicker
                                        customInput={<DatePickerInput />}
                                        selected={item.created_at ? item.created_at : ""}
                                        onChange={(e) => {
                                            addItemList(e, i, "CREATED_AT");
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </div>
                                <div style={{ width: '10%' }}>
                                    <div style={{ margin: 'auto', width: "50px" }}>
                                        <img alt={"이미지"} onClick={(e) => clearInputImgList(i)} src={`/images/component/ic_minus.png`} style={{ width: '40px', height: '40px', margin: 'auto' }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>완결여부</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='completionStatus' value="완결" checked={completionStatus === 0 && true} onChange={(e) => setCompletionStatus(0)}>완결</Radio>
                    <Radio name='completionStatus' value="미완결" checked={completionStatus === 1 && true} onChange={(e) => setCompletionStatus(1)}>미완결</Radio>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>나이제한</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='ageLimit' value="전체연령" checked={ageLimit === 0 && true} onChange={(e) => setAgeLimit(0)}>전체연령</Radio>
                    <Radio name='ageLimit' value="성인용" checked={ageLimit === 1 && true} onChange={(e) => setAgeLimit(1)}>성인용</Radio>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '170px' }}><Label type={`nomal`} required={true}>발행구분</Label></div>
                <div style={{ flexGrow: 1 }}>
                    <Radio name='issuance' value="고정" checked={issuance === 0 && true} onChange={(e) => setIssuance(0)}>고정</Radio>
                    <Radio name='issuance' value="타임" checked={issuance === 1 && true} onChange={(e) => setIssuance(1)}>타임</Radio>
                </div>
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: "170px" }}>
                        <Label type={`nomal`} required={true}>
                            적용 시작일
                        </Label>
                    </div>
                    <div style={{ flexGrow: 1, display: 'flex' }}>
                        <DatePicker
                            customInput={<DatePickerInput />}
                            selected={startDate}
                            onChange={(e) => {
                                addItem(e, "FROM_DT");
                            }}
                            dateFormat="yyyy-MM-dd"
                        />
                        <input
                            value={fromTime}
                            type="time"
                            min="00:00"
                            max="23:59"
                            style={{
                                width: "150px",
                                padding: "12px",
                                height: "19px",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: "4px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                            onChange={(e) => {
                                setFromTime(e.target.value);
                            }}
                        />
                    </div>
                </div>
                {issuance === 1 &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: "170px" }}>
                            <Label type={`nomal`} required={true}>
                                적용 종료일
                            </Label>
                        </div>
                        <div style={{ flexGrow: 1, display: 'flex' }}>
                            <DatePicker
                                customInput={<DatePickerInput />}
                                selected={endDate}
                                onChange={(e) => {
                                    addItem(e, "TO_DT");
                                }}
                                dateFormat="yyyy-MM-dd"
                            />
                            <input
                                value={toTime}
                                type="time"
                                min="00:00"
                                max="23:59"
                                style={{
                                    width: "150px",
                                    padding: "12px",
                                    height: "19px",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderRadius: "4px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                                onChange={(e) => {
                                    setToTime(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
            {isRegistModal &&
                <div className='NovelRegist_Modal_Container'>
                    <div className='NovelRegist_Modal'>
                        <div onClick={NovelRegistModalhandleClose} className="NovelRegist_Modal_close">확인</div>
                        <NovelRegistModal />
                    </div>
                </div>
            }
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title="웹소설 등록/수정" message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title="웹소설 등록/수정" message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    );
};

export default RegistBody;
