import React, { useState, useEffect, forwardRef, useRef } from "react";
import { getAnalyze, getSearchNews } from "../../../api/news/news";
import { SearchBar, TableHeader, TableRow } from "../../../style/layout";
import Button from "../../../common/Button";
import Paging from "../../../common/Paging";
import TextField from "../../../common/TextField";
import Label from "../../../common/Label";
import '../../../style/common.css';
import NewsSearchModal from "../../../modal/NewsSearch";
import MessageModal from "../../../modal/Message";
import DatePicker from "react-datepicker";
import { InNewsDate, inFlag } from '../../../slice/inNewsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { NewsSearchModalFlag, MessageModalFlag } from '../../../slice/modalSlice';

const RegistSearchBody = ({ searchKeyword, exceptKeyword, searchDt, mode }) => {

    const [keyword, setKeyword] = useState(searchKeyword);
    const [exKeyword, setExKeyword] = useState(exceptKeyword);
    const [pagesInfo, setPagesInfo] = useState();
    const [totalCnt, setTotalCnt] = useState(0);
    const [page_no, setPage_no] = useState(1);
    const [newsMessage, setNewsMessage] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [analy, setAnaly] = useState();
    const NModal = useSelector((state) => state.modal.value.NewsSearch);
    const MModal = useSelector((state) => state.modal.value.Message);
    const flag = useSelector((state) => state.inNews.value.flag);
    const dispatch = useDispatch();
    var StartDateWeek = new Date();
    var nowDay = StartDateWeek.getDate();
    var nowMonth = StartDateWeek.getMonth();
    var nowYear = StartDateWeek.getFullYear();
    const [startDate, setStartDate] = useState(new Date(new Date(nowYear, nowMonth, nowDay)));
    const localVar = useRef(1);
    const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div
                style={{
                    width: "200px",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={onClick}
                ref={ref}
            >
                <div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
                <img
                    src={"/images/component/ic_calendar.png"}
                    style={{ width: "16.67px", height: "15px" }}
                    alt="calendar_icon"
                />
            </div>
        );
    });

    useEffect(() => {
        if (localVar.current !== 1) {
            if (keyword) {
                setTitle("뉴스 검색 테스트");
                setMessage("검색중");
                dispatch(MessageModalFlag(true));
                goAnaly();
                goSearch();
            }
        } else {
            localVar.current = localVar.current + 1;
        }
    }, [page_no, startDate]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchKeyword) {
            if (searchDt) {
                var startNowDay = searchDt.substr(6, 2).replace(0, "");
                var startNnowMonth = searchDt.substr(4, 2) * 1 - 1;
                var startNnowYear = searchDt.substr(0, 4);
                setStartDate(new Date((new Date(startNnowYear, startNnowMonth, startNowDay))));
                setKeyword(searchKeyword);
                setExKeyword(exceptKeyword);
            }
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
    }

    const newSearch = () => {
        if (keyword) {
            setTitle("뉴스 검색 테스트");
            setMessage("검색중");
            dispatch(MessageModalFlag(true));
            goAnaly();
            goSearch();
        }
    }

    const goAnaly = () => {
        getAnalyze(keyword)
            .then((response) => {
                const target = response.data.data[0].tokens.map(item => item.token);
                setAnaly(target);
                dispatch(MessageModalFlag(false));
            })
    }

    const goSearch = () => {

        var searchStartDate = new Date();
        if (mode === "modify" && flag === false) {
            var NowDay = searchDt.substr(6, 2).replace(0, "");
            var NnowMonth = searchDt.substr(4, 2) * 1 - 1;
            var NnowYear = searchDt.substr(0, 4);
            dispatch(inFlag(true));
            searchStartDate = new Date((new Date(NnowYear, NnowMonth, NowDay)));
        } else {
            searchStartDate = new Date(startDate);
        }
        var startNowDay = String(searchStartDate.getDate()).padStart(2, "0");
        var startNnowMonth = String(searchStartDate.getMonth() + 1).padStart(2, "0");
        var startNnowYear = searchStartDate.getFullYear();
        const searchDate = startNnowYear + "" + startNnowMonth + "" + startNowDay + "000000";
        const searchTodt = new Date();
        var startDay = String(searchTodt.getDate()).padStart(2, "0");
        var startMonth = String(searchTodt.getMonth() + 1).padStart(2, "0");
        var startYear = searchTodt.getFullYear();
        const searchToDt2 = startYear + "" + startMonth + "" + startDay + "235900";
        if (searchDate * 1 > searchToDt2 * 1) {
            setTitle("뉴스 검색 테스트");
            setMessage("검색 시작일은 현재일보다 작아야됩니다");
            dispatch(MessageModalFlag(true));
            return;
        }
        dispatch(InNewsDate(startNnowYear + "" + startNnowMonth + "" + startNowDay + "000000"));
        getSearchNews(
            {
                from_dt: searchDate,
                to_dt: "",
                page_no: page_no,
                size: 10,
                sort: 1,
                search_opt: 2,
                keyword: keyword,
                exKeyword: exKeyword
            }
        ).then((response) => {
            setTotalCnt(response.data.total_row);
            setPagesInfo(response.data.news_list);
            dispatch(MessageModalFlag(false));
        })
    }

    const newsSearchModal = (e) => {
        setNewsMessage(e);
        dispatch(NewsSearchModalFlag(true));
    }

    const enterkey = (e) => {
        if (window.event.keyCode === 13) {
            setTitle("뉴스 검색 테스트");
            setMessage("검색중");
            dispatch(MessageModalFlag(true));
            goSearch();
            goAnaly();
        }
    }
    const addItem = (value, type) => {
        if (type === "FROM_DT") {
            setStartDate(value);
            var searchStartDate = new Date(value);
            var startNowDay = String(searchStartDate.getDate()).padStart(2, "0");
            var startNnowMonth = String(searchStartDate.getMonth() + 1).padStart(2, "0");
            var startNnowYear = searchStartDate.getFullYear();
            dispatch(InNewsDate(startNnowYear + "" + startNnowMonth + "" + startNowDay + "000000"));
            setPage_no(1);
        }
    };

    return (
        <React.Fragment>
            <SearchBar>
                <div className='filter'>
                    <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                        {(totalCnt && (totalCnt > 0)) &&
                            <React.Fragment>
                                (<span style={{ color: '#ED5255' }}>{totalCnt}</span>개)
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <TextField width={`30%`} value={keyword || ""} placeholder={`검색어를 입력해주세요`} onChange={(e) => setKeyword(e.target.value)} onKeydown={enterkey} />&nbsp;
                        <TextField width={`30%`} value={exKeyword || ""} placeholder={`배제어를 입력해주세요`} onChange={(e) => setExKeyword(e.target.value)} onKeydown={enterkey} />&nbsp;
                    </div>
                    <Button name='검색' onClick={newSearch} />
                </div>
                <div style={{ display: 'flex', width: '100%', height: '60px' }}>
                    <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                        <Label type={`nomal`} required={true}>
                            검색 시작일
                        </Label>
                    </div>
                    <div style={{ margin: 'auto', width: "500px" }}>
                        <DatePicker
                            customInput={<DatePickerInput />}
                            selected={startDate}
                            onChange={(e) => {
                                addItem(e, "FROM_DT");
                            }}
                            dateFormat="yyyy-MM-dd"
                        />
                    </div>
                    <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                        <Label type={`nomal`}>
                        </Label>
                    </div>
                    <div style={{ margin: 'auto', width: "500px" }} >
                    </div>
                </div>
            </SearchBar>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                {(pagesInfo && pagesInfo.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>제목</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>매체명</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>뉴스 타입</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }}>등록일시</div>
                        </TableHeader>
                        <div>
                            {pagesInfo.map((item, index) => {
                                return (
                                    <TableRow key={index} onClick={(e) => newsSearchModal(item.contents)}>
                                        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }} >{item.title}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} >{item.med_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }} >{item.cat_nm}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '242px' }} >{item.news_dt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                    </React.Fragment>
                }
                <Paging count={totalCnt} pageSize={10} viewSize={page_no} onClick={handlePageClick} />
            </div>
            {NModal &&
                <div className='NewsSearch_Modal_Container' onClick={() => dispatch(NewsSearchModalFlag(false))}>
                    <div className='NewsSearch_Modal'>
                        <NewsSearchModal message={newsMessage} analy={analy} />
                    </div>
                </div>
            }
            {MModal &&
                <div className='Message_Modal_Container' onClick={() => dispatch(MessageModalFlag(false))}>
                    <div className='Message_Modal'>
                        <MessageModal title={title} message={message} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default RegistSearchBody;