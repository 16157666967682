import React, { useState, useEffect, forwardRef, useRef } from "react";
import styled from "styled-components";
import Label from "../../../common/Label";
import TextField from "../../../common/TextField";
import DatePicker from "react-datepicker";
import { getBanner, getBannerSelectList } from "../../../api/banner/banner";
import { useSelector, useDispatch } from 'react-redux';
import { bannerListDelete, bannerSelectBox, bannerListInsert, bannerSelect } from "../../../api/bannerList/bannerList";
import Form from 'react-bootstrap/Form';
import { MessageModalFlag, LoadingModalFlag } from '../../../slice/modalSlice';
import { BannerListInsert, Flag } from '../../../slice/bannerListSlice';
import MessageModal from "../../../modal/Message";
import { useNavigate } from 'react-router-dom'


const RegistBodyC = styled.div`
    width:100%;
    margin:50px 0px;
`;

const RegistBody = ({ bLocation, mode }) => {


    const [selectBox, setSelectBox] = useState();
    const [useSelectBox, setUseSelectBox] = useState();

    const [listDetailType, setListDetailType] = useState();
    const [bannerType, setBannerType] = useState();
    const [selectBlocation, setSelectBlocation] = useState();// eslint-disable-line no-unused-vars
    const [bannerLocation, setBannerLocation] = useState();
    const [bannertList, setBannertList] = useState([]);
    const Insert = useSelector((state) => state.bannerList.value.bannerListInsert);
    const [msgTitle, setMsgTitle] = useState('');
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    var StartDateWeek = new Date();
    var nowDay = StartDateWeek.getDate();
    var nowMonth = StartDateWeek.getMonth();
    var nowYear = StartDateWeek.getFullYear();
    var UpdatenewArr = new Array([]);
    UpdatenewArr.splice(0, 1);
    const localVar = useRef(1);
    const InsertFlag = useRef(1);
    const InsertResult = useRef(1);
    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const insertFlag = useSelector((state) => state.bannerList.value.insertFlag);

    useEffect(() => {
        setUseSelectBox([{ BANNER_IS_USE_NAME: '미사용', BANNER_IS_USE: 0 }, { BANNER_IS_USE_NAME: '사용', BANNER_IS_USE: 1 }]);
        if (bLocation) {
            const data = { params: { "b_location": bLocation } };
            bannerSelect(data)
                .then((response) => {
                    if (localVar.current === 1) {
                        setBannerLocation(response.data[0].b_location);
                        setBannerType(response.data[0].b_type);
                        for (var i = 0; i < response.data.length; i++) {
                            UpdateaddInputImgList(response.data[i]);
                        }
                        localVar.current = localVar.current + 1;
                    }
                })
            setListDetailType("Update");
        } else {
            addInputImgList();
            setListDetailType("Insert");
        }
        getBannerSelectList()
            .then((response) => {
                setSelectBox(response.data);
            }).catch(err => {
                return null;
            });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Insert === true) {
            if (mode === 'add') {
                setMsgTitle("베너리스트 등록");
                setMessage("진행중");
                dispatch(LoadingModalFlag(true));
                bannerSelectBox()
                    .then((response) => {
                        var arr = new Array([]);
                        arr.splice(0, 1);
                        for (var k = 0; k < response.data.length; k++) {
                            arr.push(response.data[k].b_location);
                        }
                        var newArr = [...new Set(arr)];
                        var lastarr = new Array([]);
                        lastarr.splice(0, 1);
                        for (var l = 0; l < newArr.length; l++) {
                            lastarr.push({ "b_location": newArr[l] });
                        }
                        for (var h = 0; h < lastarr.length; h++) {
                            if (lastarr[h].b_location === bannerLocation) {
                                InsertFlag.current = 2;
                            }
                        }
                        if (InsertFlag.current === 2) {
                            dispatch(LoadingModalFlag(false));
                            setMsgTitle("베너리스트 등록");
                            setMessage("중복된 로케이션이 존재합니다");
                            dispatch(MessageModalFlag(true));
                            dispatch(BannerListInsert(false));
                            InsertFlag.current = 1;
                            return;
                        } else if (InsertFlag.current === 1) {
                            if (bannertList.length !== 0) {
                                for (var j = 0; j < bannertList.length; j++) {
                                    if (bannerLocation === "" || bannerLocation === undefined) {
                                        dispatch(LoadingModalFlag(false));
                                        setMsgTitle("베너리스트 등록");
                                        setMessage("배너 명을 입력해주세요");
                                        dispatch(MessageModalFlag(true));
                                        dispatch(BannerListInsert(false));
                                        return;
                                    }
                                }
                            }
                            for (var i = 0; i < bannertList.length; i++) {
                                let TO_DT_Month = String(bannertList[i].TO_DT.getMonth() + 1).padStart(2, "0");
                                let FROM_DT_Month = String(bannertList[i].FROM_DT.getMonth() + 1).padStart(2, "0");
                                let EVENT_DT_Month = "";

                                let TO_DT_Date = String(bannertList[i].TO_DT.getDate()).padStart(2, "0");
                                let FROM_DT_Date = String(bannertList[i].FROM_DT.getDate()).padStart(2, "0");
                                let EVENT_DT_Date = "";

                                let toTime = bannertList[i].toTime;
                                let fromTime = bannertList[i].fromTime;
                                let eventTime = "";

                                let to_dt = bannertList[i].TO_DT.getFullYear() + "-" + TO_DT_Month + "-" + TO_DT_Date + " " + toTime + ":00";
                                let from_dt = bannertList[i].FROM_DT.getFullYear() + "-" + FROM_DT_Month + "-" + FROM_DT_Date + " " + fromTime + ":00";
                                let event_dt = "";
                                if (bannertList[i].EVENT_DT === undefined || bannertList[i].EVENT_DT === "") {
                                } else {
                                    EVENT_DT_Month = String(bannertList[i].EVENT_DT.getMonth() + 2).padStart(2, "0");
                                    EVENT_DT_Date = String(bannertList[i].EVENT_DT.getDate()).padStart(2, "0");
                                    eventTime = bannertList[i].eventTime;
                                    event_dt = bannertList[i].EVENT_DT.getFullYear() + "-" + EVENT_DT_Month + "-" + EVENT_DT_Date + " " + eventTime + ":00";
                                }
                                const formData = new FormData();

                                formData.append('b_seq', bannertList[i].BANNER_SEQ);
                                formData.append('b_order', i + 1);
                                formData.append('is_use', bannertList[i].BANNER_IS_USE);
                                formData.append('b_location', bannerLocation);
                                formData.append('to_dt', to_dt);
                                formData.append('from_dt', from_dt);
                                formData.append('event_dt', event_dt);
                                formData.append('b_type', bannerType);
                                bannerListInsert(formData)
                                    .then((response) => {
                                        if (response.status === 200) {
                                            InsertResult.current = 1;
                                        } else {
                                            InsertResult.current = 2;
                                        }
                                    })
                            }
                            if (InsertResult.current === 1) {
                                dispatch(LoadingModalFlag(false));
                                setMsgTitle("베너리스트 등록");
                                setMessage("베너 리스트 등록 성공");
                                dispatch(MessageModalFlag(true));
                                dispatch(BannerListInsert(false));
                                dispatch(Flag(true));
                                return;
                            } else {
                                dispatch(LoadingModalFlag(false));
                                setMsgTitle("베너리스트 등록");
                                setMessage("베너 리스트 등록 실패");
                                dispatch(MessageModalFlag(true));
                                dispatch(BannerListInsert(false));
                                return;
                            }
                        } else {
                            setMsgTitle("베너리스트 등록");
                            setMessage("베너를 추가해주세요");
                            dispatch(MessageModalFlag(true));
                            dispatch(BannerListInsert(false));
                            return;
                        }
                    })
            } else if (mode === 'modify') {
                dispatch(LoadingModalFlag(true));
                if (bannertList.length !== 0) {
                    const deleteDate = { params: { "b_location": bannerLocation } };
                    bannerListDelete(deleteDate)
                        .then((response) => {
                            for (var i = 0; i < bannertList.length; i++) {
                                let TO_DT_Month = String(bannertList[i].TO_DT.getMonth() + 1).padStart(2, "0");
                                let FROM_DT_Month = String(bannertList[i].FROM_DT.getMonth() + 1).padStart(2, "0");
                                let EVENT_DT_Month = "";

                                let TO_DT_Date = String(bannertList[i].TO_DT.getDate()).padStart(2, "0");
                                let FROM_DT_Date = String(bannertList[i].FROM_DT.getDate()).padStart(2, "0");
                                let EVENT_DT_Date = "";

                                let toTime = bannertList[i].toTime;
                                let fromTime = bannertList[i].fromTime;
                                let eventTime = "";

                                let to_dt = bannertList[i].TO_DT.getFullYear() + "-" + TO_DT_Month + "-" + TO_DT_Date + " " + toTime + ":00";
                                let from_dt = bannertList[i].FROM_DT.getFullYear() + "-" + FROM_DT_Month + "-" + FROM_DT_Date + " " + fromTime + ":00";
                                let event_dt = "";
                                if (bannertList[i].EVENT_DT === undefined || bannertList[i].EVENT_DT === "") {

                                } else {
                                    EVENT_DT_Month = String(bannertList[i].EVENT_DT.getMonth() + 1).padStart(2, "0");
                                    EVENT_DT_Date = String(bannertList[i].EVENT_DT.getDate()).padStart(2, "0");
                                    eventTime = bannertList[i].eventTime;
                                    event_dt = bannertList[i].EVENT_DT.getFullYear() + "-" + EVENT_DT_Month + "-" + EVENT_DT_Date + " " + eventTime + ":00";
                                }
                                const formData = new FormData();
                                formData.append('b_seq', bannertList[i].BANNER_SEQ);
                                formData.append('b_order', i + 1);
                                formData.append('is_use', bannertList[i].BANNER_IS_USE);
                                formData.append('b_location', bannerLocation);
                                formData.append('to_dt', to_dt);
                                formData.append('from_dt', from_dt);
                                formData.append('event_dt', event_dt);
                                formData.append('b_type', bannerType);
                                if (bannerType === 0) {
                                    formData.append('b_type_name', "리스트");
                                } else if (bannerType === 1) {
                                    formData.append('b_type_name', "롤링");
                                } else if (bannerType === 2) {
                                    formData.append('b_type_name', "웹소설");
                                }
                                bannerListInsert(formData)
                                    .then((response) => {
                                        if (response.status === 200) {
                                            InsertResult.current = 1;
                                        } else {
                                            InsertResult.current = 2;
                                        }
                                    })
                            }
                            dispatch(LoadingModalFlag(false));
                            if (InsertResult.current === 1) {
                                setMsgTitle("베너리스트 수정");
                                setMessage("베너 리스트 수정 성공");
                                dispatch(MessageModalFlag(true));
                                dispatch(BannerListInsert(false));
                                return;
                            } else {
                                setMsgTitle("베너리스트 수정");
                                setMessage("베너 리스트 수정 실패");
                                dispatch(MessageModalFlag(true));
                                dispatch(BannerListInsert(false));
                                return;
                            }
                        })
                } else {
                    setMsgTitle("베너리스트 수정");
                    setMessage("베너를 추가해주세요");
                    dispatch(MessageModalFlag(true));
                    dispatch(BannerListInsert(false));
                    return;
                }
            }
        }
    }, [Insert]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (insertFlag === true && MModal === false) {
            dispatch(Flag(false));
            navigate("/operation/bannerList");
        }
    }, [insertFlag, MModal]);// eslint-disable-line react-hooks/exhaustive-deps

    const UpdateaddInputImgList = (selectList) => {
        const from_date = selectList.from_dt;
        const from_yy = from_date.substr(0, 4);
        const from_mm = from_date.substr(5, 2);
        const from_dd = from_date.substr(8, 2);
        const from_time = from_date.substr(11, 5);

        const to_date = selectList.to_dt;
        const to_yy = to_date.substr(0, 4);
        const to_mm = to_date.substr(5, 2);
        const to_dd = to_date.substr(8, 2);
        const to_time = to_date.substr(11, 5);

        let event_date = selectList.event_dt;
        let event_yy = "";
        let event_mm = "";
        let event_dd = "";
        let event_time = "";
        if (event_date === "" || event_date === undefined) {
            UpdatenewArr.push({
                BANNER_IS_USE: selectList.is_use,
                BANNER_ORDER: selectList.b_order,
                BANNER_SEQ: selectList.b_seq,
                TO_DT: new Date(new Date(to_yy, to_mm - 1, to_dd)),
                FROM_DT: new Date(new Date(from_yy, from_mm - 1, from_dd)),
                EVENT_DT: "",
                eventTime: "",
                fromTime: from_time,
                toTime: to_time,
                BANNER_IMG: selectList.b_url + "_w.png"
            });
        } else {
            event_yy = event_date.substr(0, 4);
            event_mm = event_date.substr(5, 2);
            event_dd = event_date.substr(8, 2);
            event_time = event_date.substr(11, 5);
            UpdatenewArr.push({
                BANNER_IS_USE: selectList.is_use,
                BANNER_ORDER: selectList.b_order,
                BANNER_SEQ: selectList.b_seq,
                TO_DT: new Date(new Date(to_yy, to_mm - 1, to_dd)),
                FROM_DT: new Date(new Date(from_yy, from_mm - 1, from_dd)),
                EVENT_DT: new Date(new Date(event_yy, event_mm - 1, event_dd)),
                fromTime: from_time,
                eventTime: event_time,
                toTime: to_time,
                BANNER_IMG: selectList.b_url + "_w.png"
            });
        }
        setBannertList(UpdatenewArr);
    };

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <div
                style={{
                    width: "200px",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={onClick}
                ref={ref}
            >
                <div style={{ fontSize: "13px", color: "#000000" }}>{value}</div>
                <img
                    src={"/images/component/ic_calendar.png"}
                    style={{ width: "16.67px", height: "15px" }}
                    alt="calendar_icon"
                />
            </div>
        );
    });

    const addImgItem = (index, value, type) => {
        const newArr = bannertList.slice();
        if (type === "BANNER_IS_USE") {
            newArr[index].BANNER_IS_USE = value;
        } else if (type === "BANNER_SEQ") {
            const data = { params: { "b_seq": value } };
            getBanner(data)
                .then((response) => {
                    newArr[index].BANNER_IMG = response.data.data[0].b_url + "_w." + response.data.data[0].b_extension;
                    newArr[index].BANNER_SEQ = value;
                    handleSelectBox(value);
                })
        } else if (type === "TO_DT") {
            newArr[index].TO_DT = value;
        } else if (type === "FROM_DT") {
            newArr[index].FROM_DT = value;
        } else if (type === "toTime") {
            newArr[index].toTime = value;
        } else if (type === "fromTime") {
            newArr[index].fromTime = value;
        } else if (type === "EVENT_DT") {
            newArr[index].EVENT_DT = value;
        } else if (type === "eventTime") {
            newArr[index].eventTime = value;
        }
        setBannertList(newArr);
    };

    const addInputImgList = () => {
        const newArr = bannertList.slice();
        const data = { params: { "b_seq": "1" } };
        getBanner(data)
            .then((response) => {
                newArr.push({
                    BANNER_IS_USE: 1,
                    BANNER_SEQ: 1,
                    TO_DT: new Date(),
                    toTime: "23:59",
                    fromTime: "23:59",
                    EVENT_DT: "",
                    eventTime: "",
                    FROM_DT: new Date(new Date(nowYear, nowMonth - 1, nowDay)),
                    BANNER_IMG: response.data.data[0].b_url + "_w." + response.data.data[0].b_extension,
                });
                setBannertList(newArr);
            })
    };

    const clearInputImgList = (i) => {
        const newArr = bannertList.slice();
        newArr.splice(i, 1);
        setBannertList(newArr);
    };

    const handleSelectBox = (value) => {
        setSelectBlocation(value);
    }

    return (
        <RegistBodyC>
            <div style={{ width: '100%', height: '100%', maxHeight: '900px', minHeight: '500px', background: 'White', overflowY: 'scroll' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                        <Label type={`nomal`} required={true}>
                            베너 경로
                        </Label>
                    </div>
                    {listDetailType === 'Insert' &&
                        <div id="selectBox" style={{ margin: 'auto', width: "500px" }}>
                            <TextField
                                value={bannerLocation || ""}
                                width="45%"
                                placeholder={"BANNER_LOCATION"}
                                onChange={(e) => setBannerLocation(e.target.value)}
                            />
                        </div>
                    }
                    {listDetailType === 'Update' &&
                        <div id="selectBox" style={{ margin: 'auto', width: "500px" }}>
                            <TextField
                                value={bannerLocation || ""}
                                width="50%"
                                placeholder={"BANNER_LOCATION"}
                                disabled
                                onChange={(e) => setBannerLocation(e.target.value)}
                            />
                        </div>
                    }
                    <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                        <Label type={`nomal`} required={true}>
                            베너 타입
                        </Label>
                    </div>
                    <div style={{ width: "500px", margin: 'auto' }}>
                        {bannerType &&
                            <Form.Select className="selectBox" defaultValue={bannerType} onChange={(e) => setBannerType(e.target.value)}>
                                <option value={"0"}>롤링</option>
                                <option value={"1"}>리스트</option>
                                <option value={"2"}>웹소설</option>
                            </Form.Select>
                        }
                        {!bannerType &&
                            <Form.Select className="selectBox" onChange={(e) => setBannerType(e.target.value)}>
                                <option value={"0"}>롤링</option>
                                <option value={"1"}>리스트</option>
                                <option value={"2"}>웹소설</option>
                            </Form.Select>
                        }
                    </div>
                    <div style={{ margin: 'auto', width: "50px" }}>
                        <img onClick={addInputImgList} src={`/images/component/ic_plus.png`} style={{ width: '40px', height: '40px', margin: 'auto' }} alt={"이미지"}></img>
                    </div>
                </div>
                <div style={{ borderBottom: '1px solid' }}></div>
                {bannertList && bannertList.map((item, i) => (
                    <div key={i} style={{ height: '100%' }}>
                        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                            <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    배너 명{i + 1}
                                </Label>
                            </div>
                            <div id="selectBox" style={{ margin: 'auto', width: "500px" }}>
                                {(selectBox && selectBox.length > 0) ?
                                    <Form.Select className="selectBox" defaultValue={item.BANNER_SEQ} onChange={(e) => addImgItem(i, e.target.value, "BANNER_SEQ")}>
                                        {selectBox.map((selectitem, index) => {
                                            return <option key={index} value={selectitem.b_seq}>{selectitem.b_desc}</option>
                                        })}
                                    </Form.Select>
                                    :
                                    'Loding'
                                }
                            </div>
                            <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true} >
                                    베너 사용 여부{i + 1}
                                </Label>
                            </div>
                            <div id="selectBox" style={{ margin: 'auto', width: "500px" }}>
                                {(useSelectBox && useSelectBox.length > 0) ?
                                    <Form.Select className="selectBox" defaultValue={item.BANNER_IS_USE} onChange={(e) => addImgItem(i, e.target.value, "BANNER_IS_USE")}>
                                        {useSelectBox.map((selectitem, index) => {
                                            return <option key={index} value={selectitem.BANNER_IS_USE} >{selectitem.BANNER_IS_USE_NAME}</option>
                                        })}
                                    </Form.Select>
                                    :
                                    'Loding'
                                }
                            </div>
                            <div style={{ margin: 'auto', width: "50px" }}>
                                <img onClick={(e) => clearInputImgList(i)} src={`/images/component/ic_minus.png`} style={{ width: '40px', height: '40px', margin: 'auto' }} alt={"이미지"}></img>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '60px' }}>
                            <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    시작일{i + 1}
                                </Label>
                            </div>
                            <div style={{ margin: 'auto', width: "220px" }}>
                                <DatePicker
                                    customInput={<DatePickerInput />}
                                    selected={item.FROM_DT ? item.FROM_DT : ""}
                                    onChange={(e) => {
                                        addImgItem(i, e, "FROM_DT");
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div style={{ margin: 'auto', width: "220px" }}>
                                <input
                                    value={item.fromTime}
                                    type="time"
                                    min="00:00"
                                    max="23:59"
                                    style={{
                                        width: "150px",
                                        padding: "12px",
                                        height: "16px",
                                        border: "1px solid rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                    onChange={(e) => {
                                        addImgItem(i, e.target.value, "fromTime");
                                    }}
                                />
                            </div>
                            <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    종료일{i + 1}
                                </Label>
                            </div>
                            <div style={{ margin: 'auto', width: "220px" }}>
                                <DatePicker
                                    customInput={<DatePickerInput />}
                                    selected={item.TO_DT ? item.TO_DT : ""}
                                    onChange={(e) => {
                                        addImgItem(i, e, "TO_DT");
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div style={{ margin: 'auto', width: "220px" }}>
                                <input
                                    value={item.toTime}
                                    type="time"
                                    min="00:00"
                                    max="23:59"
                                    style={{
                                        width: "150px",
                                        padding: "12px",
                                        height: "16px",
                                        border: "1px solid rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                    onChange={(e) => {
                                        addImgItem(i, e.target.value, "toTime");
                                    }}
                                />
                            </div>
                            <div style={{ margin: 'auto', width: "50px" }}>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    이벤트 종료일{i + 1}
                                </Label>
                            </div>
                            <div style={{ margin: 'auto', width: "220px" }}>
                                <DatePicker
                                    customInput={<DatePickerInput />}
                                    selected={item.EVENT_DT ? item.EVENT_DT : ""}
                                    onChange={(e) => {
                                        addImgItem(i, e, "EVENT_DT");
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div style={{ margin: 'auto', width: "220px" }}>
                                <input
                                    value={item.eventTime}
                                    type="time"
                                    min="00:00"
                                    max="23:59"
                                    style={{
                                        width: "150px",
                                        padding: "12px",
                                        height: "16px",
                                        border: "1px solid rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                    onChange={(e) => {
                                        addImgItem(i, e.target.value, "eventTime");
                                    }}
                                />
                            </div>
                            <div style={{ width: "120px", margin: 'auto', textAlign: 'center' }}>
                                <Label type={`nomal`} required={true}>
                                    이미지 미리보기{i + 1}
                                </Label>
                            </div>
                            <div style={{ margin: 'auto', width: "500px" }}>
                                <img src={item.BANNER_IMG} style={{ width: '300px' }} alt='Web Img' />
                            </div>
                            <div style={{ margin: 'auto', width: "50px" }}>
                            </div>
                        </div>
                        <div style={{ borderBottom: '1px solid' }}></div>
                    </div>
                ))}
            </div>
            {MModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="check" title={msgTitle} message={message} />
                    </div>
                </div>
            }
            {LModal &&
                <div className='Message_Modal_Container'>
                    <div className='Message_Modal'>
                        <MessageModal type="loading" title={msgTitle} message={message} />
                    </div>
                </div>
            }
        </RegistBodyC>
    )
}

export default RegistBody;