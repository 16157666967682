import { BodyTitle, BodyTitleFont } from "../../style/layout";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button";
const Title = () => {
  const navigate = useNavigate();
  return (
    <BodyTitle>
      <BodyTitleFont>Banner 등록</BodyTitleFont>
      <Button name={`배너 등록`} width={'160px'} onClick={(e) => navigate('/operation/banner/add')} />
    </BodyTitle>
  );
};
export default Title;