import { instance } from "../index";

export const getPush = async (Type, pageRows, page_no, title, msg) => {

    let url = `/push`;
    if (Type === "1" || Type === 1) {
        url = `/push?pageRows=${pageRows}&pageNum=${page_no}`;
    }
    if (Type === "2" || Type === 2) {
        url = `/push?pageRows=${pageRows}&pageNum=${page_no}`;
        if (title !== "" && msg !== "") {
            url = `/push?pageRows=${pageRows}&pageNum=${page_no}&title=${title}&msg=${msg}`;
        } else if (title !== "") {
            url = `/push?pageRows=${pageRows}&pageNum=${page_no}&title=${title}`;
        } else if (msg !== "") {
            url = `/push?pageRows=${pageRows}&pageNum=${page_no}&msg=${msg}`;
        }
    }
    const response = await instance
        .get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
}

export const getDetailPush = async (pseq) => {
    const url = `/push?pSeq=${pseq}`;

    const response = await instance
        .get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
};

export const getPushUseData = async () => {
    const url = `/push/getRange`;
    const response = await instance
        .get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
};

export const getExcelData = async (fromDay, toDay, pushSendRange) => {
    const url = `/push/excel?fromDay=${fromDay}&toDay=${toDay}&pushSendRange=${pushSendRange}`;
    const response = await instance
        .get(url, null, null)
        .then((response) => {
            return response;
        })
    return response;
};



export const pushUpdate = async (post) => {
    const url = '/push';
    const response = await instance.post(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json multipart/form-data",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const pushInsert = async (post) => {
    const url = '/push';
    const response = await instance.put(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json multipart/form-data",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}

export const pushDelete = async (post) => {
    const url = '/push';
    const response = await instance.delete(
        url,
        post,
        {
            headers: {
                'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            }
        }
    )
        .then((response) => {
            return response;
        }).catch(error => {
            return error.response;
        })
    return response;
}