import { uploadinstance } from '../index';

export const createPost = (data) => uploadinstance.post(`/newsroom/api/v1/post`, data);
export const readPosts = () => uploadinstance.get(``);
export const updatePost = (data) => uploadinstance.put(`/newsroom/api/v1/post`, data);
export const deletePost = (pid) => uploadinstance.put(`/newsroom/api/v1/post/del/`, { pid });
export const readDetailPost = ({ pid, rid }) => uploadinstance.get(`/newsroom/api/v1/post/view/${pid}/${rid}`);
export const readDetailPost2 = (pid, rid) => uploadinstance.get(`/newsroom/api/v1/post/view/${pid}/${rid}`);
export const updatePostFactCheckApi = (data) => uploadinstance.post(`/newsroom/api/v1/post/factcheck`, data);
export const readPostLimit = ({ pid, rid, type, sort, size }) =>
    uploadinstance.get(`/newsroom/api/v1/reply/list/${pid}/${rid}/?orderType=${type}&orderSort=${sort}&size=${size}`);
export const readPostForRepost = ({ pid, myRid }) => uploadinstance.get(`/newsroom/api/v1/post/viewRepost/${pid}/${myRid}`);

export const readRepostLimit = ({ pid, rid, type, sort, page_no, size }) =>
    uploadinstance.get(
        `/newsroom/api/v1/post/repost/list/${pid}/${rid}/?orderType=${type}&orderSort=${sort}&page_no=${page_no}&size=${size}`
    );

export const insertReact = (body, pid, rid) => {
    uploadinstance
        .post(`/newsroom/api/v1/react`, body)
        .then((r) => { })
        .catch((err) => console.log(err));
};

export const readNidsNews = (nidsStr) => uploadinstance.get(`/service/api/v1/news/briefing-news?nids=${nidsStr}`);

export const getWriteRoomList = ({ rid }) => uploadinstance.get(`/newsroom/api/v1/post/newsroom-list?rid=${rid}`);

export const getSearchRoom = (realFrom_dt, realTo_dt, sort, searchKeyword) => uploadinstance.get(`/service/api/v1/newsroom?from_dt=${realFrom_dt}&to_dt=${realTo_dt}&page_no=${1}&size=${5}&sort=${sort}&keyword=${searchKeyword}`);



export const getSearchRoomMore = (from_dt, to_dt, page_no, sort, keyword) =>
    uploadinstance.get(`/service/api/v1/newsroom?from_dt=${from_dt.length > 8 ? from_dt : from_dt + '000000'}&to_dt=${to_dt}&page_no=${page_no}&size=${5}&sort=${sort}&keyword=${keyword}`);


export const getSearchNewsMore = (from_dt, to_dt, page_no, sort, search_opt, keyword) =>
    uploadinstance.get(`/service/api/v1/news?from_dt=${from_dt.length > 8 ? from_dt : from_dt + '000000'}&to_dt=${to_dt}&page_no=${page_no}&size=${5}&sort=${sort}&search_opt=${search_opt}&keyword=${keyword}`);


export const getSearchNews = (from_dt, to_dt, sort, search_opt, keyword) =>
    uploadinstance.get(`/service/api/v1/news?from_dt=${from_dt}&to_dt=${to_dt}&page_no=${1}&size=${5}&sort=${sort}&search_opt=${search_opt}&keyword=${keyword}`);

export const getSearchPostMore = (from_dt, to_dt, page_no, sort, keyword) =>
    uploadinstance.get(`/service/api/v1/newsroom/post?from_dt=${from_dt.length > 8 ? from_dt : from_dt + '000000'}&to_dt=${to_dt}&page_no=${page_no}&size=${5}&sort=${sort}&keyword=${keyword}`);


export const getSearchPost = (from_dt, to_dt, sort, keyword) =>
    uploadinstance.get(`/service/api/v1/newsroom/post?from_dt=${from_dt}&to_dt=${to_dt}&page_no=${1}&size=${5}&sort=${sort}&keyword=${keyword}`)