import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

const PagingWrap = styled.div`
    display:inline-block;
`;

const PagingC = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
`;

const PageNumber = styled.div`
    cursor: pointer;
    margin: 0px 4px;
    padding-bottom:4px;
    height: 20px;
    color:${(props) => props.selected === true ? '#ED5255' : '#A7A7A7'};
    border-bottom: 1px solid ${(props) => props.selected === true ? '#ED5255' : '#A7A7A7'};
`;

const Paging = ({ count, pageSize, viewSize, onClick }) => {

    const [leftArrow, setLeftArrow] = useState(false);
    const [selectNum, setSelectNum] = useState(1);
    const [showList, setShowList] = useState();
    const [arrowVal, setArrowVal] = useState(1);

    useEffect(() => {
        if (viewSize !== 1) {
        } else {
            setSelectNum(viewSize);
            setArrowVal(1);
        }
        let tempSize = parseInt((count / pageSize), 10);
        let tempSize2 = parseInt(count);
        var target = null;
        if (tempSize2.toString().substr(-1) !== "0") {
            tempSize = tempSize + 1;
        }
        if (Math.floor(tempSize / 10) * 10 + 1 > viewSize) {
            if (count * 1 < 101) {
                setLeftArrow(false);
            } else {
                setLeftArrow(true);
            }
            target = Array.from({ length: 10 }, (_, i) => i + arrowVal)
        } else {
            setLeftArrow(false);
            target = Array.from({ length: Math.ceil(count / pageSize).toString().substr(-1) }, (_, i) => i + arrowVal)
        }
        setShowList(target);
    }, [count, pageSize, viewSize])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let tempSize = parseInt((count / pageSize), 10);
        var target = null;
        let tempSize2 = parseInt(count);

        if (tempSize2.toString().substr(-1) !== "0") {
            tempSize = tempSize + 1;
        }
        if (Math.floor(tempSize / 10) * 10 + 1 > viewSize) {
            if (count * 1 < 101) {
                setLeftArrow(false);
            } else {
                setLeftArrow(true);
            }
            target = Array.from({ length: 10 }, (_, i) => i + arrowVal)
        } else {
            setLeftArrow(false);
            target = Array.from({ length: Math.ceil(count / pageSize).toString().substr(-1) }, (_, i) => i + arrowVal)
        }
        setShowList(target);
    }, [viewSize, arrowVal])// eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (num) => {
        setSelectNum(num);
        onClick && onClick(num);
    }

    const handleLeftArrowClick = () => {
        setSelectNum(arrowVal - 10);
        setArrowVal(arrowVal - 10);
        onClick && onClick(arrowVal - 10);
    }

    const handleArrowClick = () => {
        setSelectNum(arrowVal + 10);
        setArrowVal(arrowVal + 10);
        onClick && onClick(arrowVal + 10);
    }

    return (
        <PagingWrap>
            <PagingC>
                {(arrowVal > 2) &&
                    <PageNumber selected={false} onClick={handleLeftArrowClick}> &lt;&lt; </PageNumber>
                }
                {(showList && showList.length > 0) &&
                    showList.map((item, index) => {
                        return <PageNumber key={index} selected={selectNum === item} onClick={(e) => handleClick(item)}>{item}</PageNumber>
                    })
                }
                {(leftArrow === true) &&
                    <React.Fragment>
                        <PageNumber selected={false} onClick={handleArrowClick}> &gt;&gt; </PageNumber>
                    </React.Fragment>
                }
            </PagingC>
        </PagingWrap>
    )
}

export default Paging;