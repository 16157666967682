import React, { useEffect, useState } from "react";
import { TableHeader, TableRow, SearchBar } from "../../style/layout";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import Check from "../../common/Check";
import Paging from "../../common/Paging";
import { MessageModalFlag, LoadingModalFlag } from '../../slice/modalSlice';
import MessageModal from '../../modal/Message';
import { useDispatch, useSelector } from 'react-redux';
import TextField from "../../common/TextField";
import Form from 'react-bootstrap/Form';
import '../../style/common.css';
import OneDayCalendarPush from "../../common/OneDayCalendarPush";
import Label from "../../common/Label";
import moment from "moment";
import { getUserList, getUserList2, getUserCount, deleteUsers, recoveryUsers } from "../../api/member/member";


const Body = () => {

    const [membertList, setMemberList] = useState();
    const [searchKey, setSearchKey] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState('');

    const MModal = useSelector((state) => state.modal.value.Message);
    const LModal = useSelector((state) => state.modal.value.Loading);
    const navigate = useNavigate();
    const [size, setSize] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [realPage_no, setRealPage_no] = useState(1);
    const [totalCnt, setTotalCnt] = useState();
    const [deleteUid, setDeleteUid] = useState([]);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [startRegDt, setStartRegDt] = useState(moment().format("2021-01-01"));
    const [endRegDt, setEndRegDt] = useState(moment().format("YYYY-MM-DD"));
    const [startBirthday, setStartBirthday] = useState(moment().format("1900-01-01"));
    const [endBirthday, setEndBirthday] = useState(moment().format("YYYY-MM-DD"));
    const [accessTp, setAccessTp] = useState(null);
    const [gender, setGender] = useState(null);
    const [status, setStatus] = useState(null);
    //const [regDtCheck, setRegDtCheck] = useState(false);
    const [birthdayCheck, setBirthdayCheck] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        // setTitle("회원 정보 조회");
        // setMessage("회원 정보 조회중");
        // dispatch(LoadingModalFlag(true));
        let data = { size: size, pageNum: 1, accessTp: accessTp, gender: gender, status: status}
        if((searchKeyword ?? '').length > 0) {
            data.searchKey = searchKey;
            data.searchKeyword = searchKeyword
        }
        if(birthdayCheck) {
            data.startBirthday = startBirthday;
            data.endBirthday = endBirthday;
        }
        if(true) { //if(regDtCheck) {
            data.startBirthday = startBirthday;
            data.endBirthday = endBirthday;
        }

        setTotalCnt(null);

        getUserListCall()
        .then((response) => {
            // dispatch(LoadingModalFlag(false));
            //setTotalCnt(response.data.page.totalCount);
            setMemberList(response.data.userList);
            const checkboxes = document.getElementsByName('selectCheckBox');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            })
            setDeleteUid([]);
            return getUserListCountCall()
        }).then((response) => {
            setTotalCnt(response.data.page.totalCount);
        }).catch((error) => {
            console.error(error)
            setTotalCnt('ERROR')
            //dispatch(LoadingModalFlag(false));
            setTitle("회원 정보 조회 실패");
            setMessage("일시적인 오류 혹은 서버 장애 : 개발팀에 문의해주세요");
            dispatch(MessageModalFlag(true));
        }).finally(() => setIsLoading(false));

    }, [size]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // setTitle("회원 정보 조회");
        // setMessage("회원 정보 조회중");
        // dispatch(LoadingModalFlag(true));
        if(page_no === null) { return }
        if(page_no === realPage_no) { return }


        // setTotalCnt(null);

        if(isLoading) { return }
        setIsLoading(true)
        getUserListCall({ pageNum: page_no })
        .then((response) => {
            // dispatch(LoadingModalFlag(false));
            //setTotalCnt(response.data.page.totalCount);
            setMemberList(response.data.userList);
            setRealPage_no(page_no)
            const checkboxes = document.getElementsByName('selectCheckBox');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            })
            setDeleteUid([]);
            // 1페이지에서만 userCount 호출 (페이징 속도 향상)            
            return page_no > 1 ? Promise.resolve(null) : getUserListCountCall()
        }).then((response) => {
            if(response === null) { return; }
            setTotalCnt(response.data.page.totalCount);
        }).catch((error) => {
            console.error(error)
            setTotalCnt('ERROR')
            //dispatch(LoadingModalFlag(false));
            setTitle("회원 정보 조회 실패");
            setMessage("일시적인 오류 혹은 서버 장애 : 개발팀에 문의해주세요");
            dispatch(MessageModalFlag(true));
        }).finally(() => setIsLoading(false));
    }, [page_no]);// eslint-disable-line react-hooks/exhaustive-deps

    const dictKorToCss = (keyword) => {
        // 키값없이 한글만 넘어오는데에 따른 역변환
        const map = {
            '탈퇴': 'withdrawal',
            '탈퇴 대기': 'w4withdrawal',
        }
        return map[keyword] ?? '';
    }

    const _makeData = (more) => {
        let data = { size: size, pageNum: page_no, accessTp: accessTp, gender: gender, status: status }
        if((searchKeyword ?? '').trim().length > 0) {
            data.searchKey = searchKey;
            data.searchKeyword = searchKeyword
        }
        if(birthdayCheck) {
            data.startBirthday = startBirthday;
            data.endBirthday = endBirthday;
        }
        if(true) {
            data.startRegDt = startRegDt;
            data.endRegDt = endRegDt;
        }
        const params = Object.assign({}, data, more ?? {})
        return { params: params }
    }
    window.xx=_makeData
    const getUserListCall = async (obj) => {
        setIsLoading(true)
        return await getUserList2(_makeData(obj))
    }
    const getUserListCountCall = async (obj) => {
        setIsLoading(false) // not good
        return await getUserCount(_makeData(obj))
    }


    // 리덕스 추가 예정 
    const handlePageClick = (pageNum) => {
        setPage_no(pageNum)
        //setRealPage_no(pageNum);  // 로딩 후 바뀌게 변경
    }

    const checkhandle = (e) => {
        const newArr = deleteUid.slice();
        for (const index in deleteUid) {
            if (`${deleteUid[index].uidList}` === e) {
                newArr.splice(index, 1);
                setDeleteUid(newArr);
                return;
            }
        }
        newArr.push({
            uid: e,
        });
        setDeleteUid(newArr);
    }

    const deletehandle = () => {
        setTitle("회원 삭제");
        setMessage("삭제중");
        dispatch(LoadingModalFlag(true));
        const selector = JSON.stringify(deleteUid);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"uid":', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll('"', '');
        const deleteData6 = "," + deleteData5;
        const deleteData7 = deleteData6.substr(1)
        if (deleteData5 === "") {
            dispatch(LoadingModalFlag(false));
            setTitle("회원 삭제");
            setMessage("삭제할 계정을 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData8 = { params: { "uidList": deleteData7 } };
        let listData = {}
        deleteUsers(deleteData8)
        .then((response) => {
            if(response.status===200 || response.status===201){
                let data = { size: size, pageNum: page_no, accessTp: accessTp, gender: gender, status: status}
                if((searchKeyword ?? '').length > 0) {
                    data.searchKey = searchKey;
                    data.searchKeyword = searchKeyword
                }
                if(birthdayCheck) {
                    data.startBirthday = startBirthday;
                    data.endBirthday = endBirthday;
                }
                if(true) { //if(regDtCheck) {
                    data.startBirthday = startBirthday;
                    data.endBirthday = endBirthday;
                }
        
                listData = data
                dispatch(LoadingModalFlag(false));
                setTitle("회원 탈퇴");
                setMessage("탈퇴 완료");
                dispatch(MessageModalFlag(true));
                setTotalCnt(totalCnt - 1)
                return getUserListCall(null)
            }else{
                return Promise.reject(null)
            }
        })
        .then((response) => {
            if(response === null) { return Promise.reject(null); }
            setMemberList(response.data.userList);
            const checkboxes = document.getElementsByName('selectCheckBox');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            })
            setDeleteUid([]);
            return getUserListCountCall()
        }).then((response) => {
            setTotalCnt(response.data.page.totalCount)
        }).catch((error) => {
            console.error(error)
            //setTotalCnt('ERROR')
            dispatch(LoadingModalFlag(false));
            setTitle("회원 탈퇴 처리 실패");
            setMessage("페이지를 새로 고침 후 다시 시도해주세요");
            dispatch(MessageModalFlag(true));
        }).finally(() => setIsLoading(false));

    }

    const rehandle = () => {
        setTitle("회원 복구");
        setMessage("복구중");
        dispatch(LoadingModalFlag(true));
        const selector = JSON.stringify(deleteUid);
        const deleteData = selector.replaceAll('[', '');
        const deleteData2 = deleteData.replaceAll('{"uid":', '');
        const deleteData3 = deleteData2.replaceAll('}', '');
        const deleteData4 = deleteData3.replaceAll(']', '');
        const deleteData5 = deleteData4.replaceAll('"', '');
        const deleteData6 = "," + deleteData5;
        const deleteData7 = deleteData6.substr(1)
        if (deleteData5 === "") {
            setTitle("회원 복구");
            setMessage("복구할 계정을 선택해주세요");
            dispatch(MessageModalFlag(true));
            return;
        }
        const deleteData8 = { "uidList": deleteData7 };
        let listData = {}
        recoveryUsers(deleteData8)
        .then((response) => {
            if(response.status===200 || response.status===201){
                let data = { size: size, pageNum: page_no, accessTp: accessTp, gender: gender, status: status}
                if((searchKeyword ?? '').length > 0) {
                    data.searchKey = searchKey;
                    data.searchKeyword = searchKeyword
                }
                if(birthdayCheck) {
                    data.startBirthday = startBirthday;
                    data.endBirthday = endBirthday;
                }
                if(true) { //if(regDtCheck) {
                    data.startBirthday = startBirthday;
                    data.endBirthday = endBirthday;
                }
                listData = data
                dispatch(LoadingModalFlag(false));
                setTitle("회원 복구");
                setMessage("복구 완료");
                dispatch(MessageModalFlag(true));
                setTotalCnt(totalCnt + 1)
                return getUserListCall(null)
            }else{
                return Promise.reject(null)
            }
        })
        .then((response) => {
            if(response === null) { return Promise.reject(null); }
            setMemberList(response.data.userList);
            const checkboxes = document.getElementsByName('selectCheckBox');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            })
            setDeleteUid([]);
            return getUserListCountCall()
        }).then((response) => {
            setTotalCnt(response.data.page.totalCount)
        }).catch((error) => {
            console.error(error)
            //setTotalCnt('ERROR')
            dispatch(LoadingModalFlag(false));
            setTitle("회원 복구 처리 실패");
            setMessage("페이지를 새로 고침 후 다시 시도해주세요")
            dispatch(MessageModalFlag(true));
        }).finally(() => setIsLoading(false));
    }

    const enterkey = (e) => {
        if (window.event.keyCode !== 13) { return }
        if(isLoading) { return }
        search(e)
    }

    const search = (e) => {
        // setTitle("회원 정보 조회");
        // setMessage("회원 정보 조회중");
        // dispatch(LoadingModalFlag(true));
        setPage_no(1);
        setRealPage_no(1);
        setTotalCnt(null)
        if(isLoading) { return }
        setIsLoading(true)
        getUserListCall({pageNum:1})
        .then((response) => {
            // dispatch(LoadingModalFlag(false));
            //setTotalCnt(response.data.page.totalCount);
            setMemberList(response.data.userList);
            const checkboxes = document.getElementsByName('selectCheckBox');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            })
            setDeleteUid([]);
            return getUserListCountCall() 
        }).then((response) => {
            setTotalCnt(response.data.page.totalCount);
        }).catch((error) => {
            console.error(error)
            setTotalCnt('ERROR')
            //dispatch(LoadingModalFlag(false));
            setTitle("회원 정보 조회 실패");
            setMessage("일시적인 오류 혹은 서버 장애 : 개발팀에 문의해주세요");
            dispatch(MessageModalFlag(true));
        }).finally(() => setIsLoading(false));

    }

    const toggleBirthdayCheck = (value) => {
        setBirthdayCheck(!value)
    }


    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '20px' }}></div>
                <SearchBar>
                    <div className='filter'>
                        <div style={{ borderLeft: '2px solid #ED5255', paddingLeft: '8px' }}>전체 리스트
                            {totalCnt !== null
                            ? (<React.Fragment>
                                    (<span style={{ color: '#ED5255' }}>{totalCnt?.toLocaleString()}</span>명)
                                </React.Fragment>
                                ):(
                                <React.Fragment>
                                    (<span style={{ color: '#909090' }}>로딩중</span>)
                                </React.Fragment>
                                )
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Select onChange={(e) => setSearchKey(e.target.value)} className="selectBox">
                            <option value={1}>아이디</option>
                            </Form.Select>&nbsp;
                            <TextField placeholder={`검색어를 입력해주세요`} onChange={(e) => setSearchKeyword(e.target.value)}
                                onKeydown={enterkey} />&nbsp;
                            <Form.Select onChange={(e) => setSize(e.target.value)} className="selectBox">
                                <option value={10}>10개씩보기</option>
                                <option value={20}>20개씩보기</option>
                                <option value={30}>30개씩보기</option>
                            </Form.Select>&nbsp;
                            <Button cType='line' name='검색' width='65px' onClick={search} />&nbsp;
                            <Button cType='b_line' name='회원 복구' width='130px' onClick={rehandle} />
                            <Button cType='b_line' name='회원 탈퇴' width='130px' onClick={deletehandle} />
                        </div>
                    </div>
                    <div className='title'>상세필터</div>
                    <div className='component'>
                        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <div style={{ width: '120px' }}><Label type={`nomal`} required={true}>회원유형</Label></div>
                            <div style={{ width: '300px' }}>
                                <Form.Select onChange={(e) => setAccessTp(e.target.value)} className="memberSelectBox">
                                    <option value={''}>전체</option>
                                    <option value={'G'}>구글</option>
                                    <option value={'a'}>애플</option>
                                    <option value={'F'}>페이스북</option>
                                    <option value={'N'}>네이버</option>
                                    <option value={'K'}>카카오</option>
                                    <option value={'T'}>트위터</option>
                                    <option value={'Z'}>일반회원가입</option>
                                </Form.Select>&nbsp;
                            </div>
                            <div style={{ width: '120px' }}><Label type={`nomal`} required={true}>성별</Label></div>
                            <div style={{ width: '300px' }}>
                                <Form.Select onChange={(e) => setGender(e.target.value)} className="memberSelectBox">
                                    <option value={''}>전체</option>
                                    <option value={'1'}>남자</option>
                                    <option value={'0'}>여자</option>
                                </Form.Select>&nbsp;
                            </div>
                            <div style={{ width: '120px' }}><Label type={`nomal`} required={true}>계정상태</Label></div>
                            <div style={{ width: '300px' }}>
                                <Form.Select onChange={(e) => setStatus(e.target.value)} className="memberSelectBox">
                                    <option value={''}>전체</option>
                                    <option value={'O'}>정상</option>
                                    <option value={'W'}>탈퇴</option>
                                    <option value={'D'}>탈퇴대기</option>
                                </Form.Select>&nbsp;
                            </div>
                        </div>
                    </div>
                    <div className='component'>
                        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <div style={{ width: '150px' }}><Label type={`nomal`} required={true}>회원가입일자</Label></div>
                            <div style={{ width: '330px', display: 'flex' }}>
                                <OneDayCalendarPush setDate={setStartRegDt} splashDate={startRegDt} />&nbsp;
                                <OneDayCalendarPush setDate={setEndRegDt} splashDate={endRegDt} />
                            </div>
                            <div style={{ width: '120px' }}><Label type={`nomal`} required={true}>생일</Label></div>
                            <div style={{ width: '350px', display: 'flex' }}>
                                <OneDayCalendarPush setDate={setStartBirthday} splashDate={startBirthday} />&nbsp;
                                <OneDayCalendarPush setDate={setEndBirthday} splashDate={endBirthday} />
                            </div>
                            <div style={{ width: '140px' }}><Label type={`nomal`} required={true}>생일 참조 여부</Label></div>
                            <Check onChange={(e) => toggleBirthdayCheck(birthdayCheck)}></Check>
                        </div>
                    </div>
                </SearchBar>
                {(membertList && membertList.length > 0) &&
                    <React.Fragment>
                        <TableHeader>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '90px' }}>uid</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>회원유형</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '60px' }}>계정상태</div>
                            <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>아이디</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}>생일</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}>성별</div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}>가입일</div>
                        </TableHeader>
                        <div style={{ flexGrow: 1, overflow: 'auto'}} className={`member-cell ${(isLoading ? ' loading':'')}`}>
                            <div className={'loading-dim'}>
                            <img src={'/images/common/favicon192.png'} alt='loading...' className={'loading-img'} />
                            </div>
                            {membertList.map((item, index) => {
                                return (
                                    <TableRow key={index} style={{ height: '42px' }} className={ dictKorToCss(item.accessTpStatus) }>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }} onChange={(e) => checkhandle(item.uid)}>
                                            {item.accessTpStatus !== '탈퇴' &&
                                                <Check name='selectCheckBox' />
                                            }
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '90px' }}
                                            onClick={(e) => navigate(`/member/management/view/${item.uid}`)}>{item.uid}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}
                                            onClick={(e) => navigate(`/member/management/view/${item.uid}`)}>{item.accessTp}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '60px' }}
                                            onClick={(e) => navigate(`/member/management/view/${item.uid}`)}>{item.accessTpStatus}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
                                            onClick={(e) => navigate(`/member/management/view/${item.uid}`)}>{item.userId}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '120px' }}
                                            onClick={(e) => navigate(`/member/management/view/${item.uid}`)}>{item.birthday}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '80px' }}
                                            onClick={(e) => navigate(`/member/management/view/${item.uid}`)}>{item.gender}</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '160px' }}
                                            onClick={(e) => navigate(`/member/management/view/${item.uid}`)}>{item.regDt}</div>
                                    </TableRow>
                                )
                            })
                            }
                        </div>
                        <Paging count={totalCnt !== null ? totalCnt : size*(realPage_no + 10)} pageSize={size} viewSize={4} onClick={handlePageClick} />
                    </React.Fragment>
                }
                {MModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="check" title={title} message={message} />
                        </div>
                    </div>
                }
                {LModal &&
                    <div className='Message_Modal_Container'>
                        <div className='Message_Modal'>
                            <MessageModal type="loading" title="회원 관리" message={message} />
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Body;