import { instance, uploadinstance } from "..";

export const getNoticeList = async (page, size) => {
	let url = `/center/getNoticeList?page=${page - 1}&size=${size}`;
	const response = await instance.get(url, null, null).then((response) => {
		return response;
	});
	return response;
};

export const getNoticeInfoBynotice_id = async (notice_id) => {
	const url = `/center/getNoticeDetail?notice_id=${notice_id}`;
	const response = await instance.get(url, null, null).then((response) => {
		return response;
	});
	return response;
};

export const getNoticeRegist = async (post) => {
	const url = `/center/insertNotice`;
	const response = await instance
		.put(url, post, null)
		.then((response) => {
			return response;
		})
		.catch((e) => {
		});
	return response;
};

export const getNoticeModify = async (post) => {
	const url = `/center/putNotice`;
	const response = await instance
		.put(url, post, null)
		.then((response) => {
			return response;
		})
		.catch((e) => {
		});
	return response;
};

export const getUploadImageReturnUrl = async (file) => {
	const url = `/store/image/upload`;
	const formData = new FormData();
	formData.append("file", file);
	const response = await uploadinstance
		.post(url, formData, null)
		.then((response) => {
			return response;
		});
	return response;
};
